@import '_seriph/variables';

#sequence-data {
  width: 100%;
  padding: 24px 32px 32px 32px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    > a,
    > div {
      display: inline-flex;
    }
    > a {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #707b90;
      &:hover {
        text-decoration: underline;
        color: #090e15;
      }
    }
    > div {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
    }
    > .sep {
      position: relative;
      top: -1px;
      svg {
        width: 16px;
        height: 16px;
        margin: 0 8px;
        color: #707b90;
      }
    }
  }
  .data-header {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .left {
      max-width: 600px;
      > h1 {
        font-weight: 600;
        margin: 0;
      }
      > p {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin: 16px 0 0 0;
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-left: 12px;
      }
      > div {
        width: 1px;
        height: 30px;
        background: #c4c4c4;
        margin: 0 12px 0 24px;
      }
    }
  }
  .data-subheader {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #e7ecf4;
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      > .intro {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin-right: 12px;
      }
      .ant-select {
        width: 300px;
      }
    }
    .right {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      flex: 1;
      text-align: right;
      b {
        font-weight: 500;
      }
    }
  }
}
