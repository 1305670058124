@import '_seriph/variables';

#onboard-create {
  width: 100%;
  .already {
    width: 100%;
    text-align: center;
    margin-top: 24px;
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707B90;
    a {
      font-family: $font-header;
      font-style: italic;
      color: #000;
      text-decoration: underline;
      font-weight: 500;
    }
  }
  .hide-show {
    position: relative;
    .tog {
      width: 60px;
      position: absolute;
      right: 16px;
      top: 44px;
      cursor: pointer;
      color: #707B90;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      user-select: none !important;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        color: #707B90;
      }
      &:hover {
        color: #505B70;
        svg {
          color: #505B70;
        }
      }
    }
  }
}