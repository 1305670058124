@import '_seriph/variables';

.seriph-menu.ant-menu {
  border-right: 0;
  background: transparent;
  padding: 0 16px;
  .ant-menu-item {
    color: #0b1e41;
    font-weight: 500;
    border-radius: 12px;
    height: 46px;
    line-height: 46px;
    padding: 0 16px;
    margin-bottom: 0 !important;
    &.ant-menu-item-active {
      background: #e7ecf4;
    }
    &.ant-menu-item-selected {
      background: #090e15;
    }
  }

  .ant-menu-item,
  .ant-menu-item a {
    display: flex;
    flex-direction: row;
    align-items: center;
    > svg {
      margin-right: 16px;
    }
    transition: none !important;
    color: #090e15;
  }

  .ant-menu-item-selected,
  .ant-menu-item-selected a {
    color: #fff !important;
  }

  .ant-menu-item-divider {
    background: #e7ecf4;
    margin: 8px 16px;
  }

  &.menu-light {
    .ant-menu-item {
      &.ant-menu-item-selected {
        background: #e7ecf4;
      }
    }
    .ant-menu-item-selected,
    .ant-menu-item-selected a {
      color: #090e15 !important;
    }
  }
  .ant-menu-item.ant-menu-item-disabled,
  .ant-menu-item.ant-menu-submenu-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background: none !important;
    border-color: transparent !important;
    cursor: not-allowed !important;
  }
}
