@import '_seriph/variables';

.agent-tile {
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;

  .agent-tile-top {
    display: flex;
    justify-content: space-between;
  }
  .agent-tile-bottom {
    h4 {
      font-family: $font-major;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin-bottom: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .joined-info {
      font-family: $font-major;
      font-weight: 500;
      color: #707b90;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .agent-tile-invite-buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    button {
      flex: 1;
    }
  }
}
