@import '_seriph/variables';

.campaign-overview-modal {
  .ant-modal {
    height: calc(100% - 80px);
  }
  .ant-modal-body {
    min-height: 400px;
    height: 100%;
  }
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }
  .overview {
    position: relative;
    height: 100%;
    .campaign-content {
      height: calc(100% - 112px);
      margin: 32px 0;
      max-height: 100%;
      overflow: auto;
      width: 100%;
      z-index: 9;
      transition: all 0.2s ease;
      &.scrolled {
        height: calc(100% - 96px);
        top: 96px;
      }
    }
  }
  .overview-container {
    margin: 0 -6px;
  }
  .overview-icon {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #e7ecf4;
    border-radius: 12px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 0 6px 12px 6px;
    width: calc(50% - 12px);
    max-width: calc(50% - 12px);
    .info {
      flex: 1;
      min-width: 0;
      h4 {
        display: block;
        font-family: $font-major;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin: 0 0 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
        max-width: 100%;
        text-transform: capitalize;
      }
      p {
        font-family: $font-major;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        margin: 0;
        padding: 0;
      }
    }
    .icn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      background: #d2d0fb;
      border-radius: 20px;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      svg {
        color: #090e15;
        width: 16px;
        height: 16px;
      }
    }
    &.customer-profile .icn {
      background: #090e15;
      svg {
        color: #fff;
      }
    }
  }
}
