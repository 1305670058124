@import '_seriph/variables';

#touchpoint-overview {
  .over-title {
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #090e15;
    border-bottom: 1px solid #e7ecf4;
  }
  .over-content {
    display: flex;
    flex-direction: row;
    .over-left {
      width: 212px;
      margin-right: 32px;
      padding: 32px 0 32px 32px;
      .info-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin-bottom: 16px;
        > div {
          width: 28px;
          height: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
          margin-right: 12px;
          background: #f5f7fb;
          svg {
            color: #090e15;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .over-right {
      flex: 1;
      .quill {
        .ql-toolbar {
          display: none;
        }
        .ql-container {
          border: 0;
        }
        .ql-editor {
          padding-left: 0;
        }
      }
    }
  }
  .seriph-modal-actions {
    border-top: 1px solid #e7ecf4;
    .left {
      float: left;
    }
  }
}
