@import '_seriph/variables';

#company-qualify-charts {
  flex: 1;
  display: flex;
  flex-direction: row;
  &.new-chart {
    padding: 0 !important;
  }
  > .three-chart {
    flex: 1;
    margin-right: 24px;
    padding: 0 !important;
    &:last-child {
      margin-right: 0;
    }
    h4 {
      padding: 0 24px;
      margin-bottom: 6px;
    }
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      color: #090e15;
      text-align: left;
      font-family: $font-major;
      padding: 0 24px;
      margin-bottom: 16px;
    }
  }
  .spacer {
    width: 1px;
    background: #e6e9ef;
    align-items: stretch;
    margin: 32px 0;
  }
  .pie-chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .pie-stats {
      margin-left: 45px;
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #0b1e41;
        white-space: nowrap;
        margin-bottom: 4px;
        > span {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: grey;
          border-radius: 6px;
          margin-right: 8px;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        color: #0b1e41;
        padding: 0;
      }
    }
  }
}
