@import '_seriph/variables';

.company-subscribe-modal {
  .ant-modal-body {
    padding: 48px 40px !important;
  }

  .subscribe-form {
    display: flex;
    flex-direction: row;
  }
  .payment-method {
    flex: 1;
    margin-right: 64px;
    h2 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #090E15;
      margin: 0 0 24px 0;
    }
  }
  .payment-breakdown {
    width: 340px;
  }
  .discount {
    margin: 24px 0;
    .seriph-radio-group {
      width: 100%;
      .ant-radio-button-wrapper {
        width: 33.33%;
      }
      .ant-radio-button-wrapper {
        font-family: $font-major;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  .itemize {
    display: flex;
    flex-direction: row;
    align-items: center;
    .itemize-info {
      flex: 1;
      h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090E15;
        margin: 0;
      }
      h3 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090E15;
        margin-bottom: 0;
      }
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #090E15;
        margin: 0;
      }
    }
    .itemize-icon {
      > span {
        width: 16px;
        height: 16px;
        background: #E7ECF4;
        border: 1px solid #C4CBD9;
        border-radius: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 12px;
          height: 12px;
        }
        &.good {
          background: #EEF9EC;
          border: 1px solid #C1DDBB;
        }
      }
    }
    .itemize-num {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090E15;
      > span {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090E15;
        margin: 0 12px;
      }
      .btn-circle {
        width: 24px;
        height: 24px;
        min-height: 24px;
        min-width: 24px;
        border-radius: 12px;
      }
    }
    &.savings {
      margin-top: 16px;
    }
    &.dis {
      margin-top: 4px;
    }
    &.total {
      border-top: 1px solid #E7ECF4;
      padding-top: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
      align-items: flex-start;
      h3, .itemize-num {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090E15;
        margin: 0;
      }
    }
    h3 > span {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: line-through;
      color: #707B90;
      float: right;
      position: relative;
      top: 3px;
      left: -8px;
    }
  }
  .notice {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    p {
      font-size: 12px;
      line-height: 18px;
      color: #4d5b76;
      margin: 0;
    }
  }
}
