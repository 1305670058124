@import '_seriph/variables';

.preview-sequence-modal {
  .ant-modal-body {
    min-height: 200px;
    padding: 0 !important;
    position: relative;
    overflow: hidden;
  }
  .seriph-modal-close {
    z-index: 9999;
  }
  .seriph-modal-actions {
    padding: 24px 32px;
    .left {
      float: left;
    }
  }

  .prev-wrap {
    display: flex;
    flex-direction: column;
    .prev-header {
      padding: 24px 32px;
      border-bottom: 1px solid #e7ecf4;
      .stats {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin-top: 12px;
        b {
          font-weight: 600;
        }
      }
    }
    .prev-touchpoints {
      max-height: 420px;
      overflow: auto;
      border-bottom: 1px solid #e7ecf4;
      padding: 32px;
      > .day {
        border-bottom: 1px solid #e7ecf4;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
    .day {
      .day-title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0;
        }
        p {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #707b90;
          margin: 0;
          margin-left: 8px;
        }
      }
    }
    .touchpoints > .touchpoint {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .info {
        flex: 1;
      }
      .type {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        margin-right: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
