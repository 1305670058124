@import 'variables';

.sx-quill {
  .quill {
    .ql-toolbar {
      border-color: #e7ecf4;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 20px 16px;
      button {
        padding: 3px 8px;
        width: 34px;
      }
    }
    .ql-container {
      border-color: #e7ecf4;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .ql-editor {
        padding: 12px 16px;
        height: 300px;
        overflow: auto;
        &.ql-blank::before {
          font-style: normal !important;
          pointer-events: none;
          position: absolute;
          font-size: 14px;
          line-height: 21px;
          color: #707b90;
        }
        p,
        li {
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
        }
        strong {
          font-weight: 600;
        }
        h1 {
          font-family: $font-tt;
          font-weight: 600;
          font-size: 32px;
          line-height: 40px;
          color: #0b1e41;
          margin-bottom: 8px;
          u {
            text-decoration: none !important;
          }
        }
        h2 {
          font-family: $font-tt;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #0b1e41;
          margin-bottom: 8px;
        }
      }
    }
  }
}
