@import 'variables';

.invite-team-member-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    position: relative;
    padding: 32px;
  }
  .itm-title {
    h3 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #090e15;
      margin: 0 0 12px 0;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      color: #242d3e;
    }
  }
  .role-info {
    margin: 0;
    display: block;
    span {
      margin: 0;
      display: block;
    }
  }
  .itm-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 32px;
    .field-role {
      width: 150px !important;
      .seriph-select .ant-select {
          width: 100%;
      }
    }
    .field-email {
      flex: 1;
      margin-right: 12px;
    }
  }
  .itm-actions {
    text-align: right;
    position: relative;

  }
}
