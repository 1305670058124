#leads-import {
  .ltbl {
    border-radius: 5px;
    border: 1px solid #eaedf3;
    .ltbl-header {
      background: #f7f8fa;
      font-weight: 500;
      color: #0b1e41;
    }
    .ltbl-row {
      color: #000000;
    }
    .ltbl-body {
      height: 200px;
      overflow: scroll;
    }
    .ltbl-header,
    .ltbl-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      border-bottom: 1px solid #eaedf3;
      > div {
        width: 33.33333%;
        word-wrap: break-word;
      }
    }
  }
  .ant-upload-drag {
    background: #f7f8fa;
    border: 1px dashed #bbc3d1;
    box-sizing: border-box;
    border-radius: 8px;
    height: 180px;
    .ant-upload-text {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #000000;
    }
  }

  .import-list {
    margin-bottom: 16px;
  }

  .import-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    .select-campaign,
    .select-csv {
      flex: 1;
    }
    .or-import {
      padding: 0 32px;
    }
    .select-campaign {
      background: #f7f8fa;
      border: 1px dashed #bbc3d1;
      box-sizing: border-box;
      border-radius: 8px;
      text-align: center;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ant-select {
        display: block;
        width: calc(100% - 32px);
      }
      h5 {
        margin-bottom: 12px;
      }
      button {
        margin-top: 16px;
        width: 120px;
      }
    }
  }
}
