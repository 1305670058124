@import '_seriph/variables';

#lead-texts {
  position: relative;
  min-height: 100%;
  .text-input {
    border-bottom: 1px solid #e7ecf4;
    padding-bottom: 16px;
    position: relative;

    textarea {
      padding-right: 160px;
    }
    .send-info {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;
      justify-content: flex-end;
      .ant-select {
        width: 220px !important;
      }
      button {
        margin-left: 16px;
      }
    }
  }
  .text-box {
    margin-bottom: 20px;
    text-align: left;
    &.out {
      text-align: right;
    }
    .speech-bubble {
      position: relative;
      display: inline-block;
      background: rgb(24,115,245);
      color: #fff;
      border-radius: 20px;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 21px;
    }
    &.in .speech-bubble {
      background: rgb(226,225,232);
      color: #090e15;
    }
    &.out .speech-bubble:after {
      content: '';
      position: absolute;
      bottom: 6px;
      right: 5px;
      width: 0;
      height: 0;
      border: 13px solid transparent;
      border-top-color: rgb(24,115,245);
      border-bottom: 0;
      border-right: 0;
      margin-left: -6.5px;
      margin-bottom: -13px;
    }
    &.in .speech-bubble:after {
      content: '';
      position: absolute;
      bottom: 7px;
      left: 10px;
      width: 0;
      height: 0;
      border: 13px solid transparent;
      border-top-color: rgb(226,225,232);
      border-bottom: 0;
      border-left: 0;
      margin-left: -6.5px;
      margin-bottom: -13px;
    }
    .text-info {
      font-size: 12px;
      margin-right: 16px;
      margin-top: 4px;
    }
    &.in .text-info {
      margin-left: 16px;
      margin-right: 0;
    }
  }
  .sxd-alert {
    margin-top: 32px;
  }
  .note-content {
    .note-date {
      text-align: center;
      border-bottom: 1px solid #e7ecf4;
      margin-bottom: 32px;
      position: relative;
      > span {
        background: #f5f7fb;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        position: relative;
        top: 12px;
        padding: 0 16px;
      }
    }
  }
  .lead-note {
    margin-bottom: 16px;
    padding: 24px;
    .note-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      .note-user {
        flex: 1;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
      }
      > span {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        color: #090e15;
      }
    }
    .note-body {
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        white-space: pre-line;
        margin: 0;
      }
    }
  }
}
