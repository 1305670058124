@import '_seriph/variables';

#onboarding.verify {
  width: 100%;
  height: 100%;
  .sxd-card {
    padding: 32px 48px;
  }
  .seriph-header {
    margin-bottom: 32px;
  }
  .logout-btn {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 999999;
  }
  .user-card {
    text-align: center;
    margin-bottom: 32px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    border-radius: 60px;
    background: #27292a;
    > div {
      display: inline-block;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #fff;
      white-space: nowrap;
      &.uc-name {
        padding: 0 10px 0 0;
      }
      &.uc-email {
        padding-left: 10px;
      }
    }
  }
  .resend-btn {
    margin-left: 12px;
  }
  .verify-box {
    margin-bottom: 48px;
  }
  .verify-box > p {
    color: #fff;
  }
  .resend-link {
    font-weight: 500;
    color: #fff;
    margin-top: 24px;
  }
  .resend-link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  input {
    background: #27292a;
    border: 1px solid #27292a;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  .code {
    margin-top: 16px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > input {
      margin-right: 8px;
      text-align: center;
      border-radius: 12px;
      font-size: 44px;
      font-weight: 600;
      color: #fff;
      height: 88px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
}
