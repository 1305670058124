@import '_seriph/variables';

#company-agent-discover {
  width: 100%;
  padding: 32px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      margin-left: 12px;
    }
  }
  .discover-search {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    .discover-left {
      width: 280px;
    }
    .discover-right {
      position: relative;
      flex: 1;
      padding-left: 30px;
    }
  }
  &.trial {
    overflow: hidden !important;
    .discover-search, .header-actions, .discover-right, #company-discover-recommended {
      user-select: none !important;
      overflow: hidden !important;
    }
  }
}

#on-trial {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(10px);
  z-index: 99;
  .popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 456px;
    background: #FFFFFF;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    text-align: center;
    padding: 40px;
    .icon {
      width: 40px;
      height: 40px;
      background: #E7ECF4;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
    }
    .in-touch {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #090E15;
      margin-bottom: 24px;
    }
  }
}
