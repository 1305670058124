@import '_seriph/variables';

#rep-analytics-scorecard {
  min-width: 100%;
  padding: 32px 40px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .seriph-header-wrapper {
    margin-bottom: 24px;
  }
  .meeting-own {
    display: flex;
    flex-direction: row;
    align-items: center;
    .seriph-avatar {
      margin-right: 12px;
    }
    .campaign-info {
      margin: 0;
      span {
        display: block;
        font-weight: 500;
        white-space: nowrap;
        font-size: 13px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }
      p {
        margin: 0;
        color: #555555;
        white-space: nowrap;
        font-size: 12px;
      }
    }
  }
  .trigger-section {
    position: relative;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e4e7fa;
    .seriph-header {
      margin-bottom: 16px;
      line-height: 40px;
    }
    .trigger-filter {
      position: absolute;
      right: 0;
      top: 0;
      .ant-select {
        width: 280px;
      }
    }
  }
}
