#sellx-verify {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #fff;
  .ready {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    .spinning {
      font-size: 60px;
      color: #e0e2e4;
    }
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
      }
      h1 {
        margin: 0 0 0 15px;
        color: #000;
        height: 48px;
        line-height: 48px;
        font-size: 36px;
        font-weight: 600;
      }
    }
  }
  .forgot-body {
    margin-top: 120px;
    max-width: 600px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    svg {
      color: #30f09e;
      margin-right: 12px;
    }
    input {
      background: #f7f8fa;
      border-radius: 8px;
      border: 1px solid transparent;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      height: 80px;
      padding: 24px;
      &:hover,
      &:focus {
        box-shadow: none;
        border: 1px solid #4353ff;
      }
    }
    button {
      margin-top: 30px;
    }
    p {
      margin-top: 30px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #4d5b76;
    }
    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 36px;
      text-align: center;
      color: #0b1e41;
      margin: 0;
    }
    h5 {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      margin-top: 10px;
      text-align: center;
      color: #4d5b76;
      span {
        text-decoration: underline;
      }
    }
  }
}
