@import '_seriph/variables';

.skill-edit {
  .antd-auto-list {
    margin-top: 0;
  }
}

#agent-skills-edit {
  .inline-actions {
    margin-top: 16px;
    text-align: right;
    button {
      margin-left: 8px;
    }
  }
  .antd-auto-tag {
    display: inline-block;
    border: 2px solid #d7dde9;
    border-radius: 30px;
    font-family: $font-major;
    background-color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #090e15;
    padding: 3px 12px;
    margin-top: 8px;
    margin-right: 8px;
    svg {
      margin-left: 6px;
      position: relative;
      top: 1px;
      cursor: pointer;
    }
  }
}

#agent-profile-skills {
  margin-top: 16px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin: 0 0 16px 0;
  }
  .prof-tag {
    background: #fff;
    border-radius: 30px;
    border: 2px solid #d7dde9;
    padding: 3px 12px;
    font-family: $font-major;
    margin-bottom: 8px;
    margin-right: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #090e15;
    display: inline-block;
    cursor: pointer;
    &:hover {
      background: #d7dde9;
    }
  }
}
