.step-list {
  width: 280px;
  min-width: 280px;
  position: absolute;
  padding: 32px 0 32px 32px;
  .ant-menu-item.invalid {
    color: #ff3768 !important;
  }

  .ant-menu-item-selected.invalid {
    border-left-color: #ff3768 !important;
  }
}
