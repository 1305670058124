@import '_seriph/variables';

#agent-settings-banking {
  position: relative;
  width: 100%;
  padding: 0;
  height: 260px;
  min-height: 260px;
  .settings-card {
    height: 100%;
    padding: 24px !important;
  }
  .pm-header,
  .seriph-alert {
    margin-bottom: 24px;
  }
  .manage-box {
    border-top: 1px solid #e6e9ef;
    padding-top: 24px;
    .manage-account {
      margin-right: 15px;
    }
    button {
      margin-right: 12px;
    }
  }
  .setup-button {
    text-align: center;
    .add-account {
      margin: 0 auto;
      margin-top: 30px;
    }
  }
  .ant-empty {
    margin-top: 30px;
  }
  .add-bank {
    text-align: center;
    padding: 30px 0 50px 0;
    button {
      cursor: pointer !important;
      padding: 16px 24px !important;
      height: 56px !important;
    }
  }
  .sources {
    > .source {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px 0;
      svg {
        color: #090e15;
        width: 48px;
        height: 48px;
        stroke-width: 2;
      }
      .source-name {
        margin-left: 16px;
        h3 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0;
        }
        > div {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
        }
      }
    }
  }
  .none {
    padding: 30px;
    text-align: center;
    font-style: italic;
  }
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    border: 1px solid #e6e9ef;
    border-radius: 4px;
    background: white;
  }

  .cancel-add {
    margin-right: 15px;
  }

  .StripeElement--focus {
    border: 1px solid #4353ff;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
  }
  .history {
    .charge {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #e6e9ef;
      &:last-child {
        border-bottom: 0;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 8px;
        color: #000000;
        font-weight: 400;
        flex: 1;
        &:first-child {
          flex: 2;
          justify-content: flex-start;
          padding: 16px 0px;
        }
        &:last-child {
          justify-content: flex-end;
          padding: 16px 0px;
        }
        svg {
          margin-right: 5px;
          position: relative;
          top: -2px;
        }
      }
    }
  }
}
