@import '_seriph/variables';
.apply-commitment {
  > h3 {
    font-family: $font-major;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    text-align: center;
    margin: 40px 0;
  }
  > p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #4d5b76;
    margin: 0 0 32px 0;
  }
  .options {
    margin-bottom: 24px;
  }
  .seriph-selection {
    > div {
      padding: 32px 12px;
    }
  }
}
