@import '_seriph/variables';

.hubspot-settings-modal {
  .chili-logo {
    width: 48px;
    height: 48px;
    margin-right: 24px;
    position: relative;
    top: -2px;
  }
  .breaker {
    padding-top: 32px;
    border-top: 1px dotted #E7ECF4;
    color: #090E15;
    font-weight: 400;
  }
  .settings-header {
    font-weight: 600;
  }
  .chili-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 -8px 40px -8px;
    > div {
      flex: 1;
      margin: 0 8px;
    }
  }
  .descript {
    margin-top: 32px;
    padding-bottom: 8px;
    .descript-title {
      border-bottom: 1px solid #E7ECF4;
      padding-bottom: 12px;
      margin-bottom: 16px;
    }
    > span {
      margin-top: 16px;
      display: block;
      b {
        display: block;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: #090E15;
        margin-bottom: 8px;
      }
    }
  }
  .settings {
    padding-left: 0px;
    .seriph-header {
      margin-top: 12px;
    }
  }
  .options-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 -16px;
    margin-bottom: 32px;
    > div {
      flex: 1;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}