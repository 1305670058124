@import '_seriph/variables';

.selected-call {
  .sxd-link {
    svg {
      margin-left: 6px;
      position: relative;
      top: -1px;
    }
  }
  .player {
    display: block;
    flex: 1;
    max-width: 320px;
  }
  .call-which {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .wave {
    margin-top: 24px;
    content: '';
    clear: both;
    height: 272px;
    .wave-holder {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 48px);
      align-items: stretch;
    }
    .wave-container {
      min-width: 0;
      flex: 1;
      position: relative;
      .wave-scroll {
        position: relative;
        overflow-x: auto;
        overflow-y: visible;
      }
      .waveform-div {
        position: relative;
        height: 200px;
        min-height: 200px;
        display: inline-block;
        #arrow {
          position: absolute;
          width: 13px;
          height: 10px;
          z-index: 9;
          top: 0;
          path {
            fill: #090e15;
          }
        }
        .tick {
          position: absolute;
          height: 200px;
          width: 1px;
          background: #e6e9ef;
          span {
            position: absolute;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            height: 20px;
            top: 0;
            background: #fff;
            color: #77839b;
          }
        }
      }
      #bg-one {
        background: #f7f8fa;
        height: 16px;
        width: 100%;
        top: 42px;
        position: absolute;
        left: 0;
      }
      #bg-two {
        background: #f7f8fa;
        height: 16px;
        width: 100%;
        top: 142px;
        position: absolute;
        left: 0;
      }
      .controls {
        height: 72px;
        padding: 16px 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: row;
        position: relative;
        button {
          margin-right: 8px;
        }
        .right-controls {
          flex: 1;
          text-align: right;
        }
        .play {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          background: #090e15;
          position: relative;
          margin-right: 16px;
          cursor: pointer;
          display: inline-block;
          &:hover {
            background: #242d3e;
          }
          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 12px;
          }
        }
        .current {
          margin-left: 8px;
          span {
            font-family: $font-major;
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
          }
        }
      }
    }
    .callers {
      padding-right: 48px;
      padding-bottom: 72px;
      .seriph-avatar img {
        margin-right: 0;
      }
    }
    .callers > div {
      height: 100px;
      display: flex;
      align-items: center;
      flex-direction: row;
      .caller-info {
        white-space: nowrap;
        flex: 1;
        margin-left: 12px;
        h4 {
          font-family: $font-major;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0;
          white-space: nowrap;
        }
        h5 {
          font-weight: 400;
          font-family: $font-major;
          font-size: 12px;
          line-height: 18px;
          margin: 0;
          color: #707b90;
          white-space: nowrap;
        }
      }
    }
  }
  .transcript {
    margin: 16px -24px -16px -24px;
  }
  .ant-table-wrapper {
    .ant-table-thead th {
      background: #f7f8fa;
      padding-top: 16px;
      padding-bottom: 14px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #77839b;
      border-bottom: 0;
      box-shadow: inset 0px -1px 0px #e7ecf4;
    }
    .ant-table-thead {
      box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
    }
    .ant-empty {
      display: none;
    }
    .ant-table-body {
      td {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
      }
    }
    .progress-table {
      display: flex;
      flex-direction: row;
      align-items: center;
      > svg {
        margin-left: 8px;
        position: relative;
        top: 2px;
        cursor: pointer;
      }
    }
    tr:hover {
      cursor: pointer;
    }
    tr:hover > td {
      background: transparent;
    }
    tr.row-agent > td {
      background: #f7f8fa;
    }
    .code {
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
    }
    tr > td {
      -moz-transition: none !important;
      -webkit-transition: none !important;
      -o-transition: color 0 ease-in !important;
      transition: none !important;
    }
    tr.row-active {
      > td {
        background: #090e15 !important;
        &:hover {
          background: #090e15 !important;
        }
        color: #fff !important;
        h4,
        .code {
          color: #fff !important;
        }
      }
    }
  }

  .spk-tbl {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .ball {
      margin-right: 8px;
      position: relative;
      top: 6px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      &.agent-ball {
        background: #00d5f2;
      }
      &.lead-ball {
        background: #7bea69;
      }
    }
    h4 {
      font-family: $font-major;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0;
      white-space: nowrap;
    }
    h5 {
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      color: #77839b;
      white-space: nowrap;
    }
  }
  .rhap_container {
    box-shadow: none;
    background: transparent;
    outline: none;
    .rhap_progress-container,
    .rhap_button-clear {
      outline: none;
    }
    .rhap_controls-section {
      flex: 0;
    }
    .rhap_time {
      font-family: $font-major;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
    }
    .rhap_progress-bar {
      background: #f0f3f7 !important;
      border-radius: 8px;
      height: 8px;
    }
    .rhap_download-progress {
      background: #f0f3f7 !important;
      border-radius: 8px;
    }
    .rhap_progress-filled {
      background: #090e15;
      border-radius: 8px;
    }
    .rhap_progress-indicator {
      display: none !important;
    }
    .rhap_main-controls-button {
      color: #090e15 !important;
      width: 40px;
      height: 40px;
      &:hover {
        color: #242d3e;
      }
      svg {
        width: 40px;
        height: 40px;
        font-size: 40px;
      }
    }
  }
}
