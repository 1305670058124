@import '_seriph/variables';

.sequence-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 16px;
  margin-bottom: 1px;
  .step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    background: #e7ecf4;
    border-radius: 16px;
    svg {
      width: 16px;
      height: 16px;
      border-width: 2px;
      color: #090e15;
    }
    &.completed {
      background: #090e15;
      svg {
        color: #fff;
      }
    }
    &.call {
      background: #c1ddbb;
    }
    &.email {
      background: #d2d0fb;
    }
    &.linkedin {
      background: #b0d0e8;
    }
    &.instructions {
      background: #cbc4b7;
    }
  }
  > h4 {
    flex: 1;
    font-family: $font-major;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #090e15 !important;
    margin: 0;
  }
  .completed {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #929aaa;
  }
  .actions {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-btn[disabled] {
      opacity: 0.6;
    }
    .seriph-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 8px;
        width: 18px;
        height: 18px;
      }
      span {
        position: relative;
        top: 1px;
      }
    }
  }
  &.completed {
    > h4 {
      text-decoration-line: line-through !important;
      color: #707b90 !important;
    }
  }
  &.todo {
    > h4 {
      color: #707b90 !important;
    }
    > svg {
      rect {
        fill: #e7ecf4 !important;
      }
      path {
        fill: #adb5c5 !important;
      }
    }
  }
  .btn-plain {
    margin-right: 12px;
  }
}

.steps > .sequence-step {
  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.tt-step-skip {
  span {
    text-transform: capitalize;
    margin-right: 8px;
  }
}
