@import '_seriph/variables';

#messaging-conversation {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f7fb;
  #no-convos {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f7f8fa;
    .sx-empty {
      padding-top: 0;
    }
  }
  .conversation-title {
    border-bottom: 1px solid #e7ecf4;
    padding: 24px 32px 24px 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      line-height: normal !important;
    }
    .title-info {
      flex: 1;
      padding-left: 4px;
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
        margin: 0;
      }
    }
  }
  .conversation {
    width: 100%;
    overflow-y: scroll;
    word-break: break-word;
    flex: 1;
    background: #f7f8fa;
    padding: 24px 24px 60px 32px;
    .conversation-container {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    .message {
      display: flex;
      flex-direction: row;
      position: relative;
      margin-bottom: 32px;
      line-height: normal;
      .message-content {
        flex: 1;
        margin-left: 24px;
        .message-info {
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #707b90;
          margin: 8px 0 0 0;
        }
        .message-body {
          align-items: flex-start;
          display: inline-block;
          margin: 0;
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          white-space: pre-line;
          width: auto;
          text-align: left !important;
          > p {
            color: #090e15;
            margin-bottom: 0;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .tstamp {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: #4d5b76;
        margin-left: 10px;
      }
    }
  }
  &.focused .conversation {
    padding-bottom: 111px !important;
  }
  .conversation-input {
    bottom: 16px;
    padding: 0;
    position: absolute;
    width: 100%;
    max-width: 640px;
    left: 50%;
    transform: translateX(-50%);
    padding: 24px 16px 0 24px;
    .input-content {
      position: relative;
      background: #e7ecf4;
      border-radius: 10px;
      padding: 0;
      border: 1px solid #e7ecf4;
      height: 48px;
      overflow: hidden;
      &:hover {
        border: 1px solid #c4cbd9;
      }
      &.focused {
        border: 1px solid #c4cbd9;
        background: #fff;
        height: auto !important;
      }
      transition: all 0.2s linear;
      .quill {
        display: flex;
        flex-direction: column-reverse;
        .ql-editor {
          padding: 12px 16px 8px 16px;
        }
        .ql-toolbar {
          line-height: normal;
          border: 0;
          padding: 16px 12px;
        }
        .ql-container {
          border: 0;
        }
        .ql-editor {
          p,
          i,
          u,
          b,
          strong,
          a {
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
          }
        }
        .ql-editor.ql-blank::before {
          color: #77839b;
          font-style: normal;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .seriph-btn {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }
}
