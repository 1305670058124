@import '_seriph/variables';

#position-chart {
  width: 100%;
  .recharts-xAxis .recharts-cartesian-axis-ticks {
    transform: translateY(12px);
  }
  .recharts-yAxis .recharts-cartesian-axis-ticks {
    transform: translateX(-12px);
  }
  .recharts-cartesian-axis-tick-value {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #090e15 !important;
    fill: #090e15 !important;
  }
}
