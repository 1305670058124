@import 'variables';

#company-discover-search {
  #search-input {
    padding: 0;
    input {
      font-size: 14px;
      color: #000;
      padding-left: 15px;
      outline: 0;
      box-shadow: none;
      border-color: transparent;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
      &:hover {
        outline: 0;
        box-shadow: none;
      }
    }
  }
  img.rep-img {
    width: 28px !important;
    height: 28px !important;
    margin-left: 8px;
  }
  #search-filters {
    margin-top: 16px;
    padding: 8px;
  }
  .sxd-card {
    overflow: visible;
  }
  .filter-location {
    div {
      font-weight: 500;
      color: #090e15;
      span {
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: #999;
        }
      }
    }
    h5 {
      font-weight: 400;
      color: #888;
      margin: 5px 0 0 0;
      font-style: italic;
    }
  }
  .reputation-panel label.ant-checkbox-wrapper img {
    height: 20px;
    width: 20px;
    margin-left: 8px;
  }
}
