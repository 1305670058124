@import '_seriph/variables';

#sequence-days {
  margin-top: 32px;
  .s-day {
    margin-bottom: 40px;
    > h3 {
      margin-bottom: 8px;
    }
  }
  .archive-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    h4 {
      margin: 0;
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      margin-left: 8px;
      position: relative;
      top: 1px;
    }
  }
  .touches .archived {
    overflow: hidden;
    transition: max-height 0.2s linear;
    > .touch {
      background: #fafbfd;
    }
  }
  .touches {
    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    > .touch,
    .archived > .touch {
      padding: 20px 24px;
      border-bottom: 1px solid #e7ecf4;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 1;
      .type {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        margin-right: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .info {
        min-width: 0;
        overflow: hidden;
        flex: 1;
        padding-right: 24px;
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0 0 2px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 1px;
          > span {
            color: #909bb0;
          }
        }
        p {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 1px;
          max-width: 500px;
        }
      }
      .t-stat {
        padding: 0 24px;
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0 0 2px 0;
          text-align: right;
        }
        p {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
          margin: 0;
          white-space: nowrap;
          text-align: right;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }

    &.none > div:nth-last-child(2) {
      border-bottom: 0 !important;
    }
  }
}
