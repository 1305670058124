#agent-profile-accomplishments {
  margin-top: 16px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin: 0 0 16px 0;
  }
  p {
    margin: 0;
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
    &:before {
      content: '•';
      position: absolute;
      left: 0px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .edit {
    display: none;
    position: absolute;
    right: 0;
    top: -6px;
    margin-left: 20px;
    svg {
      margin-right: 4px;
      font-size: 11px;
    }
  }
  .inline-item {
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    button {
      top: 0 !important;
      right: 0 !important;
      font-size: 11px;
      line-height: 16px;
      padding: 4px 8px;
      height: 24px;
      border-radius: 8px;
      svg {
        width: 11px;
        height: 11px;
        margin-right: 6px;
      }
    }
  }
  .inline-item:hover .edit {
    display: block;
  }
}

.agent-accomplishment-edit {
  .sx-form {
    padding-top: 32px;
  }
  .field {
    margin-bottom: 16px;
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      display: block;
    }
  }
}
