#date-range {
  position: relative;
  button {
    margin-left: 12px;
  }
  > svg {
    color: #0b1e41;
    font-size: 16px;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
