@import 'variables';

.campaign-launch-modal {
  .ant-modal-body {
    padding: 40px;
  }
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }
  .sxd-guide {
    margin-bottom: 0 !important;
  }
  #payment-method {
    min-height: 200px !important;
    margin-bottom: 32px !important;
  }
  .launch-content {
    display: flex;
    flex-direction: row;
    .payments {
      flex: 1;
      margin-right: 64px;
      > h5 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
        margin: 0 0 8px 0;
      }
      > h2 {
        font-family: $font-tt;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #090e15;
        margin: 0 0 48px 0;
      }
    }
    .reviewing {
      flex: 1;
      text-align: center;
      .icon-part > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        background: #ffffff;
        border: 1px solid #e7ecf4;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
        border-radius: 100px;
        svg {
          font-size: 18px;
          color: #090e15;
        }
        margin-bottom: 24px;
      }
      > p {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #4d5b76;
        margin: 0 0 8px 0;
      }
      > h3 {
        text-align: center;
        font-family: $font-tt;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #090e15;
        margin: 0 0 12px 0;
      }
      button {
        margin: 32px auto 0 auto;
      }
    }
    .summary {
      width: 276px;
      > h3 {
        font-family: $font-tt;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #090e15;
        margin: 0 0 4px 0;
      }
      > p {
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
        padding: 0 0 16px 0;
        margin: 0;
        border-bottom: 1px solid #e7ecf4;
      }
      .notice {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        border-top: 1px solid #e7ecf4;
        border-bottom: 1px solid #e7ecf4;
        svg {
          margin-right: 12px;
          color: #4d5b76;
          position: relative;
          top: 2px;
        }
        p {
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
          margin: 0;
        }
        &.no-bottom {
          border-bottom: 0;
          padding-bottom: 8px;
        }
      }
      .link-notice {
        text-align: center;
        margin-bottom: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid #e7ecf4;
      }
      .total {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 20px;
        > h4 {
          font-family: $font-tt;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0;
        }
        > div {
          flex: 1;
          text-align: right;
          font-family: $font-tt;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #4353ff;
        }
      }
      .line-items {
        margin-top: 16px;
        > .item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 16px;
          .info {
            flex: 1;
            h4 {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #090e15;
              margin: 0 0 2px 0;
            }
            p {
              font-size: 12px;
              line-height: 18px;
              color: #707b90;
              margin: 0;
            }
          }
          .value {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: right;
            color: #090e15;
            width: 100px;
          }
        }
      }
    }
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;

    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    &:hover {
      background: #e5e7eb;
    }
  }
  .close-icon {
    right: 24px;
  }
}
