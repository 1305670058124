@import '_seriph/variables';

.message-reps-modal {
  .ant-select-selection-selected-value,
  .ant-select-dropdown-menu-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    > span {
      font-weight: 400;
      font-size: 14px;
      color: #090e15;
      margin-left: 8px;
      b {
        font-family: $font-major;
        font-weight: 400;
        display: inline-block;
        margin-right: 8px;
        font-style: normal;
      }
    }
  }
  .ant-select-dropdown-menu-item-selected > span {
    color: #fff;
  }
  .seriph-select .ant-select {
    width: 70%;
  }
  .seriph-textarea {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .mo-actions {
    margin-top: 30px;
    text-align: right;
    button {
      margin-left: 12px;
    }
  }
}
