@import '_seriph/variables';

#business,
#agent {
  #company-leads {
    width: calc(100% - 70px);
  }
}

#company-leads {
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 100%;

  .leads-list {
    z-index: 11;
    width: 344px;
    min-width: 344px;
    height: 100%;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: relative;
    transition: width 0.2s ease;

    .list-heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 8px 16px;

      .list-heading-left {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        height: 40px;
        color: #000000;
        font-family: $font-major;
        display: flex;
        align-items: center;
        cursor: pointer;

        .list-heading-title {
          width: 232px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .list-heading-icon {
          margin-left: 8px;
          svg {
            height: 14px;
            width: 14px;
          }
          cursor: pointer;
        }
      }

      .list-heading-right {
        display: flex;
        align-items: center;
        .list-header-search {
          svg {
            width: 15px;
            color: #090e15;
          }
          cursor: pointer;
        }
        .add-lead {
          margin-left: 16px;
          background-color: #e7ecf4;
          border-color: #e7ecf4;
        }
      }
    }

    .list-search {
      &.hide {
        display: none;
      }
      padding: 0;
      position: relative;
      flex: 5;
      button {
        svg {
          width: 15px;
        }
        position: absolute;
        right: 12px;
        top: 8px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        padding: 0;
        border-width: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      input {
        padding: 0 16px;
        font-size: 14px;
        height: 40px;
        line-height: 12px;
        font-weight: 400;
        box-shadow: none;
        border-radius: 8px;
        color: #090e15;
        margin-right: 16px;
        border: 1px solid #c4cbd9;
        background: #ffffff;
        &::placeholder {
          color: #090e15;
        }
      }
    }
    .filters {
      padding: 0 16px 8px 16px;
      .seriph-btn {
        font-weight: 500;
        font-family: $font-major;
        border-radius: 43px;
        margin-right: 6px;
        font-size: 12px;
        line-height: 18px;
        &.btn-primary {
          &:focus {
            background: #242d3e;
            border: 1px solid #242d3e;
          }
        }
        &.btn-default {
          &:focus {
            background: #ffffff;
            border: 1px solid #d7dde9;
          }
        }
      }
      .filters-top {
        .seriph-btn {
          height: 32px;
          padding: 5px 13px;
        }
      }
      .filters-bottom {
        margin-top: 9px;
        .seriph-btn {
          height: 24px;
          padding: 3px 12px 2px;
          svg {
            width: 12px;
          }
        }
      }
    }
    .list-scroll {
      flex: 1;
      overflow: auto;
      padding: 0;




      &:hover {
        &::-webkit-scrollbar {
          width: auto;
        }
      }

      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(215, 221, 233);
        border-style: none;
        border-radius: 20px;
      }

      .circle {
        display: inline-block;
        justify-content: center;
        align-items: center;
        background: transparent;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 4px solid #abd4a2;
        position: relative;
        margin: 0;
        margin-right: 8px;
        top: 1px;
        &.in-progress {
          border-color: $in-progress;
        }
        &.completed {
          border-color: $completed;
        }
        &.call-scheduled {
          border-color: $completed;
        }
        &.review {
          border-color: $review;
        }
        &.not-interested {
          border-color: $not-interested;
        }
        &.invalid {
          border-color: $not-interested;
        }
        &.improper {
          border-color: $not-interested;
        }
        &.disputed {
          border-color: $disputed;
        }
        &.paused {
          border-color: #fff1bf;
        }
        &.unassigned {
          border-color: $unassigned;
        }
      }
      .ant-collapse-item.inactive > .ant-collapse-header {
        display: none !important;
      }
      .ant-collapse-item.active > .ant-collapse-header {
        position: fixed;
        top: 136px;
        width: 344px;
        z-index: 9999;
        &:before {
          position: absolute;
          top: -10px;
          left: 0;
          display: block;
          content: '';
          clear: both;
          width: 320px;
          height: 10px;
          background: transparent;
        }
      }

      .ant-collapse-item.nil {
        opacity: 0.65 !important;
        cursor: not-allowed !important;
        pointer-events: none !important;
        .ant-collapse-arrow, .ant-collapse-content {
          display: none !important;
        }
        .ant-collapse-header {
          cursor: not-allowed !important;
          pointer-events: none !important;
          border: 0 !important;
        }
      }

      .ant-collapse {
        background: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
        border-image-width: 0;
        transition: none !important;
        .ant-collapse-item {
          border-bottom: 0;
          border-radius: 0;
          transition: none !important;
          .ant-collapse-header {
            background: transparent;
            font-family: $font-major;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
            border-radius: 0;
            border: 0;
            padding: 16px 16px 16px 44px;
            transition: none !important;
            border-bottom: 1px solid transparent;
            .ant-collapse-arrow {
              color: #adb5c5;
              left: 14px;
              transition: none !important;
              margin-top: -1px;
            }
            .ant-collapse-extra {
              font-family: $font-major;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 21px;
              color: #707b90;
            }
          }

          &:hover {
            .ant-collapse-header {
              background: #f5f7fb;
              border-bottom: 1px solid transparent;
              .ant-collapse-arrow {
                color: #090e15;
              }
            }
          }
          &.ant-collapse-item-active {
            .ant-collapse-header {
              background: transparent;
              border-bottom: 1px solid #e7ecf4;
              .ant-collapse-arrow {
                color: #090e15;
              }
            }
          }
          .ant-collapse-content {
            border-top: 0;
            .ant-collapse-content-box {
              padding: 0 12px;
            }
          }
        }
      }

      .lh-overview {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        background: #f0f3f7;
        border-radius: 12px;
        margin: 0 16px 8px 16px;
        cursor: pointer;
        &:hover {
          background: #e7ecf4;
        }
        .info {
          flex: 1;
          font-family: $font-major;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0 12px;
        }
        .lh-icons > div {
          width: 24px;
          height: 24px;
          line-height: 24px;
          background: #000;
          border-radius: 8px;
          position: relative;
          border: 0px solid #ffffff;
          display: inline-block;
          margin: 0;
          vertical-align: top;
          &:not(:first-child) {
            margin-left: -8px;
          }
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            fill: #fff;
            font-size: 12px;
            height: 12px;
            width: 12px;
            path {
              color: #fff;
              fill: #fff;
            }
          }
          &.dark svg {
            color: #090e15;
            fill: #090e15;
            path {
              color: #090e15;
              fill: #090e15;
            }
          }
        }
      }
    }
    .inf {
      text-align: center;
      margin: 10px 0 20px 0;
    }
    .list-stats {
      border-top: 1px solid #e6e9ef;
      height: 30px;
      line-height: 30px;
      color: #77839b;
      text-align: left;
      padding: 0 24px;
      font-size: 12px;
      span {
        float: right;
      }
    }
  }

  .leads-user {
    position: relative;
    flex: 1;
    .ant-empty {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .infinite-scroll-component > .lead-card {
    &:first-child {
      margin-top: 4px;
    }
  }
  .lead-skeleton {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
    padding: 16px 14px;
    .ant-skeleton-paragraph {
      margin-bottom: 0;
    }
    > label {
      margin-right: 24px;
    }
  }

  #bottom-scroll {
    height: 1px;
    width: 100%;
    display: block;
  }

  .tt-btn > button,
  button.tt-btn {
    font-weight: 600;
    border: 1px solid;
    &:hover {
      opacity: 0.8;
    }
    background-color: #4353ff;
    color: #fff;
    border-color: #4353ff;
    height: 30px;
    font-size: 13px;
    line-height: 14px;
    padding: 0 12px;
  }

  .campaign-list-dropdown {
    position: absolute;
    width: 322px;
    min-height: 200px;
    background: #fff;
    border: 1px solid #e7ecf4;
    z-index: 9999;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    top: 60px;
    left: 10px;
    padding: 12px;
    font-family: $font-major;

    .campaign-list-header {
      background: #e7ecf4;
      border-radius: 4px;
      padding: 4px 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      color: #090e15;
    }
    .campaign-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 8px;
      font-family: $font-major;
      color: #090e15;

      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      img.av-pic {
        height: 28px;
        width: 28px;
      }
      cursor: pointer;
    }
  }

  .collapse-campaign-list {
    border: 0;

    .campaign-list-header {
      background: none;
      padding: 0;
      border: 0;

      .ant-collapse-header {
        background: #e7ecf4;
        border-radius: 4px;
        padding: 4px 12px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
      }
    }
    .ant-collapse-content {
      border: 0;
    }
    .ant-collapse-content-box {
      padding: 0;
    }
    &.ant-collapse-icon-position-end {
      .ant-collapse-arrow {
        position: absolute;
        top: 50%;
        right: 16px;
        left: auto;
        margin: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.search-dropdown {
  position: absolute;
  width: 352px;
  min-height: 200px;
  background: #fff;
  border: 1px solid #e7ecf4;
  z-index: 9999;
  box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
  border-radius: 12px;
  top: 64px;
  left: 12px;
  padding: 12px;
  font-family: $font-major;

  .search-header {
    background: #e7ecf4;
    border-radius: 4px;
    padding: 4px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: #090e15;
  }
  .search-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    font-family: $font-major;
    color: #090e15;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    img.av-pic {
      height: 28px;
      width: 28px;
    }
    cursor: pointer;
  }
}

.menu-tt.lead-tt {
  .ant-tooltip-content {
    margin-left: 0 !important;
  }
  .ant-tooltip-arrow {
    left: 3px !important;
  }
}

#company-leads .back-to-highlights {
  position: fixed;
  z-index: 11;
  bottom: -40px;
  left: 94px;
  width: 298px;
  transition: bottom 0.2s ease;
  &.active {
    bottom: 24px;
  }
  svg {
    margin-right: 8px;
  }
}

#company-leads .reset-filters {
  position: fixed;
  z-index: 11;
  bottom: -40px;
  left: 94px;
  width: 298px;
  transition: bottom 0.2s ease;
  &.active {
    bottom: 24px;
  }
  svg {
    color: #090e15;
    path {
      fill: #090e15;
    }
    margin-right: 8px;
  }
}

#company-leads .list-toggle {
  position: absolute;
  z-index: 11;
  top: 62px;
  right: -12px;
  background: #fff;
  width: 24px;
  height: 24px;
  display: none !important;
  line-height: 24px;
  font-size: 12px;
  color: #000;
  text-align: center;
  border-radius: 12px;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  cursor: pointer;
  display: none;
  &:hover {
    background: #4353ff;
    color: #fff;
  }
}

#company-leads.leads-full {
  .list-toggle {
    svg {
      position: relative;
      left: -1px;
    }
  }
  .leads-list {
    width: 375px;
    min-width: 375px;
  }
  .leads-list:hover .list-toggle {
    display: block;
  }
}

#company-leads.leads-small {
  .list-toggle {
    display: block;
    svg {
      position: relative;
      right: -1px;
    }
  }
  .leads-list {
    width: 24px;
    min-width: 24px;
    .list-header {
      display: none;
    }
    #scrollableDiv {
      display: none;
    }
  }
  #lead-filter,
  #filter-veil {
    display: none;
  }
}

.slide-down-custom-enter {
  transform: translateY(-80px);
}
.slide-down-custom-enter-active {
  transform: translateY(0);
  transition: transform 300ms;
}
.slide-down-custom-exit {
  transform: translateY(0);
}
.slide-down-custom-exit-active {
  transform: translateY(-80px);
  transition: transform 300ms;
}
