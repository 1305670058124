@import '_seriph/variables';

#dash-list {
  //padding: 8px;
  background: #ffffff;
  border: 1px solid #e7ecf4;
  box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
  border-radius: 12px;

  width: 260px;
  min-width: 260px;
  height: auto;
  max-height: 500px;
  overflow: auto;
  .filter-title {
    font-family: $font-major;
    padding: 0 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #090e15;
    margin-top: 8px;
  }
  .filter-options {
    padding: 8px;
    .filter-option {
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      padding: 8px 12px;
      border-radius: 8px;
      transition: none !important;

      &:hover {
        background: #f5f7fb;
        color: #090e15;
      }
      &.active {
        background: #090e15;
        color: #fff;
      }
      > span {
        display: inline-block;
        background: grey;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        position: relative;
        margin-right: 8px;
        top: -1px;
      }
    }
  }
}

#campaign-dropdown {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 10px;
  background: #e7ecf4;
  border: 1px solid #e7ecf4;
  &:hover {
    border: 1px solid #c4cbd9;
  }
  h4 {
    display: flex;
    align-items: center;
    padding: 0 16px;
    line-height: 46px;
    &.medium {
      line-height: 40px;
    }
    margin: 0;
    font-family: $font-major;
    font-size: 14px;
    &.medium {
      font-size: 12px;
    }
    color: #090e15;
    font-weight: 400;
    svg {
      margin-left: 8px;
    }
  }
}
