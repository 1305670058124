.sxd-card {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .btnx-edit {
    position: absolute;
    display: none;
    top: 20px;
    right: 20px;
    svg {
      margin-right: 4px;
      font-size: 11px;
    }
  }
  &:hover .btnx-edit {
    display: block;
  }
}
