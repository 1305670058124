@import 'variables';

.sequence-linkedin-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .seq-header {
    padding: 24px;
    border-bottom: 1px solid #e7ecf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    > svg {
      margin-right: 16px;
      width: 32px !important;
      height: 32px !important;
    }
    h4 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $blue-800;
      margin: 0;
    }
  }
  .seq-body {
    min-height: 220px;
    padding: 32px 40px;
    input {
      position: absolute;
      left: -1000px;
      top: -1000px;
    }
  }
  .seq-actions {
    padding: 24px;
    border-top: 1px solid #e7ecf4;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-left: 12px;
    }
  }
  .part-two {
    padding: 32px;
    .late-header {
      display: flex;
      flex-direction: row;
      div {
        margin-left: 16px;
        flex: 1;
        h3 {
          font-family: $font-tt;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #090e15;
        }
        p {
          font-size: 14px;
          line-height: 21px;
          color: #4d5b76;
        }
      }
      svg {
        position: relative;
        top: 2px;
      }
    }
    .problems {
      margin-top: 24px;
      > div {
        padding: 12px;
        border-radius: 12px;
        font-family: $font-tt;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        border: 1px solid #e7ecf4;
        cursor: pointer;
        margin-bottom: 12px;
        text-align: center;
        &:hover {
          background: #f5f7fa;
        }
        &.active {
          background: #ffd53e;
          border-color: #ffd53e;
        }
      }
    }
    .late-actions {
      text-align: right;
      margin-top: 32px;
      button {
        margin-left: 12px;
      }
    }
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    z-index: 9;
    &:hover {
      background: #e5e7eb;
    }
  }
}
