@import '_seriph/variables';

#dialer-notes {
  .notes-header {
    height: 60px;
    line-height: 60px;
    padding: 0 16px 0 64px;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    position: relative;
    .seriph-modal-close {
      right: auto;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .ql-toolbar, .ql-container {
    border-left: 0 !important;
  }
  .note-mid {
    > span {
      color: #707b90;
      font-size: 11px;
    }
  }
  .notes-actions {
    padding: 16px;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .enter {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #707b90;
      margin-right: 16px;
      svg {
        width: 16px;
        height: 16px;
        color: #707b90;
        margin: 0 2px;
      }
    }
  }
  .note-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 320px;
    .note-header {
      display: flex;
      align-items: center;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      border-bottom: 1px solid #e7ecf4;
      position: relative;
      > div {
        position: absolute;
        right: 16px;
      }
    }
    .note-list {
      height: 280px;
      overflow: auto;
      border-bottom: 1px solid #e7ecf4;
      > .note-data {
        &:first-child {
          margin-top: 8px;
        }
        display: flex;
        flex-direction: row;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        color: #090e15;
        font-size: 12px;
        line-height: 18px;
        padding: 8px;
        .note-dot {
          text-align: center;
          width: 16px;
        }
      }
    }
  }
}

// <div className="note-box">
//           <div className="note-header">
//             All notes <Tag>{notes.length}</Tag>
//           </div>
//           <div className="note-list">
//             {notes.map(n => (
//               <div key={n.id} className="note-data" dangerouslySetInnerHTML={{ __html: n.content }} />
//             ))}
//           </div>
//         </div>
