@import '_seriph/variables';

#rep-applications {
  .hired-filter {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      white-space: nowrap;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin-right: 16px;
    }
    #campaign-dropdown {
      margin-right: 8px;
    }
    .sx-select {
      max-width: 160px;
      .ant-select-selection-selected-value {
        font-weight: 500;
        color: #090e15;
        b {
          color: #707b90;
          font-weight: 500;
          margin-right: 6px;
        }
      }
    }
  }
  .review-table {
    margin-top: 32px;
  }
  .app-sort-alert {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: rgb(245, 34, 45);
    display: inline-block;
    margin-right: 6px;
    position: relative;
  }

  .applications-table {
    .app-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      .app-card-bullet {
        flex: 0 0 16px;
        svg {
          width: 8px;
          height: 8px;
          stroke: #1970b3;
          fill: #1970b3;
          transition: all 0.3s ease-in;
          &.has-expanded {
            opacity: 0;
          }
        }
      }
      .seriph-avatar {
        margin-right: 20px;
      }
    }

    .ant-table-row {
      transition: all 0.3s ease-in;
      &.unseen {
        background: #e6f3fc;
      }
    }

    .tbl-actions {
      white-space: nowrap;
      text-align: right;
      .btn-default {
        display: none;
      }
      > button {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    tr:hover .tbl-actions .btn-default {
      display: inline-flex !important;
    }

    th {
      &:first-child {
        span {
          padding-left: 8px;
        }
      }
    }
    .app-card-info {
      margin-left: 0;
      .campaign-name {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
        margin: 0 0 2px 0;
      }
      h4 {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 1px;
        width: 192px;
      }
      p {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: #707b90;
        margin: 0;
      }
    }
    .app-match {
      display: inline-flex;
      background: #f5f7fb;
      height: 28px;
      border-radius: 48px;
      padding: 0 12px;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 28px;
      color: #090e15;
      &.best-match {
        background: #e8e7fd;
      }
    }
    .not-expanded {
      border: 0px transparent !important;
      background: #fff;
    }
  }

  .more-info {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-left: 16px;
    .more-note {
      flex: 1;
      background: #f5f7fb;
      border-radius: 12px;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      font-family: $font-minor;
    }
    .actions {
      flex: 0 0 280px;
      button {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 8px;
        }
      }
    }
  }
}
