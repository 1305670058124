@import '_seriph/variables';

.main-bg {
  width: 100%;
  min-height: 100%;
  height: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: #090e15;
  position: relative;
  .ant-layout {
    background: transparent !important;
  }
  .home-logo {
    position: absolute;
    left: 40px;
    top: 40px;
    z-index: 99;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .home-bgs {
    display: none;
    img {
      position: absolute;
      z-index: 0;
    }
    .bg1 {
      top: 0;
      left: 0;
    }
    .bg2 {
      bottom: 0;
      left: 0;
    }
    .bg3 {
      bottom: 0;
      left: 130px;
    }
  }
  .home {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 9;
    overflow: scroll;
    .home-left,
    .home-right {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      background: #090e15;
    }

    .home-left {
      display: none;
    }
    .home-form {
      width: auto;
      max-width: 420px;
      padding: 16px;
      margin-top: -24px;
      .home-forgot {
        margin-top: 8px;
        a {
          font-size: 14px;
          line-height: 21px;
          text-decoration: underline;
          color: #918f8c;
          &:hover {
            color: #b1afac;
          }
        }
      }
      .home-actions {
        margin-top: 32px;
        margin-bottom: 24px;
      }
      .seriph-input.input-large .input-box > input, 
      .int-phone .PhoneInput > input {
        background: #27292a !important;
        border: 1px solid #27292a !important;
        color: #fff !important;
        &::placeholder {
          color: #565c61 !important;
        }
        &:hover {
          border: 1px solid #27292a !important;
        }
        &:focus {
          border: 1px solid #27292a !important;
          background: #27292a !important;
        }
      }
      input:-webkit-autofill::first-line {
        font-family: $font-major !important;
        font-size: 16px !important;
        letter-spacing: normal !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: all 5000000s ease-in-out 0s;
      }
    }
    .headline {
      color: #fff;
      margin: 0;
    }
    .descript {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin: 12px 0 24px 0;
      padding-bottom: 24px;
      a {
        text-decoration: underline;
        color: #d2d0fb;
        &:hover {
          color: #b2b0db;
        }
      }
    }
    .seriph-label {
      color: #fff;
    }
    .seriph-input {
      margin-top: 24px;
    }
  }
}
