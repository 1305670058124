.quill-render {
  p,
  li {
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
  }
  strong {
    font-weight: 600;
  }
  h1 {
    font-family: $font-tt;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #0b1e41;
    margin-bottom: 8px;
    u {
      text-decoration: none !important;
    }
  }
  h2 {
    font-family: $font-tt;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0b1e41;
    margin-bottom: 8px;
  }
}
