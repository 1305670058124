@import '_seriph/variables';

$max-width: 1024px;
$page-padding: 0 32px;

#lead-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .lead-banner {
    width: 100%;
    padding: $page-padding;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }
  .lead-body-wrapper {
    width: 100%;
    overflow-y: scroll;
    flex: 1;
    .lead-body {
      width: 100%;
      padding: $page-padding;
      max-width: $max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .review-actions {
    display: flex;
    > button {
      margin-left: 12px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .lead-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 24px;
    background: #f5f7fb;
    .lead-info {
      flex: 1;
      h4 {
        line-height: 40px;
        white-space: nowrap;
      }
      .lead-desc {
        margin-top: 12px;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        white-space: nowrap;
        .lead-price {
          color: #577e4e;
        }
      }
    }
    .lead-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .lead-source {
        a {
          display: block;
        }
        .seriph-avatar > img {
          margin-right: 0;
        }
      }
      .spacer {
        display: block;
        content: '';
        clear: both;
        width: 1px;
        height: 40px;
        margin: 0 16px;
        background: #d7dde9;
      }
    }
  }
  .lead-menu {
    margin-top: 24px;
    border-bottom: 1px solid #e7ecf4;
  }
  .lead-top {
    display: flex;
    align-items: center;
    .stage-tag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 3px 10px;
      border: 2px solid #abd4a2;
      box-sizing: border-box;
      border-radius: 48px;
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      align-items: center;
      color: #090e15;
      margin-left: 18px;
      &.in-progress {
        border-color: $in-progress;
      }
      &.completed {
        border-color: $completed;
      }
      &.review {
        border-color: $review;
      }
      &.not-interested {
        border-color: $not-interested;
      }
      &.disputed {
        border-color: $disputed;
      }
    }
  }
}
