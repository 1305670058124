.request-integration-modal {
  .subheader {
    margin: 12px 0 32px;
  }
  .name-input {
    margin-bottom: 18px;
  }
  .category-input {
    margin-bottom: 18px;
    width: 100%;
  }
  .description-input {
    margin-bottom: 18px;
  }
  .mo-actions {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 12px;
    }
  }
}
