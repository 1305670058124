@import '_seriph/variables';

.empty-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #E7ECF4;
  border: 1px solid #D7DDE9;
  border-radius: 8px;
  padding: 48px 24px;
  h3 {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #090E15;
    margin: 0 0 2px 0;
  }
  p {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #707B90;
    margin: 0 0 24px 0;
  }
}