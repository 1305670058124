@import '_seriph/variables';

.campaign-just-touchpoints-modal {
  .ant-modal-body {
    padding-top: 20px !important;
  }

  .just-touchpoints-header {
    h4 {
      font-family: $font-major;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      text-align: center;
    }
  }
  .just-touchpoints-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    .right {
      flex: 1;
      text-align: right;
      button {
        margin-left: 12px;
      }
    }
  }
  .close-icon,
  .back-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;

    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    &:hover {
      background: #e5e7eb;
    }
  }
  .back-icon {
    left: 24px;
  }
  .close-icon {
    right: 24px;
  }
}
