.dropdown-owners {
  .ant-select-dropdown-menu-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    > span {
      font-weight: 400;
      font-size: 14px;
      color: #090e15;
      margin-left: 12px;
      b {
        font-weight: 600;
        display: inline-block;
        margin-right: 8px;
        font-style: normal;
      }
    }
  }
}
.message-owner-modal {
  .ant-select-selection-selected-value {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    > span {
      font-weight: 400;
      font-size: 14px;
      color: #090e15;
      margin-left: 12px;
      b {
        font-weight: 600;
        display: inline-block;
        margin-right: 8px;
        font-style: normal;
      }
    }
    img.av-comp {
      right: -5px;
      bottom: 5px;
    }
  }
  .mo-owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    img.employee {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      margin-right: 24px;
    }
    img.c-logo {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background: #f7f8fa;
      position: absolute;
      top: 46px;
      left: 46px;
    }
    .mo-details {
      flex: 1;
      h3 {
        color: #0b1e41;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
      }
      p {
        color: #4d5b76;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;
      }
      .sellx-tag {
        font-size: 12px !important;
      }
    }
  }
  .mo-message {
    margin-top: 30px;
    h3 {
      font-size: 13px;
      font-weight: 500;
      color: #0b1e41;
      margin-top: 30px;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #999;
        font-style: italic;
        margin-left: 10px;
      }
    }
    input {
      margin-bottom: 20px;
    }
    textarea,
    input {
      background: #f3f5f9;
      border: 0;
      outline: 0;
      box-shadow: none;
      color: #0b1e41;
      padding: 11px 11px;
    }
  }
  .mo-actions {
    margin-top: 30px;
    text-align: right;
    .btnx-default {
      margin-right: 15px;
    }
    .btnx-primary {
      flex: 1;
    }
  }
  .field {
    margin-bottom: 32px;
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin-bottom: 8px;
      display: block;
      .optional {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #707b90;
      }
    }
    > textarea {
      padding: 16px !important;
    }
  }
}
