@import '_seriph/variables';

.threshold-modal .ant-modal-body {
  h4 {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
  }
  p,
  div {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4d5b76;
    margin: 0;
    > b {
      color: #090e15;
      font-weight: 500;
    }
  }
  .bold {
    margin-top: 24px;
  }
  button {
    margin-top: 24px;
    width: 100%;
  }
}
