@import '_seriph/variables';

#sellx-profile {
  width: 100%;
  padding: 32px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .prof-bod {
    display: flex;
    flex-direction: row;
    .left-profile,
    .right-profile {
      width: 300px;
      min-width: 300px;
    }
    .middle-profile {
      flex: 1;
      margin: 0 24px;
    }
  }
  .seriph-card .btnx-edit {
    position: absolute;
    display: none;
    top: 20px;
    right: 20px;
  }
  .seriph-card:hover .btnx-edit {
    display: block;
  }
  .seriph-card > h2 {
    font-family: $font-major;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
  }
}
