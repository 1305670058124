@import '_seriph/variables';

#lead-call-info {
  .phones {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    > svg {
      width: 16px;
      height: 16px;
      stroke-width: 2px;
      color: #090E15;
      margin: 0 8px;
    }
    div[disabled] {
      opacity: 0.5;
    }
    .seriph-tag {
      text-transform: capitalize;
      margin: 0;
      svg {
        width: 12px;
        height: 12px;
        stroke-width: 2px;
        color: #090E15;
        margin: 0;
        position: relative;
        top: 2px;
        margin-right: 4px;
      }
    }
  }
  .lead-name-company {
    color: #707B90;
    font-weight: 400;
    margin-left: 4px;
    font-size: 14px !important;
  }
  .no-calls {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #707B90;
    text-transform: capitalize;
  }
}

.nocall-pop {
  max-width: 180px;
  h3 {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #090E15;
    margin: 0 0 2px 0;
  }
  p {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #707B90;
    margin: 0 0 12px 0; 
  }
  button {
    margin: 0;
  }
}