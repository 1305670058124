#agent-settings-weekly {

  .billing-table {
    margin-top: 32px;
  }
  .seriph-alert {
    margin-top: 24px;
  }
  .filter-menu {
    margin: 24px 0;
  }
}