@import '_seriph/variables';

.select-dropdown-hide-dropdown {
  display: none;
}

.ant-select-dropdown.seriph-dropdown {
  padding: 8px;
  background: #ffffff;
  border: 1px solid #e7ecf4;
  box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
  border-radius: 12px;

  .ant-select-dropdown-menu {
    padding: 0;
    min-width: 160px;
    .ant-select-dropdown-menu-item-group-title {
      font-family: $font-major;
      font-weight: 500;
      color: #090e15;
    }
    > .ant-select-dropdown-menu-item,
    .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
      background: transparent;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      padding: 8px 12px;
      border-radius: 8px;
      transition: none !important;
      margin-bottom: 4px;
      &:not(.nocap) {
        text-transform: capitalize;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover,
      &.ant-select-dropdown-menu-item-active {
        background: #f5f7fb;
      }
      &.ant-select-dropdown-menu-item-selected {
        background: #090e15;
        color: #ffffff;
      }
    }
  }
}

.ant-dropdown.seriph-dropdown {
  .ant-dropdown-menu,
  .ant-menu {
    padding: 8px;
    background: #ffffff;
    border: 1px solid #e7ecf4;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    min-width: 160px;
    .ant-dropdown-menu-item,
    .ant-menu-item {
      background: transparent;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      padding: 8px 12px;
      border-radius: 8px;
      transition: none !important;
      margin-bottom: 4px;
      &:not(.nocap) {
        text-transform: capitalize;
      }
      a {
        color: #090e15;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover,
      &.ant-dropdown-menu-item-active,
      &.ant-menu-item-active {
        background: #f5f7fb;
      }
      &.ant-dropdown-menu-item-selected,
      &.ant-menu-item-selected {
        background: #090e15;
        color: #ffffff;
      }
    }
  }
}

.seriph-dropdown {
  .ant-dropdown-menu-item.danger,
  .ant-menu-item.danger {
    color: #b34043 !important;
    &:hover {
      background: #fbece7 !important;
    }
  }
  .ant-dropdown-menu-item.success,
  .ant-menu-item.success {
    color: #698E60 !important;
    &:hover {
      background: #EEF9EC !important;
    }
  }
}
