@import '_seriph/variables';

$transition-speed: 0.25s;

#messaging {
  position: fixed;
  width: calc(100% - 220px);
  height: 100%;
  top: 0;
  left: 220px;
  background: rgba(9, 14, 21, 0);
  z-index: 10000 !important;
  overflow: hidden;
  transition: background $transition-speed linear;
  .messaging-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 68px;
    padding: 26px 24px 0 24px;
  }
  .messaging-list {
    height: 100%;
    width: 380px;
    position: absolute;
    left: -380px;
    background: #fff;
    border-radius: 0px 12px 12px 0px;
    overflow: hidden;
    transition: left $transition-speed linear;
    z-index: 1001;
  }
  .messaging-content {
    height: 100%;
    width: calc(100% - 368px);
    position: absolute;
    left: 368px;
    overflow: hidden;
    z-index: 1000;
    background: transparent;
    content: '';
    clear: both;
    .test {
      width: 100%;
      height: 100%;
    }
  }
}

#messaging.done {
  background: rgba(9, 14, 21, 0.67);
  .messaging-list {
    left: 0px;
  }
}

body.messaging-open {
  position: fixed;
  overflow: hidden;
}
