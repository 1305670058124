@import 'variables';

#dialer-benefits {
  min-width: 284px;
  min-height: 0;
  .lead-benefit {
    margin-bottom: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #090e15;
    > span:first-child {
      margin-right: 8px;
    }
  }
}
