@import '_seriph/variables';

.form-selection {
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 10px;
    background: #fff;
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #090e15;
    font-family: $font-major;
    border: 1px solid #e7ecf4;
    min-height: 65px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    > div {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #4d5b76;
      font-weight: 400;
    }
    &:hover {
      border: 1px solid #090e15;
    }
    &.selected {
      color: #090e15;
      border: 1px solid #090e15;
    }
  }
  // &.qualify > div.selected {
  //   color: #142E24;
  //   background: #B5FAAA;
  //   border: 1px solid #95dA8A;
  //   box-shadow: none;
  // }
  // &.leadgen > div.selected {
  //   color: #250D93;
  //   background: #A9E3FF;
  //   border: 1px solid #89c3dF;
  //   box-shadow: none;
  // }
  &.disabled > div {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      border: 1px solid #e7ecf4;
    }
    &.selected {
      border: 1px solid #090e15;
    }
  }
}
