@import '_seriph/variables';

#company-discover-user {
  margin-bottom: 16px;
  .user-information {
    display: flex;
    flex-direction: row;
    .user-status {
      flex: 1;
      padding-left: 15px;
      position: relative;
      div {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        h3 {
          font-weight: 500;
          font-family: $font-major;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;
        }
        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #4d5b76;
          margin: 0;
        }
      }
    }
  }
  .prof-link {
    text-align: center;
  }
}
