@import '_seriph/variables';

.campaign-end-threshold-modal {
  .ant-modal-body {
    padding: 40px;
    min-height: 400px;
  }
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }
  .end-content {
    display: flex;
    flex-direction: row;
    .stats {
      flex: 1;
      margin-right: 64px;
      min-width: 0;
      > h5 {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
        margin: 0 0 8px 0;
      }
      > h2 {
        font-family: $font-major;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #090e15;
        margin: 0 0 12px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
      }
      > h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        > span:first-child {
          color: #090e15;
        }
        margin: 0 0 40px 0;
      }
      .reps {
        margin-top: 40px;
        > h3 {
          font-family: $font-major;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0 0 16px 0;
        }
        .rep-table {
          width: 100%;
          tr > td {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #090e15;
            padding: 0 0 12px 0;
          }
          tr > td:last-child {
            text-align: right;
          }
        }
      }
      .progress {
        .sxd-progress-container {
          .ant-progress-inner {
            height: 20px;
            > div {
              height: 20px !important;
            }
          }
          .ant-progress-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
          }
        }
        .info {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 12px;
          > div {
            font-size: 12px;
            line-height: 18px;
            color: #090e15;
          }
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            min-width: 8px;
            min-height: 8px;
            border-radius: 4px;
            margin-right: 4px;
            background: $unassigned;
          }
          .pending {
            text-align: center;
            flex: 1;
          }
          .completed > span {
            background: $completed;
          }
          .pending > span {
            background: $review;
          }
        }
      }
    }
    .summary {
      width: 276px;
      .line-items {
        margin-top: 16px;
        > .item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 16px;
          .info {
            flex: 1;
            h4 {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #090e15;
              margin: 0 0 2px 0;
            }
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #707b90;
              margin: 0;
            }
          }
          .value {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: right;
            color: #090e15;
            width: 100px;
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #707b90;
              margin: 0;
            }
          }
        }
      }
      .overview {
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;
        border-bottom: 1px solid #e7ecf4;
        .left {
          flex: 1;
          text-align: left;
        }
        .right {
          flex: 1;
          text-align: left;
        }
        > div {
          h4 {
            font-family: $font-major;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #090e15;
            margin: 0 0 2px 0;
          }
          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #707b90;
            margin: 0;
          }
        }
      }
      .notice {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        border-top: 1px solid #e7ecf4;
        border-bottom: 1px solid #e7ecf4;
        svg {
          margin-right: 12px;
          color: #4d5b76;
          position: relative;
          top: 2px;
        }
        p {
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
          margin: 0;
        }
      }
      .total {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 20px;
        > h4 {
          font-family: $font-major;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0;
        }
        > div {
          flex: 1;
          text-align: right;
          font-family: $font-major;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
        }
      }
    }
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;

    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    &:hover {
      background: #e5e7eb;
    }
  }
  .close-icon {
    right: 24px;
  }
}
