@import '_seriph/variables';

#company-settings-reps {
  position: relative;
  width: 100%;
  padding: 0;
  .settings-header {
    margin: 0 !important;
  }
  .settings-subtitle {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    margin: 8px 0 24px 0;
  }
  #reps-invites {
    margin-top: 24px;
  }
  .tbl-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: transparent;
    border-radius: 100px;
    cursor: pointer;
    &:hover {
      background: #e7ecf4;
    }
  }
  .bundle {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      margin-left: 12px;
    }
  }
  .invite-actions {
    position: absolute;
    top: 32px;
    right: 32px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    > span {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #4d5b76;
      margin-right: 16px;
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      svg {
        width: 16px;
        height: 16px;
        color: #4d5b76;
        margin-right: 8px;
        position: relative;
        top: -1px;
      }
    }
    button {
      margin-left: 12px;
    }
  }
  .reps-table-header {
    margin: 24px 0 12px 0;
    font-weight: 600;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .reps-table {
    .emp-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      h4 {
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        margin: 0 0 0 4px;
        font-weight: normal;
      }
      > div {
        > h4 {
          margin: 0 !important;
        }
        > p {
          margin: 0 !important;
          font-size: 13px;
          line-height: 18px;
          color: #707b90;
        }
      }
      .seriph-avatar {
        margin-right: 12px;
      }
    }
    .emp-delete > span {
      cursor: pointer;
      svg {
        color: #707b90;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
