@import 'variables';

.btnx {
  font-weight: 600;
  padding: 9px 12px;
  height: 40px;
  border: 1px solid;
  box-shadow: none;
  &:hover {
    opacity: 0.8;
  }
  font-family: $font-tt;

  &.btnx-default {
    background: #ffffff;
    border: 1px solid #d7dde9;
    border-radius: 10px;
    color: #090e15;
    &:hover {
      color: #4353ff;
      opacity: 1;
    }
    &:focus {
      color: #4353ff;
      background: #e7ecf4;
    }
  }

  &.btnx-primary {
    background: #4353ff;
    border: 1px solid #4353ff;
    border-radius: 10px;
    color: #fff;
    &:hover,
    &:focus {
      background: #2924ce;
      color: #2924ce;
      color: #fff;
      opacity: 1;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-neutral {
    background: #f0f3f7;
    border: 1px solid #f0f3f7;
    border-radius: 10px;
    color: #090e15;
    &:hover,
    &:focus {
      color: #4353ff;
      opacity: 1;
    }
  }

  &.btnx-active {
    background: #ffffff;
    border: 1px solid #4353ff;
    border-radius: 10px;
    color: #4353ff;
    cursor: not-allowed;
    &:hover {
      color: #4353ff;
      opacity: 1;
    }
    &:focus {
      color: #4353ff;
      background: #fff;
    }
  }

  &.btnx-locked {
    background: #f0f3f7;
    border: 1px solid #f0f3f7;
    border-radius: 10px;
    color: #0b1e41;
    &:hover,
    &:focus {
      color: #0b1e41;
      opacity: 1;
      border: 1px solid #f0f3f7;
      background: #f0f3f7;
    }
    &[disabled] {
      opacity: 1;
    }
  }

  &.btnx-caution {
    background: #ffd53e;
    border: 1px solid #ffd53e;
    border-radius: 10px;
    color: #090e15;
    &:hover,
    &:focus {
      background: #ffca0b;
      color: #ffca0b;
      color: #090e15;
      opacity: 1;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-empty {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 10px;
    color: #090e15;
    &:hover,
    &:focus {
      background: #f0f3f7;
      border: 1px solid #f0f3f7;
      color: #4353ff;
      opacity: 1;
    }
  }

  &.btnx-success {
    background: #00c06e;
    border: 1px solid #00c06e;
    border-radius: 10px;
    color: #fff;
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-danger {
    background: #ef1e51;
    border: 1px solid #ef1e51;
    border-radius: 10px;
    color: #fff;
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-hazard {
    background: #fff;
    border: 1px solid #dce0e8;
    border-radius: 10px;
    color: #090e15;
    &:hover,
    &:focus {
      color: #de1748;
      opacity: 1;
      border-color: #de1748;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-hazy {
    background: #222834;
    border: 1px solid #222834;
    border-radius: 10px;
    color: #fff;
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-lime {
    background: #abff86;
    border: 1px solid #abff86;
    border-radius: 10px;
    color: #090e15;
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-dark {
    background: #090e15;
    border: 1px solid #090e15;
    border-radius: 10px;
    color: #fff;
    &:hover,
    &:focus {
      background: #242d3e;
      border: 1px solid #242d3e;
      color: #fff;
      opacity: 1;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  &.btnx-giant {
    height: 56px;
    font-size: 18px;
    line-height: 18px;
    padding-left: 24px;
    padding-right: 24px;
  }
  &.btnx-large {
    height: 48px;
    font-size: 16px;
    line-height: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &.btnx-small {
    height: 36px;
    font-size: 13px;
    line-height: 16px;
    padding: 0 12px;
  }
  &.btnx-tiny {
    height: 32px;
    font-size: 12px;
    line-height: 12px;
    padding: 0 10px;
  }
  &.btnx-block {
    width: 100%;
  }
  &.btnx-round {
    border-radius: 100px;
  }
  &.btnx-disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
  }
  .btnx-left {
    margin-right: 8px;
  }
  .btnx-right {
    margin-left: 8px;
  }
}
