@import '_seriph/variables';

#agent-settings-banking {
  position: relative;
  width: 100%;
  padding: 0;
  .manage-box {
    border-top: 1px solid #e6e9ef;
    padding-top: 24px;
  }
  .setup-button {
    text-align: center;
    .add-account {
      margin: 0 auto;
      margin-top: 30px;
    }
  }
  .sources {
    > .source {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 0 24px 0;
      svg {
        color: #090e15;
        width: 48px;
        height: 48px;
        stroke-width: 2;
      }
      .source-name {
        margin-left: 16px;
        h3 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0;
        }
        > div {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
        }
      }
    }
  }
}
