.sx-dropdown.custom-field {
  width: 250px !important;
  li {
    overflow: visible !important;
    white-space: normal !important;
    text-overflow: initial !important;
  }
  .cust-opt {
    display: flex;
    flex-direction: row;
    svg {
      color: #242d3e;
      margin-right: 12px;
      position: relative;
      top: 4px;
    }
    h4 {
      font-size: 14px;
      line-height: 21px;
      color: #242d3e;
      margin: 0;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      color: #707b90;
      margin: 0;
      padding: 0;
    }
  }
}

.ant-select-selection-selected-value .cust-opt {
  display: inline-block;
  .cust-info {
    display: inline-block;
  }
  svg {
    color: #242d3e;
    margin-right: 12px;
    position: relative;
    line-height: 21px;
  }
  h4 {
    font-size: 14px;
    line-height: 21px;
    color: #242d3e;
    margin: 0;
    display: inline-block;
  }
  p {
    display: none;
  }
}
