@import 'variables';

#channel-conversation {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .close-icon {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 16px;
    right: 16px;
    background: #fff;
    box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
    color: #090e15;
    cursor: pointer;
    &:hover {
      background: #f5f7fb;
    }
  }
  #no-convos {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f7f8fa;
    .sx-empty {
      padding-top: 0;
    }
  }
  .conversation-title {
    border-bottom: 1px solid #e7ecf4;
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px !important;
    .title-info {
      flex: 1;
      padding-left: 12px;
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
        font-family: $font-tt;
        margin-bottom: 2px;
      }
      h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        margin: 0;
      }
      p {
        margin: 0;
        color: #000;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
  .conversation {
    width: 100%;
    overflow-y: scroll;
    word-break: break-word;
    flex: 1;
    background: #f7f8fa;
    padding: 24px 24px 120px 24px;
    .message {
      display: flex;
      flex-direction: row;
      border: 0px solid #e6e9ef;
      border-radius: 10px;
      position: relative;
      padding-right: 32px;
      margin-bottom: 24px;

      .message-content {
        flex: 1;
        margin-left: 24px;
        h3 {
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #707b90;
          margin-top: 8px;
        }
        .message-body {
          display: inline-block;
          margin: 0;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          background-color: #e7ecf4;
          padding: 12px;
          border-radius: 8px;
          border-bottom-left-radius: 0;
          white-space: pre-line;
          width: auto;
          text-align: left !important;
          > p {
            color: #090e15;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .tstamp {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: #4d5b76;
        margin-left: 10px;
      }
      &.message-own {
        padding-right: 0;
        padding-left: 32px;
        p {
          background-color: transparent;
          padding: 0;
        }
        .message-content {
          text-align: right;
        }
        .message-body {
          background: #242d3e;
          color: #fff;
          p,
          span,
          a,
          h1,
          h2,
          h3,
          h4,
          h5,
          div {
            color: #fff !important;
          }
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
  .conversation-input {
    bottom: 16px;
    padding: 0 12px;
    width: 100%;
    position: absolute;
    .no-input {
      padding: 48px 32px 32px 32px;
      text-align: center;
      border-top: 1px solid #e7ecf4;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #707b90;
      background: #f5f7fb;
    }
    .input-content {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
      border-radius: 12px;
      padding: 0;
      .quill {
        display: flex;
        flex-direction: column-reverse;
        .ql-editor {
          padding: 16px 16px 8px 16px;
        }
        .ql-toolbar {
          border: 0;
          padding: 16px 12px;
        }
        .ql-container {
          border: 0;
        }
        .ql-editor {
          p,
          i,
          u,
          b,
          strong,
          a {
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
          }
        }
        .ql-editor.ql-blank::before {
          color: #77839b;
          font-style: normal;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .btnx {
        position: absolute;
        right: 8px;
        bottom: 8px;
      }
    }
  }
}
