@import 'variables';

.sequence-email-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .seq-header {
    padding: 24px;
    border-bottom: 1px solid #e7ecf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    > svg {
      margin-right: 16px;
      width: 32px !important;
      height: 32px !important;
    }
    h4 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $blue-800;
      margin: 0;
    }
  }
  .seq-body {
    min-height: 220px;
    padding: 0;
    .ql-toolbar {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .ql-container {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .template-info {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #e7ecf4;
      height: 56px;
      line-height: 56px;
      font-weight: 500;
      font-size: 16px;
      color: #090e15;
      outline: 0;
      box-shadow: none;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .seq-actions {
    position: relative;
    padding: 24px;
    border-top: 1px solid #e7ecf4;
    text-align: right;
    button {
      margin-left: 12px;
    }
    .signature-warning {
      position: absolute;
      left: 24px;
      background: #fff1bf;
      border-radius: 8px;
      height: 40px;
      line-height: 22px;
      padding: 9px 12px;
      b {
        font-weight: 600;
      }
    }
    .invalid-tags {
      position: absolute;
      left: 24px;
      background: #fff1bf;
      border-radius: 8px;
      height: 40px;
      line-height: 22px;
      padding: 9px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        color: #090e15;
      }
      svg {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    z-index: 9;
    &:hover {
      background: #e5e7eb;
    }
  }
}
