@import '_seriph/variables';

.seriph-empty {
  text-align: center;
  max-width: 456px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;
  .empty-icon {
    width: 56px;
    height: 56px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 24px;
    border-radius: 28px;
    background: #e8e7fd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &.info {
      background: #d2d0fb;
    }
    &.warning {
      background: #efdfc4;
    }
    &.success {
      background: #c1ddbb;
    }
    &.negative {
      background: #efbba9;
    }
    svg {
      color: #090e15;
    }
  }
  .empty-desc {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #090e15;
    margin-top: 12px;
    b {
      font-weight: 600;
    }
  }
  .empty-action {
    margin-top: 24px;
    > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
    }
  }
}
