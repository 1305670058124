@import '_seriph/variables';

#admin-leaderboard {
  padding: 40px 30px 30px 30px;
  .admin-header {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    position: relative;
    > div {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      position: relative;
      left: 0;
      top: -5px;
    }
    .ant-input-search {
      width: 300px;
      position: absolute;
      right: 0;
      top: 0;
      input {
        border: 1px solid #e6e9ef;
        background: #fff;
        outline: 0;
        box-shadow: none;
        color: #000000;
        &:hover,
        &:focus {
          border-color: #4353ff;
          outline: 0;
          box-shadow: none;
        }
      }
      .anticon-search {
        font-size: 16px;
        color: #000;
        &:hover {
          color: #4353ff;
        }
      }
    }
  }
  .seriph-table-wrapper {
    margin-top: 40px;
  }
  .thresh-box {
    display: inline-block;
    .sxd-title {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      text-align: left;
      margin: 0 0 2px 0;
    }
    .thresh-dur {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      color: #4d5b76;
      margin: 0;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .tbl-link {
    margin-bottom: 8px;
    .subtitle {
      color: #707b90;
      font-size: 12px;
      margin-left: 4px;
    }
    &.review .sxd-link {
      color: $review;
    }
  }
  .ctbl-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    .seriph-avatar {
      margin-right: 16px;
    }
    .ctbl-name {
      h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 1px;
        width: 240px;
        margin: 0 0 2px 0;
      }
      h5 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
        span {
          color: #4d5b76;
        }
        .left-type {
          font-size: 12px;
          line-height: 18px;
          color: #090e15;
          font-weight: 500;
        }
      }
      .sx-status {
        display: none;
        min-width: 10px;
        min-height: 10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 6px;
        position: relative;
        top: -1px;
      }
    }
  }
  .filters {
    position: absolute !important;
    right: 0;
    left: auto !important;
    > .ant-select {
      width: 120px;
      margin-left: 12px;
      &:first-child {
        width: 150px;
      }
    }
    button {
      margin-left: 12px;
      height: 32px;
      line-height: 30px;
      padding: 0 12px;
    }
  }
  .totals {
    text-align: right;
    margin-bottom: 12px;
    > span {
      margin-left: 24px;
      font-family: $font-major;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      b {
        color: green;
        font-weight: 500;
      }
    }
  }
  .admin-table {
    .lead-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      .lead-photo {
        padding-right: 10px;
        img {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }
        span {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 20px;
          line-height: 30px;
          text-align: center;
          background: #ecedff;
          color: #4353ff;
          font-weight: 600;
        }
      }
    }
    .agent-email {
      svg {
        color: #30f09e;
        margin-left: 8px;
      }
    }
  }
}
