@import 'variables';

.sxd-progress-circle {
  position: relative;
  width: 16px;
  height: 16px;

  svg.thing {
    width: 12px;
    height: 12px;
    background: transparent;
    border-radius: 50%;
    transform: rotate(-90deg);
    position: relative;
    right: -2px;
    top: -2px;
  }
  > svg.tabler-icon {
    width: 10px;
    height: 10px;
    background: transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 99;
  }

  circle {
    fill: transparent;
    stroke: #ADB5C5;
    stroke-width: 32;
  }

  .bordered {
    width: 16px;
    height: 16px;
    border-radius: 9px;
    border: 1.5px solid #ADB5C5;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
    circle {
      stroke: #242d3e;
    }
    .bordered {
      border-color: #242d3e;
    }
  }
}
