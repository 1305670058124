@import '_seriph/variables';

#manage-call-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
  border-right: 1px solid #e7ecf4;
  margin-right: 16px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 14px;
    margin-right: 8px;
    &.btn-circle {
      padding: 0;
    }
    svg {
      width: 16px;
      height: 16px;
      stroke-width: 2px;
      color: #090e15;
    }
  }
  .timer {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #090e15;
    margin-right: 16px;
  }
}

#dv3-pad {
  padding: 24px;
  position: relative;
  top: -16px;
  background: #ffffff;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
  border-radius: 12px;
  .pressed {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: block;
    text-align: center;
    color: #090e15;
  }
  .dial-time {
    font-size: 16px;
    line-height: 24px;
    display: block;
    text-align: center;
    color: #707b90;
    margin-top: 2px;
  }
  button {
    transition: none !important;
  }

  .keys {
    display: block;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > .key {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      padding: 0 !important;
      margin-right: 16px;
      font-family: $font-header;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #090e15;
      > div {
        font-family: $font-major;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 1px;
        color: #707b90;
      }
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}