@import '_seriph/variables';

#construction {
  width: 100%;
  min-height: 100%;
  height: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: #090e15;
  position: relative;
  .ant-layout {
    background: transparent !important;
  }
  .home-logo {
    text-align: center;
    padding-top: 64px;
    img {
      width: 140px;
      height: 40px;
    }
  }
  .construction-body {
    text-align: center;
    margin-top: 72px;
    padding: 0 32px;
    h1 {
      color: #fff;
      text-align: center;
    }
    p {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #686764;
      margin-top: 24px;
    }
  }
  .unplug {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    margin-top: 48px;
    &:before {
      display: block;
      content: '';
      clear: both;
      height: 8px;
      background: #2a2928;
      position: absolute;
      left: -100%;
      top: 120px;
      width: 100%;
    }
    &:after {
      display: block;
      content: '';
      clear: both;
      height: 8px;
      background: #2a2928;
      position: absolute;
      right: -100%;
      top: 119px;
      width: 100%;
    }
  }
}
