.multiple-locations {
  &.max {
    .ant-select-search__field {
      pointer-events: none;
      display: none;
    }
  }
}
.locations-drop-max {
  display: none;
}
