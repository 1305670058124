@import '_seriph/variables';

.lead-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0;
  padding: 20px 16px;
  cursor: pointer;
  box-shadow: none !important;
  border-radius: 8px;
  position: relative;
  &:hover,
  &.selected,
  &.chosen {
    background: #f5f7fb;
  }
  .tag-text {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    color: #090e15;
  }
  &:after {
    display: block;
    content: '';
    clear: both;
    width: calc(100% - 16px);
    height: 1px;
    background: #e7ecf4;
    bottom: 0;
    left: 8px;
    position: absolute;
  }
  .lead-row {
    display: block;
    margin: 0;
    .lead-name {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      padding: 0;
      margin: 0;
    }
  }
  .chosen-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 18px;
    top: 20px;
    svg {
      width: 12px;
      height: 12px;
      stroke-width: 3px;
      color: #090e15;
    }
  }
  .lead-progress {
    width: 18px;
    height: 18px;
    max-height: 18px;
    overflow: hidden;
    line-height: 23px;
    margin-left: 12px;
    position: absolute;
    top: 25px;
    right: 21px;
    .sxd-progress-circle {
      display: inline-block;
      margin: 0;
    }
    &.has-work .sxd-progress-circle {
      .bordered {
        border-style: dotted;
      }
    }
  }
  .bottom-row {
    display: inline-block;
    margin-top: 12px;
    position: relative;
    user-select: none !important;

    > .call, > .email {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #090e15;
      display: inline-block;
      margin-right: 8px;
    }

    .lead-stage {
      display: inline-block;
      border: 2px solid #e7ecf4;
      border-radius: 48px;
      padding: 3px 10px;
      margin-right: 8px;
      line-height: 11px;
      &.in-progress {
        border-color: $in-progress;
      }
      &.completed {
        border-color: $completed;
      }
      &.call-scheduled {
        border-color: $completed;
      }
      &.review {
        border-color: $review;
      }
      &.not-interested {
        border-color: $not-interested;
      }
      &.invalid {
        border-color: $not-interested;
      }
      &.improper {
        border-color: $not-interested;
      }
      &.disputed {
        border-color: $disputed;
      }
      &.response {
        border-color: #D17E76;
      }
      &.paused {
        border-color: #fff1bf;
      }
      &.unassigned {
        border-color: $unassigned;
      }
      .stage-name {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 11px;
        color: #090e15;
        display: inline-block;
      }
    }
    .second-level {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #090e15;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #e7ecf4;
      border-radius: 48px;
      padding: 3px 10px;
      margin-right: 8px;
      height: 22px;

      svg {
        height: 10px;
        width: 10px;
        stroke-width: 4px;
      }
    }

    .lead-time {
      display: inline-block;
      text-align: right;
      .tstamp {
        font-weight: 500;
        font-size: 11px;
        color: #4d5b76;
      }
    }
  }
  .last-action {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #4d5b76;
  }
  .lead-icon {
    margin-right: 8px;
  }
}
