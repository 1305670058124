@import '_seriph/variables';

#company-settings-integrations {
  position: relative;
  width: 100%;
  padding: 0;
  padding-bottom: 8px !important;
  .settings-item > .type {
    white-space: nowrap;
  }
  .settings-header-container {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .settings-header {
      margin: 0 !important;
    }
    .settings-subtitle {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #090e15;
      margin: 8px 0;
    }
    .right-section {
      display: flex;
      align-items: center;
      .button-label {
        font-family: $font-major;
        font-size: 12px;
        line-height: 18px;
        max-width: 100px;
        color: #707b90;
        text-align: right;
        margin-right: 16px;
      }
    }
  }
  .integrations {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
  .integrations > .seriph-card {
    flex: calc(50% - 48px) 0;
    width: auto !important;
    padding: 32px;
    margin: 0 12px;
    margin-bottom: 24px;
    border: 1px solid #e7ecf4;
    .int {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100%;
      .int-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        .int-check {
          width: 24px;
          height: 24px;
          background: #c1ddbb;
          border-radius: 12px;
          margin-left: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 16px;
            height: 16px;
            stroke-width: 1.75px;
            color: #090e15;
          }
        }
        .int-logo {
          text-align: center;
          img,
          svg {
            width: 40px;
            height: 40px;
            stroke-width: 1.25px;
            &.salesforce {
              width: 40px;
              height: 40px;
            }
          }
        }
        h3.int-name {
          margin: 0 0 0 16px;
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #090e15;
          white-space: nowrap;
          text-overflow: ellipsis;
          > span {
            font-size: 12px;
            font-weight: 500;
            color: #909Bb0;
            margin-left: 4px;
            position: relative;
            top: -1px;
          }
          p {
            font-size: 12px;
            margin: 0;
            line-height: 12px;
            font-weight: 400;
            color: #707B90;
          }
        }
      }
      p.int-desc {
        text-align: left;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin-top: 16px;
        flex: 1;
      }
      .int-actions {
        height: 40px;
        text-align: right;
        margin-top: 16px;
        button {
          margin-left: 8px;
        }
      }
    }
  }
}
