@import '_seriph/variables';

#call-information {
  flex: 1;
  position: relative;
  background: #F5F7FB;
  height: 100%;

  .ant-tabs {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    .ant-tabs-content {
      overflow-y: auto;
      margin-bottom: 104px;
    }
  }
  .pane-box {
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-tabs-bar {
    background: #fff;
  }
  .meeting-actions {
    z-index: 99;
    position: absolute;
    top: 12px;
    right: 104px;
    > button {
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .general-actions {
    z-index: 99;
    position: absolute;
    right: 16px;
    top: 80px;
    height: calc(100% - 104px);
    button {
      display: flex;
      margin-bottom: 12px;
    }
    .ant-badge-count {
      background: #C4CBD9;
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12x;
        color: #090E15;
      }
    }
    &.opened {
      z-index: 99999;
      background: #ffffff;
      top: 0;
      right: 0;
      padding-top: 80px;
      padding-right: 16px;
      padding-left: 16px;
      border-left: 1px solid #d7dde9;
    }
    .manage {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      border-radius: 20px;
      overflow: hidden;
      text-align: center;
      padding: 0;
      display: flex;
      margin-bottom: 12px;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #d7dde9;
      &:hover {
        color: #090e15;
        background: #f5f7fb;
        border: 1px solid #d7dde9;
      }
      .paddme {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
      }
      .add-lead-tag {
        margin: 0;
        background: transparent;
      }
    }
  }

  .slideout {
    width: 300px;
    height: calc(100% - 104px);
    position: absolute;
    right: -316px;
    top: 0;
    background: #ffffff;
    box-shadow: none;
    z-index: 9999;
    transition: right 0.3s linear;
    border-left: 1px solid #e5e7eb;
    &.opened {
      right: 72px;
    }
  }

}