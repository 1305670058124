#company-settings-plan-subscription {
  position: relative;
  width: 100%;
  padding: 0;
  height: 260px;
  min-height: 260px;
  .settings-card {
    height: 100%;
    padding: 24px !important;
  }
  .cancel {
    position: absolute;
    right: 24px;
    top: 24px;
    color: #B34043 !important;
    font-weight: 500 !important;
  }
  .renew {
    position: absolute;
    right: 24px;
    top: 24px;
  }
  .my-reps {
    margin: 24px 0;
    padding-left: 8px;
    > span {
      margin-left: -8px;
      img {
        border-radius: 20px;
        border: 3px solid #ffffff;
        background: #fff;
        filter: none !important;
      }
    }
  }
  .my-actions {
    border-top: 1px solid #E7ECF4;
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .left-actions {
      flex: 1;
      button {
        margin-right: 8px;
      }
    }
    .tbl-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: transparent;
      border-radius: 100px;
      cursor: pointer;
      &:hover {
        background: #e7ecf4;
      }
    }
  }
}
