@import '_seriph/variables';

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  .seriph-pagination.ant-pagination {
    margin: 0;
    > li {
      border-radius: 12px;
      border-color: transparent;
    }
    > li,
    > li a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: none !important;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      background-color: transparent;
    }
    li > a i svg {
      color: #090e15 !important;
    }
    .ant-pagination-disabled,
    .ant-pagination-disabled a {
      color: #adb5c5 !important;
      background: transparent !important;
      cursor: not-allowed !important;
      i svg {
        color: #adb5c5 !important;
      }
    }
    li:hover,
    li:hover a {
      background: #e7ecf4;
    }
    .ant-pagination-item-active,
    .ant-pagination-item-active a {
      color: #fff !important;
      background: #090e15 !important;
    }
    .ant-pagination-prev a,
    .ant-pagination-next a {
      border-color: transparent;
    }
  }
  .page-info {
    font-size: 12px;
    line-height: 32px;
    color: #929aaa;
    height: 18px;
  }
}
