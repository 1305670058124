@import '_seriph/variables';

.seriph-autocomplete {
  .autocomplete-box {
    position: relative;

    .ant-select-auto-complete {
      width: 100%;
      .ant-select-selection {
        border-radius: 10px;
        .ant-select-search input {
          background: #27292a !important;
          height: 56px !important;
          color: #fdfaef !important;
          padding: 0 42px !important;
        }
        .ant-select-selection__placeholder {
          color: #565c61;
          z-index: 10;
          height: 56px;
          margin-top: 0;
          padding: 2px 32px;
        }
      }
      margin-bottom: 10px;
    }
    .autocomplete-icon-left {
      position: absolute;
      left: 16px;
      top: 20px;
      z-index: 10;
      svg {
        width: 16px;
        height: 16px;
        stroke-width: 1.75;
        color: #565c61;
      }
    }
  }
}
