@import 'variables';

.sxd-progress-container {
  position: relative;
  width: 100%;
  .in-progress {
    position: absolute;
    top: -30px;
    width: 100%;
    height: 8px;
    background: $in-progress;
    border-radius: 100px;
  }
  .sxd-progress {
    .ant-progress-text {
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
    .ant-progress-success-bg {
      background: #017af2;
    }
  }
}
