@import '_seriph/variables';

.ant-select.seriph-select-multiple-simple {
  .ant-select-selection--multiple {
    min-height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 7px !important;
  }
  &.ant-select {
    width: 100%;
    .ant-select-selection__clear {
      background: #e7ecf4;
      svg {
        color: #090e15;
      }
    }
    .ant-select-selection {
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
      border-radius: 8px;
      box-shadow: none;
      .ant-select-selection__placeholder {
        font-family: $font-major;
        font-size: 14px;
        color: #090e15;
      }
      .ant-select-selection-selected-value {
        font-family: $font-major;
        font-size: 14px;
        color: #090e15;
      }
    }
    &:hover .ant-select-selection {
      border-color: #c4cbd9;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus {
      background: #ffffff;
      border-color: #c4cbd9;
      outline: 0;
      box-shadow: none;
    }
    // &.ant-select-disabled .ant-select-selection {
    //   background: #f5f7fb !important;
    //   border: 1px solid #f5f7fb !important;
    //   .ant-select-selection__placeholder,
    //   .ant-select-selection-selected-value,
    //   .ant-select-arrow svg {
    //     color: #adb5c5 !important;
    //   }
    // }
  }
  .ant-select-selection {
    font-size: 14px;
    ul > li.ant-select-selection__choice {
      border: 1px solid #dce0e8;
      border-radius: 16px;
      background-color: #090e15;
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      padding-right: 24px;
      > span {
        margin-right: 4px;
      }
      svg {
        color: #fff;
      }
    }
  }
}
