@import '_seriph/variables';

.seriph-radio-group {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #090E15;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    background: #090E15;
    border-color: #090E15;
    box-shadow: none !important;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: 0 !important;
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #090E15;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    border-color: #090E15;
    box-shadow: none !important;
  }
}


