@import '_seriph/variables';

#discover-user {
  margin-bottom: 16px;
  box-shadow: none !important;
  padding: 24px;
  .user-information {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 24px;
    margin-bottom: 16px;
    .user-status {
      flex: 1;
      padding-left: 15px;
      position: relative;
      div {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        h3 {
          font-weight: 500;
          font-family: $font-major;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin-bottom: 4px;
        }
        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #4d5b76;
          margin: 0;
        }
      }
    }
  }
  .user-progress {
    h3 {
      font-family: $font-major;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #090e15;
      margin-top: 0;
      margin-bottom: 2px;
      text-align: center;
    }
    .ant-progress-bg {
      background-color: #c1ddbb !important;
    }
    .status-bar {
      position: relative;
      width: 100%;
      display: block;
      height: 12px;
      border-radius: 5px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.05);
      div {
        background-color: #7bea69;
        border-radius: 5px 0 0 5px;
        position: absolute;
        height: 100%;
        transition: width 1s ease;
      }
    }
    .user-update {
      text-align: center;
      margin-top: 12px;
      a {
        font-size: 12px;
      }
    }
  }
}
