/* FONTS */
$font-stack: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
$font-tt: 'TT Interfaces', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
/* END FONTS */

/* START COLORS */

/* STATUS COLORS */
$in-progress: #00d5f2;
$review: #ae84f9;
$generated: #017af2;
$qualified: #7bea69;
$completed: #017af2;
$not-interested: #ffd23d;
$disputed: #fd8321;

// Primary
$primary-blue: #4353ff;
$primary-lime: #6bf555;
$primary-sky: #43e8ff;

// Accent
$accent-purple: #5e0cef;
$accent-orange: #fd8321;
$accent-red: #ef1e51;
$accent-yellow: #ffd53e;

// Neutral
$neutral-1000: #000000;
$neutral-900: #242d3e;
$neutral-800: #4d5b76;
$neutral-700: #707b90;
$neutral-600: #929aaa;
$neutral-500: #adb5c5;
$neutral-400: #c4cbd9;
$neutral-300: #d7dde9;
$neutral-200: #e7ecf4;
$neutral-100: #f5f7fb;
$neutral-50: #fafbfd;
$neutral-0: #ffffff;

// Positive
$positive-1000: #0017b0;
$positive-900: #013fc0;
$positive-800: #0869d0;
$positive-700: #128fde;
$positive-600: #23ade9;
$positive-500: #3ac4f1;
$positive-400: #59d5f7;
$positive-300: #80e4fb;
$positive-200: #aef0fe;
$positive-100: #def9ff;

// Negative
$negative-1000: #d82c05;
$negative-900: #fc5a13;
$negative-800: #fd8321;
$negative-700: #feab2f;
$negative-600: #ffd23d;
$negative-500: #fede67;
$negative-400: #fce894;
$negative-300: #faf2c2;
$negative-200: #fdfae8;
$negative-100: #fffded;

// Blue
$blue-900: #090e15;
$blue-800: #090e15;
$blue-700: #250d93;
$blue-600: #2924ce;
$blue-500: #4353ff;
$blue-400: #528aff;
$blue-300: #75bdff;
$blue-200: #bdeaff;
$blue-100: #e7f8ff;
$blue-50: #f5fcff;

// Lime
$lime-900: #1f4738;
$lime-800: #296641;
$lime-700: #389e4b;
$lime-600: #38cf36;
$lime-500: #6bf555;
$lime-400: #83fd6f;
$lime-300: #abff86;
$lime-200: #d7feb6;
$lime-100: #efffe1;
$lime-50: #f9fff2;

// Sky
$sky-900: #00315c;
$sky-800: #00538a;
$sky-700: #0077b2;
$sky-600: #009dd6;
$sky-500: #1fd6ff;
$sky-400: #43e8ff;
$sky-300: #6bfaff;
$sky-200: #99fdff;
$sky-100: #d1fffb;
$sky-50: #f0fffe;

// Purple
$purple-900: #250066;
$purple-800: #3d03a0;
$purple-700: #4c06c6;
$purple-600: #5e0cef;
$purple-500: #6f55f7;
$purple-400: #8472f8;
$purple-300: #9b94fa;
$purple-200: #b5b5fd;
$purple-100: #d6daff;
$purple-50: #f5f6ff;

// Data
$data-blue: #017af2;
$data-purple: #ae84f9;
$data-sky: #00d5f2;
$data-orange: #fd8321;
$data-green: #7bea69;
$data-yellow: #ffd23d;

/* END COLORS */
