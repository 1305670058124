@import '_seriph/variables';

.seriph-daypicker {
  min-width: 200px;
  input {
    padding: 0 16px;
    height: 48px;
    line-height: 48px;
    outline: 0;
    box-shadow: none;
    border-radius: 10px;
    background: #e7ecf4;
    border: 1px solid #e7ecf4;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    overflow: hidden;
    &::placeholder {
      color: #4d5b76;
    }
    &:hover {
      border: 1px solid #c4cbd9;
    }
    &:focus {
      border: 1px solid #c4cbd9;
      background: #fff;
    }
    &[disabled] {
      border: 1px solid #f5f7fb;
      background: #f5f7fb;
      color: #adb5c5;
      &::placeholder {
        color: #adb5c5;
      }
    }
  }
  &:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #c4cbd9 !important;
  }
  .ant-calendar-picker-clear {
    background: #e7ecf4;
  }
}
