#admin-company-applications {
  padding: 40px 30px 30px 30px;
  .admin-header {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    position: relative;
    button {
      position: absolute;
      right: 320px;
      top: 0;
      svg {
        margin-right: 8px;
      }
    }
    > div {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      position: relative;
      left: 0;
      top: -5px;
    }
    .seriph-input {
      width: 300px;
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
    }
  }
  .multi button:first-child {
    margin-right: 8px;
  }
  .multi button {
    width: 100px;
  }
  .seriph-tag > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      position: relative;
      top: -1px;
    }
  }
  .admin-table {
    .app-status {
      font-weight: 600;
      color: #fff;
      padding: 8px 12px;
      border-radius: 8px;
      background: #ffd53e;
      &.Passed {
        background: #ef1e51;
      }
      &.Invited {
        background: #4353ff;
      }
      &.Joined {
        background: #38cf36;
      }
    }
    .lead-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      .lead-photo {
        padding-right: 10px;
        img {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }
        span {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 20px;
          line-height: 30px;
          text-align: center;
          background: #ecedff;
          color: #4353ff;
          font-weight: 600;
        }
      }
    }
    .agent-email {
      svg {
        color: #30f09e;
        margin-left: 8px;
      }
    }
  }
}
