#business {
  #create-campaign .title-bar {
    width: calc(100% - 70px);
  }
}

#create-campaign {
  .optional {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    color: #999;
    margin-left: 10px;
  }
  .title-bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    height: 80px;
    border-bottom: 1px solid #e6e9ef;
    background: #fff;
    right: 0;
    top: 0;
    z-index: 99;
    -webkit-transition: background-color 500ms linear, width 0.2s ease;
    -ms-transition: background-color 500ms linear, width 0.2s ease;
    transition: background-color 500ms linear, width 0.2s ease;
    .name {
      display: flex;
      height: 80px;
      flex: 1;
      align-items: center;
      padding-left: 40px;
      h1 {
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        color: #000000;
        margin: 0;
        display: inline-block;
      }
      .status {
        display: inline-block;
        background: #e2e2e2;
        color: #555;
        border-radius: 20px;
        padding: 8px 12px;
        line-height: 14px;
        margin-left: 15px;
        font-weight: 600;
        position: relative;
        top: -2px;
        text-transform: capitalize;
        &.status-inactive {
          background-color: #ffc900;
          color: #fff;
        }
        &.status-active {
          background-color: #00b533;
          color: #fff;
        }
      }
      .progress {
        display: inline-block;
        color: #bbb;
        padding: 6px 8px;
        line-height: 14px;
        margin-left: 15px;
        font-weight: 400;
        position: relative;
        top: -1px;
        font-style: italic;
      }
    }
    .actions {
      text-align: right;
      white-space: nowrap;
      padding-right: 40px;
      line-height: 80px;
      .sep {
        display: inline-block;
        width: 1px;
        height: 40px;
        background: #ccc;
        line-height: 40px;
        position: relative;
        top: 15px;
        margin-left: 10px;
        margin-right: 10px;
      }
      button {
        margin-left: 15px;
      }
    }
  }
  .cc-body {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    height: calc(100vh - 80px);
    width: 100%;
    .cc-main {
      flex: 1;
      padding: 32px 32px 32px 312px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      scroll-behavior: smooth !important;
    }
    #step-list {
      position: fixed;
      width: 280px;
      min-width: 280px;
      left: 70px;
      top: 80px;
    }
  }
  .objective-info {
    background: #c1fae2;
    border-radius: 5px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #0b1e41;
    padding: 15px;
    margin-top: 15px;
    b {
      font-weight: 500;
    }
    &.qualify {
      color: #142e24;
      background: #b5faaa;
    }
    &.leadgen {
      color: #250d93;
      background: #a9e3ff;
    }
  }
  .add-question {
    text-align: center;
    margin: 32px 0 24px 0;
    button svg {
      margin-right: 10px;
    }
  }
  .quest {
    padding: 32px 30px 0 30px;
    .quest-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      input {
        flex: 2;
        margin-right: 16px;
      }
      .ant-select {
        flex: 1;
      }
      button {
        width: 50px;
        margin-left: 16px;
      }
    }
    .quest-options {
      .quest-option {
        margin-top: 16px;
        input {
          height: 32px !important;
          margin: 0 16px;
        }
        .ant-radio-wrapper {
          margin-right: 0;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .remove-option {
      margin-right: 12px;
      cursor: pointer;
      svg {
        font-size: 13px;
        opacity: 0.5;
      }
      .fa-trash-alt {
        color: #0b1e41;
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
    .add-option {
      margin-top: 24px;
    }
    .quest-sep {
      display: block;
      height: 1px;
      background: #e6e9ef;
      width: 100%;
      margin-top: 32px;
    }
  }
}

#create-campaign.edited {
  .title-bar {
    background-color: #fffde7;
  }
}
.step-container {
  display: flex;
  flex-direction: row;
  .step-tips {
    padding: 0 0 0 32px;
    width: 230px;
    position: relative;
    .step-tip {
      border: 1px solid #e6e9ef !important;
      background: #fff;
      border-radius: 5px;
      padding: 24px 16px;
      .tip-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin: 0 0 16px 0;
        svg {
          margin-right: 6px;
        }
      }
      .tip-item {
        font-size: 13px;
        line-height: 18px;
        color: #4d5b76;
        margin: 0 0 16px 0;
        b {
          font-weight: 500;
        }
      }
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.step-card {
  padding: 32px 2px 17px 2px !important;
  border: 1px solid #e6e9ef !important;
  margin-bottom: 32px;
  flex: 1;
  .step-group {
    padding: 0 30px 10px 30px;
    > span {
      display: inline-block;
      width: 25%;
      margin-top: 20px;
      &.sg-disable {
        color: rgba(0, 0, 0, 0.3);
      }
      > .ant-checkbox-wrapper {
        margin-right: 10px;
      }
    }
  }
  .step-title {
    padding: 0 30px;
    > h3 {
      font-size: 22px;
      line-height: 150%;
      font-weight: 600;
      color: #090e15;
      margin-bottom: 5px;
      font-family: 'Euclid Circular A', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
      &.invalid {
        color: #ff3768 !important;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #999;
        margin-left: 10px;
      }
    }
    > h4 {
      font-size: 18px;
      line-height: 150%;
      font-weight: 600;
      color: #090e15;
      margin-bottom: 5px;
      font-family: 'Euclid Circular A', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    }
    > p {
      font-size: 13px;
    }
    &.second-title {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e6e9ef;
    }
  }
  .step-sub {
    margin-top: 20px;
    padding: 0 30px;
    > h3 {
      font-size: 18px;
      line-height: 150%;
      font-weight: 600;
      color: #090e15;
      margin-bottom: 5px;
      &.invalid {
        color: #ff3768 !important;
      }
    }
    > p {
      font-size: 13px;
    }
  }
  .step-box {
    padding: 0 30px;
    .step-review {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #e6e9ef;
      padding: 20px 0;
      > .sr-title {
        font-size: 14px;
        font-weight: 600;
        color: #090e15;
        flex: 5;
      }
      > .sr-value {
        flex: 12;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .custom-input {
    padding: 15px 30px;
    > div {
      font-weight: 500;
      color: #4353ff;
      cursor: pointer;
      display: inline-block;
      &:hover {
        opacity: 0.85;
        text-decoration: underline;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  .step-body {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
  }
  .step-input {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    border-radius: 5px;
    &:hover {
      background-color: #f5f7fa;
    }
    &.step-instruct:hover,
    &.no-hover:hover {
      background-color: transparent;
    }

    > .input-label {
      font-family: 'Euclid Circular A', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
      font-weight: 500;
      color: #090e15;
      margin-bottom: 8px;
      > p {
        font-size: 13px;
        font-weight: 400;
        color: rgba(10, 30, 66, 0.75);
      }
      > .ant-select {
        width: 100%;
      }
      &.invalid {
        color: #ff3768 !important;
      }
    }
    &.column {
      flex-direction: column;
      > .input-label {
        width: 100%;
      }
      > .input-sub-label {
        font-size: 13px;
        line-height: 21px;
        color: #4d5b76;
        margin-bottom: 8px;
      }
    }
    .input-info {
      font-size: 12px;
      line-height: 21px;
      color: #77839b;
      padding: 8px;
    }
    > .input-entry {
      flex: 1;
      &.input-icon {
        position: relative;
        input {
          padding-left: 42px;
        }
      }
      .icon-input {
        position: absolute;
        left: 16px;
        font-size: 16px;
        top: 16px;
      }
      &.custom-entry {
        display: flex;
        flex-direction: row;
        .custom-field {
          flex: 1;
          > .custom-text {
            line-height: 50px;
          }
        }
        .custom-remove {
          line-height: 50px;
          margin-left: 20px;
          cursor: pointer;
          &:hover {
            color: #4353ff;
          }
        }
      }
      .entry-search {
        position: relative;
        svg {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 16px;
          color: #4d5b76;
        }
      }
      &.input-row {
        display: flex;
        flex-direction: row;
        > .ant-select {
          width: 30%;
        }
        > .select-to {
          margin: 0 30px;
          line-height: 50px;
          font-weight: 500;
        }
      }
      &.input-half {
        > .ant-input-number {
          width: 50%;
        }
        > .suffix {
          display: inline-block;
          margin-left: 20px;
          font-weight: 400;
          color: #090e15;
        }
      }
      &.input-quarter {
        > .ant-input-number {
          width: 25%;
        }
        > .suffix {
          display: inline-block;
          margin-left: 20px;
          font-weight: 400;
          color: #090e15;
        }
      }
    }
  }
  .step-input.step-instruct {
    flex-direction: column;
    height: 220px;
    textarea.forms {
      min-height: 50px !important;
    }
    .quill {
      height: calc(100% - 42px);
    }
    .instruct-list {
      div {
        margin-bottom: 18px;
        display: flex;
        flex-direction: row;
        p {
          line-height: 26px;
          margin: 0;
          span {
            display: inline-block;
            width: 20px;
            font-weight: 500;
          }
          flex: 1;
        }
        button {
          visibility: hidden;
          color: #fff;
          background-color: #ff4d4f;
          border-color: #ff4d4f;
          min-width: 26px;
          margin-left: 15px;
          width: 26px;
          padding: 0;
          font-size: 14px;
          border-radius: 50%;
          height: 26px;
          -moz-transition: none;
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
          &:hover {
            opacity: 0.6;
          }
        }
        &:hover {
          button {
            visibility: visible;
          }
        }
      }
    }
    .instruct-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      button {
        margin-right: 20px;
      }
      div {
        color: #8a9eb2;
        width: 120px;
        line-height: 14px;
      }
    }
  }
  .no-files {
    background: #f7f8fa;
    padding: 30px;
    border-radius: 5px;
    text-align: center;
    margin: 0 30px;
    h2 {
      font-size: 14px;
      line-height: 21px;
      color: #0b1e41;
      margin-bottom: 16px;
    }
    .no-upload {
      margin-bottom: 16px;
    }
    .nf-types {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #4d5b76;
    }
  }
  .step-files {
    padding: 15px 20px 0 20px !important;
  }
  .step-sub.border-top {
    border-top: 1px solid #e6e9ef;
    padding-top: 30px;
  }
}

.wizard-row {
  .step-input {
    flex-direction: column;
    .input-label {
      width: 100%;
    }
  }
}

.forms {
  .input-icon {
    position: relative;
  }
  &.ant-input,
  &.ant-input-number {
    color: #333 !important;
    font-size: 14px !important;
    height: 50px !important;
  }
  &.ant-select > .ant-select-selection {
    height: 50px !important;
    .ant-select-selection__rendered {
      line-height: 48px !important;
      font-size: 14px !important;
    }
    &:hover {
      border-color: #4353ff !important;
    }
  }
  &.ant-select-focused > .ant-select-selection {
    border-color: #4353ff !important;
  }

  &.ant-input:focus,
  &.ant-input:hover {
    border-color: #4353ff !important;
  }
  &.forms-textarea {
    height: auto !important;
  }
  &.ant-input-number input {
    height: 48px;
    font-size: 14px;
  }
  &.ant-textarea {
    color: #333 !important;
    font-size: 14px !important;
    min-height: 50px !important;
    height: auto !important;
  }
  &.ant-textarea:focus,
  &.ant-textarea:hover {
    border-color: #4353ff !important;
  }
}

.forms-light {
  &.ant-select > .ant-select-selection {
    height: 50px !important;
    .ant-select-selection__rendered,
    .ant-select-selection__rendered > ul > li {
      line-height: 48px !important;
      font-size: 14px !important;
    }
    .ant-select-selection__rendered > ul > li {
      height: 48px !important;
      line-height: 48px !important;
      font-size: 14px !important;
      margin: 0 !important;
      padding-left: 36px;
    }
    &:hover {
      border-color: #4353ff !important;
    }
  }
  .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin: 0 !important;
    padding-left: 36px;
    height: 48px !important;
    line-height: 48px !important;
    top: 0px !important;
  }
  &.ant-select-focused > .ant-select-selection {
    border-color: #4353ff !important;
  }
}
