@import '_seriph/variables';

#internal-campaign-cards {
  margin-top: 48px;
  position: relative;
  > h3 {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 0 0 20px 0;
  }
  > a {
    position: absolute;
    right: 0;
    top: 0;
  }
  .icc-cards {
    display: flex;
    flex-direction: row;
    margin: 0 -8px;
    > div {
      margin: 0 8px;
      padding: 24px;
      display: flex;
      max-width: 50%;
      flex-direction: column;
      > h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090E15;
        margin: 24px 0 0 0;
        flex: 1 1;
      }
      > p {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #707B90;
      }
    }
  }
}
