@import '_seriph/variables';

.seriph-selection {
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 10px;
    background: #fff;
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #090e15;
    font-family: $font-major;
    border: 1px solid #e7ecf4;
    min-height: 80px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    > div {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #4d5b76;
      font-weight: 400;
      user-select: none;
    }
    > .opt-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #e7ecf4;
        border-radius: 16px;
        margin-right: 16px;
        svg {
          color: #090e15;
          width: 14px;
          height: 14px;
        }
      }
      > span {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
      }
    }
    &:hover {
      border: 1px solid #090e15;
    }
    &.selected {
      box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
      color: #090e15;
      border: 1px solid #090e15;
      > div {
        color: #4d5b76;
      }
      .opt-icon {
        .icon-circle {
          background: #090e15;
          svg {
            color: #fff;
          }
        }
      }
    }
    .helper {
      position: absolute;
      top: 6px;
      right: 10px;
      font-size: 10px;
    }
  }

  &.disabled > div {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      border: 1px solid #e7ecf4;
    }
    &.selected {
      border: 1px solid #090e15;
    }
  }
}
