@import '_seriph/variables';

#company-profile-about {
  .about-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    > h5 {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0 16px 0 0;
      padding: 0;
      white-space: nowrap;
    }
    > div {
      flex: 1;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      text-align: right;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }
  .social a {
    margin-left: 12px;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #090e15;
    svg {
      color: #090e15;
      width: 18px;
      height: 18px;
    }
  }
}
