@import '_seriph/variables';

#messaging-list {
  width: 100%;
  flex: 1;
  max-height: calc(100% - 68px);
  position: relative;
  height: 100%;
  .list-header {
    padding: 32px 24px 8px 24px;
    flex: 1;
  }
  .category {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    button {
      margin-top: 16px;
      margin-right: 16px;
    }
  }
  .category-list {
    overflow-y: scroll;
    height: calc(100% - 61px);
    max-height: calc(100% - 61px);
    padding: 0 12px;
    &.bottom {
      height: calc(40% - 61px);
      max-height: calc(40% - 61px);
    }
  }
  .no-messages {
    padding: 32px;
    width: 100%;
    text-align: center;
  }
  .load-messages {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      font-size: 32px;
      color: rgba(0, 0, 0, 0.2);
    }
  }
  .message {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 16px 12px;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 4px;
    .message-alerting {
      width: 8px;
      height: 8px;
      border-radius: 6px;
      background: #4353ff;
      margin-left: 8px;
      display: inline-block;
    }
    &.message-active {
      background: #e7ecf4;
    }
    &:hover {
      background: #e7ecf4;
    }
    .message-content {
      flex: 1;
      margin-left: 8px;
      min-width: 0;
      .message-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        .seriph-header {
          flex: 1;
        }
        .tstamp {
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #707b90;
        }
      }

      h4 {
        color: #555;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
      }
      .message-body {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        margin-top: 2px;
      }
    }

    .unread {
      display: block;
      margin-left: 20px;
      color: #fff;
      background: #ef5350;
      font-size: 12px;
      width: 14px;
      height: 14px;
      font-weight: 600;
      border-radius: 12px;
      text-align: center;
    }
    .read {
      display: block;
      margin-left: 20px;
      color: #fff;
      background: transparent;
      font-size: 12px;
      width: 14px;
      height: 14px;
      font-weight: 600;
      border-radius: 12px;
      text-align: center;
    }
    &.message-unread {
      .message-content {
        h3 {
          font-weight: 600;
        }
        h4 {
          font-weight: 500;
          color: #000;
        }
        p {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }
}
