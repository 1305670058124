@import '_seriph/variables';

.salary-modal {
  .salary-form-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f7fb;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 12px;

    .salary-form {
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-template-rows: 60px 60px 60px;
      align-items: center;
    }
    .salary-buttons {
      display: flex;
      flex-direction: column;
      button {
        margin: 6px 0;
      }
    }
  }
}
