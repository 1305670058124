.icon-wrapper {
  background-color: #e11d48;
  padding: 5.5px 7.25px;
  border-radius: 50%;
  border: 4px solid #fff1f2;
}

.call-icon {
  color: white;
}
