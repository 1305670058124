.ant-select.seriph-multiple-locations {
  min-height: 56px;
  &.max {
    .ant-select-search__field {
      pointer-events: none;
      display: none;
    }
  }
  .ant-select-selection {
    font-size: 14px;
    min-height: 56px !important;
    ul {
      overflow: auto;
      content: '';
      clear: both;
      position: relative;
      margin: 0 -4px;
      top: 8px;
    }
    ul > li.ant-select-selection__choice {
      margin: 3px 5px;
      border: 1px solid #dce0e8;
      border-radius: 16px;
      background-color: #090e15;
      color: #fff;
      font-size: 14px;
      line-height: 32px;
      font-weight: 500;
      height: 32px;
      padding: 0 6px 0 12px;
      > div {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
      }
      > span {
        position: relative;
        border-radius: 10px;
        border: 1px solid #090e15;
        margin-left: 10px;
        float: right;
        height: 16px;
        width: 16px;
        line-height: 14px;
        > i {
          font-size: 10px;
          color: #090e15;
        }
        top: 7px;
        &:hover {
          opacity: 0.75;
        }
      }
    }
    ul > li.ant-select-search {
      margin-left: 5px;
    }
  }
  .ant-select-selection__placeholder {
    font-size: 14px;
  }

  .ant-select-search__field__wrap {
    input {
      color: #090e15;
    }
  }

  &.ant-select-focused {
    .ant-select-selection__placeholder {
      display: none !important;
    }
    .ant-select-selection {
      background: #fff !important;
    }
  }

  .ant-select-search--inline {
    position: relative;
    height: 30px;
    line-height: 28px;
  }

  .ant-select-selection--multiple {
    padding-bottom: 0 !important;
    min-height: 56px;
    height: 56px;
    box-shadow: none !important;
    .ant-select-selection__rendered {
      margin-bottom: 0 !important;
      line-height: normal !important;
      min-height: 56px;
      position: relative;
      margin-left: 16px;
      margin-right: 16px;
    }
    .ant-select-selection__placeholder {
      font-size: 14px !important;
      margin-left: 0;
      color: #4d5b76 !important;
    }
    .ant-select-selection__choice {
      .ant-select-selection__choice__remove i {
        font-size: 14px !important;
        color: #fff !important;
      }
    }
  }
}

.seriph-locations-drop-max {
  display: none;
}
