@import 'variables';

.qualify-confirmation {
  .dtp {
    padding: 0 8px;
    margin-bottom: 24px;
    .datetime {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 -8px 12px -8px;
      .seriph-input-date,
      .seriph-input-time {
        flex: 1;
        margin: 0 8px;
      }
    }
    .ant-select,
    .ant-time-picker {
      width: 100%;
    }
  }
  .ant-modal-content {
    width: 456px;
    .ant-modal-body {
      padding: 32px;
      .stage-one {
        .stage-icon {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 213, 62, 0.24);
          color: #fd8321;
          margin: 0 auto 24px;
          &.missing {
            margin-top: 32px;
            background: #ef1e51;
            color: #fff;
          }
        }
        h3 {
          font-weight: 600;
          font-size: 20px;
          color: #090e15;
          margin: 0 0 0 0;
          font-family: $font-tt;
          text-align: center;
        }
        p {
          font-size: 14px;
          line-height: 21px;
          color: #4d5b76;
          margin: 12px 0 24px 0;
        }
        .stage-actions {
          margin-top: 24px;
          text-align: right;
          button {
            margin-left: 16px;
          }
        }
        .missing-list {
          margin-left: -4px;
          > div {
            display: inline-block;
            align-items: center;
            justify-content: center;
            background: rgba(255, 55, 104, 0.08);
            border-radius: 4px;
            color: #ff3768;
            font-weight: 500;
            padding: 4px 8px;
            margin: 4px;
            text-transform: capitalize;
          }
        }
        .submit-type {
          .ant-select {
            width: 100%;
          }
          margin: 16px 0;
        }
        .interest-options {
          display: block;
          flex-direction: row;
          align-items: left;
          margin-top: 0px;
          > div {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            margin-top: 12px;
            margin-right: 12px;
            background: #ffffff;
            border: 1px solid #e7ecf4;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 16px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #090e15;
            flex: 1;
            cursor: pointer;

            &:hover {
              background: #f5f7fb;
              border: 1px solid #e7ecf4;
            }
            transition: all 0.2s ease;
          }
        }
      }
      .stage-two {
        h3 {
          font-size: 20px;
          line-height: 22px;
          color: #000000;
          margin: 0 0 0 0;
        }
        p {
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #4d5b76;
          margin: 12px 0 12px 0;
        }
      }
      .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -20px;

        .back-cont,
        .close-cont {
          width: 32px;
        }

        .title-cont {
          color: #090e15;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
        }

        .back-icon,
        .close-icon {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #f5f7fb;
          color: #090e15;
          cursor: pointer;
          &:hover {
            background: #e7ecf4;
          }
        }
      }

      .interested {
        margin-top: 44px;
        p {
          color: #4d5b76;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .bottom-row {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
      }

      .reason {
        margin-top: 44px;
        padding: 0 12px;
        p {
          color: #090e15;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
        }
        .ant-radio-group {
          width: 100%;
          label {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px;
            border-radius: 12px;
            &:hover {
              background: #f5f7fb;
            }
            color: #090e15;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            .ant-radio {
              padding-right: 6px;
            }

            &.ant-radio-wrapper-checked {
              background: #f5f7fb;
              align-items: start;
              .ant-radio {
                margin-top: 3px;
              }
              > span {
                &:not(.not-ant-radio) {
                  .inline-input {
                    p {
                      font-size: 12px;
                      color: #4d5b76;
                      line-height: 18px;
                      margin: 12px 0;
                    }
                    display: block;
                    margin-left: -30px;
                    width: 328px;
                  }
                }
              }
            }
          }
        }
      }
    }
    textarea {
      &.filled {
        background: #ffffff;
      }
    }
  }
}
