.launch-campaign-modal {
  h3 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin: 0 0 20px 0;
  }
  .hiring {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 200px;
      margin-right: 20px;
    }
    .hiring-info {
      flex: 1;
      p {
        color: #000;
      }
    }
  }
  .hiring-actions {
    margin-top: 10px;
    text-align: right;
    > button:first-child {
      margin-right: 15px;
    }
  }
  .no-agents {
    p {
      font-size: 16px;
    }
  }
  .start-table {
    border-radius: 8px;
    h4 {
      padding: 12px;
      background: #e6e9ef;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin: 0;
      font-weight: 600;
    }
    .st-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-left: 1px solid #e6e9ef;
      border-right: 1px solid #e6e9ef;
      border-bottom: 1px solid #e6e9ef;
      > div {
        padding: 10px 12px;
        color: #000;
        &:first-child {
          flex: 1;
        }
        &:last-child {
          flex: 1;
          text-align: right;
        }
        b {
          font-weight: 600;
        }
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  .of-note {
    margin-top: 20px;
    font-size: 12px;
  }
}
