.manage-admin-modal {
  .ant-modal-body {
    padding: 0;
  }
  .template-content {
    position: relative;
    .add-tag {
      width: 130px !important;
      position: absolute;
      right: 16px;
      top: 8px;
    }
    .ql-editor[contenteditable='false'] {
      background: #f5f5f5 !important;
      cursor: not-allowed;
    }
    &.disabled {
      button {
        cursor: not-allowed;
      }
    }
  }
  .template {
    .template-header {
      padding: 16px;
      .add-type {
        width: 180px;
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 9;
        .ant-select-selection-selected-value {
          display: flex !important;
          flex-direction: row;
          align-items: center;
          svg {
            width: 28px !important;
            height: 28px !important;
          }
          > span {
            font-weight: 500;
            font-size: 14px;
            color: #090e15;
            text-transform: capitalize;
            margin-left: 12px;
          }
        }
      }
      .add-tag {
        width: 180px;
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 9;
      }
      input {
        line-height: 40px;
        padding: 0 8px;
        color: #090e15 !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        text-align: center;
        color: #090e15 !important;
        border: 0 !important;
        background: transparent !important;
        height: 40px;
      }
    }

    .template-info {
      border: 0;
      border-radius: 0;
      border-top: 1px solid #e7ecf4;
      height: 56px;
      line-height: 56px;
      font-weight: 500;
      font-size: 16px;
      color: #090e15;
      outline: 0;
      box-shadow: none;
      padding-left: 16px;
      padding-right: 16px;
    }
    .ql-toolbar {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .ql-container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    z-index: 9;
    &:hover {
      background: #e5e7eb;
    }
  }
  .template-actions {
    position: relative;
    text-align: right;
    padding: 16px 32px;
    .invalid-tags {
      position: absolute;
      left: 16px;
      background: #fff1bf;
      border-radius: 8px;
      height: 40px;
      line-height: 22px;
      padding: 9px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        color: #090e15;
      }
      span {
        width: 4px;
        height: 22px;
        background: #fd8321;
        border-radius: 32px;
        display: inline-block;
        margin-right: 16px;
      }
    }
    .preview-tags {
      position: absolute;
      left: 16px;
      background: #f5f7fb;
      border-radius: 8px;
      height: 40px;
      line-height: 22px;
      padding: 9px 16px 9px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .sxd-checkbox span {
        font-weight: 500;
        font-size: 14px;
        color: #090e15;
      }
    }
    button {
      margin-left: 16px;
    }
  }
}

.dropdown-templates {
  .ant-select-dropdown-menu-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    svg {
      width: 28px !important;
      height: 28px !important;
    }
    > span {
      font-weight: 500;
      font-size: 14px;
      color: #090e15;
      text-transform: capitalize;
      margin-left: 12px;
    }
  }
}
