@import '_seriph/variables';

.notify {
  z-index: 998 !important;
  &.notification {
    left: 220px;
    transition: left 0.2s ease;
    &.small {
      left: 0px;
    }
  }
  .ant-drawer-body {
    padding: 0;
    height: calc(100% - 76px);
    overflow: hidden;
  }

  .ant-tabs-tab-active {
    color: #4353ff;
  }

  .ant-tabs-nav-wrap {
    display: none;
  }

  .ant-tabs-ink-bar {
    background: #4353ff;
  }

  .ant-tabs-bar {
    display: none;
  }

  .ant-drawer-content {
    border-radius: 0px 12px 12px 0px;
    background: #f5f7fb;
  }
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  .ant-drawer-mask {
    background: rgba(14, 18, 24, 0.35);
  }
  .ant-drawer-header {
    border-bottom: 1px solid #e7ecf4;
    padding: 24px 24px 16px 24px;
    background: transparent;
    .ant-drawer-title {
      font-family: $font-major;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      color: #090e15;
      .sxd-link {
        float: right;
        margin-right: 32px;
        position: relative;
        top: 3px;
      }
    }
  }

  .ant-drawer-close {
    top: 6px;
    i {
      background: transparent;
      color: #090e15;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      line-height: 32px;
      svg {
        width: 12px;
        height: 12px;
      }
      &:hover {
        background: #fff;
      }
    }
  }

  .none {
    display: block;
    text-align: center;
    padding: 30px;
    font-style: italic;
  }

  .note-box {
    overflow: auto;
    height: calc(100%);
    position: absolute;
    width: 100%;
    padding-top: 10px;
    .sxd-link {
      position: fixed;
      top: 34px;
      transform: translate(210px, -7px);
      z-index: 999999999;
    }
  }

  .notification {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 38px 16px 12px;
    cursor: pointer;
    position: relative;
    margin: 0 16px;
    &:hover {
      background: #e7ecf4;
    }
    .cmp-invite {
      margin-left: 4px;
    }
    border-radius: 8px;
    .note-alerting {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: #ee3a3a;
      position: absolute;
      right: 12px;
      top: 20px;
    }
    &.read {
      padding-right: 12px;
    }
    div {
      min-width: 0;
      &.note-av {
        margin-right: 14px;
      }
      &.note-cont {
        flex: 1;
      }
      p {
        margin: 0;
        min-width: 0;
        color: #090e15;
        line-height: 21px;
        font-size: 14px;
        font-family: $font-major;
        b {
          font-weight: 500;
        }
      }
      .contents {
        p,
        b i {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          font-weight: 500 !important;
        }
        display: flex;
        flex-direction: row;
      }
      > span {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        margin-top: 2px;
      }
      .btn {
        line-height: 12px;
        padding: 0 8px;
        display: block;
        span {
          opacity: 1 !important;
          color: #fff !important;
        }
      }
      .btn[disabled],
      .btn.disabled,
      .btn[disabled]:hover,
      .btn.disabled:hover {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
      }
    }
  }
}
