#agent-learn {
  max-width: 1260px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  padding: 0 30px;
  .learn-box {
    width: 100%;
    margin: 30px 0;
    .learn-landing {
      margin-bottom: 105px;
    }
    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 35px;
      color: #000000;
      margin: 0 0 24px 0;
      position: relative;
      user-select: none;
    }
  }
  .slide {
    margin-top: 30px;
    height: 467px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;
    background: #fff;
    iframe {
      width: 747px;
      max-width: 747px;
      height: 467px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      overflow: hidden;
    }
    .slide-info {
      flex: 1;
      position: relative;
      padding: 20px 30px;
      align-items: center;
      .slide-tag {
        position: absolute;
        top: -20px;
        height: 24px;
        background: #c1fae2;
        border-radius: 8px;
        padding: 4px 8px;
        color: #0b1e41;
        font-size: 12px;
      }
      h1 {
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        margin: 0 0 16px 0;
        color: #000;
        text-align: left;
      }
      h5 {
        font-size: 16px;
        line-height: 24px;
        color: #909db5;
        margin: 0 0 16px 0;
        text-align: left;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        text-align: left;
        margin: 0 0 24px 0;
      }
      .slide-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        .slide-progress {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 12px;
          line-height: 14px;
          color: #4d5b76;
          margin-left: 24px;
          > .pbar {
            background: #e6e9ef;
            border-radius: 5px;
            height: 10px;
            width: 100px;
            margin-right: 8px;
            > div {
              background: #ffc900;
              border-radius: 5px;
              height: 10px;
            }
          }
        }
      }
    }
  }
  .courses {
    margin: 0 -12px;
  }
  .course {
    width: calc(20% - 24px);
    height: 206px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin: 0 12px;
    display: inline-block;
    border-radius: 16px;
    user-select: none;
    img {
      width: 100%;
      height: 206px;
    }
    &:hover > img {
      opacity: 0.9;
    }
    .course-info {
      position: absolute;
      bottom: 0;
      padding: 18px 24px;
      white-space: normal;
      h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        margin: 0 0 0 0;
      }
      span {
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}
