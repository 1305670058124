#wizard-info {
  .info-content {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }

  .req-container {
    .ant-select-selection--multiple {
      height: auto !important;
    }
    .ant-select-selection__rendered {
      margin-left: 11px;
    }
    .ant-select-selection--multiple
      .ant-select-selection__rendered
      > ul
      > li.ant-select-selection__choice {
      margin: 12px 8px 0 0;
    }
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li.ant-select-search {
      padding-left: 0;
    }
  }
  .ant-select-selection__placeholder {
    top: 0 !important;
    transform: translateY(0) !important;
    margin-top: 0 !important;
    height: 48px !important;
    line-height: 48px !important;
  }
  .ant-select-search {
    padding-left: 6px;
    margin-top: 0;
    height: 48px !important;
    line-height: 48px !important;
  }
  .info-fields {
    margin-top: 24px;
    .info-req {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-shadow: inset 0px -1px 0px #e7ecf4;
      padding: 16px;
      .left-side {
        display: flex;
        align-items: center;
        .field-icon-container {
          margin-right: 16px;
          .field-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #e7ecf4;
            width: 32px;
            height: 32px;
            svg {
              width: 15px;
              height: 15px;
              stroke-width: 1.5px;
              color: #090e15;
            }
          }
        }
        .field-name {
          font-family: Visuelt;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
        }
        .field-type {
          color: #707b90;
          font-size: 12px;
          line-height: 18px;
        }
      }
      .right-side {
        color: #77839b;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        svg {
          color: #090e15;
          width: 14px;
          height: 14px;
          stroke-width: 1.5px;
          margin-left: 6px;
        }
        .ant-checkbox + span {
          color: #707b90;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
  .custom-fields {
    padding: 28px 0;
    h3 {
      font-family: TT Interfaces;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      margin-bottom: 8px;
    }
    .custom-req {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      box-shadow: inset 0px -1px 0px #e7ecf4;
      .custom-req-left {
        font-family: Inter;
        display: flex;
        flex-direction: column;
        .cr-field {
          font-weight: 500;
          font-size: 14px;
          color: #090e15;
        }
        .cr-type {
          font-size: 12px;
          color: #929aaa;
        }
      }
      .custom-button {
        background: #f5f7fb;
        border-radius: 8px;
        color: #090e15;
        margin-right: 12px;
        border: none;
        font-size: 12px;
        font-family: TT Interfaces;
      }
    }
    .create-custom {
      margin-top: 16px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e7ecf4;
      border-radius: 12px;
      padding: 0px 24px;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      height: 48px;
    }
    .custom-editing {
      .custom-editing-top {
        display: flex;
        input {
          flex-grow: 2;
        }
      }
      .custom-editing-buttons {
        display: flex;

        margin: 16px 0;
        .custom-update-button {
          background: #4353ff;
          display: flex;
          align-items: center;
          color: #ffffff;
          height: 40px;
          padding: 16px;
          border-radius: 12px;
          font-family: TT Interfaces;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-right: 8px;
        }
        .custom-cancel-button {
          color: #090e15;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px 16px;
          position: static;
          height: 40px;
          background: #ffffff;
          border-radius: 12px;
          border: none;
        }
      }
    }
  }
  .remove {
    cursor: pointer;
    svg {
      color: #090e15;
      &:hover {
        color: #0b1e41;
      }
      width: 14px;
      height: 14px;
      stroke-width: 1.5px;
    }
  }
}
