
.carousel-list {
  display: flex;

  &:hover {
    .control, .control-background-left, .control-background-right {
      visibility: visible;
    }
  }

  .carousel-scroll {
    position: relative;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: scroll;
    -webkit-transition: left 0.5s ease;
    -moz-transition: left 0.5s ease;
    -o-transition: left 0.5s ease;
    transition: left 0.5s ease;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .scroll-area {
    overflow: visible;
  }

  .control {
    visibility: hidden;
    width: 42px;
    height: 32px;
    position: absolute;
    z-index: 1;
    display: flex;;

    button {
      margin: 0 !important;
      padding: 0 !important;
      cursor: pointer;
      margin-left: 15px;
      font-size: 24px;
      
      &.disabled {
        color: #bbc3d1;
      }
      &.control-left {
        left: 0;
      }
      &.control-right {
        right: 0;
      }

    }


    &.control-background-left {
      background: linear-gradient(90deg, rgba(255,255,255,1) 65%, rgba(255,255,255,0) 100%);
      justify-content: flex-start;
    }
    &.control-background-right {
      right: 0;
      margin-right: 10px;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%);
      justify-content: flex-end;
    }
  }

  &.xsm {
    .control {
        button {
          min-width: 24px !important;
          width: 24px !important;
          min-height: 24px !important;
          height: 24px !important;
        }
    }
  }
}
