@import '_seriph/variables';

.add-chilipiper-modal {
  .chili-logo {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    position: relative;
    top: -2px;
  }
  .breaker {
    padding-top: 32px;
    border-top: 1px dotted #e7ecf4;
    color: #090e15;
    font-weight: 400;
  }
  .chili-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 -8px 40px -8px;
    > div {
      flex: 1;
      margin: 0 8px;
    }
  }
  .descript {
    margin-top: 32px;
    padding-bottom: 8px;
    > span {
      margin-top: 16px;
      display: block;
      b {
        display: block;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: #090e15;
        margin-bottom: 8px;
      }
    }
  }
}
