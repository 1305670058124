@import 'variables';

.role-breakdown-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    position: relative;
    padding: 32px;
  }
  .itm-title {
    h3 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #090e15;
      margin: 0 0 12px 0;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      color: #242d3e;
    }
  }
  .role-info {
    margin: 0;
    display: block;
    span {
      margin: 0;
      display: block;
    }
  }
  .itm-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 32px;
    .field-role {
      width: 150px !important;
    }
    .field-email {
      flex: 1;
      margin-right: 12px;
    }
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 16px;
    right: 16px;
    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    &:hover {
      background: #e5e7eb;
    }
  }
  .itm-actions {
    text-align: right;
    position: relative;
    .btnx-default {
      margin-right: 12px;
    }
    .btnx-primary {
      flex: 1;
    }
  }
  .role-table {
    width: 100%;
    margin-top: 0;
    .fa-check-circle {
      color: #090e15;
    }
    .fa-times-circle {
      color: #d7dce4;
    }
    th {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      color: #0b1e41;
      text-align: center;
      border-top: 0 !important;
    }
    td {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      color: #000000;
      border-top: 1px solid transparent;
      border-right: 1px solid #e6e9ef;
      b {
        font-weight: 500;
      }
      text-align: center;
    }
    th,
    td {
      padding: 12px 12px;
    }
    tr > td:first-child,
    tr > th:first-child {
      text-align: left !important;
      border-top: 1px solid #e6e9ef;
    }
    tr.first > td {
      border-top: 1px solid #e6e9ef;
    }
    tr > td:last-child {
      border-right: 0 !important;
    }
    tr.cat > td {
      font-weight: 600;
      border-right: 0 !important;
      border-top: 1px solid #e6e9ef !important;
      background: #f5f7fa;
    }
    tbody > tr:first-child > td {
      border-top: 0px !important;
    }
  }
}
