@import '_seriph/variables';

.seriph-modal > .ant-modal,
.seriph-confirm.ant-modal {
  transition: width 0.2s linear;
  .ant-modal-body {
    padding: 32px;
  }
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }

  .seriph-modal-actions {
    text-align: right;
    > button {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
    &.align-left {
      text-align: left !important;
      > button {
        margin-left: 12px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &.align-center {
      text-align: center !important;
    }
  }
  .seriph-modal-title {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin-bottom: 24px;
  }
}

.seriph-modal {
  &.overflow-scroll .ant-modal-content {
    overflow: scroll;
  }
}

.seriph-modal-close {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #090e15;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background: #e5e7eb;
  }
}

.seriph-modal-minimize {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #090e15;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 56px;
  &:hover {
    background: #e5e7eb;
  }
}

.seriph-confirm.ant-modal {
  .ant-modal-body {
    padding: 20px;
  }
  .ant-modal-content {
    background: #090e15;
  }
  .ant-modal-confirm-body {
    position: relative;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      color: #efdfc4;
    }
  }
  .ant-modal-confirm-title {
    font-family: $font-major;
    padding-left: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .ant-modal-confirm-content {
    font-style: normal;
    padding-left: 36px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #c4cbd9;
  }
  .ant-modal-confirm-btns {
    > .ant-btn:first-child {
      background: #090e15 !important;
      border-radius: 8px !important;
      color: #fff !important;
      border: 1px solid transparent !important;
      &:hover {
        color: #fff !important;
        background: #2d3b56 !important;
        border: 1px solid #2d3b56 !important;
      }
    }
    .ant-btn-primary {
      background: #ffffff !important;
      border: 1px solid #d7dde9 !important;
      border-radius: 8px !important;
      color: #090e15;
      &:hover {
        background: #f5f7fb !important;
        border: 1px solid #d7dde9 !important;
      }
    }
  }
}
