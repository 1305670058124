@import 'variables';

#lead-questions {
  padding: 32px 0;
  .q-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .lead-script {
      flex: 1;
      .sxd-card {
        > h3 {
          font-family: $font-tt;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #090e15;
          margin: 0 0 16px 0;
        }
      }
      .html-instructions {
        color: #090e15 !important;
        p,
        span,
        div,
        h1,
        h2,
        h3,
        h4,
        h5 {
          color: #090e15 !important;
        }
      }
    }
    .meeting {
      padding-bottom: 16px;
      margin-bottom: 24px;
      .btnx {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        &[disabled] {
          color: #4353ff;
          border: 1px solid #528aff;
          opacity: 1;
          background: #fafbfd;
        }
      }
      h5 {
        text-align: center;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: #707b90;
        margin-top: 8px;
      }
      border-bottom: 1px dotted #e7ecf4;
    }
    .questions {
      width: 100%;
      min-width: 284px;
      min-height: 0;
      > h3 {
        margin-bottom: 24px;
      }
      .lead-quest {
        margin-bottom: 32px;
        h4 {
          margin-bottom: 16px;
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #929aaa;
            margin-right: 8px;
          }
        }
        .ant-checkbox-group {
          width: 100%;
          > label {
            display: block;
            margin: 0 0 2px 0;
            background: transparent;
            border-radius: 8px;
            padding: 8px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #250d93;
            &.ant-checkbox-wrapper-checked {
              background: #e7ecf4;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #4353ff;
              border-color: #4353ff;
            }
          }
        }
        .ant-radio-group {
          width: 100%;
          > label {
            display: block;
            margin: 0 0 2px 0;
            background: transparent;
            border-radius: 8px;
            padding: 8px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #250d93;
            &.ant-radio-wrapper-checked {
              background: #e7ecf4;
            }
            .ant-radio-checked .ant-radio-inner {
              border-color: #4353ff;
              &::after {
                background-color: #4353ff;
              }
            }
          }
        }
      }
      &.v2 {
        margin: 0;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        .lead-quest {
          margin-bottom: 16px;
        }
      }
    }
  }
}
