@import '_seriph/variables';

.seriph-textarea {
  .textarea-label {
    display: block;
    font-family: $font-major;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    margin: 0 0 8px 0;
    svg {
      cursor: pointer;
      position: relative;
      top: 3px;
      left: 8px;
    }
  }

  .textarea-box {
    position: relative;
    > textarea {
      padding: 12px 20px 12px 16px;
      outline: 0;
      box-shadow: none;
      border-radius: 10px;
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      min-height: 50px;
      &::placeholder {
        color: #4d5b76;
      }
      &:hover {
        border: 1px solid #c4cbd9;
      }
      &:focus {
        border: 1px solid #c4cbd9;
        background: #fff;
      }
      &[disabled] {
        border: 1px solid #f5f7fb;
        background: #f5f7fb;
        color: #adb5c5;
        &::placeholder {
          color: #adb5c5;
        }
      }
    }
  }

  .textarea-hint {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    color: #4d5b76;
    margin: 8px 0 0 0;
    > svg {
      margin-right: 8px;
    }
  }

  &.textarea-success {
    .textarea-box > textarea {
      background: #ebfff2;
      border: 1px solid #98f6b8;
    }
    .textarea-hint {
      color: #1fa34c;
    }
  }

  &.textarea-error {
    .textarea-box > textarea {
      background: #ffe9e9;
      border: 1px solid #f8aaaa;
    }
    .textarea-hint {
      color: #ee3a3a;
    }
  }

  &.dark {
    .textarea-box > textarea {
      background: #27292a !important;
      color: #fdfaef;
      border: 1px solid #27292a;

      &::placeholder {
        color: #565c61;
      }

      &:active,
      &:focus,
      &:hover {
        background: #27292a;
        color: #fdfaef;
      }
      &:hover {
        border: 1px solid #535250;
      }
      &:active,
      &:focus {
        border: 1px solid #fdfaef;
      }
    }
  }
}
