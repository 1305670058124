@import '_seriph/variables';

.remove-agent-modal {
  .ia-agent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    .ia-details {
      flex: 1;
      margin: 24px 0 16px;
      padding: 0px 55px;
      h3 {
        font-family: $font-major;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
      }
      p {
        font-family: $font-major;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #707b90;
      }
    }
  }

  .ia-main {
    .ia-main-table {
      height: 225px;
      overflow: scroll;
      margin-top: 5px;
      .ia-row {
        display: flex;
        height: 75px;
        align-items: center;
        border-radius: 8px;
        padding: 16px 12px;
        cursor: pointer;
        &.disabled {
          opacity: 0.5 !important;
          cursor: not-allowed;
        }
        &.selected {
          background: rgba(210, 208, 251, 0.32);
        }

        border: 1px solid white;
        &:hover {
          background: #f5f7fb;
          border: 1px solid #d7dde9;
        }
        .ia-row-left {
          flex: 0 0 30px;
          margin-right: 10px;
        }
        .ia-row-right {
          h4 {
            color: #090e15;
            font-family: $font-major;
            font-size: 14px;
            font-weight: 500;
            margin: 5px 0;
          }
          p {
            color: #090e15;
            font-family: $font-major;
            font-size: 12px;
            margin: 5px 0;
            span {
              &.obj {
                color: #090e15;
              }
              &:not(.obj) {
                color: #707b90;
              }
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .ia-entry {
    font-family: $font-major;
    font-weight: 500;
    line-height: 21px;
    color: #090e15;
    font-size: 14px;
    span {
      font-weight: 400;
      color: #637084;
    }
  }
  .ia-actions {
    margin-top: 30px;
    text-align: right;
    .ant-btn {
      margin-left: 16px;
    }
    .btn-primary {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      > div {
        margin-left: 8px;
      }
    }
  }
}
