.call-lead-modal {
  .ant-modal-body {
    padding: 0;
  }
  .cl-title {
    font-size: 20px;
    line-height: 27px;
    color: #0b1e41;
    padding: 24px 0;
    border-bottom: 1px solid #e6e9ef;
    text-align: center;
    font-weight: 600;
  }
  .cl-lead {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 32px 0;
    .lead {
      display: flex;
      flex-direction: row;
      align-items: center;
      .lead-photo {
        padding-right: 10px;
        img {
          width: 64px;
          height: 64px;
          border-radius: 32px;
        }
        span {
          display: block;
          width: 64px;
          height: 64px;
          border-radius: 32px;
          line-height: 64px;
          text-align: center;
          background: #ecedff;
          color: #4353ff;
          font-weight: 600;
          font-size: 28px;
        }
      }
      .lead-full {
        padding-left: 16px;
        h2 {
          margin: 0;
          font-size: 20px;
          color: #0b1e41;
          font-weight: 600;
        }
        h5 {
          margin: 0;
          font-size: 14px;
          color: #4d5b76;
          font-weight: 400;
        }
      }
    }
  }
  .cl-actions {
    padding: 0 24px 24px 24px;
    > button {
      width: calc(50% - 10px);
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .from {
    text-align: center;
    padding: 0 0 24px 0;
    color: #0b1e41;
    b {
      font-weight: 600;
    }
  }
  .on-call {
    padding: 0 24px 24px 24px;
    text-align: center;
    .call-status {
      margin-bottom: 50px;
      margin-top: 20px;
      font-weight: 500;
      font-size: 24px;
    }
    > button {
      width: calc(50% - 10px);
    }
  }
}
