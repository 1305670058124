@import 'variables';

.apply-submitted {
  .apply-pics {
    margin-bottom: 32px;
    text-align: center;
    > .sxd-avatar {
      img {
        border: 4px solid #fff;
        box-shadow: none !important;
      }
      &:first-child {
        position: relative;
        right: -6px;
      }
      &:last-child {
        position: relative;
        left: -6px;
      }
    }
  }
  .apply-info {
    text-align: center;
    margin: 0 0 64px 0;
    > h3 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #090e15;
      margin: 0 0 16px 0;
    }
    > p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #4d5b76;
      margin: 0 0 40px 0;
    }
  }
}
