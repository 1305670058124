@import '_seriph/variables';

.seriph-location {
  .places-container {
    padding: 8px;
    background: #ffffff;
    border: 1px solid #e7ecf4;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    margin-top: 2px;
    z-index: 9999;
    left: 0;
    top: 74px;
    position: absolute;
    .gp-item {
      background: transparent;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      padding: 8px 12px;
      border-radius: 8px;
      transition: none !important;
      margin-bottom: 4px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background: #f5f7fb;
      }
    }
  }
}
