@import '_seriph/variables';

.seriph-progress {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .bar {
    flex: 1;
    background: #e7ecf4;
    border-radius: 4px;
    height: 8px;
    position: relative;
    overflow: hidden;
    > .step {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4px;
      height: 8px;
      min-width: 4px !important;
      background-color: #090e15;
    }
  }
  .marker {
    display: block;
    content: '';
    clear: both;
    width: 1px;
    height: 10px;
    top: 2px;
    background-color: #979ca4;
    position: absolute;
    z-index: 99;
  }
  .percent {
    font-family: $font-major;
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    font-style: normal;
    font-weight: normal;
    color: #090e15;
    position: relative;
    top: 2px;
  }
  &.rounded {
    .bar {
      border-radius: 7px;
      height: 14px;
      > .step {
        height: 14px;
        border-radius: 7px;
        border: 2px solid #fff;
        min-width: 12px !important;
      }
    }
  }
  &.progress-large {
    .bar {
      border-radius: 10px;
      height: 20px;
      > .step {
        height: 20px;
        border-radius: 10px;
        min-width: 16px !important;
      }
    }
  }
}
