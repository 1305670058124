@import '_seriph/variables';

#onboarding.invitation {
  .form {
    max-width: 550px !important;
    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 56px;
      font-family: $font-major;
      color: #090e15;
      margin-bottom: 40px;
    }
  }
  .sxd-card {
    padding: 32px 48px;
  }
  .sx-email {
    position: relative;
    input {
      padding-right: 100px;
    }
    .ending {
      position: absolute;
      right: 15px;
      top: 47px;
      line-height: 20px;
      color: #e5efe7;
      font-weight: 500;
      font-size: 16px;
      font-family: $font-major;
    }
  }
  .user-example {
    display: block;
    text-align: left;
    margin-bottom: 32px;
    margin-top: 24px;
    .user-card {
      display: inline-block;
      background: #27292a;
      padding: 8px 16px;
      border-radius: 60px;
      > div {
        display: inline-block;
        font-size: 14px;
        line-height: 21px;
        align-items: center;
        color: #fff;
        font-weight: 500;
        font-family: $font-major;
        &.uc-name {
          padding: 0 10px;
        }
        &.uc-email {
          padding-left: 10px;
        }
      }
    }
  }
  .helper {
    font-size: 11px;
    margin-top: -16px;
    margin-bottom: 20px;
    text-align: center;
  }

  .big-label {
    margin-bottom: 4px;
  }
  .big-box {
    position: relative;
    .accept {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 1px solid #38cf36;
      cursor: pointer;
      svg {
        color: #38cf36;
        font-size: 12px;
      }
    }
    .btnx-tiny {
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 999;
    }
    > div > div {
      background: #27292a !important;
      border: 1px dashed #47494a !important;
      position: relative;
      height: 100px !important;
      overflow: hidden;
      border-radius: 12px;
      > svg {
        color: #090e15;
        z-index: 999;
        path {
          fill: #090e15;
        }
      }
    }
    &.pic > div > div {
      height: auto !important;
    }
    label {
      width: 100%;
      text-align: center;
      font-size: 18px;
      display: block;
      cursor: pointer;
      color: #333;
    }
    .upload-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      > div {
        background: #ffffff;
        border: 1px solid #e7ecf4;
        box-sizing: border-box;
        border-radius: 12px;
        font-weight: 400;
        font-family: $font-major;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        display: inline-flex;
        align-items: center;
        padding: 12px 24px;
        svg {
          color: #090e15;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .big-error .big-box > div > div {
    border: 1px dashed #ee5253 !important;
  }
  .signup .error-text {
    color: #ee3a3a !important;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
