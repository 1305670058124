#dialer-phone-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #E7ECF4;
  border-radius: 20px;
  margin-right: 16px;
  svg {
    width: 16px;
    height: 16px;
    stroke-width: 2px;
    color: #090E15;
  }
}