@import '_seriph/variables';

.message-company-modal {
  .mo-owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    img.employee {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      margin-right: 24px;
    }
    img.c-logo {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background: #f7f8fa;
      position: absolute;
      top: 46px;
      left: 46px;
    }
    .mo-details {
      flex: 1;
      h3 {
        color: #0b1e41;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
      }
      p {
        color: #4d5b76;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;
      }
      .sellx-tag {
        font-size: 12px !important;
      }
    }
  }
  .seriph-select .ant-select {
    width: 70%;
  }
  .ant-select-selection-selected-value,
  .ant-select-dropdown-menu-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    > span {
      font-weight: 400;
      font-size: 14px;
      color: #090e15;
      b {
        font-family: $font-major;
        font-weight: 500;
        display: inline-block;
        margin-right: 8px;
        font-style: normal;
      }
    }
  }
  .ant-select-dropdown-menu-item-selected > span {
    color: #fff;
  }
  .seriph-textarea {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .mo-actions {
    margin-top: 30px;
    text-align: right;
    button {
      margin-left: 12px;
    }
  }
}
