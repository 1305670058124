@import '_seriph/variables';

#company-settings-billing {
  .filter-menu {
    margin: 24px 0;
  }
  .billing-view {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #7872f3;
    cursor: pointer;
    &:hover {
      color: #aeaaf8;
    }
  }
  .running {
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    right: 32px;
    top: 40px;
    > span {
      color: #5e0cef;
    }
  }
}
