@import '_seriph/variables';

#robin-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -12px 16px -12px;
  > div {
    padding: 16px 24px;
    margin: 0 12px;
  }
  h2 > span {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707B90;
    margin-left: 12px;
    svg {
      width: 18px;
      height: 18px;
      margin-left: 8px;
    }
    &.good {
      color: green;
    }
    &.bad {
      color: red;
    }
  }
  .descriptor {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    margin-bottom: 8px;
  }
}