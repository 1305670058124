@import '_seriph/variables';
.seriph-checkbox-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  > button {
    &:first-child {
      margin-top: 12px;
    }

    height: 64px !important;
    padding: 20px !important;
    margin-bottom: 12px;
    font-size: 16px;
    font-family: $font-major;
    display: flex;
    justify-content: space-between;
    .left-div {
      text-align: left;
    }
    .right-div {
      text-align: right;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
  .seriph-textarea {
    margin-bottom: 12px;
  }
  &.dark {
    > button {
      background: #090e15;
      color: #fdfaef;
      border: 1px solid #2a2928;
      overflow: hidden;

      &:hover {
        border: 1px solid #535250;
      }
      &:active,
      &.btn-selected {
        border: 1px solid #fdfaef;
      }
    }
    textarea {
      background: #27292a !important;
      color: #fdfaef;
      border: 1px solid #27292a;

      &::placeholder {
        color: #565c61;
      }

      &:active,
      &:focus,
      &:hover {
        background: #27292a;
        color: #fdfaef;
      }
      &:hover {
        border: 1px solid #535250;
      }
      &:active,
      &:focus {
        border: 1px solid #fdfaef;
      }
    }
  }
}
