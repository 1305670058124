@import '_seriph/variables';

.email-chat {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 24px;
  margin-bottom: 16px;
  .seriph-avatar {
    img {
      margin-right: 0;
    }
    .character {
      background: #d2d0fb;
      color: #090e15;
    }
  }
  .tagged {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      white-space: nowrap;
    }
    > div:first-child {
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e6f3fc;
      border-radius: 48px;
      height: 24px;
      font-family: Visuelt;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      text-transform: capitalize;
      margin-right: 12px;
      &.opened {
        background: #c1ddbb;
      }
      &.unread {
        background: #090e15;
        color: #fff;
      }
      &.bounced {
        background: #efbba9;
      }
      &.delivered {
        background: #b0d0e8;
      }
    }
    .tag-tstamp {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      color: #090e15;
    }
  }
  .message-content {
    flex: 1;
    padding-left: 15px;
    position: relative;
    h3 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      margin: 0;
    }
    h4 {
      display: inline-block;
      white-space: nowrap;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #4d5b76;
      margin: 0 0 16px 0;
      position: relative;
      top: -4px;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      white-space: pre-line;
    }
    .email-attachment {
      margin-top: 12px;
      b {
        margin-right: 6px;
      }
    }
  }
  .time-action {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #fff;
    border-radius: 30px;
    display: inline-block;
    padding: 2px 8px;
    background: #707b91;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    svg {
      font-size: 10px;
      margin-right: 6px;
    }
  }
  .tstamp {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #4d5b76;
    margin-left: 10px;
  }
  .email {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4d5b76;
    margin-left: 6px;
  }
  &.message-own {
    .message-content > p {
      padding: 0;
      p {
        color: #090e15 !important;
      }
    }
  }
}
