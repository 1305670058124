#admin-companies {
  padding: 40px 30px 30px 30px;
  .admin-header {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    position: relative;
    > div {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      position: relative;
      left: 0;
      top: -5px;
    }
    .seriph-input {
      width: 300px !important;
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
    }
  }
  .seats {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      margin-left: 24px;
    }
  }
  .seriph-tabs {
    margin-bottom: 24px;
    .seriph-tab {
      text-transform: uppercase;
    }
  }
  .admin2-table {
    .lead-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      .lead-photo {
        padding-right: 10px;
        img {
          width: 36px;
          height: 36px;
          border-radius: 18px;
        }
        span {
          display: block;
          width: 36px;
          height: 36px;
          border-radius: 18px;
          line-height: 36px;
          text-align: center;
          background: #ecedff;
          color: #4353ff;
          font-weight: 600;
        }
      }
      .lead-full {
        .lead-title {
          font-size: 12px;
          color: #999;
        }
      }
    }
    .agent-email {
      svg {
        color: #30f09e;
        margin-left: 8px;
      }
    }
    .manager-column {
      display: flex;
    }
  }
}
