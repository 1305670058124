@import '_seriph/variables';

#company-analytics-call-recordings {
  min-width: 100%;
  min-height: 100%;
  padding: 32px 40px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  #waveform {
    display: none;
  }
  .call-recordings-header {
    flex: 0 0 180px;
    .seriph-header-wrapper {
      margin-bottom: 24px;
    }
    .dashboard-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      border-bottom: 1px solid #e7ecf4;
      padding-bottom: 24px;
      margin-bottom: 32px;
    }
  }
  .call-recordings-main {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    .main-top {
      display: flex;
      background: #ffffff;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #e7ecf4;
      padding: 0 32px;
      flex: 0 0 102px;
      .top-info {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        font-family: $font-major;
        color: #090e15;
        width: 156px;
        margin-right: 24px;
        .info-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
        .info-content {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .main-table {
      flex: 1;
      height: 100%;
      color: #090e15;
      .lead-cont {
        display: flex;
        align-items: center;
        gap: 16px;
        .star-section {
          svg {
            color: #d7dde9;
          }
          &.starred {
            svg {
              color: #e1d39f;
              fill: #e1d39f;
            }
          }
        }
        .lead-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
        .campaign-name {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
        .call-time {
          font-family: $font-minor;
          color: #707b90;
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
        }
      }

      .lead-stage-cont {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .lead-stage {
          border: 2px solid #e7ecf4;
          border-radius: 48px;
          padding: 3px 10px;
          &.in-progress {
            border-color: $in-progress;
          }
          &.completed {
            border-color: $completed;
          }
          &.call-scheduled {
            border-color: $completed;
          }
          &.review {
            border-color: $review;
          }
          &.not-interested {
            border-color: $not-interested;
          }
          &.invalid {
            border-color: $not-interested;
          }
          &.improper {
            border-color: $not-interested;
          }
          &.disputed {
            border-color: $disputed;
          }
          &.paused {
            border-color: #fff1bf;
          }
          &.unassigned {
            border-color: $unassigned;
          }
          .stage-name {
            font-family: $font-major;
            font-weight: 500;
            color: #090e15;
            display: inline-block;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      .actions-cont {
        display: flex;
        align-items: center;
        gap: 20px;
        background: #ffffff;
        .play-section {
          svg {
            color: #d7dde9;
          }
        }
        .call-button-placeholder {
          height: 32px;
          width: 32px;
        }
        .tbl-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background: transparent;
          border-radius: 100px;
          cursor: pointer;
          &:hover {
            background: #e7ecf4;
          }
        }
      }
    }
    .agent-picture {
      display: flex;
      flex-direction: row;
      align-items: center;
      .agent-info {
        margin-left: 12px;
        h4 {
          margin: 0;
          font-family: $font-major;
          font-weight: 500;
          color: #090e15;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }
      img {
        width: 100%;
      }
    }
    .company-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
