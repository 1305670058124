@import '_seriph/variables';

#wizard-review {
  .wz-container {
    min-height: 360px;
  }
  .launch {
    text-align: center;
    margin-bottom: 48px;
  }
  .reviews {
    margin-bottom: 32px;
  }
  .reviews > .review {
    width: 100%;
    background: #ffffff;
    border-bottom: 1px solid #e7ecf4;
    border-radius: 0;
    padding: 24px 0;
    margin-bottom: 12px;
    &:last-child {
      border-bottom: 0;
    }
    .review-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      .review-title {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #090e15;
        flex: 1;
        margin-right: 16px;
        &.invalid {
          color: #c76965;
        }
      }
      .outcome {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #090e15;
        border-radius: 16px;
        margin-right: 16px;
        svg {
          width: 14px;
          height: 14px;
          color: #fff;
        }
        &.invalid {
          background: #c76965;
        }
      }
    }

    .info {
      flex: 1;
      margin-left: 48px;
      .info-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        > div:first-child {
          min-width: 150px;
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          white-space: nowrap;
        }
        > div:last-child {
          flex: 1;
          font-size: 14px;
          line-height: 21px;
          color: #4d5b76;
          white-space: nowrap;
          font-weight: normal;
          font-family: $font-major;
        }
        &.missing > div {
          color: #c76965;
        }
        &.warning > div {
          color: #d8a473;
        }
      }
      .values,
      .missing {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        margin-bottom: 8px;
      }
      .missing {
        color: #c76965;
      }
      .warning {
        color: #d8a473;
      }
    }
  }
}

// <div className="review">
//   <div className="outcome"><FontAwesomeIcon icon={faCheck} /></div>
//   <div className="info">
//     <h3>Setup</h3>
//     <div className="values">Lead Generation</div>
//     <div className="missing">Lead Generation</div>
//   </div>
//   <Button className="btnx btnx-neutral">Edit Setup</Button>
// </div>
