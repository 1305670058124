@import '_seriph/variables';

.ant-message-notice {
  > .ant-message-notice-content {
    background: #090e15;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 10px;
    padding: 8px 16px;

    .ant-message-custom-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 12px;
        color: #fff;
        width: 18px;
        height: 18px;
      }
      span {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}
