@import 'variables';
@import '_seriph/variables';

#admin {
  min-height: 100%;
  background: $neutral-100;
  .admin-body {
    width: 100%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    background: $neutral-100;
    position: relative;
    padding-left: 220px;
    transition: padding-left 0.2s ease;
  }
  &.small-menu {
    .admin-body {
      padding-left: 70px;
    }
    #admin-menu {
      width: 70px !important;
      background: #090e15 !important;
      border-right: 1px solid transparent !important;
      box-shadow: none !important;
    }
    .alerting {
      right: 4px !important;
      top: 4px;
      width: 18px;
      height: 18px;
      padding: 0 !important;
    }
  }
  .ant-table-pagination.ant-pagination {
    float: none !important;
    text-align: center !important;
  }
}

.menu-tt {
  margin-left: 18px !important;
  .ant-tooltip-inner {
    background: #4d5b76;
    font-size: 14px;
    font-weight: 500;
  }
  .ant-tooltip-arrow {
    border-right-color: #4d5b76;
    left: 21px !important;
  }
  .ant-tooltip-content {
    margin-left: 18px !important;
  }
}

#admin-menu {
  padding: 0;
  background: none;
  position: fixed;
  width: 220px;
  padding: 32px 15px 45px 15px;
  line-height: 74px;
  height: 100%;
  background: #090e15;
  border-right: 1px solid #f0f3f9;
  box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.12), 0px 2px 12px rgba(40, 43, 48, 0.08);
  z-index: 999;
  transition: width 0.3s ease;
  .logo {
    padding-left: 5px;
    line-height: 40px;
    height: 40px;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    img {
      width: 30px;
      height: 30px;
      display: block;
    }
    div {
      font-family: $font-header;
      display: inline-block;
      vertical-align: top;
      height: 28px;
      line-height: 28px;
      color: #fff;
      font-size: 28px;
      font-weight: 600;
      &:last-child {
        margin: 0 0 0 15px;
      }
    }
  }
  .main-menu {
    background: none;
    border-bottom: none;
    line-height: 41px;
    margin-top: 16px;
    border-right: 0;
  }
  .main-menu {
    background: none;
    border-bottom: none;
    line-height: 41px;
    margin-top: 16px;
    border-right: 0;
  }
  .actions-menu {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 15px);
    .ant-menu {
      background: none;
      border-bottom: none;
      border-right: 0;
    }
    .menu-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
      width: 100%;
      overflow-x: hidden;
      overflow-y: visible;
      .sxd-avatar {
        margin-right: 16px;
        line-height: normal !important;
        overflow: visible !important;
        height: 44px !important;
      }
      img.av-pic {
        width: 40px !important;
        height: 40px !important;
        border-radius: 20px !important;
        background: transparent !important;
      }
      img.av-rep {
        bottom: 0 !important;
      }
      > .menu-av {
        position: relative;
        top: -2px;
        .menu-user-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.1px;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #fff;
          font-family: $font-major;
          margin: 0;
        }
        .menu-user-company {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #929aaa;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .ant-menu-item-divider {
    margin-bottom: 8px;
    background-color: #333;
  }
  .ant-menu-item {
    text-align: center;
    padding: 0 9px !important;
    margin: 0 0 8px 0 !important;
    font-weight: 500;
    border-bottom: none;
    border-radius: 12px;
    text-align: left;
    position: relative;
    transition-property: none !important;
    a,
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      user-select: none;
      transition-property: none !important;
      .lfa {
        display: flex;
        width: 22px;
        min-width: 22px;
        justify-content: center;
        margin-right: 12px;
        svg {
          color: #fff;
        }
      }
      .ant-badge {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .ant-badge-count {
          box-shadow: none;
          border: 0;
          font-weight: 600;
          height: 24px;
          line-height: 24px;
          border-radius: 12px;
          min-width: 24px;
          transform: translateX(50%);
          left: -15px;
        }
      }
      .alerting {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background: #ee3a3a;
        position: absolute;
        right: 10px;
        padding: 4px 8px;
        transition: all 0.3s ease;
        > span {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          letter-spacing: 0.2px;
          color: #ffffff;
          position: relative;
          top: 1px;
        }
      }
    }
    a,
    .ant-dropdown-trigger,
    div {
      color: #fff;
      font-weight: normal;
      font-size: 14px;
      font-family: $font-major;
      letter-spacing: -0.1px;
    }

    &:hover {
      a,
      div {
        color: #090e15;
      }
      .lfa svg {
        color: #090e15;
      }
      border-bottom: none;
      background: #e7ecf4;
    }
    &.input-menu-item:hover {
      background: transparent;
    }
  }
  .ant-menu-item-selected {
    color: #090e15 !important;
    border-bottom: none;
    background: #fff !important;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16) !important;
    a {
      color: #090e15 !important;
    }
    .lfa svg {
      color: #090e15 !important;
    }
    &:after {
      display: none;
    }
  }
}

.admin-dropdown {
  min-width: 200px;
  left: 219px !important;
  position: fixed;
  z-index: 99999;

  &.small {
    left: 69px !important;
  }
  .ant-dropdown-menu {
    margin-bottom: -50px;
    box-shadow: none;
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #090e15;
    border-radius: 12px;
    padding: 8px 0;
    &:after {
      display: inline-block;
      position: absolute;
      left: -10px;
      bottom: 18px;
      width: 0;
      height: 0;
      border-right: 10px solid #090e15;
      border-top: 8px solid transparent;
      border-left: 0 dotted;
      border-bottom: 8px solid transparent;

      vertical-align: middle;
      content: '';
    }
    .ant-dropdown-menu-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      font-family: $font-major;
      color: #fff;
      padding: 8px 16px;
      a {
        color: #fff;
      }
      .icon-width {
        width: 18px;
        margin-right: 8px;
        text-align: center;
        display: inline-block;
        svg {
          color: #fff;
        }
      }
      &.sign-out {
        color: #909db5;
        svg,
        a {
          color: #909db5;
        }
      }
      &:hover {
        color: #fff;
        svg,
        a {
          color: #fff;
        }
      }
    }
    .ant-dropdown-menu-item-active {
      background: #242d3e;
    }
  }
}
