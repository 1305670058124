.add-box {
  width: 100%;
  .add-box-text {
    color: #4353ff;
    font-weight: 500;
    text-align: center;
    > svg {
      margin-right: 10px;
    }
    > span {
      float: right;
      color: #000000;
      font-weight: 600;
    }
    &:hover {
      text-decoration: underline;
      color: #2333df;
      cursor: pointer;
    }
  }
}
