@import '_seriph/variables';

.seriph-select {
  .ant-select {
    width: 200px;
    .ant-select-selection--single {
      height: 48px;
      min-height: 48px;
    }
    .ant-select-selection__rendered {
      line-height: 46px;
    }
    .ant-select-selection {
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
      border-radius: 8px;
      .ant-select-selection__placeholder {
        font-family: $font-major;
        font-size: 14px;
        color: #090e15;
      }
      .ant-select-selection-selected-value {
        font-family: $font-major;
        font-size: 14px;
        color: #090e15;
      }
      .ant-select-arrow svg {
        color: #090e15;
      }
    }
    &:hover .ant-select-selection {
      border-color: #c4cbd9;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus {
      background: #ffffff;
      border-color: #c4cbd9;
      outline: 0;
      box-shadow: none;
    }
    &.ant-select-disabled .ant-select-selection {
      background: #f5f7fb !important;
      border: 1px solid #f5f7fb !important;
      .ant-select-selection__placeholder,
      .ant-select-selection-selected-value,
      .ant-select-arrow svg {
        color: #adb5c5 !important;
      }
    }
  }

  .input-box {
    position: relative;
    .select-icon-left {
      position: absolute;
      left: 16px;
      top: 16px;
      svg {
        width: 16px;
        height: 16px;
        stroke-width: 1.75;
      }
    }
  }

  &.select-icon .ant-select .ant-select-selection__rendered {
    margin-left: 40px;
  }

  &.seriph-success {
    .ant-select-selection {
      background: #ebfff2 !important;
      border: 1px solid #98f6b8 !important;
    }
  }

  &.seriph-error {
    .ant-select-selection {
      background: #ffe9e9 !important;
      border: 1px solid #f8aaaa !important;
    }
  }

  &.select-large {
    .ant-select-selection--single {
      height: 54px;
    }
    .ant-select-selection__rendered {
      line-height: 54px;
    }
    .ant-select-selection {
      .ant-select-selection__placeholder {
        font-size: 16px;
      }
      .ant-select-selection-selected-value {
        font-size: 16px;
      }
      .ant-select-arrow svg {
      }
    }
  }

  &.select-small {
    .ant-select-selection--single {
      height: 40px;
      min-height: 40px;
    }
    .ant-select-selection__rendered {
      line-height: 40px;
    }
    .ant-select-selection {
      .ant-select-selection__placeholder {
        font-size: 14px;
      }
      .ant-select-selection-selected-value {
        font-size: 14px;
      }
      .ant-select-arrow svg {
        font-size: 10px;
      }
    }
  }

  &.select-tiny {
    .ant-select-selection--single {
      height: 32px;
      min-height: 32px;
    }
    .ant-select-selection__rendered {
      line-height: 32px;
    }
    .ant-select-selection {
      .ant-select-selection__placeholder {
        font-size: 12px;
      }
      .ant-select-selection-selected-value {
        font-size: 12px;
      }
      .ant-select-arrow svg {
        font-size: 10px;
      }
    }
  }

  &.dark {
    .seriph-label {
      color: #ffffff;
    }
    .ant-select-selection {
      background: #27292a !important;
      height: 56px;
      color: #fdfaef;
      .ant-select-selection__placeholder {
        color: #fdfaef;
      }
      .ant-select-selection-selected-value {
        color: #fdfaef;
      }

      border: 1px solid #2a2928;

      &:hover {
        border: 1px solid #535250;
      }
      &:active,
      &.btn-selected {
        border: 1px solid #fdfaef;
      }
      .ant-select-selection__rendered {
        line-height: 56px;
      }

      .ant-select-arrow i svg {
        color: #fdfaef;
      }
    }
  }
}
