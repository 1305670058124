@import '_seriph/variables';

#sellx-profile-user {
  text-align: center;
  .add-box {
    margin-top: 24px;
    padding: 24px 0 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  h1 {
    margin: 24px 0 0 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #090e15;
    font-family: $font-major;
  }
  .user-rep {
    font-family: $font-major;
    font-size: 14px;
    line-height: 21px;
    margin-top: 2px;
    color: #090e15;
  }
  .user-other {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    padding: 24px 0 0 0;
    text-align: left;
    .profile-project {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin-bottom: 8px;
      span {
        float: right;
        color: #090e15;
        font-weight: 400;
      }
    }
  }
  .social a {
    margin-left: 8px;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #090e15;
  }
  .user-comp {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    h3 {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #090e15;
      margin: 8px 0 0 0;
    }
    .ant-progress-bg {
      background-color: #c1ddbb !important;
    }
  }
  .avatar-box {
    position: relative;
    display: inline-block;
    button {
      display: none;
      border-radius: 20px;
      padding: 0;
      width: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        font-size: 18px;
      }
    }
  }
  &:hover {
    .avatar-box button {
      display: block;
    }
  }
}
.agent-user-edit {
  .field {
    margin-bottom: 16px;
  }
  .field {
    position: relative;
    .place {
      position: absolute;
      left: 20px;
    }
  }
}
