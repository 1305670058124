@import '_seriph/variables';

.seriph-radio {
  &.ant-radio-wrapper {
    display: block;
    margin: 0 !important;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    background: #fff;
    padding: 8px 12px;
    border-radius: 8px;
    &:hover {
      background: #f5f7fb;
    }
  }

  span {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
  }

  .ant-radio-inner {
    outline: 0 !important;
  }

  &.ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-checked::after {
    border-color: #090e15;
  }
  &.ant-radio-wrapper-checked:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    background-color: #090e15 !important;
    border-color: #090e15 !important;
  }
}
