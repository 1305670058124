@import '_seriph/variables';

.hubspot-csv-modal {
  .ant-modal-body {
    min-height: 200px;
  }
  .auto-import {
    margin-top: 16px;
  }
  .crm-wrapper {
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      background: #FFFFFF;
      border: 1px solid #E7ECF4;
      box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
      border-radius: 96px;
      img {
        height: 25px;
      }
    }
    > h3 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #090E15;
      margin: 16px 0 4px 0;
    }
    > p {
      font-family: $font-major;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #707B90;
      display: flex;
      flex-direction: row;
      align-items: center;
      b {
        color: #090E15;
        font-weight: 400;
        margin-left: 4px;
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        color: #707B90;
        position: relative;
        top: -1px;
      }
      a {
        margin-left: 4px;
        text-decoration: underline;
        color: #707B90;
        &:hover {
          color: #505B70;
        }
      }
    }
    .ant-select {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    .seriph-modal-actions {
      margin-top: 36px;
    }
  }

}