.email-icon-wrapper {
  background-color: #1fd6ff;
  padding: 5.5px 9px;
  border-radius: 50%;
  border: 4px solid #f0fffe;
}

.email-icon {
  color: white;
}
