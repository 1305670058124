/* SERIPH VARIABLE STYLING */

/* FONTS */
$font-header: 'Tiempos', 'Didot', 'Times New Roman', Times, serif !important;
$font-major: 'Visuelt', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
$font-minor: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;

/* LEAD STATUS COLORS */
$in-progress: #abd4a2;
$review: #b5b5fd;
$disputed: #ebaea0;
$completed: #87b0ed;
$generated: #9dc0b7;
$qualified: #c1ddbb;
$not-interested: #e4d7a7;
$unassigned: #c4cbd9;
$meetings: #000000;

/* LEAD COLORS */
$unknown: #e7ecf4;
$neutral-300: #d7dde9;
$email: #d2d0fb;
$call: #c1ddbb;
$linkedin: #b0d0e8;
$note: #efbba9;

/* TAGS */
$cream-primary: #fdfaef;
$cream-500: #efdfc4;
$butterfly-100: #e6f3fc;
$butterfly-latte: #b0d0e8;
$sage-100: #eef9ec;
$sage-accent: #c1ddbb;
$ube-100: #e8e7fd;

$dark-gray: #090e15;
$bright-blue: #4353ff;
$warning: #efdfc4;
