@import '_seriph/variables';

.seriph-quill {
  .quill {
    .ql-toolbar {
      border-color: #e7ecf4;
      border-radius: 0;
      padding: 20px 16px;
      button {
        padding: 3px 8px;
        width: 34px;
      }
      > .ql-formats {
        border-left: 1px solid #e7ecf4;
        margin: 0;
        padding: 0 8px;
        &:first-child {
          padding-left: 0;
          border-left: 0;
        }
      }
    }

    .ql-container {
      border-color: #e7ecf4;
      border-radius: 0;
      .ql-editor {
        padding: 32px;
        height: 360px;
        overflow: auto;
        p,
        li,
        strong,
        div {
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
        }
        .ql-label {
          font-size: 14px !important;
          position: initial;
          bottom: auto;
          line-height: 21px;
          display: inline-block;
          user-select: none;
          margin-right: 1px;

          text-decoration: none;
          vertical-align: middle;
          background: #d2d0fb;
          padding: 5px 3px;
          font-weight: 600;
        }
        .ql-error {
          background: #efbba9;
          padding: 5px;
          top: 0;
          font-size: 100%;
          vertical-align: middle;
        }
        .ql-warning {
          background: #e8d3b9;
          padding: 5px;
          top: 0;
          font-size: 100%;
          vertical-align: middle;
        }

        &.ql-blank::before {
          font-size: 14px;
          line-height: 21px;
          font-style: normal !important;
        }
      }
    }
    .ql-container.ql-disabled {
      cursor: not-allowed !important;
    }
    .ql-snow .ql-tooltip {
      background: #e7ecf4;
      box-shadow: 0px 1px 3px rgba(20, 23, 28, 0.3);
      border-radius: 8px;
      border: 0;
      input {
        margin: 0 12px 0 4px;
        color: #090e15;
        outline: 0 !important;
        border-radius: 4px;
        height: 28px;
        line-height: 28px;
        border: 0;
        &:focus,
        &:hover {
          outline: 1px solid #c4cbd9 !important;
        }
      }
      .ql-preview {
        margin: 0 12px 0 4px;
      }
      .ql-action {
        display: inline-block;
        background: #090e15;
        border-radius: 4px;
        line-height: 28px;
        font-family: $font-major;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        padding: 0 12px;
        &:hover {
          opacity: 0.8;
        }
        &::after {
          margin: 0;
          border: 0;
          padding: 0;
        }
      }
      .ql-remove {
        display: inline-block;
        background: #bd5554;
        border-radius: 4px;
        line-height: 28px;
        font-family: $font-major;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        padding: 0 12px;
        margin-left: 8px;
        &:hover {
          opacity: 0.8;
        }
        &::before {
          margin: 0;
          border: 0;
          padding: 0;
        }
      }
    }
    .ql-snow .ql-tooltip[data-mode='link']::before {
      color: #090e15;
      font-family: $font-major;
      font-size: 14px;
      font-weight: 500;
      content: 'Enter URL:';
    }
    .ql-snow .ql-tooltip::before {
      color: #090e15;
      font-family: $font-major;
      font-size: 14px;
      font-weight: 500;
      content: 'Current URL:';
    }
  }
  &.quill-small {
    .ql-toolbar {
      border-color: #e7ecf4;
      border-radius: 0;
      padding: 8px 12px;
      button {
        padding: 3px 4px;
        width: 34px;
      }
    }
    .ql-container {
      border-color: #e7ecf4;
      border-radius: 0;
      .ql-editor {
        padding: 16px;
        height: 140px;
        p,
        li,
        strong,
        div {
          font-size: 12px;
          line-height: 18px;
          color: #090e15;
        }
      }
    }
    &.seriph-quill .quill .ql-container .ql-editor.ql-blank::before {
      font-size: 12px;
      line-height: 18px;
      font-style: normal !important;
    }
  }
  &.no-height .quill .ql-container .ql-editor {
    height: auto;
  }
}
