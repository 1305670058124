@import '_seriph/variables';

#company-settings-plan-payment {
  position: relative;
  width: 100%;
  padding: 0;

  .plansub {
    display: flex;
    flex-direction: row;
    margin: 0 -12px;
    margin-bottom: 24px;
    > div {
      flex: 1;
      margin: 0 12px;
    }
  }
  .seriph-subheader {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #090E15;
    margin: 2px 0 0 0;
  }
}



        // <div className="plan">
        //   <Subscription client={this.props.client} />
        // </div>
        // <div className="payment-method">
        //   <Elements>
        //     <PaymentMethod client={this.props.client} myself={this.props.myself} />
        //   </Elements>
        // </div>