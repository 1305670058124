@import '_seriph/variables';

.ant-popover {
  .ant-popover-content {
    max-width: 360px;
    .ant-popover-arrow {
      border-right-color: $dark-gray !important;
      border-bottom-color: $dark-gray !important;
    }
    .ant-popover-inner {
      border-radius: 12px;
      background: $dark-gray;
      .ant-popover-inner-content {
        padding: 0;
      }
      .ant-popover-message {
        color: #ffffff;
        display: flex;
        align-items: center;

        .ant-popover-message-title {
          font-family: $font-major;
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          padding: 0;
          margin-left: 10px;
        }
        .no-icon {
          margin-left: -10px;
        }

        .pop-main {
          .pop-title {
            padding: 12px 16px;
            display: flex;
            align-items: center;
            font-family: $font-major;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            border-bottom: 1px solid #242d3e;
            svg {
              stroke-width: 2px;
              color: $warning;
              width: 20px;
              height: 20px;
              margin-right: 12px;
            }
          }
        }
        .pop-content {
          padding: 12px 16px 0 16px;
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #c4cbd9;
        }
      }
      .ant-popover-buttons {
        padding: 16px;
        margin-bottom: 0 !important;
        .ant-btn {
          border-radius: 8px;
          border: none;
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          padding: 6px 12px !important;
        }
        .ant-btn-sm {
          background-color: #090e15 !important;
          color: #fff;
          border: 1px solid #3e3e3c;
          &:hover {
            background-color: #3e3e3c !important;
          }
        }
        .ant-btn-primary {
          background-color: #ffffff !important;
          background: #ffffff;
          border: 1px solid #d7dde9;
          box-sizing: border-box;
          border-radius: 8px;
          color: #090e15;
          &:hover {
            background-color: #e5e7ea !important;
          }
        }
      }
    }
  }
}

.tooltip-list {
  .title {
    border-bottom: 1px solid #4d5b76;
    padding-bottom: 4px;
  }
  > div {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
