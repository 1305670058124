.leave-campaign-modal {
  .ant-modal-body {
    position: relative;
    padding: 0;
  }
  .ra-body {
    padding: 24px;
  }
  .ra-title {
    margin: 0;
    font-size: 24px;
    line-height: 28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
  }
  .ra-agent {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 24px;
    right: 30px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin: 0 10px;
    }
    .ra-info {
      h4 {
        margin: 0;
        font-weight: 500;
        color: #000;
        font-size: 16px;
      }
    }
  }
  .ra-message {
    h3 {
      font-weight: 500;
      color: #0b1e41;
      font-size: 14px;
    }
    .ra-review {
      margin-top: 5px;
    }
    textarea {
      padding: 10px;
    }
    .notice {
      margin-top: 24px;
      b {
        font-weight: 500;
      }
    }
  }
  .ra-actions {
    text-align: right;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
    position: relative;
    .btn-default {
      margin-right: 15px;
    }
    .btn-primary {
      flex: 1;
    }
    .ra-overall {
      position: absolute;
      left: 24px;
      font-size: 16px;
      font-weight: 500;
      top: 26px;
      .ant-rate {
        margin-left: 8px;
      }
    }
  }
  .ra-skeleton {
    padding: 30px;
  }
}
