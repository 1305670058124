@import '_seriph/variables';

#overview-not-interested-chart {
  width: 100%;
  .recharts-xAxis .recharts-cartesian-axis-ticks {
    transform: translateY(12px);
  }
  .recharts-yAxis .recharts-cartesian-axis-ticks {
    transform: translateX(-12px);
  }
  .recharts-cartesian-axis-tick-value {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #090e15 !important;
    fill: #090e15 !important;
  }
  .chart-display {
    display: flex;
    flex-direction: row;
    .left-chart {
      flex: 1;
      min-width: 400px;
      margin-right: 40px;
    }
    .right-chart {
      width: 100%;
      min-width: 260px;
      max-width: 320px;
      h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        margin: 0;
        padding: 0;
      }
      .dash-data > .dash-sts {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #e7ecf4;
        &.na > div,
        &.na > h2 {
          opacity: 0.3 !important;
        }
        &:last-child {
          border-bottom: 0;
        }
        > h2 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          text-align: left;
          font-family: $font-major;
          margin: 0;
          text-align: right;
        }
        > div {
          font-family: $font-major;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          white-space: nowrap;
          text-align: left;
          flex: 1;
          > span {
            display: inline-block;
            background: grey;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            position: relative;
            margin-right: 12px;
            &.in-progress {
              background: $in-progress;
            }
            &.review {
              background: $review;
            }
            &.disputed {
              background: $disputed;
            }
            &.completed {
              background: $completed;
            }
            &.generated {
              background: $generated;
            }
            &.qualified {
              background: $qualified;
            }
            &.not-interested {
              background: $not-interested;
            }
          }
        }
      }
    }
  }
}
