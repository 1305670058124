@import '_seriph/variables';

#lead-highlights {
  width: 100%;
  max-width: 508px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  padding: 24px;
  > p,
  .paragraph {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #4d5b76;
    margin: 8px 0 0 0;
    padding: 0;
  }
  .ant-select {
    width: 300px;
  }
  .lh-filter {
    margin-top: 24px;
    .lhc-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 0;
      > .lhc-name {
        margin-left: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .lh-progress {
    margin-top: 32px;
    .bar,
    .bar > div {
      height: 24px;
      border-radius: 6px;
    }
    .percent {
      font-family: $font-major;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
    }
    .progress-info {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #707b90;
      margin: 4px 0 0 0;
    }
  }
  .lh-tasks {
    margin-top: 40px;
    .hl-card {
      background: #ffffff;
      border-radius: 12px;
      padding: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;
      border: 1px solid transparent;
      position: relative;
      &:hover {
        border: 1px solid #c4cbd9;
      }
      &.done {
        cursor: default !important;
        border: 1px solid transparent !important;
        h3 {
          text-decoration: line-through !important;
        }
        h3,
        p {
          color: #000;
          opacity: 0.4 !important;
        }
      }
      .hl-right {
        display: none;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        background: #090e15;
        border-radius: 16px;
        svg {
          width: 14px;
          height: 14px;
          stroke-width: 2px;
          color: #fff;
        }
      }
      &:hover .hl-right {
        display: flex;
      }
      .hl-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 16px;
        background: #e7ecf4;
        border-radius: 20px;
        svg {
          width: 18px;
          height: 18px;
          border-width: 2px;
          color: #090e15;
        }
        &.completed {
          background: #090e15;
          svg {
            color: #fff;
          }
        }
        &.call {
          background: #c1ddbb;
        }
        &.email {
          background: #d2d0fb;
        }
        &.linkedin {
          background: #b0d0e8;
        }
        &.instructions {
          background: #cbc4b7;
        }
      }
      h4 {
        margin: 0;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
        margin: 4px 0 0 0;
        b {
          font-weight: 600;
        }
      }
    }
  }
  .lh-follow {
    margin-top: 32px;
    > h4 {
      margin: 0 0 12px 0;
    }
    .follow-card {
      background: #ffffff;
      border-radius: 12px;
      padding: 24px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid #4353ff;
      }
      p {
        font-family: $font-major;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin: 0;
        b {
          font-weight: 600;
        }
      }
      .tag {
        width: 60px;
        padding: 4px 8px;
        font-family: $font-major;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        border-radius: 20px;
        text-align: center;
        margin-right: 16px;
        &.missed {
          background: #fbece7;
          color: #090e15;
        }
        &.email {
          background: #e6f3fc;
          color: #090e15;
        }
      }
    }
  }
}
