@import '_seriph/variables';

@keyframes rotate {
  100% {
    transform: rotate(-1turn);
  } // or 360deg
}

#sellx-missing {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #fff;
  .ready {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    .spinning {
      font-size: 60px;
      color: #e0e2e4;
    }
  }
  .logo {
    position: absolute;
    top: 40px;
    left: 40px;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      img {
        width: 48px;
        height: 48px;
      }
      h1 {
        margin: 0 0 0 15px;
        color: #000;
        height: 48px;
        line-height: 48px;
        font-size: 36px;
        font-weight: 600;
      }
    }
  }
  .img-fof {
    position: relative;
    display: inline-block;
    width: calc(50% + 100px);
    padding: 0 56px;
    max-width: 700px;
    min-width: 400px;
    > img {
      width: 100%;
      position: relative;
      z-index: 5;
    }
    > .blur {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: #090e15;
      filter: blur(15px);
    }
  }
  .img-circle {
    position: fixed;
    left: 50%;
    right: 0%;
    bottom: 0;
    transform: translate(-50%, 50%);
    width: 95%;
    max-width: 1256px;
    min-width: 800px;
    z-index: 1;
    img.circle {
      width: 100%;
      animation: 60s rotate infinite linear;
    }
  }

  .forgot-main {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    z-index: 999;
    width: 100%;
    padding: 30px;
    top: 50%;
    transform: translateY(-50%);
    > p {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 130%;
      text-align: center;
      color: #ffffff;
      margin-top: 64px;
      margin-bottom: 0;
    }
  }

  .forgot-body {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    width: 100%;
    padding: 30px;
    top: 45%;
    transform: translateY(-50%);
    > p {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #090e15;
      margin-top: 32px;
      margin-bottom: 0;
    }

    .descriptor-wrap {
      margin-top: 24px;
      > p {
        max-width: 400px;
        margin: 0 auto;
        font-family: $font-major;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        color: #090e15;
      }
    }

    .page {
      font-family: $font-header;
      font-style: normal;
      font-weight: normal;
      font-size: 120px;
      line-height: 120px;
      color: #090e15;
    }
    .not {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      > span {
        font-family: $font-header;
        font-style: italic;
        font-weight: normal;
        font-size: 120px;
        line-height: 120px;
        color: #090e15;
      }
      .fof {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 8px solid #d2d0fb;
        padding: 12px 48px;
        border-radius: 140px;
        height: 100px;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 60px;
        color: #090e15;
        position: relative;
        top: 12px;
        margin-right: 32px;
      }
    }
    .found {
      font-family: $font-header;
      font-style: normal;
      font-weight: normal;
      font-size: 120px;
      line-height: 140px;
      color: #090e15;
    }
  }
}
