#company-profile-overview {
  padding: 0 32px 24px 32px;
  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #242d3e;
    margin: 16px 0 0 0;
    border-bottom: 1px solid #e7ecf4;
    padding-bottom: 40px;
    &.empty {
      border-bottom: 0;
    }
    margin-bottom: 40px;
  }
  .recent {
    margin-top: 40px;
    margin-bottom: 24px;
  }
  .recent-listings {
    margin-top: 24px;
  }
  .brief-listing {
    margin-bottom: 16px;
    .sxd-card {
      border: 1px solid #e7ecf4;
    }
  }
}
