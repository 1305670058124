@import 'variables';

.audio-content {
  margin-top: 40px;
  > h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #090e15;
    margin-bottom: 10px;
  }
  .record-instructions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4d5b76;
    margin: 0 0 40px 0;
    svg {
      margin: 0px 6px;
      background: #090e15;
      color: white;
      border-radius: 50%;
      stroke-width: 2px;
      width: 20px;
      height: 20px;
      padding: 2px;
    }
  }

  .space-info {
    color: #707b90;
    font-size: 14px;
    line-height: 21px;
    margin: 12px 0;
    text-align: center;
  }
  .retry {
    text-align: center;
    margin-top: 16px;
  }
  .recording {
    background: #f5f7fb;
    border-radius: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    .visualizer {
      flex: 1;
      margin: 0 32px 0 31px;
      overflow: hidden;
      > wave {
        margin: 0 auto;
      }
    }
    .timestamp {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #090e15;
      padding-right: 8px;
    }
    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      min-width: 50px;
      border-radius: 25px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &.start {
        background: #090e15;
        svg {
          color: #fff;
          font-size: 20px;
        }
      }
      &.stop {
        background: #b34043;
        svg {
          color: #fff;
          font-size: 20px;
        }
      }
      &.play {
        background: #090e15;
        svg {
          color: #fff;
          font-size: 20px;
        }
      }
      &.pause {
        background: #fff;
        box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
        svg {
          color: #090e15;
          font-size: 20px;
        }
      }
    }
  }
}
