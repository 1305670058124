#call-popout {
  display: block;
  position: fixed;
  bottom: 32px;
  z-index: 9999999;
  width: 100%;
  max-width: 1120px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 32px;
  &.hidden {
    bottom: -120px;
  }
  transition: bottom 0.2s ease;
  .call-container {
    position: relative;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 24px;
    background: #ffffff;
    border-radius: 24px;
    padding: 20px 32px;
    height: 88px;
    z-index: 9;
  }
  .call-expand {
    position: absolute;
    top: 0;
    transition: top 0.2s ease;
    width: calc(100% - 64px);
    border-radius: 24px;
    background: #f5f7fb;
    border-radius: 24px;
    height: 88px;
    z-index: 1;
    text-align: center;
    padding-top: 0px;
    svg {
      font-size: 12px;
      color: #090e15;
    }
    &:hover {
      background: #e5e7eb;
      cursor: pointer;
    }
  }
  &:hover {
    .call-expand {
      top: -24px !important;
    }
  }
}
