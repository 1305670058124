@import '~antd/dist/antd.css';
@import '../variables';
@import '_seriph/variables';

#masq-user {
  position: fixed;
  width: calc(100% - 220px);
  left: 220px;
  top: 0;
  background-color: #d2d0fb;
  transition: all 0.3s ease;
  text-align: center;
  padding: 4px 0;
  font-family: $font-major;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #3e3993;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .go-back {
    margin-left: 12px;
    display: inline-flex;
    text-decoration: underline;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    svg {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}

#business.small-menu #masq-user,
#agent.small-menu #masq-user {
  width: calc(100% - 70px);
  left: 70px;
}

.btn {
  font-weight: 600;
  padding: 9px 12px;
  height: 40px;
  border: 1px solid;
  &:hover {
    opacity: 0.8;
  }

  &.btn-primary {
    background-color: #4353ff;
    color: #fff;
    border-color: #4353ff;
  }

  &.btn-dark {
    background-color: #4d5b76;
    color: #fff;
    border-color: #4d5b76;
  }

  &.btn-neutral {
    background-color: #dce0e8;
    color: #090e15;
    border-color: #dce0e8;
  }

  &.btn-bleutral {
    background-color: rgba(67, 83, 255, 0.06);
    color: #4353ff;
    border-color: rgba(67, 83, 255, 0.06);
  }

  &.btn-warning {
    background-color: #ffc900;
    color: #2a3e62;
    border-color: #ffc900;
  }

  &.btn-success {
    background-color: #00c06e;
    color: #fff;
    border-color: #00c06e;
  }
  &.btn-white {
    background-color: #fff;
    color: #4353ff;
    border-color: #fff;
  }

  &.btn-orange {
    background-color: #ff6c4b;
    color: #fff;
    border-color: #ff6c4b;
  }

  &.btn-default {
    background-color: #fff;
    color: #090e15;
    border-color: #dce0e8;
    &:hover {
      color: #4353ff;
      opacity: 1;
    }
  }
  &.btn-danger {
    background-color: #de1748;
    color: #fff;
    border-color: #de1748;
  }
  &.btn-hazard {
    background-color: #fff;
    color: #090e15;
    border-color: #dce0e8;
    &:hover {
      color: #de1748;
      opacity: 1;
      border-color: #de1748;
    }
  }
  &.btn-danger.btn-empty {
    background-color: transparent;
    color: #ff3768;
    &:hover {
      color: #de1748;
      opacity: 1;
    }
  }
  &.btn-empty {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.btn-block {
    width: 100%;
  }
  &.btn-giant {
    height: 60px;
    font-size: 18px;
    line-height: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &.btn-large {
    height: 48px;
    font-size: 16px;
    line-height: 16px;
  }
  &.btn-med {
    height: 36px;
    font-size: 13px;
    line-height: 16px;
    padding: 0 12px;
  }
  &.btn-small {
    height: 30px;
    font-size: 12px;
    line-height: 14px;
    padding: 0 12px;
  }
  &.btn-tiny {
    height: 36px;
    font-size: 14px;
    line-height: 14px;
    padding: 0 12px;
  }
}
a.btn {
  line-height: 30px !important;
}

.ant-btn.ant-btn-primary {
  font-weight: 600;
  padding: 9px 12px;
  height: 40px;
  border: 1px solid;
  &:hover {
    opacity: 0.8;
  }
  background-color: #4353ff !important;
  color: #fff;
  border-color: #4353ff;
}

.ant-btn.ant-btn-danger {
  font-weight: 600;
  padding: 9px 12px;
  height: 40px;
  border: 1px solid;
  &:hover {
    opacity: 0.8;
  }
  background-color: #de1748 !important;
  color: #fff;
  border-color: #de1748;
}

.ant-btn {
  font-weight: 600;
  padding: 9px 12px;
  height: 40px;
  border: 1px solid;
}

.sellx-tooltip {
  .ant-tooltip-inner {
    padding: 16px;
    background: #0b1e41;
    color: #fff;
    font-weight: 500;
  }
  .ant-tooltip-arrow {
    border-top-color: #0b1e41;
  }
  &.sellx-tooltip-small .ant-tooltip-inner {
    padding: 8px;
  }
  &.sellx-tooltip-center {
    top: 56px !important;
  }
}
.tt-icon {
  cursor: pointer;
  margin-left: 8px;
}

.btn[disabled],
.btn.disabled,
.btn[disabled]:hover,
.btn.disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-skeleton-paragraph {
  padding: 0;
}

#loading-svg {
  text-align: center;
  font-size: 80px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}
#loading-svg-small {
  text-align: center;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.table-content {
  background-color: #fff;
  border-radius: 5px;
  .table-header {
    padding: 20px 30px;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    color: #090e15;
    border-top: 1px solid #e6e9ef;
    border-left: 1px solid #e6e9ef;
    border-right: 1px solid #e6e9ef;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .table-footer {
    padding: 20px;
    border-bottom: 1px solid #e6e9ef;
    border-left: 1px solid #e6e9ef;
    border-right: 1px solid #e6e9ef;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .base {
      background-color: #f5f7fa;
      color: #0b1e41;
      font-weight: 600;
      border-radius: 5px;
      width: 100%;
      padding: 20px;
      button {
        margin-left: 20px;
        border: 0;
        background-color: #4353ff;
        font-weight: 600;
      }
    }
  }
}
.sellx-table {
  background-color: #fff;
}
.sellx-dropdown {
  .ant-dropdown-menu-item {
    font-size: 14px;
    font-weight: 400;
    padding: 6px 20px;
    color: #000;
    svg {
      margin-right: 10px;
      color: #333;
    }
    a {
      color: #000;
    }
    &.ant-dropdown-menu-item-active {
      background-color: #f3f5f9;
    }
  }
}

.ant-menu.sellx-menu {
  border: 0;
  font-size: 16px;
  font-weight: 500;
  .ant-menu-item {
    color: #4d5b76;
    > svg {
      margin-right: 8px;
    }
    border-bottom: 0 !important;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    &.ant-menu-item-selected:before,
    &.ant-menu-item-active:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 3px;
      width: 40px;
      border-bottom: 3px solid #4353ff;
    }
    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      > a {
        color: #4353ff;
      }
    }
  }
}

.ant-form.sellx-form {
  .ant-form-item {
    margin: 0;
  }
}

.ant-btn-link {
  color: #1890ff;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: transparent;
}

.sts-ball {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  background-color: #dce0e8;
  margin-right: 8px;
  &.completed {
    background-color: #4353ff;
  }
  &.inactive {
    background-color: #ffc900;
  }
  &.active {
    background-color: #00b533;
  }
}

.sx-actions {
  text-align: right;
  margin-top: 16px;
  button {
    margin-left: 16px;
  }
}

.hired-status-drop {
  .ant-select-dropdown-menu-item b {
    display: none;
  }
  .app-sort-alert {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: rgb(245, 34, 45);
    display: inline-block;
    margin-right: 6px;
    position: relative;
  }
}
