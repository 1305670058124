@import '_seriph/variables';

.seriph-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #ffffff;
  border: 2px solid #d7dde9;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  user-select: none;
  margin-right: 8px;
  font-family: $font-major;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #090e15;
  > div:first-child {
    display: inline-block;
    position: relative;
    top: 1px;
  }
  > .tag-hide {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    cursor: pointer;
  }
  &:hover {
    background: #f5f7fb;
  }
  &:focus {
    background: #e7ecf4;
  }
  &[disabled] {
    cursor: not-allowed;
    background: #f5f7fb;
    border-color: #e7ecf4;
  }
}

/* HANDLE SIZES */
.seriph-tag.tag-tiny {
  border-radius: 10px;
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  font-weight: 500;
  border: 1px solid #d7dde9;
}
.seriph-tag.tag-medium {
  border-radius: 16px;
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.seriph-tag.tag-large {
  border-radius: 20px;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

/* HANDLE TYPES/COLORS */
.seriph-tag.tag-primary {
  background: #090e15;
  border-color: #090e15;
  color: #fff;
  &:hover {
    background: #242d3e;
    border-color: #242d3e;
  }
  &:focus {
    background: #4d5b76;
    border-color: #4d5b76;
  }
}
.seriph-tag.tag-warning {
  background: #fffbed;
  border-color: #f9e59f;
  &:hover {
    background: #fdf0c4;
    border-color: #f9e59f;
  }
  &:focus {
    background: #f9e59f;
    border-color: #f9e59f;
  }
}
.seriph-tag.tag-info {
  background: #d2d0fb;
  border-color: #d2d0fb;
  &:hover {
    background: #aeaaf8;
    border-color: #aeaaf8;
  }
  &:focus {
    background: #7872f3;
    border-color: #7872f3;
  }
}
.seriph-tag.tag-success {
  background: #eef9ec;
  border-color: #eef9ec;
  &:hover {
    background: #c1ddbb;
    border-color: #c1ddbb;
  }
  &:focus {
    background: #c1ddbb;
    border-color: #c1ddbb;
  }
}
.seriph-tag.tag-danger {
  background: #ffe9e9;
  border-color: #ffe9e9;
  &:hover {
    background: #f8aaaa;
    border-color: #f8aaaa;
  }
  &:focus {
    background: #f37272;
    border-color: #f37272;
  }
}
