@import 'variables';

#message-company {
  position: fixed;
  z-index: 100;
  top: 144px;
  transform: translateY(-50%);
  right: 30px;
}

@media screen and (min-width: 1260px) {
  #message-company {
    right: calc((100% - 1260px) / 2 + 30px);
  }
}

#live-training {
  display: block;
  width: 100%;
  padding: 32px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .training-bod {
    display: flex;
    flex-direction: row;
    .sellx-card {
      h1 {
        font-size: 22px;
        line-height: 33px;
        color: #000000;
      }
      h3 {
        font-size: 16px;
        line-height: 18px;
        color: #0b1e41;
      }
    }
    #save-guidelines {
      position: absolute;
      top: 11px;
      right: 20px;
    }
    .editor-col {
      width: 66.66%;
      margin-right: 30px;
      display: flex;
      flex-grow: 1;
      position: relative;
      .quill {
        background: #fff;
        height: calc(100% - 58px);
        width: 100%;
        .ql-toolbar {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-color: #e6e9ef;
          padding: 16px;
        }
        .ql-container {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-color: #e6e9ef;
          background: #fff;
        }
      }
      .guidelines-card {
        margin-bottom: 0;
        width: 100%;
      }
      .sxd-card > h2 {
        font-family: $font-tt;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #090e15;
        margin: 0 0 24px 0;
      }
      .html-instructions {
        color: #090e15 !important;
        p,
        span,
        div,
        h1,
        h2,
        h3,
        h4,
        h5 {
          color: #090e15 !important;
        }
      }
    }
    .prof-col {
      width: 33.33333%;
      h3 {
        font-family: $font-tt;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #090e15;
        margin: 0 0 16px 0;
      }
      .cust-prof {
        > div {
          margin-bottom: 24px;
          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
            margin: 0 0 4px 0;
          }
          p {
            font-size: 12px;
            line-height: 18px;
            color: #242d3e;
            padding: 0;
            margin: 0;
            text-transform: capitalize;
          }
        }
      }
    }
    > .ant-row {
      margin-bottom: 30px;
      position: absolute;
      min-height: 100%;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .step-instruct {
      flex-direction: column;
      textarea.forms {
        min-height: 50px !important;
      }
      .instruct-list {
        div {
          margin: 0 -15px 0 -15px;
          padding: 10px 15px;
          display: flex;
          flex-direction: row;
          border-radius: 5px;
          position: relative;
          p {
            font-size: 16px;
            line-height: 20px;
            margin: 0;
            padding-right: 56px;
            span {
              display: inline-block;
              width: 20px;
              font-weight: 500;
            }
            flex: 1;
          }
          button {
            visibility: hidden;
            color: #fff;
            background-color: #ff4d4f;
            border-color: #ff4d4f;
            min-width: 20px;
            width: 26px;
            padding: 0;
            font-size: 14px;
            border-radius: 50%;
            height: 26px;
            -moz-transition: none;
            -webkit-transition: none;
            -o-transition: none;
            transition: none;
            position: absolute;
            right: 15px;
            top: 7px;
            &:hover {
              opacity: 0.6;
            }
          }
          &:hover {
            button {
              visibility: visible;
            }
            background: #f7f8fa;
          }
        }
      }
      .instruct-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        button {
          margin-right: 20px;
        }
        div {
          color: #8a9eb2;
          width: 120px;
          line-height: 14px;
        }
      }
    }
  }
}
