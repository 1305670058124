@import 'variables';

.instructions-modal {
  .ant-modal {
    padding: 0;
    height: 100%;
    .ant-modal-content {
      height: 100%;
      max-height: 100%;
      max-width: 1256px;
      overflow: auto;
      background: #f5f7fb;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
