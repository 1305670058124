@import 'variables';

.sxd-menu {
  .ant-dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    padding: 12px;
  }
  .ant-dropdown-menu-item {
    font-weight: 500;
    border-radius: 8px;
    color: $blue-800;
    font-size: 14px;
    line-height: 21px;
    padding: 8px 12px;
    > a {
      color: $blue-800;
    }
  }
  .ant-dropdown-menu-item.end-it {
    color: #ff3768;
  }
  .ant-dropdown-menu-item.start-it {
    color: #14b554;
  }
  .ant-dropdown-menu-item-active {
    background: $neutral-100;
  }
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    background-color: #e7ecf4;
  }
}
