@import '_seriph/variables';

#lead-calls {
  padding: 32px 0;
  position: relative;
  .sx-empty {
    margin-top: 96px;
  }
  .selected-call {
    border: 1px solid transparent;
    padding: 24px;
    cursor: pointer;
    margin-bottom: 8px;
    &:hover {
      border-color: #d7dde9;
    }
    &.disabled:hover {
      border-color: transparent;
      cursor: default;
    }
  }
  .call-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    h2 {
      flex: 1;
    }
  }
  .selected {
    margin-bottom: 32px;
  }
  .calls-table {
    .seriph-table.table-lined tr:not(.ant-table-expanded-row) > th {
      border-top: 0 !important;
    }
    .ant-pagination {
      display: none !important;
    }
    .player {
      display: block;
      flex: 1;
      max-width: 320px;
    }
    .call-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      .call-details {
        h3 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          margin: 0;
          color: #090E15;
        }
        p {
          font-family: $font-major;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin: 0;
          color: #707B90;
        }
      }

    }
    .call-own {
      > div {
        margin-right: 12px;
      }
    }
    .call-icon {
      width: 40px;
      height: 40px;
      background: #b0d0e8;
      border-radius: 20px;
      position: relative;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 16px;
        height: 16px;
        color: #090e15;
      }
      &.incoming {
        background: #c1ddbb;
      }
      &.missed {
        background: #efbba9;
      }
    }
    .rhap_container {
      box-shadow: none;
      background: transparent;
      outline: none;
      padding: 0 !important;
      .rhap_progress-container,
      .rhap_button-clear {
        outline: none;
      }
      .rhap_controls-section {
        flex: 0;
      }
      .rhap_time {
        font-family: $font-major;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
      }
      .rhap_progress-bar {
        background: #f0f3f7 !important;
        border-radius: 8px;
        height: 8px;
      }
      .rhap_progress-container {
        display: none !important;
      }
      .rhap_download-progress {
        background: #f0f3f7 !important;
        border-radius: 8px;
      }
      .rhap_progress-filled {
        background: #090e15;
        border-radius: 8px;
      }
      .rhap_progress-indicator {
        display: none !important;
      }
      .rhap_main-controls-button {
        color: #090e15 !important;
        width: 40px;
        height: 40px;
        &:hover {
          color: #242d3e;
        }
        svg {
          width: 40px;
          height: 40px;
          font-size: 40px;
        }
      }
    }
  }
  .lead-call {
    display: flex;
    flex-direction: row;
    align-items: center;
    .call-icon {
      width: 40px;
      height: 40px;
      background: #b0d0e8;
      border-radius: 20px;
      position: relative;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 16px;
        height: 16px;
        color: #090e15;
      }
      &.incoming {
        background: #c1ddbb;
      }
      &.missed {
        background: #efbba9;
      }
    }
    .call-info {
      white-space: nowrap;
      flex: 1;
      h3 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
        margin: 0;
        white-space: nowrap;
      }
      h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        margin: 2px 0 0 0;
        white-space: nowrap;
      }
    }
    .play {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: #e6e9ef;
      position: relative;
      margin-left: 40px;
      cursor: pointer;
      &:hover {
        background: #c6c9cf;
      }
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #090e15;
      }
    }
  }
}
