@import '_seriph/variables';

.overview-header {
  z-index: 99;
  width: 100%;
  .oc-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background: #fff;
    transition: all 0.2s ease;
    .oc-actions {
      display: flex;
      button {
        margin-left: 12px;
      }
    }
    box-shadow: none;
    .oc-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .oc-header-match {
        background: #e8e7fd;
        font-family: $font-major;
        font-weight: 400;
        border-radius: 48px;
        padding: 4px 16px;
        color: #090e15;
        margin-left: 16px;
      }
    }
  }
  .invite-actions {
    button {
      margin-left: 16px;
    }
  }
  .oc-scroll {
    padding-left: 16px;
    flex: 1;
    opacity: 0;
    transition: opacity 0.2s ease;
    &.visible {
      opacity: 1;
    }
    .oc-types {
      font-family: $font-major;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      margin: 0;
      b {
        color: #090e15;
        font-weight: 500;
      }
    }
    h3 {
      font-family: $font-major;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      margin: 0;
    }
  }
  &.scrolled {
    .oc-scroll {
      opacity: 1;
    }
    .oc-header {
      padding: 24px;
      box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
    }
  }
}
