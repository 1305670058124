@import '_seriph/variables';

#agent-status-chart {
  flex: 1;
  margin-bottom: 48px;
  padding-top: 24px !important;
  > h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0b1e41;
    padding: 0 24px;
    margin-bottom: 12px;
  }
  .status-row {
    display: flex;
    flex-direction: row;
    > div {
      flex: 1;
    }
  }
  .dash-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 8px;
    padding: 0 24px;

    .chart-chart {
      width: 100%;
    }
    > .dash-card {
      display: inline-block;
      width: auto;
      padding: 0;
      min-width: 120px;
      margin-right: 32px;
      margin-bottom: 24px;
      &:last-child {
        margin-right: 0;
      }
      height: 100%;
      &.dc-1 {
        flex: 1;
      }
      &.dc-2 {
        flex: 2;
      }
      &.dc-3 {
        flex: 3;
      }
      &.dc-4 {
        flex: 4;
      }
      &.dc-5 {
        flex: 5;
      }
      &.dc-6 {
        flex: 6;
      }
    }
    .dash-sts {
      > h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: #090e15;
        text-align: left;
        font-family: $font-major;
        margin: 0 0 8px 0;
      }
      > div {
        font-family: $font-major;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        white-space: nowrap;
        text-align: left;
        margin-bottom: 8px;
        > span {
          display: inline-block;
          background: grey;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          position: relative;
          margin-right: 8px;
          &.in-progress {
            background: $in-progress;
          }
          &.review {
            background: $review;
          }
          &.disputed {
            background: $disputed;
          }
          &.completed {
            background: $completed;
          }
          &.generated {
            background: $generated;
          }
          &.qualified {
            background: $qualified;
          }
          &.not-interested {
            background: $not-interested;
          }
        }
      }
    }
  }
}
