@import '_seriph/variables';

#company-analytics {
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  .analytics-menu {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    padding: 24px 12px;
    .menu-tabs {
      padding: 0 12px;
      > .menu-tab {
        padding: 0 16px;
        height: 48px;
        line-height: 48px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        position: relative;
        cursor: pointer;
        margin-bottom: 4px;
        > svg {
          position: absolute;
          right: 20px;
          width: 14px;
          height: 14px;
        }
        &:hover {
          background: #f0f2f7;
        }
        &.active {
          background: #090e15;
          color: #fff;
        }
      }
      .menu-divider {
        content: '';
        margin: 12px 16px;
        height: 1px;
        width: calc(100% - 32px);
        background: #e7ecf4;
      }
    }
    .menu-title {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #090e15;
      padding: 0 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      > span {
        flex: 1;
      }
      &.menu-space {
        margin-top: 24px;
      }
    }
  }
  .analytics-main {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .chart-box {
      background: #fff;
      border-radius: 8px;
      margin-bottom: 32px;
      .chart-title {
        padding: 24px 32px 16px 32px;
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0 0 8px 0;
          padding: 0;
          > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #707b90;
            margin-left: 8px;
          }
        }
        > p {
          font-family: $font-major;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #090e15;
          margin: 0;
          padding: 0;
        }
      }
      .chart-display {
        padding: 16px 32px 32px 32px;
      }
    }
    .chart-column {
      width: 100%;
    }
    .chart-toggles {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
      .chart-toggle {
        margin-right: 16px;
        cursor: pointer;
        min-width: 140px;
        &.disabled {
          opacity: 0.4;
        }
        border-bottom: 4px solid transparent;
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0 0 8px 0;
          padding: 0;
        }
        h2 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 38px;
          color: #090e15;
          margin: 0;
          padding: 0;
        }
      }
    }
    .chart-table table {
      width: 100%;
      th {
        border-bottom: 1px solid #e7ecf4;
        border-top: 1px solid #e7ecf4;
        padding: 16px 24px;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
      }
      td {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        padding: 20px 24px;
        border-bottom: 1px solid #e7ecf4;
      }
      tbody > tr:last-child > td {
        border-bottom: 0 !important;
      }
      tr.summary > td {
        font-weight: 500;
      }
    }
  }
}

#company-analytics {
  .chart-tooltip {
    background: #090e15;
    border-radius: 8px;
    padding: 0;
    .tip-box {
      .tip-title {
        padding: 8px 12px;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        font-family: $font-major;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
      .tip-contents {
        padding: 8px 12px;
        .tip-item {
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          &.tip-last {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            padding-top: 8px;
          }
          .tip-circle {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin-right: 10px;
          }
          .tip-name {
            color: #fff;
            flex: 1;
            font-family: $font-major;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
          .tip-value {
            color: #fff;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
