#agent-settings-account {
  position: relative;
  padding: 0;
  .save-account {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .change-password {
    max-width: 240px;
    padding: 16px 0;
    input {
      margin-bottom: 10px;
      color: #000;
      font-weight: 500;
      border-radius: 5px;
      &:focus,
      &:hover {
        outline: 0;
        box-shadow: none;
        border: 1px solid #4353ff;
      }
    }
    .change-actions {
      text-align: right;
      margin-top: 5px;
      button {
        margin-left: 10px;
      }
    }
  }
  .value.resend {
    flex-direction: row !important;
    svg {
      margin-left: 5px;
      position: relative;
      top: -2px;
      color: #30f09e;
      cursor: pointer;
    }
  }
  .settings-picture {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
}
