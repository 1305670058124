@import '_seriph/variables';

.manage-touchpoint-modal {
  .ant-modal-content {
    overflow: visible !important;
  }
  .ant-modal-body {
    min-height: 200px;
    padding: 0 !important;
    position: relative;
  }
  .seriph-modal-close {
    z-index: 9999;
  }
  .seriph-modal-actions {
    padding: 24px 32px;
  }
}
