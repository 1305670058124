@import '_seriph/variables';

#wizard-threshold {
  .wz-container > .input-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    > .field {
      flex: 1;
      &:first-child {
        margin-right: 24px;
      }
    }
    .ant-input[disabled] {
      color: #0b1e41 !important;
      background: #f5f7fb !important;
    }
    .ant-select-selection-selected-value {
      font-size: 14px;
      color: #0b1e41;
    }
  }
  .hint {
    display: flex;
    flex-direction: row;
    svg {
      color: #6f55f7;
      margin-right: 16px;
      position: relative;
      top: 4px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0;
    }
  }
  .thresh-info {
    margin-top: 32px;
    background: #e8e7fd;
    border-radius: 12px;
    padding: 40px;
    > div,
    p,
    h4 {
      text-align: center;
    }
    h4 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #090e15;
      margin: 0;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      color: #090e15;
      margin: 8px 0 16px 0;
    }
    .icon-holder > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
      border-radius: 44px;
      margin-bottom: 16px;
    }
  }
}
