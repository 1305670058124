@import './variables';
@import '_seriph/styles.scss';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #090e15;
}

#root {
  width: 100%;
  height: 100%;
}

.info-tip {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
