@import 'variables';

#admin-campaigns {
  padding: 40px 30px 30px 30px;
  .admin-header {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    position: relative;
    > div {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      position: relative;
      left: 0;
      top: -5px;
    }
    .ant-input-search {
      width: 300px;
      position: absolute;
      right: 0;
      top: 0;
      input {
        border: 1px solid #e6e9ef;
        background: #fff;
        outline: 0;
        box-shadow: none;
        color: #000000;
        &:hover,
        &:focus {
          border-color: #4353ff;
          outline: 0;
          box-shadow: none;
        }
      }
      .anticon-search {
        font-size: 16px;
        color: #000;
        &:hover {
          color: #4353ff;
        }
      }
    }
  }
  .admin-table {
    .lead-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      .lead-photo {
        padding-right: 10px;
        img {
          width: 36px;
          height: 36px;
          border-radius: 18px;
        }
        span {
          display: block;
          width: 36px;
          height: 36px;
          border-radius: 18px;
          line-height: 36px;
          text-align: center;
          background: #ecedff;
          color: #4353ff;
          font-weight: 600;
        }
      }
      .lead-full {
        .lead-title {
          font-size: 12px;
          color: #999;
        }
      }
    }
    .agent-email {
      svg {
        color: #30f09e;
        margin-left: 8px;
      }
    }
  }
  .table-content {
    .ant-table-wrapper {
      .ant-table-thead th {
        background: #ffffff;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #77839b;
        border-bottom: 1px solid rgba(40, 43, 48, 0.08);
      }
      .ant-table-thead {
        box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 8px rgba(40, 43, 48, 0.04);
      }
      .ant-empty {
        display: none;
      }
      .ant-table-body {
        td {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
        }
      }
      .progress-table {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        > svg {
          margin-left: 12px;
          position: relative;
          top: 2px;
          cursor: pointer;
        }
      }
    }
    .review-actions {
      text-align: right;
      button {
        margin-right: 12px;
      }
    }
    .ctbl-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 64px;
      .sxd-avatar {
        margin-right: 16px;
      }
      .ctbl-name {
        h4 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 1px;
          width: 192px;
          font-family: $font-tt;
          margin: 0 0 4px 0;
        }
        h5 {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #090e15;
          span {
            color: #707b90;
          }
        }
        .sx-status {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          margin-left: 2px;
          margin-right: 6px;
        }
      }
    }
    .tbl-status {
      white-space: nowrap;
    }
    .tbl-type {
      border-radius: 12px;
      padding: 3px 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: inline-block;
      white-space: nowrap;
      &.qualify {
        color: #142e24;
        background: #b5faaa;
      }
      &.leadgen {
        color: #250d93;
        background: #a9e3ff;
      }
      &.draft {
        color: #090e15;
        background: #dce0e8;
      }
    }
  }
}
