#launching-status {
  text-align: center;
  > img {
    width: 216px;
    height: 206px;
  }
  h3 {
    margin-top: 16px;
  }
  .ant-progress {
    width: 300px;
  }
}
