@import '_seriph/variables';

#internal-home {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .home-main {
    padding: 40px 64px 40px 32px;
    flex: 1;
    .home-name {
      font-weight: normal;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 8px;
    }
    .home-ttl {
      font-weight: 400;
      margin-bottom: 32px;
    }
  }
  .seriph-alert {
    margin-bottom: 40px;
  }
}

#internal-home {
  .chart-tooltip {
    background: #090e15;
    border-radius: 8px;
    padding: 0;
    .tip-box {
      .tip-title {
        padding: 8px 12px;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        font-family: $font-major;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
      .tip-contents {
        padding: 8px 12px;
        .tip-item {
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          &.tip-last {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            padding-top: 8px;
          }
          .tip-circle {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin-right: 10px;
          }
          .tip-name {
            color: #fff;
            flex: 1;
            font-family: $font-major;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
          .tip-value {
            color: #fff;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
