@import '_seriph/variables';

#dialer-lead-info {
  .info-header {
    height: 60px;
    line-height: 60px;
    padding: 0 16px 0 64px;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    position: relative;
    .seriph-modal-close {
      right: auto;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .info-list {
    border-top: 1px solid #e7ecf4;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
    .section {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      padding: 8px 24px;
      background: #f0f2f8;
    }
    .info {
      padding: 16px 24px;
      > div > h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        padding: 0;
        margin: 0 0 4px 0;
      }
      > div > p {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        padding: 0;
        margin: 0 0 4px 0;
        i {
          opacity: 0.3;
        }
      }
    }
  }
}
