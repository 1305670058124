.sellx-infotip {
  .ant-tooltip-inner {
    padding: 12px;
    background: #0b1e41;
    color: #fff;
    font-weight: 400;
    text-align: center;
  }
  .ant-tooltip-arrow {
    border-top-color: #0b1e41;
  }
}

.sellx-infotip-icon {
  position: relative;
  top: 1px;
  cursor: pointer;
  margin-left: 6px;
}
