@import '_seriph/variables';

#create-touchpoint {
  .create-header {
    text-align: center;
    margin-top: 24px;
    padding-top: 16px;
  }

  .create-top {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e7ecf4;
    position: relative;
    z-index: 1;
  }

  .type-error {
    color: #ee3a3a;
    display: flex;
    align-items: center;
    > svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
  }

  .create-content {
    position: relative;
    padding: 0 24px;
    margin-top: 40px;
    .type {
      margin-top: 24px;
      label {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin-bottom: 8px;
        display: block;
      }
    }
    .types {
      margin: 0 -6px;
    }
    .types > .type-opt {
      display: inline-flex;
      align-items: center;
      border: 1px solid #d7dde9;
      border-radius: 10px;
      background: #fff;
      padding: 12px 24px;
      cursor: pointer;
      margin-left: 6px;
      margin-right: 6px;
      margin-bottom: 12px;
      &:hover,
      &.active {
        border: 1px solid #090e15;
        background: #fff;
      }
      font-family: $font-major;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      > div {
        width: 24px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        margin-right: 12px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
