@import '_seriph/variables';

#change-number {
  background: #FFFFFF;
  border-top: 1px solid #E7ECF4;
  padding: 8px 16px;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  &:hover {
    background: #fafbfc;
  }
  .number-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    .seriph-select {
      flex: 1;
      margin-right: 16px;
    }
  }
  .change-actions {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    > button {
      flex: 1;
    }
  }
  .from-bar {
    width: 100%;
    height: 32px;
    line-height: 32px;
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #090E15;
    > span {
      font-weight: 400;
    }
  }
  .bar-toggle > svg {
    width: 20px;
    height: 20px;
    color: #090E15;
  }
}