@import '_seriph/variables';

#lead-sequences {
  padding: 24px 0 32px 0;
  position: relative;
  min-height: 100%;
  .sequence {
    .sequence-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      h3 {
        font-family: $font-major;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #090e15;
        flex: 1;
        white-space: nowrap;
        margin: 0;
        > span {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #4d5b76;
          margin-left: 12px;
          position: relative;
          top: -1px;
        }
      }
      > div {
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        text-align: right;
        white-space: nowrap;
      }
    }
    .steps {
      margin-bottom: 32px;
    }
  }
  .will-begin {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 15px 0 25px;
    &::after {
      content: '';
      position: absolute;
      top: 14px;
      width: 100%;
      height: 1px;
      background-color: #d7dde9;
    }
    h3 {
      font-size: 14px;
      color: #090e15;
      z-index: 11;
      background: #f3f6fa;
      display: inline-block;
      padding-left: 10px;
      padding-right: 22px;
    }
    .fa-cont {
      background: #f3f6fa;
      padding-left: 22px;
      z-index: 11;
      svg {
        color: #929aaa;
      }
    }
  }
}
