@import '_seriph/variables';

.invitations {
  overflow: hidden;
  position: relative;
  .inv-filter {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .sx-search {
      max-width: 240px;
    }
  }
  .invites-table {
    margin-top: 16px;
  }
  #invitations-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
    margin: 24px 0;
  }

  .ctbl-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .ctbl-name {
      margin-left: 16px;
      h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 1px;
        width: 192px;
        margin: 0 0 2px 0;
      }
      .joined-info {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
      }
    }
  }
  .camp-list {
    max-width: 600px;
    > div {
      display: block;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      font-family: $font-major;
      font-weight: 400;
    }
  }
  .row-actions {
    button {
      margin: 0 5px;
    }
  }
  .agent-actions-dropdown {
    .ant-dropdown-menu-item {
      font-weight: 500;
      color: #0b1e41;
      > a {
        color: #0b1e41;
      }
    }
    .ant-dropdown-menu-item.end-it {
      color: #ff3768;
    }
    .ant-dropdown-menu-item.start-it {
      color: #14b554;
    }
    .ant-dropdown-menu-item-active {
      background: #f0f2f4;
    }
    .ant-dropdown-menu-item-disabled {
      color: #0b1e41 !important;
      opacity: 0.2;
    }
  }
}
