@import '_seriph/variables';

#agent-profile-experience {
  margin-top: 16px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin: 0 0 16px 0;
  }
  .position-info {
    margin-bottom: 8px;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #090e15;
    font-weight: 500;
    span {
      color: #4d5b76;
      font-weight: 400;
    }
  }
  .position-description {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
  }
  .position-box {
    position: relative;
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
    }
    button {
      top: 0 !important;
      right: 0 !important;
      font-size: 11px;
      line-height: 16px;
      padding: 4px 8px;
      height: 24px;
      border-radius: 8px;
      svg {
        width: 11px;
        height: 11px;
        margin-right: 6px;
      }
    }
    .edit {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      svg {
        margin-right: 4px;
        font-size: 11px;
      }
    }
    &:hover .edit {
      display: inline-block;
    }
  }
}

.agent-experience-edit {
  .sx-form {
    padding-top: 32px;
  }
  .field {
    margin-bottom: 16px;
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      display: block;
    }
  }
}

.form-modal {
  .ant-modal-body {
    padding-top: 4px;
    .form-title {
      margin-top: 20px;
    }
  }
  .two-column {
    display: flex;
    flex-direction: row;
    > div {
      flex: 1;
    }
    &.spaced {
      > div {
        &:first-child {
          padding-right: 10px;
        }
        &:last-child {
          padding-left: 10px;
        }
      }
    }
  }
}
