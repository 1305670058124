#agent-discover {
  width: 100%;
  padding: 32px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .discover-search {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    .discover-left {
      width: 300px;
    }
    .discover-right {
      flex: 1;
      padding-left: 30px;
    }
  }

  .reco-page {
    text-align: center;
  }
  .sx-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    h4 {
      flex: 1;
    }
  }
  .header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      margin-left: 12px;
    }
  }
}
