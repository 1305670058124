
#onboard-company {
  .ant-select {
    width: 100%;
    margin-bottom: 24px;
    &.no-bottom {
      margin-bottom: 0;
    }
  }
  .ant-select-open .ant-select-selection__placeholder {
    display: none !important;
  }
}