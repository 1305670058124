@import 'variables';

#schedule-meeting {
  padding-bottom: 16px;
  margin-bottom: 24px;
  .btnx {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    &[disabled] {
      color: #4353ff;
      border: 1px solid #528aff;
      opacity: 1;
      background: #fafbfd;
    }
  }

  h5 {
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #707b90;
    margin-top: 8px;
  }
  border-bottom: 1px dotted #e7ecf4;
}
.schedule-calendar {
  margin-right: 12px;
}
