@import '_seriph/variables';

#agent-campaigns {
  width: 100%;
  padding: 32px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .seriph-table-wrapper {
    margin-top: 40px;
  }
  .thresh-box {
    display: inline-block;
    .sxd-title {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      text-align: left;
      margin: 0 0 2px 0;
    }
    .thresh-dur {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      color: #4d5b76;
      margin: 0;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .tbl-link {
    margin-bottom: 8px;
    .subtitle {
      color: #707b90;
      font-size: 12px;
      margin-left: 4px;
    }
    &.review .sxd-link {
      color: $review;
    }
  }
  .ctbl-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    .seriph-avatar {
      margin-right: 16px;
    }
    .ctbl-name {
      h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 1px;
        width: 240px;
        margin: 0 0 2px 0;
      }
      h5 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
        span {
          color: #707b90;
        }
        .left-type,
        a {
          font-size: 12px;
          line-height: 18px;
          color: #090e15;
        }
        a {
          font-weight: 500;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .sx-status {
        min-width: 10px;
        min-height: 10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 6px;
        position: relative;
        top: -1px;
      }
    }
  }
  .rep-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    .rep-avs {
      position: relative;
      padding-left: 10px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 2.4px solid #ffffff;
        background: #fff;
        margin-left: -10px;
      }
    }
    .oversized {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      margin-left: 8px;
    }
  }
  .no-reps {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-left: 8px;
  }
}
