@import '_seriph/variables';

#internal-meetings-table {
  margin-top: 48px;
  position: relative;
  > h3 {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 0 0 20px 0;
  }
  > a {
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-pagination, .page-info {
    display: none;
  }
  .meeting-lead {
    h3 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090E15;
      margin: 0 0 2px 0;
    }
    p {
      font-family: $font-major;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #707B90;
      margin: 0;
    }
  }

}