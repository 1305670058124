@import '_seriph/variables';

#wizard-setup {
  .seriph-selection > div {
    max-width: 50% !important;
  }
  .objective {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #e7ecf4;
    border-radius: 12px;
    padding: 24px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 16px;
    .obj-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e7ecf4;
      border-radius: 20px;
      height: 40px;
      width: 40px;
      min-width: 40px;
      min-height: 40px;
      margin-right: 20px;
      box-shadow: none;
      svg {
        color: #090e15;
        width: 16px;
        height: 16px;
      }
    }
    .obj-desc {
      h3 {
        font-family: $font-major;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
        margin: 0 0 2px 0;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
        margin: 0;
      }
    }
    &:hover {
      border-color: #090e15;
    }
    &.active {
      border-color: #090e15;
      .obj-icon {
        background: #090e15;
        box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
        svg {
          color: #fff;
        }
      }
    }
  }
  .is-locked .objective {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
