@import '_seriph/variables';

.seriph-alert {
  background: #e7ecf4;
  border-radius: 12px;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .alert-icon > svg {
    color: #577e4e;
    width: 20px;
    height: 20px;
    stroke-width: 2;
    display: flex;
  }
  .alert-line {
    border-radius: 32px;
    width: 4px;
    margin: 5px 0;
    align-self: stretch;
  }
  .alert-content {
    flex: 1;
    margin: 0 24px 0 16px;
    h5 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #4d5b76;
      margin: 0;
    }
    p {
      font-family: $font-major;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0;
    }
  }
  .alert-close {
    cursor: pointer;
    > svg {
      color: #090e15;
      width: 14px;
      height: 14px;
      stroke-width: 3;
    }
  }
  > div:first-child {
    margin-left: 0;
  }
}

/* HANDLE TYPES */
.seriph-alert.alert-success {
  background: #eef9ec;
  .alert-icon > svg {
    color: #577e4e;
  }
  .alert-content > h5 {
    color: #577e4e;
  }
  .alert-line {
    background: #577e4e;
  }
}
.seriph-alert.alert-warning {
  background: #fdfaef;
  .alert-icon > svg {
    color: #c8803e;
  }
  .alert-content > h5 {
    color: #c8803e;
  }
  .alert-line {
    background: #c8803e;
  }
}
.seriph-alert.alert-info {
  background: #e8e7fd;
  .alert-icon > svg {
    color: #3e3993;
  }
  .alert-content > h5 {
    color: #3e3993;
  }

  .alert-line {
    background: #3e3993;
  }
}
.seriph-alert.alert-negative, .seriph-alert.alert-danger {
  background: #fbece7;
  .alert-icon > svg {
    color: #b34043;
  }
  .alert-content > h5 {
    color: #b34043;
  }
  .alert-line {
    background: #b34043;
  }
}
