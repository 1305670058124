@import 'variables';
@import '_seriph/variables';

#frozen-agent {
  min-height: 100%;
  background: $neutral-100;
  width: 100%;
  .agent-body {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 30px 30px 30px;
    max-width: 1440px;  
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    background: $neutral-100;
    position: relative;
    padding-left: 70px;
    transition: padding-left 0.5s ease;
    .seriph-alert {
      margin: 32px 30px 24px 30px;
      a {
        color: #c8803e;
        text-decoration: underline;
      }
    }
  }
  #agent-settings {
    padding-top: 0;
  }
  #masq-user {
    width: 100% !important;
    left: 0 !important;
  }
}
