@import '_seriph/variables';

#onboard-type {
  width: 100%;
  .list-select {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      border: 1px solid #E7ECF4;
      border-radius: 8px;
      margin-bottom: 16px;
      width: 100%;
      cursor: pointer;
      user-select: none;
      &:hover {
        border: 1px solid #090E15;
      }
      &.selected {
        border: 1px solid #090E15;
      }
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: #000;
        color: #fff;
        margin-right: 24px;
      }
      > h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin: 0;
        flex: 1;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: #000;
        color: #fff;
        margin-left: 24px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}