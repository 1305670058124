@import '_seriph/variables';

.overview-company {
  .oc-info {
    .oc-types {
      font-family: $font-major;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      margin-bottom: 4px;
      b {
        color: #090e15;
        font-weight: 500;
      }
    }
    h3 {
      font-family: $font-major;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #090e15;
      margin: 0 0 16px 0;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: #4d5b76;
      margin: 0 0 32px 0;
    }
  }
  .oc-overview {
    margin-bottom: 32px;
  }
  .oc-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 32px;
    border-bottom: 1px solid #e7ecf4;
    .social {
      flex: 1;
      padding-left: 20px;
      a {
        margin-left: 12px;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        color: #090e15;
        &:hover {
          color: #707b90;
        }
      }
    }
    .posted {
      font-family: $font-major;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #707b90;
    }
  }
}
