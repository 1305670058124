@import '_seriph/variables';

#company-campaigns {
  width: 100%;
  padding: 32px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .seriph-table-wrapper {
    margin-top: 40px;
  }
  .thresh-box {
    display: inline-block;
    .sxd-title {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      text-align: left;
      margin: 0 0 2px 0;
    }
    .thresh-dur {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      color: #4d5b76;
      margin: 0;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .tbl-link {
    margin-bottom: 8px;
    .subtitle {
      color: #707b90;
      font-size: 12px;
      margin-left: 4px;
    }
    &.review .sxd-link {
      color: $review;
    }
  }
  .ctbl-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    .sxd-avatar {
      margin-right: 16px;
    }
    .ctbl-name {
      h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 1px;
        width: 240px;
        margin: 0 0 2px 0;
      }
      h5 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
        span {
          color: #4d5b76;
        }
        .left-type {
          font-size: 12px;
          line-height: 18px;
          color: #090e15;
          font-weight: 500;
        }
      }
      .sx-status {
        display: none;
        min-width: 10px;
        min-height: 10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 6px;
        position: relative;
        top: -1px;
      }
    }
  }
  .rep-list {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    .rep-avs {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      padding-left: 10px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 2.4px solid #ffffff;
        background: #fff;
        margin-left: -10px;
      }
    }
    .oversized {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      margin-left: 8px;
    }
  }
  .no-reps {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-left: 8px;
  }
}

.progress-tt {
  .pt-title {
    font-family: $font-major;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px dotted #757f77;
    margin-bottom: 4px;
    &.top {
      margin-top: 12px;
    }
  }
  > div {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  > div > span {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: transparent !important;
    display: inline-block;
    margin-right: 4px;
    border: 4px solid $in-progress;
    position: relative;
    top: 1px;
    &.in-progress,
    &.progress {
      border-color: $in-progress;
    }
    &.generated {
      border-color: $generated;
    }
    &.qualified {
      border-color: $qualified;
    }
    &.completed,
    &.complete {
      border-color: $completed;
    }
    &.review {
      border-color: $review;
    }
    &.not-interested {
      border-color: $not-interested;
    }
    &.disputed {
      border-color: $disputed;
    }
    &.unassigned {
      border-color: $unassigned;
    }
    &.email {
      border-color: $email;
    }
    &.call {
      border-color: $call;
    }
    &.linkedin {
      border-color: $linkedin;
    }
  }
}
