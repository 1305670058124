.google-places {
  position: relative;
  margin-top: 22px;
  input {
    font-size: 14px;
    line-height: 14px;
    color: #000;
  }
  .places-container {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none;
    font-variant: initial;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1050;
    left: 0;
    top: 43px;
    position: absolute;
    outline: none;
    font-size: 14px;
    max-width: 500px;
    width: 100%;
    .gp-item {
      position: relative;
      display: block;
      padding: 5px 12px;
      line-height: 22px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.65);
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: background 0.3s ease;
      background: none;
      &.gp-item--active {
        background: #e6f7ff;
      }
    }
  }
}
