#sellx-footer {
  text-align: center;
  width: 100%;
  padding: 0 30px;
  .footer-contain {
    border-top: 1px solid #e6e9ef;
    max-width: 1000px;
    margin: 0 auto;
    padding: 24px;
    img {
      width: 50px;
      height: 50px;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #bbc3d1;
      margin: 0;
      padding: 0;
    }
    .footer-links {
      padding: 32px 0 24px 0;
      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #0b1e41;
        margin: 0 20px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
