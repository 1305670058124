.sellx-card {
  border: 1px solid #e6e9ef;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(235, 237, 240, 0.2);
  margin-bottom: 30px;
  position: relative;
  h2 {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 20px;
  }
  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 12px;
  }
  .editable,
  .addable {
    position: absolute;
    display: none;
    top: 20px;
    right: 20px;
    color: #3277f1;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #1257d1;
    }
  }
  .editable.on,
  .addable.on {
    display: block !important;
  }
  &:hover {
    .editable,
    .addable {
      display: block;
    }
  }
  .editing {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #999;
    font-size: 12px;
    font-style: italic;
  }
}
