@import '_seriph/variables';

.seriph-inputnumber {
  .input-box {
    position: relative;
    > .ant-input-number {
      height: 48px;
      line-height: 48px;
      outline: 0;
      box-shadow: none;
      border-radius: 10px;
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      overflow: hidden;
      width: 100%;
      &::placeholder {
        color: #4d5b76;
      }
      &:hover {
        border: 1px solid #c4cbd9;
      }
      &.ant-input-number-focused {
        border: 1px solid #c4cbd9;
        background: #fff;
      }
      &[disabled] {
        border: 1px solid #f5f7fb;
        background: #f5f7fb;
        color: #adb5c5;
        &::placeholder {
          color: #adb5c5;
        }
      }
      input {
        padding: 0 16px;
        height: 48px;
        line-height: 48px;
      }
    }
    .input-icon-left {
      position: absolute;
      left: 16px;
      top: 17px;
      svg {
        width: 16px;
        height: 16px;
        stroke-width: 1.75;
      }
    }
    .input-icon-right {
      position: absolute;
      right: 16px;
      top: 17px;
      svg {
        width: 16px;
        height: 16px;
        stroke-width: 1.75;
      }
    }
  }

  &.seriph-success {
    .input-box > input {
      background: #ebfff2;
      border: 1px solid #98f6b8;
    }
  }

  &.seriph-error {
    .input-box > input {
      background: #ffe9e9;
      border: 1px solid #f8aaaa;
    }
  }

  &.input-large {
    .input-box > input {
      padding: 0 16px;
      height: 56px;
      line-height: 56px;
      border-radius: 12px;
      font-size: 16px;
      line-height: 24px;
    }
    .input-icon-left {
      top: 20px;
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 1.75;
      }
    }
    .input-icon-right {
      top: 20px;
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 1.75;
      }
    }
  }

  &.input-left {
    .input-box > input {
      padding-left: 42px;
    }
  }

  &.input-right {
    .input-box > input {
      padding-right: 42px;
    }
  }
}
