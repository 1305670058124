.seq-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-right: 8px;
  }
  .sequence-actions {
    margin-left: 16px;
  }
}
