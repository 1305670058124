@import 'variables';

.ant-notification {
  .ant-notification-notice {
    background: #090e15;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    padding: 16px 24px !important;
  }
  .ant-notification-notice-message {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 4px;
  }
  .ant-notification-notice-description {
    font-size: 12px;
    line-height: 18px;
    color: #c4cbd9;
    margin: 0;
  }
  .ant-notification-notice-close {
    top: 12px;
    right: 12px;
    color: #fff;
  }
  svg.fa-info-circle {
    color: #1fd6ff;
    margin-right: 8px;
    font-size: 16px;
  }
}
