.sxd-avatar {
  position: relative;
  display: inline-block;

  img.av-pic {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    box-shadow: 1px 1px 3px rgba(22, 45, 60, 0.2);
    background: #fff;
  }
  img.av-rep {
    width: 32px;
    height: 32px;
    position: absolute;
    right: -6px;
    bottom: -6px;
  }
  img.av-comp {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: 3px solid #ffffff;
    background: #fff;
    position: absolute;
    right: -6px;
    bottom: -6px;
  }

  span.character {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    line-height: 64px;
    text-align: center;
    background: #242d3e;
    font-weight: 600;
    font-size: 28px;
    color: #ffffff;
  }

  &.size-giant {
    img.av-pic {
      width: 112px;
      height: 112px;
      border-radius: 56px;
    }
    img.av-rep {
      width: 64px;
      height: 64px;
      right: -12px;
      bottom: -12px;
    }
    img.av-comp {
      width: 64px;
      height: 64px;
      right: -12px;
      bottom: -12px;
      border: 4px solid #ffffff;
      border-radius: 32px;
    }
    span.character {
      width: 112px;
      height: 112px;
      line-height: 112px;
      border-radius: 56px;
      font-size: 48px;
    }
  }

  &.size-large {
    img.av-pic {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
    img.av-rep {
      width: 42px;
      height: 42px;
      right: -8px;
      bottom: -8px;
    }
    img.av-comp {
      width: 42px;
      height: 42px;
      right: -8px;
      bottom: -8px;
      border: 3px solid #ffffff;
      border-radius: 21px;
    }
    span.character {
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 40px;
      font-size: 36px;
    }
  }

  &.size-small {
    img.av-pic {
      width: 48px;
      height: 48px;
      border-radius: 24px;
    }
    img.av-rep {
      width: 26px;
      height: 26px;
      right: -6px;
      bottom: -6px;
    }
    img.av-comp {
      width: 26px;
      height: 26px;
      right: -6px;
      bottom: -6px;
      border: 2px solid #ffffff;
      border-radius: 13px;
    }
    span.character {
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 24px;
      font-size: 20px;
    }
  }

  &.size-tiny {
    img.av-pic {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    img.av-rep {
      width: 22px;
      height: 22px;
      left: 22px;
      top: 22px;
      right: auto !important;
      bottom: auto !important;
    }
    img.av-comp {
      width: 22px;
      height: 22px;
      right: -4px;
      bottom: -4px;
      border: 2px solid #ffffff;
      border-radius: 11px;
    }
    span.character {
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      font-size: 16px;
    }
  }

  &.size-mini {
    img.av-pic {
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }
    img.av-rep {
      width: 18px;
      height: 18px;
      right: -2px;
      bottom: -2px;
    }
    img.av-comp {
      width: 18px;
      height: 18px;
      right: -2px;
      bottom: -2px;
      border: 1px solid #ffffff;
      border-radius: 9px;
    }
    span.character {
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      font-size: 14px;
    }
  }

  &.size-micro {
    img.av-pic {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }
}
