.file-box {
  width: 33.33333%;
  display: inline-block;
  padding: 0 10px;
  .file {
    padding: 24px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e6e9ef;
    border-radius: 10px;
    margin-bottom: 24px;
    width: 100%;
    > svg {
      font-size: 40px;
    }
    .fa-file {
      color: #2c3e50;
    }
    .fa-file-pdf {
      color: #ff3768;
    }
    .fa-file-word {
      color: #3498db;
    }
    .fa-file-excel {
      color: #27ae60;
    }
    .fa-file-image {
      color: #9b59b6;
    }
    .fa-file-audio {
      color: #ffc900;
    }
    .fa-file-video {
      color: #e74c3c;
    }
    .info {
      flex: 1;
      padding: 0 15px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      h5 {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > div {
        color: #4d5b76;
        font-size: 12px;
        line-height: 14px;
      }
    }
    .ant-dropdown-trigger {
      cursor: pointer;
      svg {
        font-size: 18px;
        color: #203254;
        &:hover {
          color: #001234;
        }
      }
    }
    .remove {
      color: #ff4d4f;
      cursor: pointer;
      font-size: 18px;
      &:hover {
        color: #df2d2f;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .file-wrapper {
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .file-wrapper {
    width: 100%;
  }
}
