@import 'variables';

.csv-mapping-modal {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }
  .csv-content {
    .mapping-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      h4 {
        flex: 1;
        margin: 0;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #242d3e;
        font-family: $font-tt;
        padding-left: 32px;
      }
    }
    .mapping-actions {
      padding: 32px;
      text-align: right;
      border-top: 1px solid #e7ecf4;
      button {
        margin-left: 16px;
      }
    }
  }
  .close-icon,
  .back-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    &:hover {
      background: #e5e7eb;
    }
  }
}
