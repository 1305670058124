
#onboard-personal {
  width: 100% !important;
  .ant-select {
    width: 100%;
    margin-bottom: 24px;
    &.no-bottom {
      margin-bottom: 0;
    }
  }
}