.seriph-avatar {
  position: relative;
  display: inline-block;
  line-height: normal !important;
  img.av-pic {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    box-shadow: none;
    background: #fff;
    filter: drop-shadow(0px 1px 2px rgba(40, 43, 48, 0.04))
      drop-shadow(0px 1px 16px rgba(40, 43, 48, 0.04));
  }
  img.av-rep {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 35px;
    left: 35px;
  }
  img.av-comp {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: 3px solid #ffffff;
    background: #fff;
    position: absolute;
    top: 35px;
    left: 35px;
  }

  .av-campaign {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: 3px solid #090e15;
    background: #090e15;
    position: absolute;
    top: 35px;
    left: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #fff;
      width: 18px;
      height: 18px;
      stroke-width: 1.5;
    }
  }

  span.character {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    line-height: 64px;
    text-align: center;
    background: #242d3e;
    font-weight: 600;
    font-size: 28px;
    color: #ffffff;
  }

  &.size-giant {
    img.av-pic {
      width: 112px;
      height: 112px;
      border-radius: 56px;
    }
    img.av-rep {
      width: 64px;
      height: 64px;
      top: 64px;
      left: 64px;
    }
    img.av-comp {
      width: 64px;
      height: 64px;
      right: -12px;
      bottom: -12px;
      border: 4px solid #ffffff;
      border-radius: 32px;
    }
    span.character {
      width: 112px;
      height: 112px;
      line-height: 112px;
      border-radius: 56px;
      font-size: 48px;
    }
  }

  &.size-large {
    img.av-pic {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
    img.av-rep {
      width: 42px;
      height: 42px;
      right: -8px;
      bottom: -8px;
      left: auto;
      top: auto;
    }
    img.av-comp {
      width: 42px;
      height: 42px;
      right: -8px;
      bottom: -8px;
      border: 3px solid #ffffff;
      border-radius: 21px;
    }
    span.character {
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 40px;
      font-size: 36px;
    }
  }

  &.size-small {
    img.av-pic {
      width: 48px;
      height: 48px;
      border-radius: 24px;
    }
    img.av-rep {
      width: 28px;
      height: 28px;
      top: 26px;
      left: 26px;
    }
    img.av-comp {
      width: 26px;
      height: 26px;
      top: 26px;
      left: 26px;
      border: 2px solid #ffffff;
      border-radius: 13px;
    }
    span.character {
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 24px;
      font-size: 20px;
    }
  }

  &.size-tiny {
    img.av-pic {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    img.av-rep {
      width: 22px;
      height: 22px;
      left: 22px;
      top: 22px;
      right: auto !important;
      bottom: auto !important;
    }
    img.av-comp {
      width: 22px;
      height: 22px;
      right: -4px;
      bottom: -4px;
      border: 2px solid #ffffff;
      border-radius: 11px;
    }
    .av-campaign {
      width: 22px;
      height: 22px;
      border-radius: 11px;
      border: 2px solid #090e15;
      top: 22px;
      left: 24px;
      svg {
        color: #fff;
        width: 14px;
        height: 14px;
        stroke-width: 1.5;
      }
    }
    span.character {
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      font-size: 16px;
    }
  }

  &.size-mini {
    img.av-pic {
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }
    img.av-rep {
      width: 18px;
      height: 18px;
      top: 18px;
      left: 18px;
    }
    img.av-comp {
      width: 18px;
      height: 18px;
      right: -2px;
      bottom: -2px;
      border: 1px solid #ffffff;
      border-radius: 9px;
    }
    span.character {
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      font-size: 14px;
    }
  }

  &.size-micro {
    img.av-pic {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }

  &.size-icon {
    img.av-pic {
      width: 18px;
      height: 18px;
      border-radius: 9px;
    }
  }
}
