@import '_seriph/variables';

#internal-home-highlights {
  flex: 0 0 424px;
  min-height: 100%;
  height: 100%;
  background: transparent;
  padding-top: 40px;
  > h3 {
    font-family: $font-major;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    padding: 0 12px;
    margin: 0;
    margin-bottom: 16px;
  }
  > h4 {
    font-family: $font-major;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    padding: 0 12px;
    margin: 32px 0 0 0;
  }
  .no-high .ant-skeleton {
    padding: 0 16px;
    .ant-skeleton-avatar, .ant-skeleton-title, .ant-skeleton-paragraph > li {
      background: #E7ECF4;
    }
    .ant-skeleton-title, .ant-skeleton-paragraph > li {
      width: 100% !important;
      border-radius: 16px;
    }
    .ant-skeleton-paragraph {
      margin-top: 16px;
    }
    .ant-skeleton-title {
      margin-top: 8px;
    }
  }
  #agent-balance {
    padding: 0 12px 32px 12px;
    border-bottom: 1px solid #e7ecf4;
    margin-bottom: 24px;
    .stipend-table {
      margin-top: 24px;
    }
    .others {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;
      > div {
        padding: 16px 24px;
        &:first-child {
          margin-right: 6px;
        }
        &:last-child {
          margin-left: 6px;
        }
      }
    }
    .descriptor {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #4d5b76;
    }
    .sxd-link {
      margin-top: 16px;
    }
  }
  > .activity-sub {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #4d5b76;
    margin: 0;
    padding: 0 12px;
  }
  .all-highlights {
    padding: 0 0 16px 0;
  }
  .divider {
    display: block;
    content: '';
    clear: both;
    height: 1px;
    width: 100%;
    background: #e7ecf4;
    margin-bottom: 32px;
  }
  .highlight {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    cursor: pointer;
    > svg {
      color: #707b90;
    }
    &:hover {
      background: #fff;
      > svg {
        color: #4353ff;
      }
    }
    .info {
      flex: 1;
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        margin: 0;
      }
    }
    .highlight-icon {
      width: 32px;
      height: 32px;
      background: #000;
      border-radius: 50%;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        stroke-width: 1.5px;
        height: 14px;
        width: 14px;
        color: #090e15;
      }
    }
    &.none {
      margin: 8px 12px;
      padding: 16px;
      cursor: default;
      align-items: flex-start;
      background: #fff;
      box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
      margin-bottom: 16px;
      h5 {
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
      }
      p {
        margin-top: 2px;
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
      }
      .highlight-icon {
        width: 32px;
        height: 32px;
        background: #000;
        border-radius: 50%;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          stroke-width: 1.5px;
          height: 14px;
          width: 14px;
          color: #fff;
          fill: #fff;
        }
      }
    }
  }
  .live-activity {
    padding: 0 12px 32px 12px;
    margin-top: 24px;
    > .status-activity:nth-child(odd) {
      margin-right: 8px;
    }
    .status-activity {
      display: inline-block;
      width: calc(50% - 4px);
      padding: 24px 32px 24px 36px;
      background: #ffffff;
      border-radius: 12px;
      margin-bottom: 8px;
      position: relative;
      height: 100px;
      max-height: 100px;
      overflow: hidden;
      &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        box-shadow: none;
      }
      &:hover {
        cursor: pointer;
        background: #edeff3;
      }
      &.analytics {
        width: 100%;
        text-align: center;
        background: #e7ecf4;
        font-family: $font-major;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        height: auto;
        color: #090e15;
        svg {
          margin-left: 8px;
        }
        &:hover {
          background: #d7dde9;
        }
      }
      .bar {
        display: block;
        position: absolute;
        top: 25px;
        left: 24px;
        width: 4px;
        height: 50px;
        left: 20px;
        bottom: 25px;
        background: #f0f5f7;
        border-radius: 100px;
        &.in-progress {
          background: $in-progress;
        }
        &.review {
          background: $review;
        }
        &.disputed {
          background: $disputed;
        }
        &.completed {
          background: $completed;
        }
        &.generated {
          background: $generated;
        }
        &.qualified {
          background: $qualified;
        }
        &.not-interested {
          background: $not-interested;
        }
        &.meetings {
          background: $meetings;
        }
      }
      .status-type {
        > h1 {
          font-family: $font-major;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          margin: 0;
          color: #090e15;
        }
        > div {
          font-family: $font-major;
          font-size: 14px;
          line-height: 21px;
          color: #4d5b76;
          margin: 0;
        }
      }
    }
  }
}
