@import '_seriph/variables';

#lead-information {
  padding: 32px 0;
  h3 {
    margin-bottom: 24px;
  }
  .seriph-select .ant-select {
    width: 100%;
  }
  .inputs {
    padding: 24px 32px 16px 32px;
    margin-bottom: 24px;
    .info-note {
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      color: #707b90;
      margin-left: 4px;
    }
    &.hidden {
      display: none !important;
    }
    > .field {
      margin-bottom: 24px;
      width: calc(50% - 12px);
      display: inline-block;
      vertical-align: top;
      position: relative;
      &:nth-child(even) {
        margin-right: 12px;
      }
      &:nth-child(odd) {
        margin-left: 12px;
      }
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #0b1e41;
        margin-bottom: 4px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > svg {
        position: absolute;
        right: 16px;
        bottom: 16px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.1);
      }
      > a {
        position: absolute;
        right: 16px;
        bottom: 14px;
        height: 20px;
        overflow: hidden;
        > svg {
          font-size: 16px;
          color: #4353ff;
        }
      }
      > img {
        position: absolute;
        right: 16px;
        bottom: 17px;
        cursor: pointer;
      }
      input.is-link {
        padding-right: 40px;
      }
    }
    > .field-select {
      > img {
        position: absolute;
        right: 32px;
        bottom: 16px;
        cursor: pointer;
      }
      > svg {
        position: absolute;
        right: 32px;
        bottom: 16px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
  }
  > div:last-child {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  #lead-information .inputs > .field {
    display: block;
    width: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

// @media only screen and (min-width: 600px) {
//   #lead-information .inputs > .field {

//   }
// }
