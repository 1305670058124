@import '_seriph/variables';

.onboard-company-modal {
  .ant-modal-body {
    padding-top: 16px !important;
  }
  .new-header {
    text-align: center;
    font-weight: 500;
    .subheader {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #707b90;
    }
  }
  .sequence-form {
    padding: 32px 0;
    > div {
      margin-bottom: 16px;
    }
  }
  .step-desc {
    padding: 12px 0;
    button {
      margin-top: 12px;
    }
    > div > button:first-child {
      margin-right: 12px;
    }
  }
  .seriph-input {
    margin-top: 12px;
    margin-bottom: 4px;
  }
  .seriph-tag {
    margin-top: 12px;
  }
  .left {
    float: left;
  }
}
