@import '_seriph/variables';

.seriph-steps {
  .ant-steps-item-title {
    font-family: $font-major;
    font-size: 14px !important;
    color: #090e15 !important;
    font-weight: 500 !important;
  }
  .ant-steps-item-wait .ant-steps-item-title {
    opacity: 0.5 !important;
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background: #090e15;
      border-color: #090e15;
      > .ant-steps-icon {
        color: #fff;
      }
    }
    > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: #090e15;
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background: #090e15;
      border-color: #090e15;
      > .ant-steps-icon {
        color: #fff;
      }
    }
  }
}
