@import 'variables';

.sxd-alert {
  display: block;
  background: #e7ecf4;
  border-radius: 12px;
  text-align: center;
  width: 100%;
  padding: 32px;
  position: relative;
  overflow: hidden;
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #250d93;
    margin: 0 auto 8px auto;
    max-width: 66%;
    svg {
      font-size: 16px;
      margin-right: 16px;
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #090e15;
    margin: 0 auto;
    max-width: 66%;
  }
  .infobg {
    color: rgba(215, 221, 233, 0.6);
    font-size: 165px;
    position: absolute;
    left: -40px;
    bottom: -40px;
  }
}
