@import 'variables';

.confirm-csv {
  flex-direction: row;
  padding: 0px 8px;
  .csv-bubble {
    border: 1px solid #e7ecf4;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 16px 0px;
    margin: 16px 0px;
    > div {
      padding: 15px 0px;
    }
    .csv-icon {
      background: #e7ecf4;
      border-radius: 16px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .csv-description {
      width: 75%;
      padding: 0px 0px 0px 16px;
    }
    .csv-delete {
      border-radius: 16px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #e7ecf4;
      }
    }
    .filename {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
    .description {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
.file-upload {
  .back-icon {
    width: 32px;
    height: 32px;
    left: 0px;
    top: 6px;
    text-align: center;

    /* Neutral / 100 */

    background: #f5f7fb;
    border-radius: 100px;
    cursor: pointer;
    svg {
      margin: 0 auto;
    }
  }
  .back-icon,
  .ant-modal-close-x svg {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Neutral / 900 */

    color: #242d3e;
  }
  .ant-upload-drag-icon {
    width: 52px;
    height: 52px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: blue;
    svg {
      width: 30px;
      height: 24px;
    }
  }
  .ant-upload-text {
    font-family: $font-tt;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-upload-desc {
    font-family: $font-stack;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    a {
      color: #4353ff;
    }
  }
  > p {
    font-family: $font-stack;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #707b90;
  }
  .ant-upload {
    margin: 16px 0px;
  }
  .sxd-guide {
    margin: 16px 0px;
  }
  .full-message {
    b {
      font-weight: 700;
      font-family: $font-stack;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }
}
.confirm-csv {
  display: flex;
  flex-direction: column;
  // padding: 48px 16px;
  .sxd-guide {
    margin: 16px 0px;
  }
  .header-loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 0px 16px 0px;
  }
  .header-description {
    text-align: center;
    padding: 0px 0px 16px 0px;
  }
  .validation-list {
    width: 100%;
    .validation-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      box-shadow: inset 0px -1px 0px #e7ecf4;
      padding: 15px 16px;
      // border-radius: 8px;
      &.warning {
        background: #fff9f0;
        box-shadow: none;
        .validation-status {
          color: #242d3e;
        }
      }
      .loader-bubble {
        margin: 0px 5% 0px 0px;
        background: #e7ecf4;
        width: 20px;
        height: 20px;
        border-radius: 53px;
        font-size: 12px;
        &.warning {
          background: #ffd53e;
        }
        &.success {
          background: #4353ff;

          color: #fff;
        }
        .sk-chase-dot:before {
          background: #707b90;
        }
        .sk-chase {
          height: 50%;
          width: 50%;
        }
      }
      .validation-type {
        width: 65%;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #242d3e;
      }
      .validation-status {
        width: 30%;
        color: #707b90;
      }
      &:last-child {
        box-shadow: none;
      }
    }
  }
  .header-bar {
    align-items: center;
    justify-content: center;
    .loader-bubble {
      background: #ffffff;
      font-size: 22px;
      color: #4353ff;
    }
    .sk-chase-dot:before {
      background-color: #4353ff;
    }
  }

  .loader-bubble {
    width: 56px;
    height: 56px;
    box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .sk-chase {
    width: 24px;
    height: 24px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }

  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both;
  }

  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
  }

  .sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
  }
  .sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
  }
  .sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  .sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
  }
  .sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
  }
  .sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
  }
  .sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
  }
  .sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
  }
  .sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
  }
  .sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
  }
  .sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
  }
  .sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
  }

  @keyframes sk-chase {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot {
    80%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%,
    0% {
      transform: scale(1);
    }
  }
}
