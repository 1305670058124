.sxd-reputation {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #929aaa;
  .earner {
    color: #ceae00;
  }
  .big-earner {
    color: #25c20c;
  }
  .super-earner {
    color: #00b9a6;
  }
  .ultra-earner {
    color: #00a9c0;
  }
  .extreme-earner {
    color: #4353ff;
  }
}
