.apply-note {
  .note-info {
    text-align: center;
    > h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #090e15;
      text-align: center;
      margin: 12px 0 4px 0;
    }
    > p {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #4d5b76;
      margin: 0 0 32px 0;
    }
    .sxd-avatar {
      margin: 20px 0;
    }
  }
}
