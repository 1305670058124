@import 'variables';

#dialer-script {
  .dialer-script {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #090E15;
    .script-tag {
      background: #E7ECF4;
      padding: 2px 4px;
      border-radius: 4px;
    }
  }

}
