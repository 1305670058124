@import '_seriph/variables';

#company-profile {
  width: 100%;
  padding: 32px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .data-tweet {
    > h1 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #090e15;
      margin: 0;
    }
    padding: 32px;
  }
  .profile-menu {
    padding: 16px 32px;
  }
  .company-notify {
    text-align: center;
    margin-bottom: 24px;
    > .bell {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      width: 40px;
      height: 40px;
      background: #e8e7fd;
      border-radius: 20px;
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: auto;
      svg {
        color: #3e3993;
      }
    }
    > .seriph-header {
      margin-bottom: 24px;
    }
  }
  .company-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    .company-cards {
      width: 300px;
      min-width: 300px;
      margin-left: 24px;
    }
    .company-data {
      padding: 0;
      .data-nav {
        padding: 24px 32px;
        > .nav {
          display: inline-block;
          padding: 10px 24px;
          background: #f5f7fb;
          border-radius: 32px;
          margin-right: 16px;
          font-family: $font-major;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          cursor: pointer;
          &:hover {
            transition: background 0.3s ease, color 0.3s ease;
          }
          &.active,
          &:hover {
            background: #090e15;
            color: #fff;
          }
        }
      }
      .data-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 32px;
        .av-comp {
          background: transparent;
          border-color: transparent;
          right: -12px;
          bottom: -12px;
        }
        .data-text {
          flex: 1;
          margin: 0 24px;
          img {
            width: 32px;
            height: 32px;
            margin-left: 8px;
          }
          p {
            font-family: $font-major;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #4d5b76;
            margin: 4px 0 0 0;
          }
        }
        .data-team {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #f5f7fb;
          border-radius: 64px;
          padding: 8px 8px 8px 16px;
          .team-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 8px;
            .team-avs {
              position: relative;
              padding-left: 10px;
              img {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                border: 2.4px solid #ffffff;
                background: #fff;
                margin-left: -10px;
              }
            }
            .oversized {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #000000;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .sx-header-actions button {
    margin-left: 12px;
  }
}
