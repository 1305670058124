@import '_seriph/variables';
.seriph-radio-btn-list {
  width: 100%;
  > button {
    margin: 8px 0;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &.btn-selected {
      background: #090e15;
      color: #fdfaef;
    }
  }

  .label-cont {
    display: flex;
    font-family: $font-major;
    font-size: 14px;
    line-height: 21px;
    .label-main {
      font-weight: 500;
      margin-right: 3px;
    }
    .label-helper {
      font-weight: 400;
      font-style: italic;
    }
  }
  &.dark {
    background: #090e15;
    > button {
      background: #090e15;
      color: #fdfaef;
      border: 1px solid #2a2928;
      &.btn-selected {
        border: 1px solid #fdfaef;
      }
    }
    .label-cont {
      color: #fdfaef;
    }
  }
}
