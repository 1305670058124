@import 'variables';

#dial-training {
  min-width: 284px;
  min-height: 0;
  .html-instructions {
    color: #090e15 !important;
    p,
    span,
    div,
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #090e15 !important;
    }
  }
}
