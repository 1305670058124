@import '_seriph/variables';

.add-agent-back-modal {
  .ia-agent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    .ia-agent-pics {
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      .left-right-arrow {
        margin: 0px 14px;
      }
      .campaign-icon {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #d2d0fb;
        color: #090e15;
        svg {
          stroke-width: 1.1;
          height: 40px;
          width: 40px;
        }
      }
    }
    .ia-details {
      flex: 1;
      h3 {
        font-family: $font-major;
        font-weight: 500;
        color: #090e15;
        margin-top: 12px;
        font-size: 20px;
        line-height: 30px;
      }
      p {
        font-family: $font-major;
        color: #090e15;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
      }
      .sellx-tag {
        font-size: 12px !important;
      }
    }
  }

  .ia-main {
    .ia-main-header {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      font-family: $font-major;
      font-weight: 500;
      line-height: 21px;
      color: #090e15;
      font-size: 14px;
      padding-bottom: 8px;
    }
    .ia-main-table {
      height: 225px;
      overflow: scroll;
      margin-top: 5px;
      .ia-row {
        display: flex;
        height: 75px;
        align-items: center;
        border-radius: 8px;
        padding: 16px 12px;
        cursor: pointer;
        &.disabled {
          opacity: 0.5 !important;
          cursor: not-allowed;
        }
        &.selected {
          border: 1px solid #090e15;
        }

        border: 1px solid white;
        &:hover {
          background: #f5f7fb;
          border: 1px solid #d7dde9;
        }
        .ia-row-left {
          flex: 0 0 30px;
          margin-right: 10px;
        }
        .ia-row-right {
          h4 {
            color: #090e15;
            font-family: $font-major;
            font-size: 14px;
            font-weight: 500;
            margin: 5px 0;
          }
          p {
            color: #090e15;
            font-family: $font-major;
            font-size: 12px;
            margin: 5px 0;
          }
          span {
            font-weight: 300;
          }
        }
      }
    }
  }
  .ia-entry {
    font-family: $font-major;
    font-weight: 500;
    line-height: 21px;
    color: #090e15;
    font-size: 14px;
    span {
      font-weight: 400;
      color: #637084;
    }
  }
  .ia-actions {
    margin-top: 30px;
    text-align: right;
    .ant-btn {
      margin-left: 16px;
    }
    .btn-primary {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      > div {
        margin-left: 8px;
      }
    }
  }
}
