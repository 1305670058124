.seriph-header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    flex: 1;
  }
  .header-actions {
    > button {
      margin-left: 12px;
    }
  }
}
