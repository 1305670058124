@import '_seriph/variables';

#onboard-verify {
  .seriph-header {
    width: 100%;
    text-align: left;
    margin-bottom: 12px;
  }
  > p {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #686764;
    margin: 0 0 40px 0;
    b {
      color: #000;
      font-weight: 500;

    }
  }
  > .notice {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #090E15;
    margin: 0 0 0 0;
    text-align: left;
    width: 100%;
    b {
      color: #000;
      font-weight: 500;
    }
    button {
      margin-top: 16px;
    }
  }
}
