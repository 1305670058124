#company-settings-payments {
  position: relative;
  width: 100%;
  padding: 0;
  .history {
    width: 100%;
    th {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      color: #0b1e41;
    }
    td {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      color: #000000;
      border-top: 1px solid #e6e9ef;
      b {
        font-weight: 500;
      }
      .disputed {
        color: red;
        font-weight: 500;
      }
      .money {
        color: green;
        font-weight: 500;
      }
      .cost {
        font-weight: 500;
      }
    }
    th,
    td {
      padding: 16px 8px;
    }
    .payment > td:last-child,
    .charge > th:last-child {
      text-align: right;
    }
  }
  .receipt {
    cursor: pointer;
    color: #000000 !important;
    padding: 2px;
  }
  .payment-actions {
    text-align: center;
    margin-top: 32px;
    button {
      margin: 0 8px;
      width: 60px;
    }
  }
}
