@import '_seriph/variables';

.seriph-accordion.ant-collapse {
  border: 0;
  background: none;
  > .ant-collapse-item {
    border: 0;
    .ant-collapse-header {
      background: #fff;
      border-radius: 12px;
      position: relative;
      padding: 16px 48px 16px 16px;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      i {
        position: absolute;
        right: 16px;
        left: auto;
        svg {
          color: #090e15;
          transform: rotate(90deg) !important;
        }
      }
      .panel-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        > svg {
          width: 16px;
          height: 16px;
          stroke-width: 2;
          margin-right: 12px;
          position: relative;
          top: -1px;
        }
        > span.panel-name {
          position: relative;
          top: 1px;
        }
        .amount {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #e7ecf4;
          border-radius: 100px;
          padding: 2px 6px 0 6px;
          font-family: $font-major;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          color: #090e15;
          margin-left: 12px;
        }
      }
      &:hover {
        background: #f5f7fb;
      }
    }

    &.ant-collapse-item-active > .ant-collapse-header {
      background: #f5f7fb;
      .anticon svg {
        transform: rotate(-90deg) !important;
      }
    }

    .ant-collapse-content {
      border: 0;
      background: none !important;
      p {
        margin: 0;
      }
      .ant-checkbox-wrapper {
        background: none !important;
      }
    }

    &.panel-menu .ant-collapse-content-box {
      position: relative;
      padding: 8px 8px 8px 24px;
      &:after {
        position: absolute;
        display: block;
        content: '';
        clear: both;
        height: calc(100% - 16px);
        left: 16px;
        top: 8px;
        width: 2px;
        background: #e7ecf4;
      }
    }

    &:last-child > .ant-collapse-header {
      border-radius: 12px !important;
    }
  }
  .ant-collapse-item.ant-collapse-item-disabled {
    opacity: 0.4 !important;
    .ant-collapse-content {
      display: none !important;
    }
    .ant-collapse-header i svg {
      -webkit-transform: rotate(90deg) !important;
      transform: rotate(90deg) !important;
    }
    .ant-collapse-header {
      background: transparent !important;
    }
  }
}
