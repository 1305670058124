@import '_seriph/variables';

#call-settings {
  display: flex;
  flex-direction: column;
  background: #f5f7fb;
  flex: 1;
  padding: 24px;
  height: calc(100% - 100px);
  overflow-y: auto;
  .menu-head {
    margin-bottom: 8px;
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #090e15;
  }
  .menu-title {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #090e15;
    margin-bottom: 16px;
  }
  .menu-label {
    margin-bottom: 4px;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #090e15;
  }
  .menu-sep {
    width: 100%;
    height: 1px;
    background: #e7ecf4;
    margin: 8px 0 20px 0;
    content: '';
  }
  .menu-top {
    padding: 0 0 8px 0;
    .menu-head {
      padding: 0 16px;
    }
    .ant-menu {
      padding: 0 24px;
    }
  }
  .menu-bottom {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding: 8px 24px 24px 24px;
  }
  .menu-settings {
    flex: 1;
    padding: 24px;
  }
  .menu-selecting {
    margin-bottom: 16px;
    .ant-select-selection--multiple .ant-select-selection__choice__content {
      text-transform: capitalize;
    }
    .ant-select-selection--multiple .ant-select-selection__clear {
      top: 20px;
    }
    .ant-select {
      width: 100%;
    }
  }
  .seriph-alert {
    margin-bottom: 24px;
    .alert-icon {
      display: none;
    }
    .alert-content {
      margin-left: 0;
    }
  }
  .menu-numbers {
    border-top: 1px solid #e7ecf4;
    padding-top: 24px;
    margin-top: 20px;
    .test-buttons {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: -6px;
      margin-right: -6px;
      button {
        flex: 1;
        margin: 0 6px;
      }
    }
  }

}