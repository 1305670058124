@import '_seriph/variables';

.xdr-stats-export-modal {
  .ant-modal-content {
    min-height: 200px;
  }
  .dashboard-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 40px 0;
    .ant-select {
      width: 120px;
    }
    .company-drop-down {
      width: 160px;
    }
    .ant-calendar-picker {
      width: 200px;
      margin: 0;
    }
    > span {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0 12px;
      &.no-left {
        margin-left: 0;
      }
    }
    margin-bottom: 32px;
  }
  .ant-calendar-picker-input {
    height: 40px;
    min-height: 40px;

  }
}
