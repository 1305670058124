@import '_seriph/variables';

.seriph-popover {
  &.ant-popover .ant-popover-content .ant-popover-inner {
    background: #FFFFFF;
    /* Cool Neutral/200 */

    border: 1px solid #E7ECF4;
    /* Dropshadow / Large */

    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    margin-bottom: -8px;
    padding: 12px;
  }
  .ant-popover-arrow {
    display: none !important;
  }
}
