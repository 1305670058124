@-webkit-keyframes loadpulse {
  0% {
    -webkit-transform: scale(0.9);
    opacity: 0.3;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9);
    opacity: 0.3;
  }
}

@keyframes loadpulse {
  0% {
    transform: scale(0.9);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.3;
  }
}

#loading-pulse {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  > img {
    width: 60px;
    height: 60px;
    transform: translate(-50px, -50px);
    -webkit-animation: loadpulse 2s infinite ease-in-out;
    -o-animation: loadpulse 2s infinite ease-in-out;
    -ms-animation: loadpulse 2s infinite ease-in-out;
    -moz-animation: loadpulse 2s infinite ease-in-out;
    animation: loadpulse 2s infinite ease-in-out;
  }
  &.tiny > img {
    width: 24px;
    height: 24px;
  }
  &.small > img {
    width: 40px;
    height: 40px;
  }
  &.large > img {
    width: 80px;
    height: 80px;
  }
  &.extra-large > img {
    width: 96px;
    height: 96px;
  }
}
