@import '_seriph/variables';

#join {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #fff;
  > img.join-logo {
    position: absolute;
    left: 24px;
    top: 20px;
  }
  > .join-form {
    flex: 1;
    min-width: 512px;
    display: flex;
    align-tiems: center;
    justify-content: center;
    padding: 24px;
    position: relative;
    .join-fields {
      width: 100%;
      .seriph-input {
        margin-bottom: 24px;
      }
      > div:last-child {
        margin-bottom: 0;
      }
      .no-margin {
        margin-bottom: 0;
      }
      .join-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 -8px;
        > div {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
    .join-form-box {
      display: flex;
      flex-direction: column;
      width: auto;
      max-width: 400px;
      justify-content:center;
      align-items: center;
    }
    .join-title {
      margin-bottom: 28px;
      h2 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        color: #000000;
        text-align: center;
        margin: 0 0 12px 0;
      }
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 12px 0;
        color: #686764;
        text-align: center;
      }
    }
    .join-actions {
      margin-top: 24px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
  > .join-marketing {
    background: #F5F7FB;
    flex: 1;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .join-quote {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 64px 32px;
      > img {
        margin-right: 18px;
        position: relative;
        top: -16px;
      }
      .quote-box {
        max-width: 620px;
        .quote-text {
          font-family: $font-header;
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 38px;
          color: #000000;
          i {
            font-weight: bold;
            font-family: $font-header;
          }
        }
        .quote-person {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 32px;
          .qp-info {
            margin-left: 18px;
            h3 {
              font-family: $font-major;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: #000000;
              margin: 0 0 2px 0;
            }
            p {
              font-family: $font-major;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              color: #000000;
              margin: 0;
            }
          }
        }
      }
    }

    .join-bg {
      width: 100%;
      height: 610px;
      max-width: 2000px;
      min-width: 770px;
      background-position: 72px 0px;
      background-size: 130%;
      background-repeat: no-repeat;
    }
    
  }
}

@media screen and (max-width: 991px) {
  #join .quote-text {
    font-size: 24px !important;
    line-height: 1.4 !important;
  }
  #join .join-quote {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  #join > .join-marketing  {
    display: none !important;
  }
  #join > .join-form  {
    min-width: 100%;
  }
  #join > .join-form .join-form-box {
    display: block;
    overflow: auto;    
  }
  #join > .join-form .join-title {
    margin-top: 32px;   
  }



}
