@import '_seriph/variables';

.seriph-toggle {
  background: #d7dde9;
  height: 24px;
  line-height: 22px;
  &:hover {
    background: #4d5b76;
  }
  &.ant-switch-checked {
    background: #090e15;
  }
  &[disabled] {
    opacity: 0.2 !important;
  }
  .ant-click-animating-node {
    display: none;
  }
  &::after {
    width: 20px;
    height: 20px;
  }
  &.ant-switch-small {
    height: 16px !important;
    line-height: 14px !important;
  }
  &.ant-switch-small::after {
    width: 12px !important;
    height: 12px !important;
  }
}
