@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Tiempos';
  src: url('/_assets/fonts/Tiempos/tiempos-medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Tiempos';
  src: url('/_assets/fonts/Tiempos/tiempos-medium-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Tiempos';
  src: url('/_assets/fonts/Tiempos/tiempos-semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Tiempos';
  src: url('/_assets/fonts/Tiempos/tiempos-semibold-italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-light-italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-medium-italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Visuelt';
  src: url('/_assets/fonts/Visuelt/visuelt-bold-italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
