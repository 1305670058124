@import '_seriph/variables';

.seriph-tabs {
  display: block;
  > .seriph-tab {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: $font-major;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: transparent;
    color: #090e15;
    border-radius: 10px;
    margin-right: 8px;
    transition: all 0.1s linear;
    user-select: none;
    .tab-amount {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #e7ecf4;
      border-radius: 100px;
      padding: 2px 8px 0 8px;
      font-family: $font-major;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      margin-left: 12px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: #e7ecf4;
    }
    &.active {
      background: #090e15;
      color: #fff;
      box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
      .tab-amount {
        background: #fff;
      }
    }
    &.disabled {
      cursor: not-allowed;
      background: transparent;
      color: #adb5c5;
    }
  }

  &.tabs-small > .seriph-tab {
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
  }

  &.tabs-large > .seriph-tab {
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
  }
}

.seriph-tabs.tabs-underline {
  > .seriph-tab {
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: 32px;
    &:hover {
      border-color: #090e15;
      color: #090e15 !important;
      background: transparent;
    }
    &.active {
      border-color: #090e15;
      background: transparent;
      color: #090e15 !important;
      box-shadow: none;
    }
    &.disabled {
      cursor: not-allowed;
      background: transparent;
      color: #adb5c5 !important;
      border-color: transparent !important;
    }
  }
}
