@import '_seriph/variables';

#campaign-dashboard {
  width: 100%;
  padding: 32px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .dashboard-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0 12px;
      &.no-left {
        margin-left: 0;
      }
    }
    border-bottom: 1px solid #e7ecf4;
    padding-bottom: 24px;
    margin-bottom: 32px;
  }
  .live-charts {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 40px;
    #cmpgn-tbl {
      flex: 1;
      border: 0;
      overflow: hidden;
      margin-right: 24px;
      padding: 0;
    }
    .status-chart {
      align-items: stretch;
    }
  }
  .chart-box > .seriph-header {
    margin-bottom: 16px;
  }
  .statistics {
    display: flex;
    flex-direction: row;
    align-items: center;
    h4 {
      flex: 1;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #090e15;
      margin: 0;
      font-family: $font-major;
    }
    margin-bottom: 24px;
  }
  .new-chart {
    padding: 32px 0 32px 0;
    position: relative;
    background: #ffffff;
    border: 1px solid #e6e9ef;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    .chart-container {
      width: 100%;
      height: 100%;
    }
  }
  .sx-header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    #date-range {
      margin-right: 12px;
    }
  }
}

#campaign-dashboard {
  .sellx-tooltip {
    background: #0b1e41;
    border-radius: 5px;
    padding: 0;
    .tip-box {
      .tip-title {
        padding: 12px;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }
      .tip-contents {
        padding: 8px 12px;
        .tip-item {
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .tip-circle {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin-right: 10px;
          }
          .tip-name {
            color: #fff;
            flex: 1;
          }
          .tip-value {
            color: #fff;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
