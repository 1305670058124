@import '_seriph/variables';

#wizard-sequences-v2 {
  .wz-container {
    min-height: 360px;
  }

  .seq-content {
    .seq-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      > div {
        flex: 1;
      }
      button {
        margin-left: 12px;
      }
    }
  }
  .seq-options {
    margin-top: 32px;
    > .seq-option {
      padding: 24px;
      background: #ffffff;
      border: 1px solid #e7ecf4;
      border-radius: 12px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      cursor: pointer;

      .preview-btn {
        display: none;
        position: absolute;
        right: 24px;
        top: 24px;
      }
      .seq-icon {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e7ecf4;
        margin-right: 16px;
        svg {
          color: #090e15;
          width: 16px;
          height: 16px;
        }
      }
      &:hover {
        border-color: #090e15;
        .preview-btn {
          display: inline-block;
        }
      }
      &.active {
        border-color: #090e15;
        .seq-icon {
          background: #090e15;
          box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
          svg {
            color: #fff;
          }
        }
      }
      .seq-details {
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0 0 8px 0;
        }
        p {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #707b90;
          margin: 0;
        }
      }
    }
  }
}
