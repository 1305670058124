#wizard-schedule {
  .check-wrapper {
    text-align: center;
    margin-bottom: 32px;
    .sxd-checkbox {
      display: inline-block;
    }
  }

  .schedule {
    .days {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
      > button {
        flex: 1;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      &.invalid button {
        border: 1px solid #ef1e51 !important;
      }
    }
    .day-table {
      margin-bottom: 16px;
      .dt-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        &.disabled {
          .dt-name {
            opacity: 0.3;
          }
        }
        > div {
          flex: 1;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
        }
        .dt-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          opacity: 1;
          transition: opacity 0.3s ease;
        }
      }
    }
  }
}
