@import '_seriph/variables';

.create-lead-modal {
  .ant-modal-content {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    max-height: 600px;
    overflow: scroll !important;
  }
  .ant-modal-body {
    padding: 24px !important;
  }
  .csv-table {
    width: 100%;
  }
  .upload-header {
    padding: 0px 0px 16px 0px;
    h3 {
      text-align: center;
      background: #fff;
      color: #090e15;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      font-family: $font-major;
      // min-height: 65px;
    }
    p {
      text-align: center;
    }
  }
  .campaign-list {
    padding: 16px 0px;
    .campaign-list-item {
      padding: 14px;
      display: flex;
      align-items: stretch;
      &:hover {
        background: #f5f7fb;
        border-radius: 12px;
        cursor: pointer;
      }
      &.active {
        border: 1px solid #090e15;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
        border-radius: 12px;
      }
      .campaign-image {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        display: flex;
        align-items: center;
        height: 100%;
        &.active {
          background: linear-gradient(0deg, #090e15, #090e15);
          border-radius: 100px;
        }
        img {
          display: block;
          margin: auto;
        }
        svg {
          margin: 0 auto;
          color: white;
        }
      }
      .campaign-info {
        padding: 0px 0px 0px 16px;
        .campaign-name {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
        .campaign-type {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  // h3 {
  //   font-size: 24px;
  //   color: #000;
  //   font-weight: 600;
  //   margin: 0 0 0 0;
  // }
  // h5 {
  //   font-size: 14px;
  //   line-height: 21px;
  //   display: flex;
  //   align-items: center;
  //   text-align: right;
  //   color: #77839b;
  // }

  // .select-lead {
  //   margin: 24px 0;
  //   .ant-select {
  //     width: 100%;
  //   }
  // }
  .lead-upload {
    p {
      margin: 32px 0px 8px 0px;
      // padding: 0px 8px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      color: #242d3e;
    }
    .lead-upload-actions {
      display: flex;
      flex-direction: row;
      .lead-type-container {
        cursor: pointer;
        flex-direction: column;
        width: 50%;
        &:first-child {
          padding: 0px 8px 16px 0px;
        }
        &:last-child {
          padding: 0px 0px 16px 8px;
        }
        > div {
          border: 1px solid #e7ecf4;
          box-sizing: border-box;
          border-radius: 12px;
          height: 125px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          &:hover {
            background: #f5f7fb;
          }
          > div {
            padding: 8px 0px;
            color: #090e15;
            font-family: $font-major;
          }
          .upload-icon {
            background: #e7ecf4;
            border-radius: 20px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &.active > div {
          border: 1px solid #090e15;
          box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);

          .upload-icon {
            background: #e7ecf4;
            border-radius: 20px;
            background: #090e15;
            color: white;
          }
        }
      }
    }
  }
  .create-actions {
    // margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px 8px 0px;
    button {
      width: 100%;
    }
  }
}
.create-lead-page {
  .mapping-actions {
    // margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px 8px 0px;

    .btn-primary {
      background: #4353ff;

      &:disabled,
      &[disabled] {
        background: #4353ff;
        opacity: 0.24;
        color: #ffffff;
      }
    }
    button {
      // flex: 0 0 100%;
      border-radius: 12px;
      &:only-child {
        margin-left: auto;
        flex: 0 0 100%;
      }
    }
    &button:disabled,
    button[disabled] {
      background: #4353ff;
      opacity: 0.24;
      color: #ffffff;
    }
    // > button:first-child {
    //   margin-right: 15px;
    // }
  }
}
