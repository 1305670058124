@import '_seriph/variables';

.bill-details-modal {
  .ant-modal-body {
    padding: 0 !important;
    min-height: 200px;
    transition: height 0.2s linear;
  }
  .bd-wrapper {
    display: flex;
    flex-direction: row;
    .bd-details {
      flex: 1;
      position: relative;
      padding: 32px;
      > h2 {
        margin-top: 12px;
      }
      > h5 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #929aaa;
      }
      .bd-info {
        margin-top: 20px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e7ecf4;
        > .last-date {
          color: #090e15;
          margin-left: 8px;
        }
      }
    }
    .bd-summary {
      margin-top: 16px;
      .line-cat {
        margin-bottom: 8px;
      }
      .line-info {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .line-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          text-transform: capitalize;
          margin-right: 8px;
        }
        > h4:first-child,
        > h5:first-child,
        > h3:first-child {
          flex: 1;
        }
        h3 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #090e15;
          margin: 0;
        }
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: #090e15;
          margin: 0;
        }
        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #242d3e;
          margin: 0 0 8px 0;
        }
        &.payment-total {
          margin: 16px 0;
          padding: 16px 0;
          border-top: 1px solid #e7ecf4;
          border-bottom: 1px solid #e7ecf4;
          .subtotal {
            color: #6f55f7;
          }
        }
      }
    }
    .bd-hint {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #4d5b76;
      margin-bottom: 24px;
      a {
        color: #6f55f7;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .bd-activity {
      width: 240px;
      padding: 44px 24px 24px 24px;
      background: #f9f7f2;
      .bd-list {
        margin-top: 24px;
        > .activity-item {
          display: flex;
          flex-direction: row;
          position: relative;
          padding-bottom: 24px;
          &:after {
            content: '';
            clear: both;
            display: block;
            width: 2px;
            background: #e4e2dc;
            height: calc(100% - 13px);
            position: absolute;
            left: 3px;
            top: 17px;
          }
          &:last-child:after {
            display: none !important;
          }
          .left {
            width: 8px;
            height: 8px;
            border: 2px solid #090e15;
            border-radius: 4px;
            position: relative;
            top: 6px;
          }
          .right {
            flex: 1;
            font-weight: 400;
            font-size: 12px;
            line-height: 21px;
            color: #090e15;
            padding-left: 12px;
            b {
              font-weight: 600;
              color: #000;
            }
          }
        }
      }
    }
  }
}
