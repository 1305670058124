@import '_seriph/variables';

#discover-recommended {
  margin-top: 0px;
  padding-bottom: 10px;
  .recommended-title {
    margin-bottom: 30px;
    h1 {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      display: inline-block;
      margin-bottom: 0;
    }
    .sort-by {
      float: right;
      span {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        margin-right: 10px;
      }
      .ant-select-selection-selected-value {
        font-size: 13px;
        font-weight: 500;
        color: #000;
      }
    }
  }
  .loading-cards {
    .sxd-card {
      margin-bottom: 16px;
    }
  }

  .listing-fade {
    position: relative;
    .fade-fade {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(245, 247, 251, 0), rgba(245, 247, 251, 1));
    }
  }
  .company-list {
    margin-bottom: 32px;
    > h3 {
      font-family: $font-header;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 36px;
      color: #090e15;
      margin: 24px 0 24px 0;
    }
    .company-container {
      display: flex;
      flex-direction: row;
      margin: 0 -8px;
    }
    .company-card {
      flex: 1;
      background: #ffffff;
      border-radius: 12px;
      padding: 24px 24px 16px;
      margin: 0 8px;
      height: 220px;
      position: relative;
      h4 {
        font-family: $font-major;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 2px 0;
      }
      > h5 {
        font-family: $font-major;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
      }
      .tstamp {
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
        position: absolute;
        bottom: 16px;
        left: 24px;
      }
    }
  }

  .fade {
    padding: 24px;
    .fade-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      .av {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background: #eceef0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
      }
      .info {
        flex: 1;
        > div {
          display: block;
          line-height: 12px;
          .h3 {
            display: inline-block;
            height: 24px;
            width: 30%;
            border-radius: 12px;
            background: #eceef0;
            margin-bottom: 8px;
          }
          .h5 {
            display: inline-block;
            height: 12px;
            width: 18%;
            border-radius: 6px;
            background: #eceef0;
          }
        }
      }
      .action {
        width: 70px;
        height: 40px;
        border-radius: 12px;
        background: #eceef0;
        display: inline-block;
      }
    }
    .fade-body {
      margin-top: 24px;
      p {
        display: block;
        height: 12px;
        border-radius: 6px;
        background: #eceef0;
        margin-bottom: 8px;
        width: 70%;
      }
      p:first-child {
        width: 80%;
      }
    }
    .fade-footer {
      position: relative;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      > span {
        display: inline-block;
        height: 16px;
        width: 48px;
        border-radius: 12px;
        background: #eceef0;
        margin-right: 8px;
        &:first-child {
          height: 24px;
        }
        &:last-child {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}

// <div className="fade">
//       <div className="fade-header">
//         <div className="av"><FontAwesomeIcon icon={faLock} /></div>
//         <div classname="info"><h3 /><h5 /></div>
//         <div className="action" />
//       </div>
//       <div className="fade-body"><p /><p /></div>
//       <div classname="fade-footer"><span /><span /><span /><span /><span /><span /></div>
//     </div>
