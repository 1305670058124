@import '_seriph/variables';

.seat-info-modal {
  .ant-modal-content {
    min-height: 200px;
  }
  .numbers {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid #e7ecf4;
    border-bottom: 1px solid #e7ecf4;
    padding-top: 12px;
    padding-bottom: 12px;
    > div {
      flex: 1;
      h3 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #090e15;
        margin: 0 0 4px 0;
        text-align: center;
      }
      p {
        font-size: 12px;
        margin: 0;
        line-height: 12px;
        font-weight: 400;
        color: #707B90;
        text-align: center;
      }
    }
  }
  .seat-list {
    padding-top: 12px;
  }
  .team-table {
    margin-top: 8px;
    .ant-table-thead {
      display: none;
    }
    .ant-table-tbody > tr > td {
      padding: 8px 8px !important;
    }
    .emp-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      h4 {
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        margin: 0 0 0 4px;
        font-weight: normal;
      }
      > div {
        > h4 {
          margin: 0 !important;
        }
        > p {
          margin: 0 !important;
          font-size: 13px;
          line-height: 18px;
        }
      }
      .seriph-avatar {
        margin-right: 12px;
      }
    }
    .emp-delete > span {
      cursor: pointer;
      svg {
        color: #707b90;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
