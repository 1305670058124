@import 'variables';

.email-popout {
  width: 524px;
  min-width: 324px;
  max-width: 524px;
  background: #ffffff;
  border: 1px solid #e7ecf4;
  border-bottom: 0;
  box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
  border-radius: 12px 12px 0px 0px;
  min-height: 200px;
  position: relative;
  &.hidden {
    max-width: auto !important;
    width: auto !important;
    min-height: auto !important;
    min-width: auto !important;
    cursor: pointer;
    &:hover {
      background: #f5f7fb;
    }
    .mini-headline {
      display: flex;
      flex-direction: row;
      padding: 16px;
      .info {
        margin-left: 12px;
        flex: 1;
        padding-right: 32px;
        h4 {
          font-family: $font-tt;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0 0 2px 0;
        }
        h5 {
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
          font-weight: normal;
          margin: 0;
          b {
            font-family: $font-tt;
            font-weight: 600;
          }
        }
      }
      .mini-actions {
        position: absolute;
        right: 8px;
        top: 8px;
        > div {
          display: inline-block;
          cursor: pointer;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 12px;
          min-width: 24px;
          min-height: 24px;
          margin-left: 8px;
          svg {
            color: #090e15;
            font-size: 12px;
          }
          &:hover {
            background: #fff;
          }
        }
      }
    }
  }
  .title-bar {
    background: #f5f7fb;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 8px 8px 8px 12px;
    > h4 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      flex: 1;
    }
    > div {
      margin-left: 8px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
      min-width: 24px;
      min-height: 24px;
      svg {
        color: #090e15;
        font-size: 12px;
      }
      &:hover {
        background: #fff;
      }
    }
  }
  .headline {
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    .info {
      margin-left: 12px;
      flex: 1;
      h4 {
        font-family: $font-tt;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin: 0 0 2px 0;
      }
      h5 {
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
        font-weight: normal;
        margin: 0;
        b {
          font-family: $font-tt;
          font-weight: 600;
        }
      }
    }
    .actions {
      button {
        margin-left: 8px;
      }
    }
  }
  .subject,
  .cc,
  .bcc,
  .mail-attachment, {
    border-bottom: 1px solid #e7ecf4;
    border-top: 1px solid #e7ecf4;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    > span {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #4d5b76;
      margin-right: 12px;
    }
    > input {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      border: 0;
      box-shadow: none;
      outline: none;
      background: transparent;
      padding: 16px 0 !important;
      height: 53px;
    }
  }
  .cc,
  .bcc,
  .mail-attachment, {
    border: 0 !important;
    > input {
      padding: 8px 0 !important;
      height: 37px;
    }
    .ant-select-selection {
      border: 0 !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
    .ant-select-selection__choice {
      border: 1px solid #e7ecf4;
      background: #e7ecf4;
      border-radius: 4px;
      padding-left: 8px;
      > div {
        font-size: 12px;
        line-height: 22px;
        color: #242d3e;
      }
    }
    .ant-select-search__field {
      font-size: 12px;
      line-height: 18px;
      color: #242d3e;
    }
  }
  .has-cc .subject {
    margin-top: 8px;
  }

  .mail-attachment {
    margin-bottom: 6px;
  }
  .content {
    padding: 16px 24px 16px 24px;
    .quill {
      display: flex;
      flex-direction: column-reverse;
      .ql-editor {
        padding: 0;
      }
      .ql-toolbar {
        border: 0;
        padding-left: 0;
        margin-top: 12px;
      }
      .ql-container {
        border: 0;
      }
      .ql-editor {
        min-height: 275px;
        max-height: 400px;
        overflow-y: scroll;
      }
      .ql-editor p {
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
      }
      .ql-editor.ql-blank::before {
        color: #77839b;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
      }
    }
    > button {
      position: absolute;
      right: 24px;
      bottom: 16px;
    }
  }
}
