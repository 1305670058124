@import '_seriph/variables';

.withdraw-modal {
  .wm-header {
    margin-top: 12px;
    h3 {
      font-family: $font-major;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      font-weight: 500;
      color: #090e15;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      font-family: $font-minor;
      font-weight: 500;
      color: #4d5b76;
      margin-top: 12px;
    }
  }
  .wm-main {
    .wm-main-header {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      font-family: $font-major;
      font-weight: 500;
      line-height: 21px;
      color: #090e15;
      font-size: 14px;
      padding-bottom: 8px;
      border-bottom: 1px solid #e7ecf4;
    }
    .wm-main-table {
      height: 225px;
      overflow: scroll;
      margin-top: 5px;
      .wm-row {
        display: flex;
        height: 75px;
        align-items: center;
        border-radius: 8px;
        padding: 16px 12px;
        &.selected {
          background: rgba(210, 208, 251, 0.32);
        }

        border: 1px solid white;
        &:hover {
          background: #f5f7fb;
          border: 1px solid #d7dde9;
        }
        .wm-row-left {
          flex: 0 0 30px;
          margin-right: 10px;
        }
        .wm-row-right {
          h4 {
            color: #090e15;
            font-family: $font-major;
            font-size: 14px;
            font-weight: 500;
            margin: 5px 0;
          }
          p {
            color: #090e15;
            font-family: $font-major;
            font-size: 12px;
            margin: 5px 0;
          }
          span {
            font-weight: 300;
          }
        }
      }
    }
  }
  .wm-actions {
    margin-top: 30px;
    text-align: right;
    .btn-default {
      margin-right: 15px;
    }
  }
}
