@import '_seriph/variables';

#column-menu {
  padding: 8px;
  border: 1px solid #e7ecf4;
  box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
  border-radius: 12px;
}

#real-hired-agents {
  #hired-table {
    padding-top: 24px;
    .my-camps {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 1px;
    }
    .no-campaigns {
      opacity: 0.5;
    }
    #hired-columns {
      display: flex;
      width: 28px;
      height: 28px;
      background: transparent;
      border-radius: 100px;
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      color: #090e15;
      z-index: 999;
      svg {
        width: 14px;
        height: 14px;
      }
      &:hover {
        background: #e7ecf4;
        color: #090e15;
      }
    }
    overflow: hidden;
    position: relative;
    .ant-table-wrapper {
      .ant-table-row.inactive {
        > td {
          opacity: 0.5;
          &.row-actions {
            opacity: 0.5;
          }
        }
      }
    }
    .ctbl-box {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .sxd-avatar {
        margin-right: 16px;
      }
      .ctbl-name {
        margin-left: 16px;
        h5 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 21px;
          min-width: 1px;
          width: 192px;
          margin: 0 0 2px 0;
        }
        .ctbl-campaigns {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
          margin: 0 0 2px 0;
        }
        .ctbl-hired {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #707B90;
          margin: 0;
        }
      }
    }
    .tbl-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: transparent;
      border-radius: 100px;
      cursor: pointer;
      &:hover {
        background: #e7ecf4;
      }
    }
    .agent-actions {
      cursor: pointer;
      padding-right: 12px;
    }
  }

  #hired-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
    margin: 24px 0;
  }
}

#real-hired-agents {
  .real-hired-filter {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .filter-right {
      display: flex;
      gap: 8px;
    }
  }
  .review-table {
    margin-top: 32px;
  }
}
