@import '_seriph/variables';

#company-analytics-progress {
  min-width: 100%;
  padding: 32px 40px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .seriph-header-wrapper {
    margin-bottom: 24px;
  }
  .dashboard-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0 12px;
      &.no-left {
        margin-left: 0;
      }
    }
    border-bottom: 1px solid #e7ecf4;
    padding-bottom: 24px;
    margin-bottom: 32px;
  }
  .sx-header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    #date-range {
      margin-right: 12px;
    }
  }
}
