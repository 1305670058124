@import 'variables';

#seq-questions {
  min-width: 284px;
  min-height: 0;
  .sxd-card {
    box-shadow: none;
  }
  .lead-quest {
    margin-bottom: 32px;

    h4 {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #929aaa;
      }
    }
    textarea {
      padding: 16px;
      background: #ffffff;
      border: 1px solid transparent;
      background: #f5f7fb;
      box-shadow: none;
      outline: 0;
      border-radius: 12px;
      &:hover,
      &:focus {
        border: 1px solid #4353ff;
      }
    }
    .ant-checkbox-group {
      width: 100%;
      > label {
        display: block;
        margin: 0 0 2px 0;
        background: transparent;
        border-radius: 8px;
        padding: 8px 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        &.ant-checkbox-wrapper-checked {
          background: #e7ecf4;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #4353ff;
          border-color: #4353ff;
        }
      }
    }
    .ant-radio-group {
      width: 100%;
      > label {
        display: block;
        margin: 0 0 2px 0;
        background: transparent;
        border-radius: 8px;
        padding: 8px 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        &.ant-radio-wrapper-checked {
          background: #e7ecf4;
        }
        .ant-radio-checked .ant-radio-inner {
          border-color: #4353ff;
          &::after {
            background-color: #4353ff;
          }
        }
      }
    }
  }
}
