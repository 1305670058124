@import 'variables';

.sequence-text-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .text-bubble {
    background: #74b9ff;
    border-radius: 30px;
    padding: 20px;
    > div p {
      color: #fff;
      margin: 0;
    }
    margin-bottom: 32px;
  }
  .seq-header {
    padding: 24px;
    border-bottom: 1px solid #e7ecf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    > svg {
      margin-right: 16px;
      width: 32px !important;
      height: 32px !important;
    }
    h4 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $blue-800;
      margin: 0;
    }
  }
  .send-to {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 32px;
    width: 100%;
    > div:first-child {
      margin-right: 16px;
      color: #000;
    }
    border-top: 1px solid #e7ecf4;
    .seriph-select {
      width: 100%;
      flex: 1;
      .ant-select {
        width: 100%;
      }
    }
  }
  .seq-body {
    min-height: 220px;
    padding: 32px 40px;
  }
  .seq-actions {
    padding: 24px;
    border-top: 1px solid #e7ecf4;
    text-align: right !important;
    justify-content: flex-end;
    button {
      margin-left: 12px;
    }
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    z-index: 9;
    &:hover {
      background: #e5e7eb;
    }
  }
}
