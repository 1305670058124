@import '_seriph/variables';

.seriph-progress-circle {
  .ant-progress-text {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #090E15;
  }
  
}
.ant-progress-circle-trail {
  stroke: #D7DDE9 !important;
}