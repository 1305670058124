@import 'variables';

.custom-domain-modal {
  .ant-modal-body {
    min-height: 200px;
    padding: 32px;
    .ant-alert-message p {
      margin-bottom: 0 !important;
    }
    .domain-table {
      width: 100%;
      th,
      td {
        padding: 10px 12px;
        border-bottom: 1px solid #e7ecf4;
        text-align: left;
        white-space: nowrap;

        svg {
          &.fa-check-circle {
            color: #4353ff;
          }
          &.fa-times-circle {
            color: #fd8321;
          }
        }
      }
      tr > td:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        button {
          margin-left: 8px;
          display: inline-block;
        }
        input {
          display: inline-block;
        }
      }
      th {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #707b90;
      }
    }
  }
}
