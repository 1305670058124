.file-training-wrapper {
  width: 100%;
  display: inline-block;
  padding: 0 10px;
  margin: 0 -10px;
  .file {
    padding: 16px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
    background: #fff;
    border-radius: 10px;
    margin-bottom: 8px;
    width: 100%;
    > svg {
      font-size: 40px;
    }
    .fa-file {
      color: #2c3e50;
    }
    .fa-file-pdf {
      color: #ff3768;
    }
    .fa-file-word {
      color: #3498db;
    }
    .fa-file-excel {
      color: #27ae60;
    }
    .fa-file-image {
      color: #9b59b6;
    }
    .fa-file-audio {
      color: #ffc900;
    }
    .fa-file-video {
      color: #e74c3c;
    }
    .info {
      flex: 1;
      padding: 0 15px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > div {
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
      }
    }
    .download {
      cursor: pointer;
      font-size: 16px;
      cursor: pointer;
      svg {
        font-size: 16px;
        color: #929aaa;
      }
    }
    &:hover {
      .download svg {
        color: #4353ff;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .file-wrapper {
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .file-wrapper {
    width: 100%;
  }
}
