@import '_seriph/variables';

.company-brief-listing {
  .company-listing {
    .listing-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px;
      .listing-info {
        margin-left: 16px;
        h3 {
          font-family: $font-major;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #090e15;
          margin: 0;
        }
        .sxd-reputation {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          > span {
            color: #242d3e !important;
          }
        }
        .name-cat {
          font-size: 13px;
          color: #4d5b76;
          span {
            font-weight: 500;
            color: #000;
          }
        }
      }
    }
    .company-information {
      flex: 1;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        padding: 0px 24px 16px 24px;
        a {
          margin-left: 8px;
          font-weight: 500;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .skills {
        background: none;
        padding: 12px 24px;

        .info {
          display: flex;
          align-items: center;
          > span {
            display: inline-flex;
            align-items: center;
            font-family: $font-major;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #090e15;
            svg {
              color: #707b90;
              margin-right: 8px;
              position: relative;
              top: -1px;
            }
          }

          .app-match {
            background: #e5e7ea;
            border-radius: 12px;
            padding: 3px 12px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #090e15;
            margin-right: 12px;
            &.best-match {
              background: #eef0ff;
              color: #5e0cef;
            }
          }
        }
      }
    }
  }
  .company-actions {
    position: absolute;
    top: 20px;
    right: 20px;
    button {
      margin-left: 12px;
    }
  }
  .sellx-card {
    margin-bottom: 20px;
  }
}
