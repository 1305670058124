@import '_seriph/variables';

#wizard-quill {
  .field.invalid {
    .ql-toolbar,
    .ql-container {
      border-color: #ef1e51 !important;
    }
  }
  .wz-container {
    max-width: 100% !important;
    .wz-card-header {
      max-width: 640px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .quill {
    .ql-toolbar {
      border-left: 0;
      border-right: 0;
      border-radius: 0 !important;
      border-color: #e7ecf4 !important;
      padding: 12px 0;
    }
    .ql-container {
      border: 0 !important;
    }
    .ql-editor {
      padding: 24px 0;
      color: #090e15;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
    .ql-container .ql-editor.ql-blank::before {
      font-family: $font-major;
      font-style: normal !important;
      pointer-events: none;
      position: absolute;
      font-size: 14px;
      line-height: 21px;
      color: #707b90;
    }
  }
}
