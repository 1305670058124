@import '_seriph/variables';

#edit-touchpoint {
  .edit-top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    border-bottom: 1px solid #e7ecf4;
    position: relative;
    z-index: 1;
    .seriph-input {
      width: 100%;
    }
    .seriph-input > .input-box > input {
      background: transparent;
      border: 0;
      border-bottom: 1px solid transparent;
      text-align: center;
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #090e15;
      width: 100%;
      box-shadow: none;
      outline: none;
      border-radius: 0;
      height: 64px;
      &:hover {
        border-color: #c4cbd9;
      }
      &:focus {
        border-color: #c4cbd9;
      }
    }
    .type {
      position: absolute;
      left: 16px;
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .edit-subject {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #e7ecf4;
    .seriph-input {
      width: 100%;
    }
    .seriph-input > .input-box > input {
      background: transparent;
      border: 1px solid transparent;
      text-align: center;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: left;
      padding-left: 80px;
      color: #090e15;
      width: 100%;
      box-shadow: none;
      outline: none;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      &:hover {
        border-color: #c4cbd9;
      }
      &:focus {
        border-color: 1px solid #c4cbd9;
        background: transparent;
      }
    }
    .subject {
      position: absolute;
      left: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin-top: 2px;
    }
  }

  .edit-content {
    position: relative;
    .smart-tags {
      display: inline-flex;
      position: absolute;
      right: 16px;
      top: 13px;
      .ant-select {
        width: 200px;
      }
    }

    .alert-warning {
      position: absolute;
      top: 66px;
      width: 100%;
      z-index: 999;
    }
    &.alert .ql-container {
      padding-top: 64px;
    }
    .ql-toolbar {
      border-top: 0;
    }
  }
  .danger,
  .back {
    float: left;
  }
  .bottom-row {
    display: flex;
    gap: 8px;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    left: 0;
    h4 {
      font-family: $font-major;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
      margin: 0 0 0 12px;
    }
    .real-toggle {
      align-items: center;
      padding: 12px 24px;
      display: flex;
    }
    .disable-signature {
      display: flex;
      align-items: center;
    }
  }
}
