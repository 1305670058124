@import '_seriph/variables';

#agent-stipend {
  padding: 0 12px 0 12px;
  border-bottom: 1px solid #e7ecf4;
  margin-bottom: 24px;
  .stipend-table {
    margin-top: 24px;
  }
  .seriph-card {
    background: transparent;
    padding-left: 0 !important;
  }
  h1 {
    cursor: pointer;
  }
  h1 > span {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707B90;
    svg {
      width: 18px;
      height: 18px;
      margin-left: 8px;
    }
  }
  .stipend-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    > a {
      flex: 1;
      margin: 0 8px;
    }
    button {
      width: 100%;
    }
  }
  .others {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    > div {
      padding: 16px 24px;
      &:first-child {
        margin-right: 6px;
      }
      &:last-child {
        margin-left: 6px;
      }
    }
  }
  .descriptor {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    margin-bottom: 8px;
  }
  .sxd-link {
    margin-top: 16px;
  }
  .stipend-steps {
    border-top: 1px solid #E7ECF4;
    padding-top: 24px;
  }
  .stipend-steps .stipend-step {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    .ss-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-family: $font-major;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      > svg {
        width: 14px;
        height: 14px;
      }
    }
    > p {
      font-family: $font-major;
      margin: 0 12px;
      flex: 1;
    }
    .ss-amount {
      font-family: $font-major;
    }
    &.complete {
      .ss-icon {
        background: #D2D0FB;
        svg {
          color: #090e15;
        }
      }
      p, .ss-amount {
        color: #707B90;
      }
    }
    &.active {
      .ss-icon {
        background: #090e15;
        color: #fff;
      }
      p, .ss-amount {
        color: #090e15;
        font-weight: 500;
      }
    }
    &.locked {
      .ss-icon {
        background: #E7ECF4;
        svg {
          color: #ADB5C5;
        }
      }
      p, .ss-amount {
        color: #929AAA;
      }
    }
  }
  .stipend-steps .stipend-step.active {
    margin-bottom: 0;
  }
  .stipend-info {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #707B90;
    padding-left: 32px;
  }
  .stipend-progress {
    background: #E7ECF4;
    height: 16px;
    width: 100%;
    content: '';
    margin-top: 12px;
    margin-bottom: 24px;
    > div {
      height: 16px;
      background: #090e15;
      min-width: 0.01%;
      content: '';
    }
  }

}

