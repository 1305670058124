@import 'variables';

.sequence-skip-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .skip-content {
    padding: 32px;
    .skip-info {
      display: flex;
      flex-direction: row;
      .stage-icon {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 32px;
        left: 32px;
        background: #ffd53e;
        color: #090e15;
        margin-right: 16px;
      }
      .skip-title {
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #000000;
          margin: 0;
        }
        h5 {
          font-size: 14px;
          line-height: 21px;
          color: #4d5b76;
          margin: 4px 0 0 0;
          font-weight: 400;
        }
      }
    }

    .skip-options {
      margin-top: 32px;
      > div {
        display: inline-block;
        width: calc(50% - 12px) !important;
        margin-right: 12px;
        margin-bottom: 16px;
        background: #ffffff;
        border: 1px solid #dce0e8;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(11, 30, 65, 0.06);
        border-radius: 8px;
        padding: 10px 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #0b1e41;
        flex: 2;
        cursor: pointer;
        &:last-child {
          flex: 1;
        }
        &:hover,
        &.active {
          background: #4353ff;
          color: #fff;
          border: 1px solid #4353ff;
        }
        transition: all 0.2s ease;
      }
    }
    textarea {
      margin-top: 16px;
      padding: 16px !important;
      background: #f3f5f9 !important;
    }
  }
  .skip-actions {
    margin-top: 10px;
    padding: 32px;
    text-align: right;
    > button:first-child {
      margin-right: 15px;
    }
    border-top: 1px solid #e6e9ef;
  }
  .close-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    background: #f5f7fb;
    color: #090e15;
    cursor: pointer;
    z-index: 9;
    &:hover {
      background: #e5e7eb;
    }
  }
}
