@import '_seriph/variables';

.seriph-label {
  display: block;
  font-family: $font-major;
  font-size: 14px;
  line-height: 21px;
  color: #090e15;
  margin: 0 0 8px 0;
  svg {
    cursor: pointer;
    position: relative;
    top: 3px;
    left: 8px;
  }
}

.seriph-hint {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  color: #4d5b76;
  margin: 8px 0 0 0;
  > svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }
}

.seriph-success {
  .seriph-hint {
    color: #1fa34c;
  }
}

.seriph-error {
  .seriph-hint {
    color: #ee3a3a;
  }
}
