@import '_seriph/variables';

$max-width: 1024px;
$page-padding: 0 32px;

#lead-thread {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f5f7fb;

  .thread-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }

  .subject-hr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 32px;
    .subject-title {
      flex: 1;
      text-align: center;
      h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #090e15;
        margin: 0;
      }
      h5 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #707b90;
        margin: 0;
      }
    }
  }

  .thread-conversation {
    width: 100%;
    word-break: break-word;
    flex: 1;
    overflow-y: scroll;
    padding: 0 32px;
  }

  .email-input {
    padding: 0 32px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 32px;
    position: absolute;
    bottom: 0;
    &.old {
      position: relative !important;
      bottom: auto;
      margin-top: 0;
    }
    .thread-back {
      margin-bottom: 16px;
    }
    .input-content {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
      border-radius: 12px;
      padding: 0;
      padding-top: 4px;

      .email-field {
        font-family: $font-major;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        display: flex;
        border-bottom: 1px solid #e7ecf4;
        padding: 12px 24px;

        &.from-address {
          flex-direction: row;
          align-items: center;
        }
        &.to-address {
          flex-direction: column;
        }
        .info {
          flex: 1;
        }
        b {
          font-weight: 500;
          margin-right: 12px;
        }
        .actions {
          button {
            margin-left: 8px;
          }
        }

        .ant-select-selection {
          border: 0;
          box-shadow: none;
        }

        .ant-select-disabled {
          .ant-select-selection__choice {
            background: none;
          }
          .ant-select-selection {
            background: none;
          }
          .ant-select-selection__choice {
            color: #090e15;
            border: 0;
            background: none;
            padding: 0;
          }
        }


      }

      .content {
        padding: 24px 24px 12px 24px;
        .quill {
          display: flex;
          flex-direction: column-reverse;
          .ql-editor {
            padding: 0;
          }
          .ql-toolbar {
            border: 0;
            padding-left: 0;
          }
          .ql-container {
            border: 0;
            min-height: 60px;
          }
          .ql-editor p {
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
          }
          .ql-editor.ql-blank::before {
            color: #77839b;
            font-style: normal;
            font-size: 14px;
            line-height: 21px;
          }
        }
        .seriph-btn {
          position: absolute;
          right: 24px;
          bottom: 16px;
        }
      }
    }
  }
}
