@import '_seriph/variables';

.seriph-checkbox {
  &.ant-checkbox-wrapper {
    display: block;
    margin: 0 !important;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    &:not(.no-bg) {
      background: #fff;
    }
    padding: 8px 12px;
    border-radius: 8px;
    &:hover {
      background: #f5f7fb;
    }
    user-select: none;
  }

  &.reverse {
    direction: rtl;
  }

  span {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
  }

  &.font-minor span {
    font-family: $font-minor;
  }

  .ant-checkbox-inner {
    outline: 0 !important;
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #090e15;
  }
  &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #090e15 !important;
    border-color: #090e15 !important;
  }

  &.size-small {
    padding: 4px 0px;
    span {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #090e15;
    }
  }
}
