#admin-applications {
  padding: 40px 30px 30px 30px;
  .admin-header {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    position: relative;
    button {
      position: absolute;
      right: 320px;
      top: 0;
      svg {
        margin-right: 8px;
      }
    }
    > div {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      position: relative;
      left: 0;
      top: -5px;
    }
    .ant-input-search {
      width: 300px;
      position: absolute;
      right: 0;
      top: 0;
      input {
        border: 1px solid #e6e9ef;
        background: #fff;
        outline: 0;
        box-shadow: none;
        color: #000000;
        &:hover,
        &:focus {
          border-color: #4353ff;
          outline: 0;
          box-shadow: none;
        }
      }
      .anticon-search {
        font-size: 16px;
        color: #000;
        &:hover {
          color: #4353ff;
        }
      }
    }
  }
  .admin-table {
    .app-status {
      font-weight: 600;
      color: #fff;
      padding: 8px 12px;
      border-radius: 8px;
      background: #ffd53e;
      &.Passed {
        background: #ef1e51;
      }
      &.Invited {
        background: #4353ff;
      }
      &.Joined {
        background: #38cf36;
      }
    }
    .lead-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      .lead-photo {
        padding-right: 10px;
        img {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }
        span {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 20px;
          line-height: 30px;
          text-align: center;
          background: #ecedff;
          color: #4353ff;
          font-weight: 600;
        }
      }
    }
    .agent-email {
      svg {
        color: #30f09e;
        margin-left: 8px;
      }
    }
  }
}
