@import '_seriph/variables';

h1,
h2,
h3,
h4,
h5 {
  &.seriph-header {
    font-family: $font-header;
    margin: 0;
    color: #090e15;
  }
}

/* Handle default headings */
h1.seriph-header {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.01em;
}

h2.seriph-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
}

h3.seriph-header {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

h4.seriph-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

h5.seriph-header {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

/* Handle Font */
h1,
h2,
h3,
h4,
h5 {
  &.seriph-header.header-sans {
    font-family: $font-major;
  }
}

/* Handle Display */
h1.seriph-header.header-display {
  font-weight: 600;
  font-size: 56px;
  line-height: 72px;
}
h2.seriph-header.header-display {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
}
h3.seriph-header.header-display {
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
}
h4.seriph-header.header-display {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}
h5.seriph-header.header-display {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
}

h1,
h2,
h3,
h4,
h5 {
  &.seriph-header.header-display.display-light {
    font-weight: normal;
  }
}
