#wizard-questions {
  .add-question {
    text-align: center;
    padding-top: 8px;
    margin: 0 0 24px 0;
    button {
      min-width: 240px;
    }
    button svg {
      margin-right: 10px;
    }
  }
  .quest {
    padding: 12px 24px 20px 24px;
    box-shadow: none;
    border: 1px solid #d7dde9;
    margin-bottom: 16px;
    .quest-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
      .seriph-input {
        flex: 2;
        margin-right: 16px;
      }
      .seriph-select {
        flex: 1.3;
      }
      .quest-trash {
        width: 30px;
        cursor: pointer;
        margin-left: 16px;
        text-align: right;
        svg {
          width: 18px;
          height: 18px;
          color: #090e15;
        }
        &:hover {
          svg {
            color: red;
          }
        }
      }
    }
    .quest-options {
      .quest-option {
        margin-top: 16px;
        .seriph-input {
          flex: 1;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .remove-option {
      margin-left: 16px;
      cursor: pointer;
      svg {
        width: 18px;
        height: 18px;
        color: #090e15;
      }
      &:hover {
        svg {
          color: red;
        }
      }
    }
    .add-option {
      margin-top: 24px;
    }
    .quest-sep {
      display: block;
      height: 1px;
      background: #e6e9ef;
      width: 100%;
      margin-top: 32px;
    }
  }
}
