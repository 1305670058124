.profile-form-modal {
  .ant-modal {
    width: 800px !important;
    .form-title {
      margin-top: 20px;
    }
    .ant-radio-button-wrapper {
      margin-top: 10px;
    }
  }
}
