#company-discover-recommended {
  margin-top: 0px;
  padding-bottom: 10px;
  .recommended-title {
    margin-bottom: 30px;
    h1 {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      display: inline-block;
      margin-bottom: 0;
    }
    .sort-by {
      float: right;
      span {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        margin-right: 10px;
      }
      .ant-select-selection-selected-value {
        font-size: 13px;
        font-weight: 500;
        color: #000;
      }
    }
  }
  .loading-cards {
    .sxd-card {
      margin-bottom: 16px;
    }
  }
}
