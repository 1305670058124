@import '_seriph/variables';

#lead-activity {
  padding: 24px 0 32px 0;
  position: relative;
  min-height: 100%;
  .activity-date {
    text-align: center;
    border-bottom: 1px solid #e7ecf4;
    margin-bottom: 32px;
    position: relative;
    > span {
      background: #f5f7fb;
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      position: relative;
      top: 12px;
      padding: 0 16px;
    }
  }
  .date-section > div:last-child:after {
    display: none !important;
  }
  .date-section > div:nth-child(2):nth-last-child(1):after {
    display: none !important;
  }
  .lead-activity {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    margin-bottom: 24px;
    min-height: 32px;
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      left: 16px;
      top: 38px;
      height: calc(100% - 20px);
      background: #d7dde9;
      z-index: 1;
    }
    .act-icon {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $unknown;
      border-radius: 16px;
      position: absolute;
      z-index: 9;
      svg {
        width: 14px;
        height: 14px;
        color: #090e15;
      }
      &.email {
        background: $email;
      }
      &.call {
        background: $call;
      }
      &.note {
        background: $note;
      }
      &.incoming {
        background: #090e15 !important;
        svg {
          color: #fff !important;
        }
      }
    }
    .descript {
      font-family: Visuelt;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #4d5b76;
      margin-left: 48px;
      padding-top: 6px;
      b,
      .changes {
        color: #090e15;
        font-weight: 500;
        font-family: $font-major;
      }
      > .act-tstamp {
        font-weight: 400;
        font-size: 14px;
        color: #707b90;
        display: inline-block;
        margin: 0 4px;
      }
      .sxd-link {
        margin-left: 4px;
      }
      .changes {
        text-transform: capitalize;
      }
    }
    .information {
      flex: 1;
      text-align: right;
      padding-left: 16px;
      b {
        font-weight: 500;
        color: #000;
      }
      &.capitalize {
        text-transform: capitalize;
      }
      &.italics {
        font-style: italic;
      }
      &.money {
        color: #00c06e;
        font-weight: 500;
        font-size: 16px;
        font-style: italic;
      }
    }
  }
}
