@import '_seriph/variables';

#onboarding.mobile {
  width: 100%;
  min-height: 100%;
  height: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: #090e15;
  position: relative;
  .home-logo {
    position: absolute;
    left: 40px;
    top: 40px;
    z-index: 99;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .mobile-actions {
    text-align: center;
    margin-top: 32px;
  }
  .mobile-body {
    width: 100%;
    max-width: 420px !important;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    h3,
    .onboarding-mobile {
      color: #fff;
    }
    .onboarding-mobile {
      font-size: 16px;
      font-weight: normal;
      font-family: $font-major;
      line-height: 24px;
      margin-top: 16px;
    }
  }
}
