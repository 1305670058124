@import 'variables';

#wizard-budget {
  .wz-container {
    min-height: 360px;
  }
  input.invalid,
  .invalid > input,
  .ant-select.invalid .ant-select-selection,
  .ant-input-number.invalid input {
    border: 1px solid #facb94 !important;
  }
  .sxd-guide {
    margin-bottom: 16px !important;
  }
  .budget-number {
    .val {
      font-family: $font-tt;
      font-style: normal;
      font-weight: 500;
      font-size: 56px;
      line-height: 72px;
      align-items: center;
      text-align: center;
      color: #090e15;
      margin-bottom: 32px;
      &.invalid {
        color: #facb94;
      }
    }
    .ant-slider {
      .ant-slider-step,
      .ant-slider-track,
      .ant-slider-rail {
        height: 16px;
      }
      .ant-slider-handle {
        margin-top: -8px;
        width: 24px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #e7ecf4;
        box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
        border-radius: 8px;
      }
      .ant-slider-track {
        background-color: #43e8ff;
      }
      &.invalid .ant-slider-track {
        background-color: #facb94;
      }
    }
    margin-bottom: 64px;
  }
  .input-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    > .field {
      flex: 1;
      &:first-child {
        margin-right: 24px;
      }
    }
    .ant-input[disabled] {
      color: #0b1e41 !important;
      background: #f5f7fb !important;
    }
    .ant-select-selection-selected-value {
      font-size: 14px;
      color: #0b1e41;
    }
  }
  .budget-info {
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #090e15;
    margin-top: 12px;
    margin-bottom: 32px;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
  }
  .ant-input-number {
    height: 48px;
    border: 0;
    width: 100%;
    outline: 0;
    box-shadow: none;
    .ant-input-number-handler-wrap {
      height: 46px;
      top: 1px;
      right: 1px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
      .ant-input-number-handler-up {
        border-top-right-radius: 8px;
      }
      .ant-input-number-handler-down {
        border-bottom-right-radius: 8px;
      }
    }
    input {
      background: #f5f7fb;
      border: 1px solid transparent;
      border-radius: 8px;
      height: 48px;
      font-size: 14px;
      color: #0b1e41;
      padding-left: 16px;
      padding-right: 16px;
      &:hover,
      &:focus {
        border-color: #4353ff;
        outline: 0;
        box-shadow: none;
      }
    }
  }
}
