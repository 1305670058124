@import '_seriph/variables';

.bonus-modal {
  .bonus-form-cont {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 12px;

    .bonus-form > div {
      margin-bottom: 24px;
    }

    .bonus-buttons {
      display: flex;
      gap: 12px;
    }
  }
}
