@import '_seriph/variables';

#wizard-import {
  .wz-container {
    min-height: 360px;
  }
  .add-more {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 32px;
    border: none;
    background: transparent;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto;
  }
  .auto-map {
    margin-top: 24px;
  }
  .more-container {
    text-align: center;
    .ant-upload-btn {
      padding: 0;
    }
  }
  .crm-csv {
    > h4 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090E15;
      margin: 0 0 8px 0;
    }
    .crm-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px;
      background: #FFFFFF;
      border: 1px solid #E7ECF4;
      border-radius: 12px;
      margin-bottom: 12px;
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 1px solid #E7ECF4;
        border-radius: 96px;
        margin-right: 16px;
        img {
          height: 18px;
        }
      }
      > h4 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090E15;
        margin: 0;
        flex: 1;
      }
    }
    .crm-or {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin: 32px 0;
      > span {
        flex: 1;
        height: 1px;
        background: #E7ECF4;
      }
      > div {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #090E15;
        margin: 0 24px;
      }
    }
  }
  .import-selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      display: flex;
      flex: 1;
      margin-right: 0;
      .ant-upload-drag-container > div {
        text-align: center;
      }
    }
    .dragger-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    > div,
    > span > .csv {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100px;
      align-items: center;
      justify-content: center;
      border: 1px dashed #D7DDE9;
      border-radius: 12px;
      h3 {
        font-family: $font-major;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #090E15;
        margin: 0;
      }
      .type-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #f5f7fb;
        border-radius: 20px;
        margin: 0 16px 0 0;
        svg {
          width: 14px;
          height: 14px;
          color: #090e15;

        }
      }
      &:hover,
      &.active {
        cursor: pointer;
        border: 1px dashed #090e15;
      }
      &.active {
        .type-icon {
          background: #090e15;
          svg {
            color: #fff;
          }
        }
        h3 {
          color: #090e15;
        }
      }
    }
    .csv {
      background: #fff;
    }
    .campaign {
      margin-left: 12px;
    }
    &.invalid {
      .campaign,
      .ant-upload.csv {
        border: 1px solid #ef1e51 !important;
      }
    }
  }
  .campaign-field {
    margin-top: 32px;

    .from-campaign {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        margin-left: 16px;
      }
    }
  }
  .import {
    padding: 40px 64px 40px 40px;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #4353ff;
    box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
    .import-info {
      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #4353ff;
        margin: 0;
        text-align: left;
      }
      > div {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
      }
    }

    .type-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background: #4353ff;
      border-radius: 24px;
      margin-right: 16px;
      svg {
        font-size: 24px;
        height: 40 !important;
        color: #fff;
        path {
          fill: #fff;
        }
      }
    }
    .close-icon {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 12px;
      right: 12px;
      background: #f5f7fb;
      color: #090e15;
      cursor: pointer;
      &:hover {
        background: #e5e7eb;
      }
    }
    &.disabled {
      .close-icon {
        display: none !important;
      }
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
  .import-container {
    text-align: center;
  }
  .import.small {
    padding: 24px;
    display: inline-flex;
    margin: 12px;
    .import-info {
      h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        margin: 0;
        text-align: left;
      }
      > div {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
      }
    }
    .close-icon {
      width: 24px;
      height: 24px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 8px;
      right: 8px;
      background: #f5f7fb;
      color: #1b0054;
      cursor: pointer;
      svg {
        font-size: 12px;
      }
      &:hover {
        background: #e5e7eb;
      }
    }
  }
}
