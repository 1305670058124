@import '_seriph/variables';

.bulk-email-modal {
  .ant-modal-content {
    width: 900px;
    border-radius: 16px;
    .ant-modal-close {
      background: #f5f7fb;
      border-radius: 50%;
      right: 16px;
      top: 16px;
      .ant-modal-close-x {
        height: 32px;
        width: 32px;
        font-size: 14px;
        line-height: 32px;
        color: #090e15;
      }
    }
    .ant-modal-body {
      position: relative;
      padding: 32px 40px 27px;
      background: #f5f7fb;
      border-radius: 16px;
      min-height: 200px;
      .be-title {
        font-family: $font-header;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .be-subtitle {
        font-family: $font-minor;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        font-weight: 400;
      }
      .be-body {
        margin: 16px 0;
        background: #fff;
        padding: 0;
        border-radius: 12px;
        border: 1px solid transparent;
        transition: 0.5s all;
        &.sending {
          background: #e8e7fd;
          border: 1px solid #d2d0fb;
        }
        &.sent {
          background: #eef9ec;
          border: 1px solid #c1ddbb;
        }
        &:hover,
        &.expanded {
          border: 1px solid #d7dde9;
        }
        .persistent {
          cursor: pointer;
          padding: 20px 24px;
          &.expanded {
            border-bottom: 1px solid #e7ecf4;
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
          .be-pers-left {
            .subject {
              margin-bottom: 5px;
              .hidden-toolbar {
                display: none;
              }
              .ql-container {
                .ql-editor {
                  font-family: $font-major;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #090e15;
                }
              }
            }
            .from-label {
              display: inline-block;
              margin-bottom: 0;
              font-family: $font-major;
              font-weight: 500;
              font-size: 12px;
              color: #4d5b76;
            }
            .from {
              display: inline-block;
              margin-left: 8px;
              font-size: 12px;
              font-family: $font-major;
              padding: 2px 8px;
              background: #e7ecf4;
              border-radius: 4px;
              font-weight: 400;
              color: #090e15;
            }
          }
          .be-pers-right {
            .spinning {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-family: $font-major;
              font-weight: 500;
              font-size: 14px;
              color: #090e15;
              .spinfo {
                margin-left: 16px;
              }
              svg {
                color: #090e15;
              }
              .icon-tabler {
                margin-left: 16px;
              }
            }
            .be-expand {
              border-radius: 50%;
              border: none;
              background: #e7ecf4;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;

              color: #090e15;

              font-size: 11px;
            }
          }
        }
        &.sending .persistent,
        &.sent .persistent {
          cursor: default;
        }
        .be-main {
          padding: 25px 32px 10px;
          .be-main-cont {
            display: flex;
            .be-main-form {
              flex: 4;
              .ql-container {
                .ql-editor {
                  color: #090e15;
                  font-family: Inter;
                  font-size: 14px;
                  line-height: 24px;
                }
              }
              margin-right: 48px;
            }
            .be-main-summary {
              border-left: 1px solid #e7ecf4;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: left;
              padding: 0 32px;

              .be-bullets {
                list-style-type: none;
                padding: 0;
                max-width: 180px;
                li {
                  color: #090e15;
                  margin: 8px 0;
                  display: flex;
                  align-items: center;

                  svg {
                    height: 16px;
                    width: 16px;
                    margin-right: 12px;
                    flex: 0 0 16px;
                  }
                  .seriph-avatar {
                    position: relative;
                    left: -1px;
                    margin-right: 10px;
                  }
                  div {
                    font-family: $font-major;
                    font-weight: 500;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
              button {
                width: 100%;
                padding: 0px 16px;
                border-radius: 12px;
                font-family: $font-major;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                svg {
                  margin-right: 5px;
                  font-size: 10px;
                  margin-bottom: 1px;
                }
              }
              .be-test-button {
                background: #ffffff;
                color: #090e15;
                border: 1px solid #e7ecf4;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .be-send-button {
                background: #090e15;
                color: #ffffff;
                border: none;
              }
            }
          }
          .be-main-buttons {
            display: flex;
            align-items: center;
            padding-top: 20px;
            border-top: 1px solid #e7ecf4;

            .be-show-with {
              margin-right: 6px;
              .ant-switch {
                background: #d7dde9;
              }
              .ant-switch-checked {
                background: #4353ff;
              }
              .real-label {
                display: inline-block;
                margin-left: 12px;

                font-family: $font-major;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #090e15;
              }
            }
            .be-toolbar {
              display: flex;
              border: none;
              .be-lb {
                border-left: 1px solid #e7ecf4;
              }
              button {
                margin: 0 6px;
              }
            }
          }
          .be-main-signature {
            margin-top: 0;
            color: #090e15;
            font-size: 14px;
            line-height: 24px;
            font-family: Inter;
            font-weight: 500;
            .be-sig {
              margin-bottom: 34px;
              .be-sig-title {
                color: #707b90;
                font-size: 12px;
                line-height: 18px;
              }
              .be-sig-phone,
              .be-sig-website {
                color: #4353ff;
                font-size: 12px;
                line-height: 18px;
              }
            }
            .be-sig-button {
              font-size: 12px;
              height: 30px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              background: #ffffff;

              border: 1px solid #e7ecf4;
              border-radius: 100px;

              margin: 16px 0px;
            }
          }
        }
      }
      .be-header {
        background: #e7ecf4;
        border-radius: 12px;
        padding: 18px 24px;
        color: #4353ff;
        font-weight: 500;
        display: flex;
      }
    }
  }
  .ql-container {
    border: none;
    .ql-editor {
      padding: 0;
      s {
        text-decoration: none;
        background: #e7f8ff;
        color: #4353ff;
        padding: 5px;
        font-weight: 500;
      }
    }
  }
}

.sellx-tooltip {
  .ant-tooltip-inner {
    font-family: Inter;
    font-size: 14px;
    padding: 12px 0;
    background: #090e15;
    text-align: center;
    border-radius: 8px;
  }
}

.spinning-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #4353ff;
    border-radius: 50%;
    animation: spinning-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #4353ff transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes spinning-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
