.field-mapping {
  padding: 8px 32px;
  max-height: 630px;
  overflow-y: scroll;
  .csv-fields {
    margin-top: 24px;
    width: 100%;
    table-layout: fixed;
    th {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #242d3e;
      padding: 8px 8px 8px 0;
    }
    th,
    td {
      border-bottom: 1px solid #e7ecf4;
    }
    tr:last-child > td {
      border-bottom: 0 !important;
    }
    tr > td {
      padding: 20px 0 20px 0;
      position: relative;
    }
    tr:hover {
      .ant-input {
        background: #e7ecf4 !important;
      }
    }
    tr.unmapped {
      .ant-input,
      .ant-select-selection {
        background-color: #fff1bf !important;
        border-color: #fff1bf;
      }
    }
    tr.custom-row > td {
      border-bottom: 1px transparent;
      padding-bottom: 0 !important;
    }
  }
  div.arrow {
    width: 40px;
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
    height: 40px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      color: #adb5c5;
    }
  }
  .ant-select {
    width: calc(100% - 40px);
    .ant-select-selection {
      background: #fff;
      border-radius: 12px;
      border-color: #e7ecf4;
      outline: 0 !important;
      box-shadow: none !important;
    }
    .ant-select-selection-selected-value {
      text-transform: capitalize;
    }
  }
  .type-option {
    color: #242d3e;
    svg {
      margin-right: 12px;
      &.fa-check-circle {
        color: #4353ff;
      }
      &.fa-info-circle {
        color: #fd8321;
      }
      &.fa-ban {
        color: #929aaa;
      }
      &.fa-plus {
        color: #242d3e;
      }
    }
  }
  .ant-input {
    width: calc(100% - 40px);
    background: #f5f7fb;
    border-radius: 12px;
    border-color: transparent;
  }
}
