.ro-emails {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: stretch;
  .email-popout {
    margin-right: 8px;
    &.invisible {
      display: none;
    }
  }
}
