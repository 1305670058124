#wizard-reps {
  .calendly {
    .field {
      position: relative;
      > svg {
        position: absolute;
        left: 16px;
        bottom: 18px;
      }
      input {
        padding-left: 40px;
      }
    }
    .input-entry {
      margin-top: 8px;
    }
    .input-label {
      margin-top: 0;
    }
    .ant-checkbox-wrapper {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff !important;
    }
  }
  .integration-label {
    margin-top: 32px;
  }
  .calendly-select {
    .ant-select-selection-selected-value,
    .ant-select-selection__placeholder {
      padding-left: 32px;
    }
  }
  .chilipiper-check {
    color: #090e15;
    svg {
      margin: 0 8px 0 12px;
      color: #090e15;
    }
  }
  .check-wrapper {
    margin-bottom: 32px;
    padding-top: 32px;
    border-top: 1px solid #e5e7ef;
  }
}


.custom-domain-drop {
  .ant-select-dropdown-menu-item {
    text-transform: lowercase !important;
  }
}