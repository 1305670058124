.dispute-modal {
  .ant-modal-body {
    padding: 0;
  }
  .dispute-content {
    padding: 24px;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: #000000;
      margin: 0;
    }
    h5 {
      font-size: 14px;
      line-height: 21px;
      color: #4d5b76;
      margin: 4px 0 0 0;
      font-weight: 400;
    }
    .dispute-options {
      display: block;
      flex-direction: row;
      align-items: center;
      margin-top: 32px;
      > div {
        display: inline-block;
        margin-right: 12px;
        margin-bottom: 12px;
        background: #ffffff;
        border: 1px solid #dce0e8;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(11, 30, 65, 0.06);
        border-radius: 8px;
        padding: 10px 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #0b1e41;
        flex: 2;
        cursor: pointer;
        &:last-child {
          flex: 1;
        }
        &:hover,
        &.active {
          background: #4353ff;
          color: #fff;
          border: 1px solid #4353ff;
        }
        transition: all 0.2s ease;
      }
    }
    textarea {
      margin-top: 32px;
      padding: 16px !important;
      background: #f3f5f9 !important;
    }
  }
  .dispute-actions {
    margin-top: 10px;
    padding: 24px;
    text-align: right;
    > button:first-child {
      margin-right: 15px;
    }
    border-top: 1px solid #e6e9ef;
  }
}
