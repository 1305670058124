@import 'variables';

.sxd-progress-campaign {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .bar {
    flex: 1;
    background: #e7ecf4;
    border-radius: 4px;
    height: 8px;
    position: relative;
    overflow: hidden;
    > .step {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4px;
      height: 8px;
      min-width: 4px !important;
    }
  }
  .percent {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
  }
}
