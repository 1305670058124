#wizard-benefits {
  margin-bottom: 24px;
  .benefits {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }
  .sx-bullet {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .bullet-and-input {
      display: flex;
      flex: 8;
      span {
        margin-right: 8px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }
      .seriph-input {
        width: 100%;
      }
      input {
        font-size: 14px;
        line-height: 40px;
        padding: 0 8px;
        color: #090e15;
        border: 1px solid transparent;
        background: transparent;
        height: 40px;
        &:focus,
        &:hover {
          background: #f5f7fb;
        }
        width: 100%;
      }
    }
  }
  .add-benefit {
    margin-bottom: 32px;
    button {
      width: 100%;
    }
  }
  .remove-option {
    margin-left: 12px;
    cursor: pointer;
    svg {
      font-size: 13px;
    }
    .fa-trash-alt {
    }
    &:hover {
      svg {
        color: #0b1e41;
      }
    }
  }
  input.invalid {
    border: 1px solid #ef1e51 !important;
  }
}

#wizard-objections {
  .add-objection {
    margin-bottom: 32px;
    margin-top: 24px;
    button {
      width: 100%;
    }
  }
  .object {
    margin-bottom: 16px;
    box-shadow: 0px 0px 2px rgba(11, 30, 65, 0.09), 0px 2px 12px rgba(11, 30, 65, 0.06);
  }
  .object-info {
    margin-left: -8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .seriph-input {
      width: 100%;
    }
    input {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 8px;
      color: #090e15;
      border: 1px solid transparent;
      background: transparent;
      height: 40px;
      &:focus,
      &:hover {
        background: #f5f7fb;
      }
    }
    .remove-option svg {
      font-size: 16px;
    }
  }
  input.invalid,
  textarea.invalid {
    border: 1px solid #ef1e51 !important;
  }
  .sx-bullet {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .bullet-and-input {
      display: flex;
      flex: 8;
      span {
        margin-right: 8px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }
      .seriph-input {
        width: 100%;
      }
      input {
        font-size: 14px;
        line-height: 40px;
        padding: 0 8px;
        color: #090e15;
        border: 1px solid transparent;
        background: transparent;
        height: 40px;
        &:focus,
        &:hover {
          background: #f5f7fb;
        }
        width: 100%;
      }
    }
  }
  .remove-option {
    margin-left: 12px;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    svg {
      font-size: 13px;
    }
    .fa-trash-alt {
    }
    &:hover {
      svg {
        color: #0b1e41;
      }
    }
  }
  .add-option {
    margin-top: 24px;
    > div {
      cursor: pointer;
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #4353ff;
      padding: 4px 4px;
      border: 0;
      background: transparent !important;
      &:hover {
        color: #2333df;
      }
    }
  }
}
