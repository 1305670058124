@import 'variables';

#dialer-objections {
  min-width: 284px;
  min-height: 0;
  .lead-object {
    margin-bottom: 32px;
    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      margin-bottom: 16px;
    }
    .lead-resp {
      margin-bottom: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      > span:first-child {
        margin-right: 8px;
      }
    }
  }
}
