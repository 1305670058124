@import '_seriph/variables';

#call-bar {
  height: 104px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  z-index: 999;

  .bf {
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .bf-actions {
      text-align: right;
      display: flex;
      flex-direction: row;
      align-items: center;
      .real-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        button {
          margin-right: 8px;
        }
        button.answer {
          svg {
            color: #009432;
          }
        }
        button.no-answer {
          svg {
            color: #EA2027;
          }
        }
        button.voicemail {
          svg {
            color: #929497;
            width: 16px;
            height: 16px;
          }
        }
        div.transition {
          cursor: default;
          width: auto;
          height: 40px;
          line-height: 40px;
          border-radius: 10px;
          padding: 0 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #fff;
          opacity: 1 !important;
          position: relative;
          background: #090e15;
          background: linear-gradient(to left, #090e15 50%, #929aaa 50%) right;
          background-size: 200%;
          > svg {
            margin-left: 8px;
            width: 14px;
            height: 14px;
            border-width: 2px;
          }
          animation: 5000ms linear 0s 1 buttonSlideIn;
          animation-fill-mode: forwards;
        }
      }
    }
    .bf-lead {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .dialing {
    height: 12px;
    width: 100%;
    background: repeating-linear-gradient(
      to right,
      #acaadb 0%,
      #e6f3fc 33%,
      #e6f3fc 66%,
      #acaadb 100%
    );
    box-shadow: none !important;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: DialColorBar 1.5s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    &.ready,
    &.ended,
    &.transition,
    &.paused,
    &.loading {
      background: #e5e7eb !important;
    }
    &.in-progress {
      background: #c1ddbb !important;
    }
  }

}


@keyframes DialColorBar {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes buttonSlideIn {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
