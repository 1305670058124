@import 'variables';

.sequence-call-modal {
  .ant-modal-body {
    padding: 0;
    height: 100%;
    padding-bottom: 121px;
  }
  .ant-modal {
    padding: 56px;
    width: 100% !important;
    max-width: 1324px !important;
    height: 100% !important;
    .ant-modal-content {
      border-radius: 16px;
      overflow: hidden;
      height: 100% !important;
    }
  }
  .seq-header {
    padding: 24px;
    border-bottom: 1px solid #e7ecf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    > svg {
      margin-right: 16px;
      width: 32px !important;
      height: 32px !important;
    }
    h4 {
      font-family: $font-tt;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $blue-800;
      margin: 0;
    }
  }
  .call-h3 {
    font-family: $font-tt;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $blue-800;
    margin-bottom: 32px;
  }
  .seq-body {
    min-height: 220px;
    display: flex;
    flex-direction: row;
    height: 100%;
    .seq-menu {
      width: 360px;
      background: #f5f7fb;
      padding: 56px 40px;
      .menu-title {
        padding: 12px 0;
        font-family: $font-tt;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $blue-800;
        > span {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background: transparent;
          margin-right: 8px;
        }
      }
      .menu-item {
        padding: 16px 16px 16px 24px;
        font-family: $font-tt;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $neutral-700;
        cursor: pointer;
        border-radius: 12px;
        position: relative;
        svg {
          display: none;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
        }
        &:hover,
        &.active {
          background: #ffffff;
          color: #4353ff;
          box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
          svg {
            display: block;
          }
        }
      }
      .menu-sep {
        height: 1px;
        width: 100%;
        background: #e7ecf4;
        margin: 24px 0 32px 0;
      }
    }
    .seq-content {
      flex: 1;
      height: 100%;
      overflow-y: scroll;
      padding: 80px 40px 0 40px;
      position: relative;
      .seq-text {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .seq-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 32px;
    border-top: 1px solid #e7ecf4;
    text-align: right;
    button {
      margin-left: 12px;
    }
  }
  .options {
    position: absolute;
    top: 80px;
    right: 20px;
    z-index: 9;
    > div {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      background: #ffffff;
      border: 1px solid #e7ecf4;
      border-radius: 12px;
      color: #090e15;
      cursor: pointer;
      font-size: 14px;
      &:hover,
      &.active {
        background: #e5e7eb;
      }
    }
  }
  .close-options {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 9;
    > div {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      border-radius: 12px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f5f7fb;
      color: #090e15;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        background: #e5e7eb;
      }
    }
  }
  .seq-text.sm {
    .call-script > div p,
    .lead-benefit > span,
    .lead-resp > span {
      font-size: 14px !important;
      line-height: 21px !important;
    }
    .object-info {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }
  .seq-text.md {
    .call-script > div p,
    .lead-benefit > span,
    .lead-resp > span {
      font-size: 16px !important;
      line-height: 24px !important;
    }
    .object-info {
      font-size: 18px !important;
      line-height: 27px !important;
    }
  }
  .seq-text.lg {
    .call-script > div p,
    .lead-benefit > span,
    .lead-resp > span {
      font-size: 18px !important;
      line-height: 27px !important;
    }
    .object-info {
      font-size: 20px !important;
      line-height: 30px !important;
    }
  }
  .seq-text.xl {
    .call-script > div p,
    .lead-benefit > span,
    .lead-resp > span {
      font-size: 20px !important;
      line-height: 30px !important;
    }
    .object-info {
      font-size: 22px !important;
      line-height: 33px !important;
    }
  }
}

#note-input {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
  border: 1px solid #e7ecf4;
  border-radius: 12px;
  overflow: hidden;
  padding: 0;
  position: relative;
  left: -48px;
  top: -44px;
  min-width: 350px;
  .input-content {
    position: relative;
    padding: 12px 17px;
    .quill {
      display: flex;
      flex-direction: column-reverse;
      .ql-toolbar {
        border: 0;
      }
      .ql-container {
        border: 0;
      }
      .ql-editor {
        min-height: 80px;
      }
      .ql-editor p {
        color: #090e15;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
      }
      .ql-editor.ql-blank::before {
        color: #77839b;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .btnx {
      position: absolute;
      right: 24px;
      bottom: 16px;
    }
  }
}

#font-options {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
  border: 1px solid #e7ecf4;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  left: -48px;
  top: -44px;
  min-width: 240px;
  > div {
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
    padding: 8px;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    svg {
      margin-right: 10px;
    }
    &:hover,
    &.active {
      background: #f5f7fb;
      color: #4353ff;
    }
  }
}
