@import '_seriph/variables';

#lead-notes {
  position: relative;
  min-height: 100%;
  .note-input {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 8px;
    .input-content {
      position: relative;
      background: #ffffff;
      border: 1px solid #e7ecf4;
      box-sizing: border-box;
      border-radius: 12px;
      padding: 12px;
      .quill {
        display: flex;
        flex-direction: column-reverse;
        .ql-editor {
          min-height: 80px;
        }
        .ql-toolbar {
          border: 0;
        }
        .ql-container {
          border: 0;
        }
        .ql-editor p {
          color: #090e15;
        }
        .ql-editor.ql-blank::before {
          color: #707b90;
          font-style: normal;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .btn-primary {
        position: absolute;
        right: 24px;
        bottom: 16px;
      }
    }
  }
  .sxd-alert {
    margin-top: 32px;
  }
  .note-content {
    .note-date {
      text-align: center;
      border-bottom: 1px solid #e7ecf4;
      margin-bottom: 32px;
      position: relative;
      > span {
        background: #f5f7fb;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        position: relative;
        top: 12px;
        padding: 0 16px;
      }
    }
  }
  .lead-note {
    margin-bottom: 16px;
    padding: 24px;
    .note-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      .note-user {
        flex: 1;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
      }
      > span {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        color: #090e15;
      }
    }
    .note-body {
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        white-space: pre-line;
        margin: 0;
      }
    }
  }
}
