@import '_seriph/variables';

#company-agents {
  width: 100%;
  padding: 32px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .rep-header {
    margin: 24px 0 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    .invite-rep {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      .seats {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        margin-right: 16px;
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        svg {
          width: 16px;
          height: 16px;
          color: #4d5b76;
          margin-right: 8px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
  .ant-menu {
    border-bottom: 1px solid #e7ecf4;
    background: transparent;
    margin-top: 0;
    line-height: 32px;
    .ant-menu-item {
      font-size: 16px;
      font-family: $font-minor;
      font-weight: 600;
      line-height: 40px;
      color: #707b90;
      border-bottom: 3px solid transparent;
      line-height: 32px;
      padding-left: 0;
      padding-right: 0;
      margin-right: 32px;
      padding-bottom: 8px;
      svg {
        margin-right: 8px;
      }
      svg.fa-circle-notch {
        margin-right: 0;
        font-size: 12px;
        position: relative;
        top: -1px;
      }
      position: relative;

      .applications-menu-item {
        display: flex;
        align-items: center;
        gap: 12px;
        .rep-alerting {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #ee3a3a;
          transition: all 0.3s ease;
          > span {
            font-weight: bold;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #ffffff;
            padding: 4px 5px;
          }
        }
      }
    }
    .status-ball {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: inline-block;
      background-color: #dce0e8;
      margin-right: 8px;
      &.status-inactive {
        background-color: #ffc900;
      }
      &.status-active {
        background-color: #00b533;
      }
    }
    .ant-menu-item-selected,
    .ant-menu-item-active {
      border-bottom: 3px solid #090e15;
      color: #090e15;
    }
  }

  .app-alert {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: rgb(245, 34, 45);
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
  .rep-type {
    display: flex;

    .internal-label,
    .xdr-label,
    .removed-label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 48px;
      color: #090e15;

      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      height: 24px;
      border: 2px solid #d7dde9;
      padding: 5px 8px 3px;
    }

    .internal-label {
      border: 2px solid #d7dde9;
      background: #e8e7fd;
    }
    .removed-label {
      background: #EFBBA9;
    }
    .xdr-label {
      background: #ffffff;
      border: 2px solid #e8e7fd;
    }
  }
}
