@import '_seriph/variables';

#sl-campaigns {
  padding: 0 32px;
  margin-bottom: 32px;
  .select-title {
    margin: 12px;
    height:32px;
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #242D3E;
  }
  .slc-card {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      border-color: #C4CBD9;
    }
    .slc-info {
      margin-left: 16px;
      flex: 1;
      > h3 {
        margin: 0;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090E15;
      }
      > p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #707B90;
      }
    }
  }
}