@import '_seriph/variables';

.seriph-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: none;
  padding: 32px;
  width: 100%;
  overflow: hidden;
  position: relative;

  &.card-medium {
    box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
  }

  &.card-light {
    box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
  }

  &.card-bold {
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
  }
}
