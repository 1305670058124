@import '_seriph/variables';

#company-sequences-touchpoints {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  padding: 24px 12px;
  .touchpoints-title {
    font-family: $font-major;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    padding: 0 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    > span {
      flex: 1;
    }
    button {
      svg {
        stroke-width: 2;
      }
    }
  }
  .ant-collapse > .ant-collapse-item:last-child {
    border-top: 1px solid #d7dce4;
    padding-top: 8px;
    margin-top: 8px;
  }
  .touchpoint-list {
    .touchpoint {
      display: flex;
      align-items: center;
      padding: 4px 12px;
      border-radius: 8px;
      cursor: pointer;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      color: #090e15;
      > span {
        flex: 1;
      }
      span.icn {
        display: flex;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
          stroke-width: 2;
          position: relative;
          top: -1px;
          margin-right: 8px;
        }
      }
      .preview {
        display: none;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background: transparent;
        border-radius: 10px;
        svg {
          color: #090e15;
          width: 16px;
          height: 16px;
        }
      }
      &:hover {
        background: #f5f7fb;
        .preview {
          display: flex;
        }
      }
    }
  }
}
