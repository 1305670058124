@import '_seriph/variables';

.invite-rep-member-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    position: relative;
    padding: 32px;
  }
  .itm-title {
    h3 {
      font-family: $font-major;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #090e15;
    }
    p {
      font-family: $font-major;
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #707b90;
    }
  }
  .role-info {
    margin: 0;
    display: block;
    span {
      margin: 0;
      display: block;
    }
  }
  .itm-form {
    margin-top: 40px;
    margin-bottom: 32px;
    display: flex;
    gap: 8px;
    .seriph-input {
      flex: 1;
    }
    #invite-dropdown-menu {
      white-space: nowrap;
      cursor: pointer;
      border-radius: 10px;
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 140px;
      gap: 4px;
    }
  }
  .campaign-list {
    .filter-options {
      padding: 8px;
      height: 280px;
      overflow-y: scroll;
    }
  }
  .itm-actions {
    margin-top: 40px;
    text-align: right;
    position: relative;
    button {
      margin-left: 12px;
    }
  }
}

.team-rep .rep-box {
  .seriph-avatar {
    margin-right: 12px;
  }
  > span {
    font-family: $font-major;
    font-size: 14px;
    font-weight: 500;
  }
}
