@import '_seriph/variables';

button.seriph-btn {
  font-family: $font-major;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #090e15;
  padding: 9px 16px;
  height: 40px;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #d7dde9;
  border-radius: 10px;
  &:hover {
    color: #090e15;
    background: #f5f7fb;
    border: 1px solid #d7dde9;
  }
  &:focus {
    color: #090e15;
    background: #e7ecf4;
    border: 1px solid #e7ecf4;
  }
  &[disabled] {
    opacity: 1 !important;
    color: #adb5c5 !important;
    background: #e7ecf4 !important;
    border: 1px solid #e7ecf4 !important;
  }
}

/* HANDLE TYPES */
button.seriph-btn {
  &.btn-primary {
    background: #090e15;
    border: 1px solid #090e15;
    border-radius: 10px;
    color: #fff;
    &:hover {
      color: #fff;
      background: #242d3e;
      border: 1px solid #242d3e;
    }
    &:focus {
      color: #fff;
      background: #4d5b76;
      border: 1px solid #4d5b76;
    }
    &[disabled] {
      opacity: 0.2 !important;
      color: #fff !important;
      background: #090e15 !important;
      border: 1px solid #090e15 !important;
    }
  }
  &.btn-secondary {
    background: #e7ecf4;
    border: 1px solid #e7ecf4;
    border-radius: 10px;
    color: #090e15;
    &:hover {
      color: #090e15;
      background: #d7dde9;
      border: 1px solid #d7dde9;
    }
    &:focus {
      color: #090e15;
      background: #c4cbd9;
      border: 1px solid #c4cbd9;
    }
    &[disabled] {
      opacity: 1 !important;
      color: #adb5c5 !important;
      background: #f5f7fb !important;
      border: 1px solid #f5f7fb !important;
    }
  }
  &.btn-plain {
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 10px;
    color: #090e15;
    &:hover {
      color: #090e15;
      background: #f5f7fb;
      border: 1px solid #f5f7fb;
    }
    &:focus {
      color: #090e15;
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
    }
    &[disabled] {
      opacity: 1 !important;
      color: #adb5c5 !important;
      background: #ffffff !important;
      border: 1px solid #ffffff !important;
    }
  }
  &.btn-danger {
    background: #fbece7;
    border: 1px solid #fbece7;
    border-radius: 10px;
    color: #b34043;
    &:hover {
      color: #b34043;
      background: #ebdcd7;
      border: 1px solid #ebdcd7;
    }
    &:focus {
      color: #b34043;
      background: #ebdcd7;
      border: 1px solid #ebdcd7;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #b34043 !important;
      background: #fbece7 !important;
      border: 1px solid #fbece7 !important;
    }
  }
  &.btn-warning {
    background: #FDFAEF;
    border: 1px solid #EFDFC4;
    border-radius: 10px;
    color: #C8803E;
    svg {
      color: #C8803E;
    }
    &:hover {
      color: #C8803E;
      background: #EFDFC4;
      border: 1px solid #EFDFC4;
    }
    &:focus {
      color: #C8803E;
      background: #EFDFC4;
      border: 1px solid #EFDFC4;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #C8803E !important;
      background: #FDFAEF !important;
      border: 1px solid #FDFAEF !important;
    }
  }
  &.btn-success {
    background: #EEF9EC;
    border: 1px solid #C1DDBB;
    border-radius: 10px;
    color: #577E4E;
    svg {
      color: #577E4E;
    }
    &:hover {
      color: #577E4E;
      background: #C1DDBB;
      border: 1px solid #C1DDBB;
    }
    &:focus {
      color: #577E4E;
      background: #C1DDBB;
      border: 1px solid #C1DDBB;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #577E4E !important;
      background: #EEF9EC !important;
      border: 1px solid #EEF9EC !important;
    }
  }
  &.btn-info {
    background: #d2d0fb;
    border: 1px solid #d2d0fb;
    border-radius: 10px;
    color: #3e3993;
    &:hover {
      color: #3e3993;
      background: #e8e7fd;
      border: 1px solid #e8e7fd;
    }
    &:focus {
      color: #3e3993;
      background: #e8e7fd;
      border: 1px solid #e8e7fd;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #3e3993 !important;
      background: #fbece7 !important;
      border: 1px solid #fbece7 !important;
    }
  }
  &.btn-transparent {
    background: transparent;
    border-color: transparent;
    border-radius: 10px;
    color: #090e15;
    &:hover {
      color: #090e15;
      background: #e7ecf4;
    }
  }
}

/* HANDLE SIZE */
button.seriph-btn {
  &.btn-tiny {
    font-size: 12px;
    line-height: 18px;
    padding: 7px 12px;
    height: 32px;
    border-radius: 8px;
  }
  &.btn-micro {
    font-size: 12px;
    line-height: 14px;
    padding: 4px 10px;
    height: 24px;
    border-radius: 8px;
  }
  &.btn-medium {
    font-size: 14px;
    line-height: 21px;
    padding: 13px 24px;
    height: 48px;
    border-radius: 10px;
  }
  &.btn-large {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 32px;
    height: 56px;
    border-radius: 12px;
  }
}

/* HANDLE CIRCLES */
button.seriph-btn {
  &.btn-circle {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
      stroke-width: 1.5;
    }
  }
  &.btn-circle.btn-tiny {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    border-radius: 16px;
    svg {
      width: 14px;
      height: 14px;
      stroke-width: 1.5;
    }
  }
}

/* HANDLE ICONS */
button.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.icon-left svg {
    margin-right: 8px;
  }
  &.icon-right svg {
    margin-left: 8px;
  }
  svg {
    width: 14px;
    height: 14px;
    border-width: 2px;
  }
  &.btn-tiny svg {
    width: 12px;
    height: 12px;
    border-width: 2px;
  }
  &.btn-large svg {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }
}
