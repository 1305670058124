.message-agent-modal {
  .ma-agent {
    display: flex;
    flex-direction: row;
    width: 100%;
    img {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      margin-right: 24px;
    }
    .ma-details {
      flex: 1;
      h3 {
        color: #0b1e41;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        .app-applied {
          font-size: 14px;
          font-weight: 400;
          color: #999;
          display: inline-block;
          margin-left: 10px;
          font-style: italic;
          position: relative;
          top: -1px;
        }
      }
      p {
        color: #4d5b76;
        font-size: 14px;
        line-height: 21px;
      }
      .sellx-tag {
        font-size: 12px !important;
      }
    }
  }
  .invite-info {
    color: #0b1e41;
    margin-top: 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    b {
      font-weight: 600;
    }
  }
  .ma-message {
    margin-top: 30px;
    h3 {
      font-size: 13px;
      font-weight: 500;
      color: #0b1e41;
      margin-top: 30px;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #999;
        font-style: italic;
        margin-left: 10px;
      }
    }
    input {
      margin-bottom: 20px;
    }
    textarea,
    input {
      background: #f3f5f9;
      border: 0;
      outline: 0;
      box-shadow: none;
      color: #0b1e41;
      padding: 11px 11px;
    }
  }
  .ma-actions {
    margin-top: 30px;
    text-align: right;
    .btn-default {
      margin-right: 15px;
    }
    .btn-primary {
      flex: 1;
    }
  }
}
