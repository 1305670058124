@import '_seriph/variables';

.emailer-modal {
  .ant-modal {
    max-width: 1200px;
  }
  .ant-modal-content {
    border-radius: 16px;
    overflow: auto !important;
    .ant-modal-close {
      background: #f5f7fb;
      border-radius: 50%;
      right: 16px;
      top: 16px;
      .ant-modal-close-x {
        height: 32px;
        width: 32px;
        font-size: 14px;
        line-height: 32px;
        color: #090e15;
      }
    }
    .ant-modal-body {
      position: relative;
      background-color: #fff;
      border-radius: 16px;
      min-height: 200px;
      padding: 0;
      font-family: $font-major;
      .emailer-body {
        padding: 0;
        border-radius: 12px;
        border: 1px solid transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 90vh;
        min-width: 400px;

        .emailer-left {
          padding: 0px 24px;
          display: flex;
          flex-direction: column;
          flex: 0 0 300px;

          border-right: 1px solid #e7ecf4;

          .emailer-list-header {
            display: flex;
            align-items: center;
            padding: 28px 8px;

            .emailer-list-header-title {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: #000000;
              margin: 0 10px 0 0;
            }
            .emailer-list-header-description {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #707b90;
            }
          }

          .emailer-list {
            display: flex;
            height: 100%;
            overflow: hidden;
            flex-direction: column;
            &:hover {
              overflow: scroll;
            }
            &::-webkit-scrollbar-track {
              background-color: none;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #ADB5C5;
            }

            .emailer-list-item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              padding: 16px;
              height: 74px;
              border-radius: 8px;

              border: 1px solid #fff;
              cursor: pointer;

              &.selected {
                border: 1px solid #d7dde9;
                background: #f5f7fb;
              }

              &:hover {
                background: #e7ecf4;
              }

              &.hiding {
                transition: all 1s;
                visibility: hidden;
                opacity: 0;
              }

              .emailer-list-item-left {
                display: flex;
                flex-direction: column;

                .lead-name {
                  color: #000000;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                }
                .lead-email {
                  font-family: $font-minor;

                  color: #707b90;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 16px;
                }
              }

              .icon-container {
                background: #090e15;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                svg {
                  color: #fff;
                  width: 11px;
                }
              }
            }

            .emailer-list-item-spacer {
              height: 1px;
              background-color: #e7ecf4;
              margin: 0px 8px;
            }
          }
        }
        .emailer-main {
          flex: 1;
          padding: 32px;
          .none-left {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
          .emailer-main-cont {
            display: flex;
            flex-direction: column;
            height: 100%;
            .subject {
              font-family: $font-major;
              font-weight: 500;
              display: flex;
              justify-content: stretch;
              align-items: center;
              gap: 8px;
              padding: 16px;
              border: 1px solid #e7ecf4;
              border-bottom: none;
              border-radius: 8px 8px 0px 0px;

              .hidden-toolbar {
                display: none;
              }
              .subject-label {
                font-size: 12px;
                line-height: 18px;
                color: #707b90;
                flex: 0 0 50px;
              }
              .quill {
                flex: 1;
                .ql-container {
                  font-size: 14px;
                  line-height: 21px;
                  color: #000000;
                  .ql-editor {
                    overflow: hidden;
                    padding: 4px;
                    font-family: $font-major;
                    height: 28px;
                  }
                }
              }
            }

            .main-toolbar-container {
              display: flex;
              justify-content: space-between;
              border: 1px solid #e7ecf4;
              align-items: center;
              padding: 12px;
              height: 64px;

              .main-toolbar {
                border: none;
                display: flex;
                .emailer-lb {
                  border-left: 1px solid #e7ecf4;
                }
                button {
                  margin: 0 6px;
                }
              }
            }

            .emailer-main-form {
              position: relative;
              flex: 1;
              border: 1px solid #e7ecf4;
              border-top: none;
              border-bottom: none;
              .ql-container {
                border: none;
                .ql-editor {
                  color: #090e15;
                  font-family: Inter;
                  font-size: 14px;
                  line-height: 24px;
                  p {
                    margin-bottom: 0;
                  }
                }
              }
              .expand-button {
                position: absolute;
                left: 24px;
                cursor: pointer;
                border-radius: 100px;
                border: 1px solid #d7dde9;
                padding: 0px 12px;
                font-size: 18px;
                color: #090e15;
              }
              .signature-container {
                padding: 0px 32px;
                margin-top: -24px;
              }
            }
          }

          .emailer-main-problems {
            border: 1px solid #e7ecf4;
            border-top: none;
            border-radius: 0px 0px 8px 8px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .problem-none,
            .problem-warning,
            .problem-error {
              display: flex;
              border-radius: 8px;
              justify-content: stretch;
              align-items: center;
              padding: 10px 8px;

              svg {
                flex: 0 0 20px;
                margin-left: 2px;
                margin-right: 15px;
              }
              .problem-right {
                color: #000000;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
              }
            }
            .problem-none {
              background: #eef9ec;
              border: 1px solid #c1ddbb;
              svg {
                color: #577e4e;
              }
            }
            .problem-warning {
              border: 1px solid #efdfc4;
              background: #fdfaef;
              svg {
                color: #c8803e;
              }
            }
            .problem-error {
              border: 1px solid #efbba9;
              background: #fbece7;
              svg {
                color: #b34043;
              }
            }
          }
          .emailer-main-footer {
            padding: 12px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .emailer-show-with {
              margin-right: 6px;
              .ant-switch {
                background: #d7dde9;
              }
              .ant-switch-checked {
                background: $dark-gray;
              }
              .real-label {
                display: inline-block;
                margin-left: 12px;

                font-family: $font-major;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #090e15;
              }
            }
          }
        }
        .emailer-right {
          flex: 0 0 232px;
          padding: 32px 48px 0px 0px; 

          .emailer-company {
            padding: 32px 0;
            img {
              width: 48px;
              height: 48px;
            }
            .emailer-company-name {
              margin: 16px 0;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #090e15;
            }
          }

          .emailer-summary {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .emailer-summary-header {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #707b90;
              border-top: 1px solid #e7ecf4;
              padding-top: 24px;
            }

            .emailer-bullets {
              list-style-type: none;

              border-bottom: 1px solid #e7ecf4;
              padding: 0px 0px 24px;
              li {
                margin: 8px 0;
                display: flex;
                align-items: center;

                svg {
                  height: 16px;
                  width: 16px;
                  margin-right: 12px;
                  flex: 0 0 16px;
                  color: #090e15;
                }
                .seriph-avatar {
                  position: relative;
                  left: -1px;
                  margin-right: 10px;
                }
                div {
                  font-family: $font-major;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 21px;
                  color: #090e15;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }

          .emailer-sending {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .emailer-sending-label {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #707b90;
            }
            .emailer-sending-to,
            .emailer-sending-from {
              border: 1px solid #d2d0fb;
              border-radius: 4px;
              padding: 2px 8px;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #000000;
              margin-bottom: 16px;
            }
            .emailer-sending-to {
              background: #e8e7fd;
            }
            .emailer-sending-from {
              background: #e6f3fc;
            }
          }
        }
      }
    }
  }

  .ql-container {
    border: none;
    .ql-editor {
      padding: 24px;
      s {
        text-decoration: none;
        background: #d2d0fb;
        padding: 5px 3px;
        font-weight: 600;
      }
      sup {
        background: #efbba9;
        padding: 5px;
        top: 0;
        font-size: 100%;
        vertical-align: middle;
      }
      sub {
        background: #e8d3b9;
        padding: 5px;
        top: 0;
        font-size: 100%;
        vertical-align: middle;
      } 
    }
  }
}

.emailer-modal-none-left {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-close {
      background-color: #fff;
      border-radius: 50%;
      right: 16px;
      top: 16px;
      .ant-modal-close-x {
        height: 32px;
        width: 32px;
        font-size: 14px;
        line-height: 32px;
        color: #090e15;
      }
    }
    .ant-modal-body {
      position: relative;
      background-color: #fff;
      border-radius: 16px;
      min-height: 200px;
      padding: 0;
      font-family: $font-major;
      .none-left-body {
        padding: 32px;
        border-radius: 12px;
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .none-left-icon {
          width: 56px;
          height: 56px;

          background: #ffffff;
          border: 1px solid #e7ecf4;

          box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 32px;
            height: 32px;
            stroke-width: 1.5px;
            color: #090e15;
          }
          margin-bottom: 25px;
        }

        .none-left-title {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: #090e15;
          margin-bottom: 15px;
        }

        .none-left-subtitle {
          font-family: $font-minor;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          margin-bottom: 25px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
