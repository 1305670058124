@import '_seriph/variables';

.seriph-table {
  .ant-table-body {
    background: #fff;
  }

  &.table-rounded {
    .ant-table-body {
      border-radius: 12px;
      overflow: hidden;
    }
    .ant-table-fixed-right {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      overflow: hidden;
      z-index: 900;
    }
    .ant-table-fixed-left {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      overflow: hidden;
      z-index: 900;
    }
  }

  .ant-table-thead > tr > th {
    background: #fff;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #707b90;
    padding: 12px 16px;
    border-color: #e7ecf4;
  }

  .ant-table-tbody > tr > td {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    border-bottom: 0;
  }

  .ant-table-column-sorter {
    position: relative;
    top: -3px;
  }

  tr:hover td,
  tr.ant-table-row-hover td {
    background: #fff !important;
  }

  &.table-striped tr:nth-child(2n) td {
    background: #FAFBFD;
  }

  &.table-lined {
    tr {
      &.ant-table-expanded-row {
        > td,
        > th {
          background: #fff;
          padding-top: 0px;
        }
      }
      &:not(.ant-table-expanded-row) {
        > td,
        > th {
          border-top: 1px solid #e7ecf4;
        }
      }
    }
  }

  &.table-lined tbody > tr:first-child > td {
    border-top: 0 !important;
  }

  /* PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 24px 0 0 0;
    > li {
      border-radius: 12px;
      border-color: transparent;
    }
    > li,
    > li a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: none !important;
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      background: transparent;
    }
    li > a i svg {
      color: #090e15 !important;
    }
    .ant-pagination-disabled,
    .ant-pagination-disabled a {
      color: #adb5c5 !important;
      background: transparent !important;
      cursor: not-allowed !important;
      i svg {
        color: #adb5c5 !important;
      }
    }
    li:hover,
    li:hover a {
      background: #e7ecf4;
      color: #090e15;
    }
    .ant-pagination-item-active,
    .ant-pagination-item-active a {
      color: #fff !important;
      background: #090e15 !important;
    }
    .ant-pagination-prev a,
    .ant-pagination-next a {
      border-color: transparent;
    }
  }

  .ant-spin .ant-spin-dot-item {
    background-color: #090e15;
  }
}

.seriph-table-wrapper {
  position: relative;
  .page-info,
  .custom-info {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #929aaa;
    height: 32px;
    line-height: 32px;
    white-space: nowrap;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
