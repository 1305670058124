@import '_seriph/variables';

#agent-settings-reputation {
  position: relative;
  width: 100%;
  padding: 0;
  hr {
    height: 1px;
    border: none;
    background-color: #e7ecf4;
    margin: 30px 0px 0px 0px;
  }
  .table-header {
    font-family: $font-major;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin: 25px 0px 25px 0px;
  }
  .small-font {
    font-size: 14px;
    font-family: $font-minor;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    color: #090e15;
  }
  .sub-header {
    font-family: $font-minor;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #4d5b76;
  }
  .align-right {
    text-align: right;
    margin-bottom: 25px;
  }
  .reputation-header {
    font-family: $font-major;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
  }
  .point-section {
    .badge-row {
      float: left;
      margin: 0px 25px 0px 0px;
    }
  }
  .badge {
    &.tiny-badge {
      font-size: 10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    position: relative;
    text-align: center;
    .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .settings-card-header {
    display: flex;
    flex-direction: row;
    > div {
      flex-grow: 0;
      flex-basis: 50%;
    }
  }

  .gradient-min-max {
    display: flex;
    flex-direction: row;
    color: #000000;
    margin: 5px 0px 0px 0px;
    > div {
      flex-grow: 0;
      flex-basis: 50%;
      &:last-child {
        text-align: right;
      }
    }
  }
  .gradient-row {
    .badge-row {
      float: right;
    }
  }
  .ant-pagination {
    display: none;
  }
  .earner-gradient {
    clear: both;
    background: #e7ecf4;
    border-radius: 24px;
    height: 12px;
    .fill {
      border-radius: 24px;
      height: 12px;
      &.fill1 {
        background: linear-gradient(90deg, #f7dd52 0%, #7bea69 147.65%);
      }
      &.fill2 {
        background: linear-gradient(90deg, #7bea69 0%, #00d5bf 147.65%);
      }
      &.fill3 {
        background: linear-gradient(90deg, #00d5bf 0%, #43e8ff 147.65%);
      }
      &.fill4 {
        background: linear-gradient(90deg, #43e8ff 0%, #4353ff 147.65%);
      }
      &.fill5 {
        background: linear-gradient(90deg, #4353ff 0%, #090e15 147.65%);
      }
      &.fill6 {
        background: linear-gradient(90deg, #000000 0%, #090e15 147.65%);
      }
    }
  }
}
