@import '_seriph/variables';

.overview-requirements {
  padding: 32px 0;
  > h3 {
    font-family: $font-major;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #090e15;
    margin: 0 0 12px 0;
  }
}
