@import '_seriph/variables';

#onboarding.invitation {
  .home-form {
    margin-top: 32px;
  }
  .member-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    > .seriph-input:first-child {
      margin-right: 12px;
    }
  }
  .joining {
    margin-bottom: 16px;
    .join-box {
      display: inline-block;
      background: #27292a;
      padding: 8px 16px;
      border-radius: 60px;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #fff;
      b {
        font-weight: 500;
      }
    }
    .seriph-avatar {
      margin-right: 12px;
    }
  }
  .sx-email {
    position: relative;
    input {
      padding-right: 100px;
    }
    .ending {
      position: absolute;
      right: 15px;
      top: 47px;
      line-height: 20px;
      color: #e5efe7;
      font-weight: 500;
      font-size: 16px;
      font-family: $font-major;
    }
  }
  .error-text {
    color: #ee3a3a !important;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
