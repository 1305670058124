@import '_seriph/variables';

.seriph-tab-menu {
  .ant-tabs-bar {
    margin: 0;
    border-bottom: 1px solid #E7ECF4;
  }
  .ant-tabs-nav {
    margin-left: 16px;
    .ant-tabs-tab {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #707B90;
      padding-top: 21px;
      padding-bottom: 20px;
      margin-right: 8px;
      user-select: none;
      &:hover, &.ant-tabs-tab-active {
        color: #090E15;
      }
    }
  }
  .ant-tabs-ink-bar {
    background-color: #090E15;
  }
}
