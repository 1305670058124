@import '_seriph/variables';

.add-lead-tag {
  background: $unknown;
  border-radius: 100px;
  padding: 0px 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $dark-gray;
  margin-left: 12px;
  cursor: pointer;
  &:hover {
    background: $neutral-300;
  }
}

.lead-tags {
  > div {
    padding: 3px 10px;
  }
  .nurture-tag {
    background: $sage-100;
    border: 2px solid $sage-accent;
  }
  .follow-tag {
    background: 2px solid $butterfly-latte;
    border: 2px solid $butterfly-100;
  }
  .pause-tag {
    border: 2px solid $cream-500;
    background: $cream-primary;
  }
  .manual-tag {
    border: 2px solid $email;
    background: $ube-100;
  }
}

.lead-tag-modal {
  .ant-modal-body {
    padding: 24px !important;
  }
  .seriph-modal-close {
    position: initial;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      flex-grow: 1;
      text-align: center;
      color: $dark-gray;
      font-family: $font-major;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .lead-tag-body {
    padding: 32px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .ant-calendar-picker {
      width: 100%;
    }
  }
  .lead-tag-footer {
    display: flex;
    justify-content: space-between;
    button {
      margin-left: 8px;
    }
  }
}
.lead-card {
  .bottom-row {
    .lead-stage {
      &.nurture {
        background: $sage-100;
        border: 2px solid $sage-accent !important;
        border-color: $sage-accent !important;
      }
      &.follow {
        background: $butterfly-100;
        border: 2px solid $butterfly-latte !important;
        border-color: $butterfly-latte !important;
      }
      &.pause {
        border: 2px solid $cream-500;
        background: $cream-primary !important;
        border-color: $cream-500 !important;
      }
      &.manual {
        border: 2px solid $email;
        background: $ube-100 !important;
        border-color: $email !important;
      }
    }
  }
}

// lead-tags
.lead-tags {
  padding: 0px;
  margin: 0px;
  &.ant-select-dropdown {
    li {
      &:hover {
        background: $unknown;
      }
    }
  }
  li {
    font-family: $font-major;
    padding: 8px 12px 8px 8px;
    list-style-type: none;
    display: flex;
    > div {
      display: inline;
      padding: 2px 10px;
      border-radius: 48px;

      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
    }
  }
  .nurture-tag {
    background: $sage-100;
    border: 2px solid $sage-accent;
  }
  .follow-tag {
    background: $butterfly-100;
    border: 2px solid $butterfly-latte;
  }
  .pause-tag {
    border: 2px solid $cream-500;
    background: $cream-primary;
  }
  .manual-tag {
    border: 2px solid $email;
    background: $ube-100;
  }
}
