@import '_seriph/variables';

#wizard {
  position: relative;
  .wz-body {
    max-width: 956px;
    margin-left: auto;
    margin-right: auto;
    padding: 48px 32px;
    margin-top: 120px;
  }
  .wz-header {
    background: #fff;
    -webkit-transition: background-color 500ms linear, width 0.2s ease;
    -ms-transition: background-color 500ms linear, width 0.2s ease;
    transition: background-color 500ms linear, width 0.2s ease;
    padding: 32px 92px 0 92px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    .wz-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      .wz-left {
        flex: 1;
        text-align: left;
        button {
          margin-right: 16px;
        }
      }
      .wz-name {
        white-space: nowrap;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #090e15;
      }
      .wz-right {
        flex: 1;
        text-align: right;
        button {
          margin-left: 16px;
        }
        > div {
          display: inline-block;
          margin-left: 16px;
          button {
            margin-left: 0;
          }
        }
      }
    }
    a.logo {
      position: absolute;
      left: 15px;
      top: 32px;
      img {
        width: 40px;
        height: 40px;
        display: block;
      }
    }
  }
  .wz-unsaved {
    width: 100%;
    height: 80px;
    background: #e8e7fd;
    position: fixed;
    left: 0;
    top: -80px;
    z-index: 999;
    display: block;
    transition: all 0.2s linear;
    &.edited {
      top: 0;
    }
    .unsaved-name {
      white-space: nowrap;
      font-family: $font-major;
      font-weight: 500;
      font-size: 16px;
      line-height: 80px;
      text-align: center;
      color: #090e15;
    }
    .unsaved-actions {
      position: absolute;
      right: 92px;
      top: 20px;
      button {
        margin-left: 16px;
      }
    }
    img {
      position: absolute;
      left: 15px;
      top: 20px;
      width: 40px;
      height: 40px;
      display: block;
    }
  }
  .ant-select {
    width: 100%;
  }
  .wz-card {
    padding: 0 40px;
    .wz-container {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
      padding: 56px 0 24px 0;
      .field {
        margin-bottom: 32px;
        label {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin-bottom: 8px;
          display: block;
          .optional {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #707b90;
          }
        }
        .ant-input-affix-wrapper input {
          padding-left: 24px;
        }
        .ant-input-prefix {
          line-height: 21px;
        }
        .ant-select-selection--multiple {
          height: auto !important;
        }
        > textarea {
          padding: 16px;
        }
        .field-inline {
          display: flex;
          flex-direction: row;
          align-items: center;
          .inline-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #090e15;
            margin: 0 16px;
            &.left {
              margin-left: 0;
            }
          }
        }
      }
    }
    .wz-card-header {
      margin-bottom: 40px;
      text-align: center;
      > .seriph-tag {
        margin-bottom: 24px;
      }
      h3 {
        font-family: $font-header;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #090e15;
        margin-bottom: 8px;
        svg {
          font-size: 18px;
          margin-left: 16px;
        }
      }
      h5 {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #4d5b76;
      }
      .optional {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #4d5b76;
      }
    }
    .wz-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top: 1px solid #e7ecf4;
      padding: 24px 40px;
      margin-left: -40px;
      margin-right: -40px;
      button {
        transition: opacity 0.3s ease;
      }
      &.disabled a {
        button {
          opacity: 0.5 !important;
        }
        pointer-events: none !important;
      }
      .wz-actions-left {
        flex: 1;
        text-align: left;
        button {
          margin-right: 16px;
        }
      }
      .wz-actions-right {
        flex: 1;
        text-align: right;
        button {
          margin-left: 16px;
        }
      }
    }
  }
  .wz-menu {
    margin-top: 8px;
    text-align: center;
    .seriph-tab {
      text-transform: uppercase;
      color: #707b90;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
