@import 'variables';

.cancel-meeting-modal {
  .ant-modal-body {
    padding: 32px 32px 32px 80px;
    .stage-one {
      .stage-icon {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 32px;
        left: 32px;
        background: #ffd53e;
        color: #090e15;
        &.missing {
          background: #ef1e51;
          color: #fff;
        }
      }
      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #090e15;
        margin: 0 0 0 48px;
        font-family: $font-tt;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        margin: 12px 0 24px 0;
      }
      .stage-actions {
        margin-top: 24px;
        text-align: right;
        button {
          margin-left: 16px;
        }
      }
      .missing-list {
        margin-left: -4px;
        > div {
          display: inline-block;
          align-items: center;
          justify-content: center;
          background: rgba(255, 55, 104, 0.08);
          border-radius: 4px;
          color: #ff3768;
          font-weight: 500;
          padding: 4px 8px;
          margin: 4px;
          text-transform: capitalize;
        }
      }
    }
    .close-icon {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 12px;
      right: 12px;
      background: #f5f7fb;
      color: #090e15;
      cursor: pointer;
      &:hover {
        background: #e5e7eb;
      }
    }
  }
}
