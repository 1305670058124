@import '_seriph/variables';

.manage-admin-user-modal {
  .emb {
    margin-bottom: 24px;
  }
  .btn-danger {
    float: left;
  }
  .seriph-modal-actions {
    margin-top: 32px;
  }
  .formed-label {
    display: block;
    font-family: $font-major;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    margin: 0 0 8px 0;
  }
  .formed > div {
    margin-bottom: 24px;
  }
  .multiple-user-avatar img {
    width: 18px !important;
    height: 18px !important;
  }
}
