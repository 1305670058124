@import '_seriph/variables';

#internal-tools {
  margin-bottom: 20px;
  .tool-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 -16px;
    .tool {
      flex: 1;
    }
    &.bottom .tool {
      border-bottom: 1px solid #e7ecf4;
    }
  }

  .tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    margin: 0 16px;
    > span {
      margin-right: 20px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background: #090E15;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 14px;
        height: 14px;
        color: #fff;
      }
    }
    > h4 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      margin: 0;
    }
    &.done > span {
      background: #b5b5fd;
      svg {
        color: #000000;
      }
    } 
  }
}