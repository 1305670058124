@import '_seriph/variables';

#edit-sequence {
  margin-top: 84px !important;
  .seriph-alert {
    margin-bottom: 24px;
  }
  .auto-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
    width: 100px;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .days {
    width: 100%;
    max-height: 24%;
    position: relative;
    .day-card {
      background: #ffffff;
      border-radius: 12px;
      box-shadow: none;
      padding: 32px;
      width: 100%;
      overflow: hidden;
      position: relative;
      padding-left: 40px !important;
    }
    > .day {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      padding: 32px 32px 16px 32px;
      overflow: visible;
      .drag-day {
        position: absolute;
        top: 32px;
        left: 4px;
        padding: 4px 8px;
        display: none;
        cursor: hand;
        svg {
          width: 18px;
          height: 18px;
          color: #090e15;
        }
      }
      &:hover {
        .drag-day {
          display: block;
        }
      }
      .drag-day.dragging {
          display: block;
      }
      .day-title {
        flex: 1;
        h4 {
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #090e15;
          margin: 0 0 4px 0;
        }
        p {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #707b90;
          margin: 0;
        }
      }
      .day-touchpoints {
        flex: 2;
        .add-another {
          margin-top: 12px;
          position: relative;
          padding-left: 48px;
        }
        .touchpoint {
          display: flex;
          margin-bottom: 8px;
          .seriph-select {
            flex: 1;
            .ant-select {
              width: 100%;
            }
          }
          .time-button {
            flex: 1;
            background: #e7ecf4;
            border: 1px solid #e7ecf4;
            border-radius: 8px;
            height: 48px;
            padding: 0;
            line-height: 48px;
            font-family: $font-major;
            font-size: 14px;
            color: #090e15;
            cursor: pointer;
            position: relative;
            transition: border 0.2s linear;
            > .place {
              margin: 0 11px;
            }
            &:hover {
              border-color: #c4cbd9;
            }
            &.ant-dropdown-open {
              background: #fff;
              .drop-arrow {
                transform: rotate(180deg) !important;
              }
            }
            .drop-arrow {
              display: inline-block;
              line-height: 0;
              text-align: center;
              position: absolute;
              top: 50%;
              right: 11px;
              margin-top: -6px;
              color: rgba(0, 0, 0, 0.25);
              font-size: 12px;
              line-height: 1;
              transform-origin: center;
              transition: transform 0.3s;
              svg {
                color: #090e15;
              }
            }
          }
          .arrow,
          .trash,
          .drag {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 16px;
              height: 16px;
              color: #090e15;
            }
          }
          .arrow {
            cursor: initial;
          }
          .trash {
            cursor: pointer;
            border-radius: 16px;
            margin-left: 12px;
            margin-top: 8px;
            width: 32px;
            height: 32px;
            svg {
              display: none;
              color: #b34043;
            }
          }
          .drag {
            svg {
              display: none;
            }
          }
          &:hover .trash {
            background: #fbece7;
            &:hover {
              background: #ebdcd7;
            }
            svg {
              display: block;
            }
          }

          &:hover .drag {
            svg {
              display: block;
            }
          }
        }
      }
      .day-touchpoints.dragging .drag,
      .day-touchpoints.dragging .trash {
        background: transparent !important;
        svg {
          display: none !important;
        }
      }
      .day-touchpoints .drag.dragging {
        svg {
          display: block !important;
        }
      }
      .touch-option-drop {
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
          width: 28px;
          height: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
          margin-right: 12px;
          svg {
            width: 14px;
            height: 14px;
          }
        }
        &.new > div {
          margin-right: 12px;
          width: auto !important;
          position: relative;
          top: 1px;
          svg {
            stroke-width: 2.5;
          }
        }
      }
    }
  }
  .sequence-wrap {
    position: relative;
    overflow: hidden !important;
    padding: 32px 0 !important;
  }
  .add-days {
    text-align: center;
    padding: 16px 0;
    margin-top: 40px;
    h4 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #090e15;
      margin: 0 0 24px 0;
    }
    .types {
      text-align: center;
      position: relative;
      .lined {
        position: absolute;
        width: 100%;
        height: 1px;
        background: #d7dde9;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
      }
      .type-box {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #f5f7fb;
        padding: 0 40px;
        z-index: 99;
        position: relative;
        > .add-touchpoint {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          cursor: pointer;
          margin: 0 6px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .sequence-title {
    display: flex;
    align-items: center;
    position: relative;
    padding: 24px 32px 8px 32px;
    background: #f5f7fb;
    position: fixed;
    top: 0;
    left: 350px;
    height: 84px;
    width: calc(100% - 350px);
    z-index: 999;
    > .title {
      flex: 1;
      width: 100%;
      text-align: center;
      input {
        background: transparent;
        border: 1px solid transparent;
        text-align: center;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #090e15;
        max-width: 500px;
        box-shadow: none;
        outline: none;
        &:hover {
          border: 1px solid #c4cbd9;
        }
        &:focus {
          border: 1px solid #c4cbd9;
          background: #fff;
        }
      }
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #707b90;
        margin: 0;
      }
    }
    .left-actions,
    .right-actions {
      display: flex;
      align-items: center;
      width: 120px;
    }
    .left-actions > button {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
    .right-actions > button {
      margin-left: 12px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .ant-select-selection-selected-value .preview {
    display: none !important;
  }
  .sel-touch {
    display: flex;
    align-items: center;
    position: relative;
    .preview {
      position: absolute;
      right: 8px;
      display: none;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: transparent;
      border-radius: 14px;
      svg {
        color: #090e15;
        width: 14px;
        height: 14px;
      }
      &:hover {
        background: #d7dde9;
      }
    }
    &:hover {
      .preview {
        display: flex;
      }
    }
  }

  .time-menu {
    background: #ffffff;
    box-shadow: 0px 6px 16px rgba(40, 43, 48, 0.16);
    border-radius: 12px;
    height: 300px;
    overflow: hidden;
    .time-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 8px;
      border-top: 1px solid #e7ecf4;
      height: 60px;
      background: #fff;
      button {
        width: 100%;
      }
    }
    .selectors {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .options {
        flex: 1;
        padding: 8px;
        min-height: 240px;
        height: 240px;
        overflow: auto;
        border-right: 1px solid #e7ecf4;
      }
      .numbers {
        flex: 1;
        min-height: 240px;
        height: 240px;
        overflow: auto;
        padding: 8px;
      }
      .options > div,
      .numbers > div {
        background: #ffffff;
        padding: 8px 12px;
        border-radius: 8px;
        cursor: pointer;
        font-family: $font-major;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        &:hover {
          background: #f5f7fb;
        }
        &.active {
          background: #e8e7fd;
        }
      }
      .numbers > span {
        height: 1px;
        overflow: hidden;
        display: none;
      }
    }
  }
}
