@import '_seriph/variables';

#agent-settings {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 30px 30px 30px;
  max-width: 1440px;
  .settings-header {
    margin-bottom: 24px;
  }
  .settings-content {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    .settings-menu {
      width: 275px;
      margin-right: 30px;

      .menu-card {
        padding: 16px 0;
      }
    }
    .settings-main {
      display: flex;
      flex: 1;
      position: relative;
    }
  }
  .settings-card {
    padding: 32px;
    position: relative;
    width: 100%;
    .settings-card-header {
      font-size: 32px;
      line-height: 35px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 16px;
    }
    .settings-card-info {
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }
  .settings-items {
    .settings-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 64px;
      height: 64px;
      max-height: 64px;
      overflow: hidden;
      background: #ffffff;
      border-radius: 12px;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        background: #f5f7fb;
      }
      .type {
        text-align: left;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        padding-left: 16px;
        width: 40%;
      }
      .value {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        padding-right: 16px;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        input {
          border-color: transparent;
          background: transparent;
          position: relative;
          left: -12px;
          min-width: 200px;
          &:hover,
          &:focus {
            border-color: #d7dde9;
            background: #fff;
          }
        }
        .PhoneInput input {
          left: auto !important;
        }
      }
      .edit {
        flex: 1;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding-right: 16px;
      }
      span.link {
        transition: color 0.2s ease;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #8472f8;
        cursor: pointer;
        &:hover {
          color: #6452d8;
        }
      }
    }
  }
}
