#agent-profile-aboutme {
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin: 0 0 16px 0;
  }
  p {
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
  }
  .edit-note {
    font-size: 12px;
    line-height: 18px;
    color: #4d5b76;
    margin-top: 16px;
  }
}

#agent-aboutme-edit {
  .inline-actions {
    margin-top: 16px;
    text-align: right;
    button {
      margin-left: 8px;
    }
  }
}
