@import '_seriph/variables';

#company-analytics-stats {
  min-width: 100%;
  padding: 32px 40px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .seriph-header-wrapper {
    margin-bottom: 24px;
  }
  .dashboard-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .ant-select {
      width: 120px;
    }
    .ant-calendar-picker {
      width: 200px;
      margin: 0;
    }
    > span {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0 12px;
      &.no-left {
        margin-left: 0;
      }
    }
    border-bottom: 1px solid #e7ecf4;
    padding-bottom: 24px;
    margin-bottom: 32px;
  }
  .sx-header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    #date-range {
      margin-right: 12px;
    }
  }
  .meeting-own {
    white-space: nowrap;
    .seriph-avatar {
      margin-right: 12px;
    }
  }
  .page-info {
    display: none;
  }
}

#company-analytics-overview {
  .sellx-tooltip {
    background: #0b1e41;
    border-radius: 5px;
    padding: 0;
    .tip-box {
      .tip-title {
        padding: 12px;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }
      .tip-contents {
        padding: 8px 12px;
        .tip-item {
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .tip-circle {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin-right: 10px;
          }
          .tip-name {
            color: #fff;
            flex: 1;
          }
          .tip-value {
            color: #fff;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
