@import 'variables';

label.sxd-checkbox {
  display: block;
  margin: 0 !important;
  background: transparent;
  border-radius: 8px;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $blue-800;
  &:hover {
    background: #f5f7fb;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4353ff;
    border-color: #4353ff;
  }
}

label.sxd-radio {
  display: block;
  margin: 0 !important;
  background: transparent;
  border-radius: 8px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $blue-800;
  &:hover {
    background: #f5f7fb;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #4353ff;
    &::after {
      background-color: #4353ff;
    }
  }
}
