@import '_seriph/variables';

.seriph-tooltip {
  .ant-tooltip-inner {
    padding: 8px 12px;
    background: #090e15;
    color: #fff;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    border-radius: 10px;
  }
  .ant-tooltip-arrow {
    border-top-color: #090e15;
  }
  &.centered .ant-tooltip-inner {
    text-align: center;
  }
  .progress-tt .nowrap {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .progress-tt .light {
    color: #929AAA;
    svg {
      color: #929AAA;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
