@import '_seriph/variables';

.new-sequence-modal {
  .ant-modal-body {
    padding-top: 16px !important;
  }
  .new-header {
    text-align: center;
    font-weight: 500;
    .subheader {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #707b90;
    }
  }
  .sequence-form {
    padding: 32px 0;
  }
}
