@import '_seriph/variables';

#internal-task-chart {
  width: 100%;
  position: relative;
  .recharts-xAxis .recharts-cartesian-axis-ticks {
    transform: translateY(12px);
  }
  .recharts-yAxis .recharts-cartesian-axis-ticks {
    transform: translateX(-12px);
  }
  .recharts-cartesian-axis-tick-value {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #090e15 !important;
    fill: #090e15 !important;
  }
  &.zero .recharts-responsive-container {
    opacity: 0.3;
    pointer-events: none !important;
  }
  .zero-data {
    background: #FFFFFF;
    border: 2px solid #D7DDE9;
    box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
    border-radius: 10px;
    font-family: $font-major;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #090E15;
    display: block;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    padding: 8px 16px;
  }
  .task-totals {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E7ECF4;
    margin-bottom: 32px;
    .ttl-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      > div {
        flex: 1;
        > p {
          font-family: $font-major;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #090E15;
          margin: 0 0 8px 0;
        }
        > h2 {
          font-family: $font-major;
          font-weight: 500;
          font-size: 28px;
          line-height: 38px;
          color: #090E15;
          margin: 0 0 12px 0;
        }
      }
    }
  }
}
