.ant-select-auto-complete.ant-select .ant-input {
  padding: 0 16px !important;
  height: 48px !important;
  line-height: 48px !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  background: #e7ecf4 !important;
  border: 1px solid #e7ecf4 !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #090e15 !important;
  overflow: hidden !important;
  font-family: $font-major;
  font-size: 14px;
  color: #090e15;
  &::placeholder {
    color: #4d5b76 !important;
  }
  &:hover {
    border: 1px solid #c4cbd9 !important;
  }
  &:focus {
    border: 1px solid #c4cbd9 !important;
    background: #fff !important;
  }
  &[disabled] {
    border: 1px solid #f5f7fb !important;
    background: #f5f7fb !important;
    color: #adb5c5 !important;
    &::placeholder {
      color: #adb5c5 !important;
    }
  }
}
