.campaign-actions {
  cursor: pointer;
  padding: 0;
  width: 48px;
  height: 48px;
  display: inline-block;
  border-radius: 8px;
  &:hover {
    background: #e7ecf4;
  }
  .ell-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.seriph-dropdown {
  .ant-dropdown-menu-item.danger {
    color: #b34043 !important;
    &:hover {
      background: #fbece7 !important;
    }
  }
}
