@import '_seriph/variables';

#admin-agents {
  padding: 40px 30px 30px 30px;
  .admin-header {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    position: relative;
    > div {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      position: relative;
      left: 0;
      top: -5px;
    }
    .seriph-input {
      width: 300px;
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
    }
    .seriph-tabs {
      margin-top: 24px;
      .seriph-tab {
        text-transform: uppercase;
      }
    }
  }
  .cohort {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }
  .admin2-table {
    .lead-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      .lead-photo {
        margin-right: 16px;
      }
      .lead-full {
        font-family: $font-major;
        font-size: 14px;
        color: #000;
        weight: 500;
        img {
          width: 16px;
          height: 16px;
          position: relative;
          left: 6px;
          top: -1px;
        }
      }
    }
    .agent-email {
      svg {
        color: #30f09e;
        margin-left: 8px;
      }
    }
    .manager-column {
      display: flex;
    }
  }
}
