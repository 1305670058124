@import 'variables';

#payment-method {
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 250px;

  .manage-box {
    padding-top: 24px;
    .manage-account {
      margin-right: 15px;
    }
  }
  .setup-button {
    text-align: center;
    .add-account {
      margin: 0 auto;
      margin-top: 30px;
    }
  }
  .ant-empty {
    margin-top: 30px;
  }
  .add-bank {
    text-align: center;
    padding: 30px 0 50px 0;
    button {
      cursor: pointer !important;
      padding: 16px 24px !important;
      height: 56px !important;
    }
  }
  .sources {
    > h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #242d3e;
      margin: 0 0 8px 0;
    }
    > .source {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #4353ff;
      box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 12px rgba(11, 30, 65, 0.04);
      .source-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        min-height: 40px;
        background: #090e15;
        border-radius: 16px;
        svg {
          font-size: 16px;
          color: #fff;
        }
      }
      .checked {
        font-size: 20px;
        color: #4353ff;
      }
      .source-name {
        margin-left: 16px;
        flex: 1;
        h3 {
          font-family: $font-tt;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #090e15;
          margin: 0;
        }
        > div {
          font-size: 12px;
          line-height: 18px;
          color: #4d5b76;
        }
      }
    }
  }
  .none {
    padding: 30px;
    text-align: center;
    font-style: italic;
  }
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    border: 1px solid #e6e9ef;
    border-radius: 4px;
    background: white;
  }

  .cancel-add {
    margin-right: 15px;
  }

  .StripeElement--focus {
    border: 1px solid #090e15;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
  }
  .history {
    .charge {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #e6e9ef;
      &:last-child {
        border-bottom: 0;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 8px;
        color: #000000;
        font-weight: 400;
        flex: 1;
        &:first-child {
          flex: 2;
          justify-content: flex-start;
          padding: 16px 0px;
        }
        &:last-child {
          justify-content: flex-end;
          padding: 16px 0px;
        }
        svg {
          margin-right: 5px;
          position: relative;
          top: -2px;
        }
      }
    }
  }
}
