@import '_seriph/variables';

#company-settings-signature {
  position: relative;
  width: 100%;
  padding: 0;
  .settings-header {
    margin: 0 !important;
  }
  .settings-subtitle {
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    margin: 8px 0 24px 0;
  }
  .signature {
    display: block;
    background: #ffffff;
    border-top: 1px solid #e7ecf4;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 32px;
    .signature-demo {
      padding: 24px 32px;
    }
    .signature-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 0;
      border-top: 1px solid #e7ecf4;
      h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        margin: 0 0 0 12px;
      }
    }
  }
}
