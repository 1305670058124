@import '_seriph/variables';

#agent-get-started {
  .gs-title {
    margin-top: 48px;
  }
  .sx-empty {
    margin-top: 64px;
  }
  .all-started {
    margin-top: 16px;
  }
  .task {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 12px;
    .task-image {
      width: 48px;
      height: 48px;
      background: #d2d0fb;
      border-radius: 24px;
      position: relative;
      margin-right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #090e15;
      }
    }
    &.done .task-image {
      background: #090e15;
      svg {
        color: #fff;
      }
    }
    &.task-drop {
      display: block;
      padding: 0;
      .task-container {
        padding: 24px 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .task-form {
        transition: max-height 0.2s ease-out;
        max-height: 0;
        overflow: hidden;
        &.opened {
          max-height: 500px;
        }
        .form-box {
          padding: 0 32px 24px 32px;
        }
        .form-actions {
          text-align: right;
        }
        textarea {
          margin-top: 12px;
          margin-bottom: 12px;
        }
        .feedback {
          margin-bottom: 12px;
        }
      }
    }
    .task-info {
      flex: 1;
      margin-right: 16px;
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        margin: 4px 0 0 0;
      }
    }
    &.done .task-info {
      p,
      h4 {
        text-decoration: line-through;
      }
    }
  }
}
