.sxd-popover {
  .ant-tooltip-inner {
    padding: 0;
    background: #090d14;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    .popover-body {
      h4 {
        padding: 12px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        border-bottom: 1px solid #242d3e;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        color: #c4cbd9;
        padding: 8px 16px 16px 16px;
      }
    }
  }
  .ant-tooltip-arrow {
    border-top-color: #090d14;
  }
}
