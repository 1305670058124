@import 'variables';

.call-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  .btnx-round {
    width: 56px !important;
    text-align: center;
    padding: 9px 0 !important;
  }
  .btnx-success {
    background: #4de401 !important;
    border: 1px solid #4de401 !important;
  }
  > button {
    margin: 0 8px;
  }
  .right-actions {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
  }
  .left-actions {
    position: absolute;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    .who {
      h4 {
        font-family: $font-tt;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 0;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      h5 {
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        margin-top: 2px;
        font-weight: 400;
      }
    }
    .timer {
      background: #f5f7fb;
      border-radius: 56px;
      padding: 10px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin-left: 16px;
      > span {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: #c4cbd9;
        margin-right: 8px;
      }
      &.call > span {
        background: #6bf555;
      }
    }
  }

  .ant-popover {
    .ant-popover-inner {
      border: 1px solid #e7ecf4;
      border-radius: 12px;
      box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
    }
    .ant-popover-message-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #090e15;
      margin-bottom: 8px;
    }
    .btnx.btnx-danger {
      background-color: #ef1e51 !important;
      padding: 9px 12px !important;
      height: 40px !important;
      line-height: 21px !important;
    }
    .btnx.btnx-default {
      padding: 9px 12px !important;
      height: 40px !important;
      line-height: 21px !important;
    }
  }
}

#cl-settings {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
  border: 1px solid #e7ecf4;
  border-radius: 12px;
  padding: 32px;
  position: relative;
  top: -16px;
  h3 {
    font-family: $font-tt;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin: 0;
  }
  .mic {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 8px;
    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      flex: 1;
      margin: 0;
    }
    button {
      margin-left: 8px;
    }
  }

  .status-sounds {
    margin-top: 24px;
  }
}

#call-lead-drop {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
  border: 1px solid #e7ecf4;
  border-radius: 12px;
  padding: 32px;
  position: relative;
  top: -16px;
  > h3 {
    font-family: $font-tt;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #090e15;
    margin-bottom: 4px;
  }
  .from {
    text-align: center;
    padding: 0 0 12px 0;
    font-size: 14px;
    line-height: 21px;
    color: #4d5b76;
    b {
      font-weight: 600;
    }
    margin-bottom: 16px;
  }
  .cl-actions {
    padding: 0;
    > button {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      height: auto !important;
      > div {
        margin-top: 4px;
        font-size: 11px;
        font-weight: 500;
        font-family: $font-stack;
      }
    }
  }
  .cl-settings {
    margin-bottom: 20px;
    .ant-select {
      width: 200px;
    }
  }
  .on-call {
    padding: 0 24px 24px 24px;
    text-align: center;
    .call-status {
      margin-bottom: 50px;
      margin-top: 20px;
      font-weight: 500;
      font-size: 24px;
    }
    > button {
      width: calc(50% - 10px);
    }
  }
}

#call-dialpad {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(40, 43, 48, 0.08), 0px 2px 12px rgba(40, 43, 48, 0.04);
  border: 1px solid #e7ecf4;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  top: -16px;
  .pressed {
    font-family: $font-tt;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: block;
    text-align: center;
    color: #090e15;
  }
  .dial-time {
    font-size: 16px;
    line-height: 24px;
    display: block;
    text-align: center;
    color: #707b90;
    margin-top: 2px;
  }

  .keys {
    display: block;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > .key {
      width: 72px;
      height: 72px;
      border-radius: 36px;
      padding-left: 0;
      padding-right: 0;
      margin-right: 16px;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #090e15;
      > div {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 1px;
        color: #707b90;
      }
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
