@import '_seriph/variables';

.company-onboarding {
  h1.seriph-header.header-display {
    font-size: 80px;
    line-height: 96px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 24px;
    font-family: $font-header;
  }

  .descript {
    color: #918f8d;
    font-size: 24px;
    line-height: 30px;
    font-family: $font-major;
    strong {
      color: #0a1015;
      font-family: $font-header;
    }
  }
  display: flex;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
  .onb-left {
    padding: 48px;
    background: #ffffff;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.full-screen {
      width: 100%;
      align-items: center;
      text-align: center;
      overflow: scroll;

      .onb-left-main {
        width: 80%;
        max-width: 525px;

        .onb-walk {
          .onb-walk-main {
            margin: 8px 0 32px;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          > button {
            margin-bottom: 12px;
            width: 300px;
          }
          .start-over {
            margin-top: 2px;
            color: #090e15;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            display: flex;
            align-items: center;
            svg {
              height: 14px;
              width: 14px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .onb-left-main {
      flex: 8;
      .onb-logo-nav {
        img {
          width: 40px;
          height: 40px;
        }
        margin-bottom: 72px;
      }
    }
    .onb-left-footer {
      a {
        font-family: $font-major;
        text-transform: uppercase;
        color: #686764;
        font-size: 12px;
        letter-spacing: 0.2em;
        &:visited {
          color: #686764;
        }
      }
      span.spacer {
        margin: 0px 12px;
        color: #686764;
      }
    }
  }
  .onb-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    &.hidden {
      display: none;
    }
    .onb-right-main {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 80%;
      max-width: 500px;
      overflow: scroll;
      &.extra-wide {
        max-width: 648px;
      }
      font-weight: 500;
      .seriph-input {
        width: 100%;
        margin-bottom: 32px;
      }
      .onb-select {
        width: 100%;
        margin: 16px 0 32px;
        label {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 16px;
        }
        .seriph-select {
          margin-top: 8px;
        }
        .ant-select {
          width: 100%;
        }
      }
    }
    .onb-progress {
      height: 4px;
      background: #2a2928;
      position: relative;
      align-self: stretch;
      z-index: 100;
      .onb-progress-bar {
        position: absolute;
        left: 0;
        right: 80%;
        top: 0;
        bottom: 0;
        background: #d2d0fb;
        transition: all 0.3s ease-in;
      }
    }
    .onb-footer {
      padding: 32px;
      height: 132px;
      display: flex;
      justify-content: space-between;
      align-self: stretch;
      align-items: center;
      .back-button {
        border: 1px solid #2a2928;
      }
      background-color: #090e15;
      z-index: 100;
    }
  }
  @media (max-width: 1350px) {
    h1.seriph-header.header-display {
      font-size: 5vw;
      line-height: 120%;
    }
    p.descript {
      font-size: 16px;
      line-height: 24px;
    }
    .onb-left {
      padding: 24px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: 100vh;

    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
    h1.seriph-header.header-display {
      font-size: 8vw;
      margin-bottom: 12px;
      font-weight: 400;
    }
    .descript {
      font-size: 16px;
    }
    .onb-left {
      width: 100%;
      &.full-screen {
        height: 100vh;
        .onb-left-main {
          .onb-logo-nav {
            margin-bottom: 72px;
          }
          justify-content: start;
        }
      }
      .onb-left-footer {
        display: none;
      }
      .onb-left-main {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .onb-logo-nav {
          margin-bottom: 32px;
        }
      }
    }
    .onb-right {
      &.hidden {
        height: 0;
      }
      overflow: scroll;
      .onb-right-main {
        overflow: scroll;
        margin-top: 30px;
        justify-content: start;
        width: 100%;
        max-width: 100%;
        .seriph-input,
        .seriph-radio-btn-list,
        .seriph-checkbox-list,
        .onb-select {
          padding: 0 24px;
        }
        .calendly-inline-widget {
          iframe {
            min-height: 40vh;
          }
        }
      }
      .onb-footer {
        padding: 24px;
        height: 90px;

        button {
          font-size: 14px;
          line-height: 21px;
          padding: 13px 24px;
          height: 48px;
          border-radius: 10px;
        }
      }
    }
  }
}
