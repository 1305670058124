@import '_seriph/variables';

#call-window {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  .cw-left {
    width: 300px;
    border-right: 1px solid #E7ECF4;
    position: relative;
  }
  .cw-right {
    flex: 1;
    .caller-window {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100%;
      position: relative;
    }
  }
  .infover {
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    border: 1px solid #e7ecf4;
    box-shadow: 0px 4px 12px rgba(40, 43, 48, 0.1);
    border-radius: 38px;
    padding: 0 16px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: calc(50% + 160px);
    transform: translateX(-50%);
    bottom: 50px;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    transition: bottom 0.15s linear;
    z-index: 9;
    &.visible {
      bottom: 120px;
    }
  }
  .menu-tabs {
    border-top: 1px solid #e7ecf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    > .mtab {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        color: #b2baca;
      }
      &:hover svg,
      &.active svg {
        color: #090e15;
      }
    }
  }
}