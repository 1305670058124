.avatar-edit {
  .ant-modal-body {
    padding: 0;
  }
  .logo-preview {
    text-align: center;
    padding: 50px 0;
    > div {
      margin-top: 20px;
    }
    img {
      width: 200px;
      height: 200px;
      border: 3px solid rgba(0, 0, 0, 0.247);
      border-radius: 100px;
    }
  }
  .ant-upload-drag {
    background: none;
    border: 0;
    padding: 50px 0;
    &:hover {
      border: 0;
    }
    .ant-upload-text {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }
    .ant-upload-hint {
      font-size: 14px;
      font-weight: 500;
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  .ant-upload-drag-icon > svg {
    font-size: 50px;
    color: #bac3cc;
  }
}
