@import 'variables';

.agent-marketing {
  background: #090e15;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 48px;
  margin-top: 32px;
  .marketing-info {
    .marketing-title {
      color: #fff;
      font-weight: 600;
    }
    p {
      color: #d7dde9;
      margin: 12px 0 32px 0;
    }
  }
  .marketing-image {
    display: inline-block;
    width: 220px;
    min-width: 220px;
    height: 220px;
    min-height: 220px;
  }
}
