@import '_seriph/variables';

.seriph-input-time {
  .input-box {
    position: relative;
    .ant-time-picker input {
      padding: 0 16px;
      height: 48px;
      line-height: 48px;
      outline: 0;
      box-shadow: none;
      border-radius: 10px;
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      overflow: hidden;
      &::placeholder {
        color: #4d5b76;
      }
      &:hover {
        border: 1px solid #c4cbd9;
      }
      &:focus {
        border: 1px solid #c4cbd9;
        background: #fff;
      }
      &[disabled] {
        border: 1px solid #f5f7fb;
        background: #f5f7fb;
        color: #adb5c5;
        &::placeholder {
          color: #adb5c5;
        }
      }
    }
    .input-icon-left {
      position: absolute;
      left: 16px;
      top: 17px;
      svg {
        width: 16px;
        height: 16px;
        stroke-width: 1.75;
      }
    }
    .input-icon-right {
      position: absolute;
      right: 16px;
      top: 17px;
      svg {
        width: 16px;
        height: 16px;
        stroke-width: 1.75;
      }
    }
  }

  &.seriph-success {
    .input-box > input {
      background: #ebfff2;
      border: 1px solid #98f6b8;
    }
  }

  &.seriph-error {
    .input-box > input {
      background: #ffe9e9;
      border: 1px solid #f8aaaa;
    }
  }

  &.input-medium {
    .input-box > input {
      padding: 0 12px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 21px;
    }
    .input-icon-left {
      top: 14px;
      svg {
        width: 14px;
        height: 14px;
        stroke-width: 1.75;
      }
    }
    .input-icon-right {
      top: 14px;
      svg {
        width: 14px;
        height: 14px;
        stroke-width: 1.75;
      }
    }
  }

  &.input-large {
    .input-box > input {
      padding: 0 16px;
      height: 56px;
      line-height: 56px;
      border-radius: 12px;
      font-size: 16px;
      line-height: 24px;
    }
    .input-icon-left {
      top: 20px;
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 1.75;
      }
    }
    .input-icon-right {
      top: 20px;
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 1.75;
      }
    }
  }

  &.input-left {
    .input-box > input {
      padding-left: 42px;
    }
  }

  &.input-right {
    .input-box > input {
      padding-right: 42px;
    }
  }
}

.seriph-input-time-drop {
  .ant-time-picker-panel-input-wrap {
    height: 48px;
    line-height: 48px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-time-picker-panel-select li:hover {
    background: #e5e7ef;
  }
  .ant-time-picker-panel-select li:focus {
    color: #090e15;
    font-weight: 600;
    outline: none;
  }
}
