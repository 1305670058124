@import '_seriph/variables';

#company-analytics-meetings {
  min-width: 100%;
  padding: 32px 40px;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .seriph-header-wrapper {
    margin-bottom: 24px;
  }
  .dashboard-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    .export-btn {
      flex: 1;
      text-align: right;
    }
    > span {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      margin: 0 12px;
      &.no-left {
        margin-left: 0;
      }
    }
    border-bottom: 1px solid #e7ecf4;
    padding-bottom: 24px;
    margin-bottom: 32px;
  }
  .meetings-table .meeting-lead {

    > h3 {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #090E15;
      margin: 0;
      padding: 0;
    }
    > div {
      font-family: $font-major;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #090E15;
      margin: 0;
      padding: 0;
    }
    > p {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #707B90;
      margin: 0;
      padding: 0;
    }
  }
  .meetings-table .meeting-own {
    white-space: nowrap;
    .seriph-avatar {
      margin-right: 12px;
    }
  }


}

.rep-select-me {
  .seriph-avatar {
    margin-right: 8px;
  }
}

