@import '_seriph/variables';

#agent-home {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  .home-main {
    padding: 40px 64px 40px 32px;
    flex: 1;
    .home-name {
      font-weight: normal;
      color: #4d5b76;
    }
  }
}
