@import 'variables';

#discover-featured {
  .featured-title {
    margin-bottom: 26px;
    h1 {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      line-height: 16px;
      display: inline-block;
      margin-bottom: 0;
    }
    a {
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      line-height: 12px;
      float: right;
      position: relative;
      top: 4px;
      svg {
        margin-left: 10px;
      }
      &:hover {
        color: #222;
        text-decoration: underline;
      }
    }
  }
  .featured-companies {
    .featured-company {
      text-align: center;
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-top: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 1px solid $neutral-100;
        cursor: pointer;
      }
      p {
        margin: 0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .fc-box {
        cursor: pointer;
        &:hover {
          h3 {
            text-decoration: underline;
          }
        }
      }
    }
    .star {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      svg {
        color: #000;
        font-size: 16px;
      }
    }
  }
  .company-card {
    &:hover {
      border: 1px solid #ccc;
    }
  }
}
