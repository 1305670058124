@import 'variables';

.custom-domain-modal {
  .ant-modal-body {
    min-height: 200px;
    padding: 32px;
    .ant-alert-message p {
      margin-bottom: 0 !important;
    }
    .stage-one {
      .stage-actions {
        margin-top: 24px;
        text-align: right;
        button {
          margin-left: 16px;
        }
        .refresh {
          float: left;
        }
      }
    }
    .stage-body {
      margin: 24px 0 0 0;
      p {
        margin-bottom: 24px;
      }
      .field {
        margin-bottom: 40px;
        display: block;
        position: relative;
        label {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #090e15;
          margin-bottom: 4px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        > svg {
          position: absolute;
          right: 16px;
          bottom: 16px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.1);
        }
        > img {
          position: absolute;
          right: 16px;
          bottom: 17px;
          cursor: pointer;
        }
      }
      .domain-table {
        width: 100%;
        th,
        td {
          padding: 10px 12px;
          border-bottom: 1px solid #e7ecf4;
          text-align: left;
          white-space: nowrap;

          svg {
            &.fa-check-circle {
              color: #4353ff;
            }
            &.fa-times-circle {
              color: #fd8321;
            }
          }
        }
        tr > td:last-child {
          display: flex;
          flex-direction: row;
          align-items: center;
          button {
            margin-left: 8px;
            display: inline-block;
          }
          input {
            display: inline-block;
          }
        }
        th {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #707b90;
        }
      }
    }
    .close-icon {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 12px;
      right: 12px;
      background: #f5f7fb;
      color: #090e15;
      cursor: pointer;
      &:hover {
        background: #e5e7eb;
      }
    }
  }
}
