@import '_seriph/variables';

#agent-earnings-chart {
  padding: 32px 8px 32px 32px !important;
  margin-bottom: 48px;
  > h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0b1e41;
    padding: 0;
    margin-bottom: 12px;
  }
  .earnings-amounts {
    text-align: center;
    > .eamount {
      display: inline-block;
      margin: 0 40px 32px 40px;
      h3 {
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        color: #090e15;
        text-align: left;
        font-family: $font-major;
        margin-bottom: 8px;
      }
      h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        text-align: left;
        font-family: $font-major;
        > span {
          display: inline-block;
          background: grey;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          position: relative;
          margin-right: 8px;
          &.generated {
            background: $generated;
          }
          &.total {
            background: #090e15;
          }
          &.qualified {
            background: $qualified;
          }
        }
      }
    }
  }
  .earnings-chart {
    flex: 1;
    display: flex;
    flex-direction: row;
    > div {
      flex: 1;
    }
  }
}
