@import '_seriph/variables';

.admin-manage-users {
  padding: 40px;
  .new-header {
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .admin-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    .seriph-header {
      flex: 1;
    }
  }
  .company-tags {
    max-width: 400px;
  }
}
