@import '_seriph/variables';

.call-export-modal {
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
    button {
      flex: 1;
      margin: 0 16px;
    }
  }

  p.desc {
    margin-top: 16px;
  }
}
