.sxd-guide {
  position: relative;
  > .bar {
    position: absolute;
    left: 12px;
    top: 12px;
    background: #43e8ff;
    border-radius: 32px;
    width: 4px;
    display: block;
    content: '';
    clear: both;
    height: calc(100% - 24px);
  }
  .ant-alert {
    background: #090e15;
    border-radius: 8px;
    border: 0;
    padding: 16px 32px !important;
    .ant-alert-message {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }
    .ant-alert-close-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      .anticon-close {
        color: #929aaa;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
      }
      &:hover .anticon-close {
        color: #fff;
      }
    }
  }
  &.disputed {
    h5 {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #fd5621;
      margin: 0;
    }
    .ant-alert {
      background: #fff1bf;
    }
    .ant-alert-message p {
      color: #090e15;
      margin: 0;
    }
    > .bar {
      background: #fd8321;
    }
  }
  &.qualified {
    h5 {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #5e0cef;
      margin: 0;
    }
    .ant-alert {
      background: #d6daff;
    }
    .ant-alert-message p {
      color: #090e15;
      margin: 0;
    }
    > .bar {
      background: #5e0cef;
    }
  }
  &.info {
    h5 {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #4353ff;
      margin: 0;
    }
    .ant-alert {
      background: #e7f8ff;
    }
    .ant-alert-message p {
      color: #090e15;
      margin: 0;
    }
    > .bar {
      background: #4353ff;
    }
  }
  &.budget-bad {
    h5 {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #fd8321;
      margin: 0;
    }
    .ant-alert {
      background: #fff1bf;
    }
    .ant-alert-message p {
      color: #090e15;
      margin: 0;
    }
    > .bar {
      background: #fd8321;
    }
  }
}
