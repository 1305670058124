@import '_seriph/variables';

#call-list {

  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  

  .call-tag {
    display: inline-block;
    border: 2px solid #e7ecf4;
    border-radius: 48px;
    padding: 2px 6px;
    margin-left: 12px;
    line-height: 11px;
    margin-top: 4px;
    .tag-text {
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #090e15;
    }
    &.nurture {
      background: $sage-100;
      border: 2px solid $sage-accent !important;
      border-color: $sage-accent !important;
    }
    &.follow {
      background: $butterfly-100;
      border: 2px solid $butterfly-latte !important;
      border-color: $butterfly-latte !important;
    }
    &.pause {
      border: 2px solid $cream-500;
      background: $cream-primary !important;
      border-color: $cream-500 !important;
    }
    &.manual {
      border: 2px solid $email;
      background: $ube-100 !important;
      border-color: $email !important;
    }
  }
  .lead-list {
    flex: 1;
    overflow: auto;
    padding: 0;
    > .ls-section {
      padding: 24px 12px 8px 16px;
      background: #FFFFFF;
      font-family: $font-major;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #090E15;
      border-top: 1px solid #ddd;
      > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        content: '';
        border-radius: 5px;
        background: #ABD4A2;
        margin-right: 10px;
      }
      &.complete > span {
        background: #B0D0E8;
      }
      &.available > span {
        background: #ABD4A2;
      }
    }
    > .ls-section:nth-of-type(1){
      border-top: 0;
      padding-top: 8px;
    }
    > .call-lead {
      padding: 16px 24px;
      border-radius: 0;
      background: #fff;
      border: 1px solid #fff;
      border-right: 0 !important;
      border-left: 0 !important;
      cursor: pointer;
      margin-bottom: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      .cl-content {
        flex: 1;
        > p {
          display: flex;
          align-items: center;
          font-size: 11px;
          &.comp {
            font-size: 12px;
            color: #303B50;
          }
          svg {
            width: 12px;
            height: 12px;
            color: #707B90;
            position: relative;
            top: -1px;
            margin-right: 8px;
          }
        }
      }
      .cl-check {
        width: 16px;
        height: 16px;
        content: '';
        border-radius: 8px;
        background: #E7ECF4;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        > svg {
          width: 12px;
          height: 12px;
          color: #707B90;
        }
      }
      .cl-chev {
        display: none;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        content: '';
        border-radius: 12px;
        background: #090E15;
        align-items: center;
        justify-content: center;
        > svg {
          width: 12px;
          height: 12px;
          color: #fff;
        }
      }
      &:hover {
        background: #fff;
        border: 1px solid #E7ECF4;
        .cl-chev {
          display: flex;
        }
      }
      &.disabled {
        cursor: not-allowed;
        .cl-chev {
          display: none !important;
        }
      }
      &.active {
        background: #F5F7FB;
        border: 1px solid #E7ECF4;
        .cl-chev {
          display: flex !important;
        }
      }
      &.completed {
        background: transparent;
        h3, p {
          text-decoration: line-through;
          opacity: 0.65;
        }
      }
      
      &:hover {
        opacity: 1.0;
      }
      h3 {
        margin: 0;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090E15;
      }
      p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #707B90;
        text-transform: capitalize;
      }
    }
  }

  .cl-campaign {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    box-shadow: 0px 1px 2px rgba(11, 30, 65, 0.08), 0px 2px 6px rgba(11, 30, 65, 0.04);
    z-index: 9;
    .cl-info {
      margin-left: 16px;
      flex: 1;
      > h3 {
        margin: 0;
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090E15;
      }
      > p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #707B90;
      }
    }
  }
}