@import 'variables';

#filter-veil {
  display: block;
  position: absolute;
  width: calc(100% - 344px);
  left: 344px;
  top: 0;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: opacity 1s ease;
  z-index: 9;
  left: -100%;
  &.filtering {
    opacity: 0.25;
  }
  &.show {
    left: 344px;
  }
}

#lead-filter {
  background: #ffffff;
  box-shadow: none;
  border-left: 1px solid #e7ecf4;
  border-radius: 0 8px 8px 0;
  width: 300px;
  min-width: 300px;
  height: auto;
  max-height: 100%;
  min-height: 120px;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 34px;
  overflow: auto;
  transition: left 0.25s ease;
  display: flex;
  flex-direction: column;
  .filter-header {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      flex: 1;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: #0b1e41;
    }
    border-bottom: 1px solid #e6e9ef;
  }
  &.filtering {
    left: 344px;
  }
  .filter-container {
    flex: 1;
    overflow-y: scroll;
  }
  .filter-title {
    padding: 16px 24px 0 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0b1e41;
  }
  .filter-options {
    padding: 8px 0;
    border-bottom: 1px solid #e6e9ef;
    margin: 0 24px;
    &.field {
      padding: 16px 0;
    }
    &.no-bottom {
      border-bottom: 0;
    }
    .filter-option {
      font-size: 14px;
      line-height: 14px;
      color: #0b1e41;
      padding: 12px 16px;
      border-radius: 8px;
      cursor: pointer;
      position: relative;
      &:hover,
      &.active {
        background: #f7f8fa;
      }
      svg {
        display: none;
        color: #4353ff;
        position: absolute;
        right: 16px;
        font-size: 12px;
        top: 14px;
      }
      &.active {
        svg {
          display: block;
        }
      }
    }
    .ant-select-selection-selected-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      > span {
        font-weight: 400;
        font-size: 14px;
        color: #090e15;
        margin-left: 12px;
        b {
          font-weight: 600;
          display: inline-block;
          margin-right: 8px;
          font-style: normal;
        }
      }
      img.av-comp {
        right: -5px;
        bottom: 5px;
      }
    }
  }
  .filter-actions {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #e6e9ef;
    padding: 16px;
    button svg {
      margin-right: 8px;
    }
    .fa-left {
      flex: 1;
      text-align: left;
    }
    .fa-right {
      flex: 1;
      text-align: right;
    }
  }
  .sts {
    display: inline-block;
    background: grey;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    top: -1px;
    &.in-progress {
      background: $in-progress;
    }
    &.completed {
      background: $completed;
    }
    &.review {
      background: $review;
    }
    &.not-interested {
      background: $not-interested;
    }
    &.disputed {
      background: $disputed;
    }
  }
}
