@import '_seriph/variables';

.sxd-link {
  font-family: $font-major;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #090e15;
  cursor: pointer;
  border-bottom: 1px dashed #77839b;
  display: inline-block;
  &:hover {
    color: #4d5b76;
    border-bottom: 1px dashed #a7b3cb;
    text-decoration: none !important;
  }
}
