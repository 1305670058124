.import-mapping-modal {
  .ant-modal-body {
    padding: 0;
  }
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    padding: 24px 24px 0 24px;
  }
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4d5b76;
    margin-bottom: 32px;
    padding: 0 24px;
  }
  .horiz-map {
    display: flex;
    flex-direction: row;
    width: calc(100% - 24px);
    max-width: calc(100% - 24px);
    overflow-x: scroll;
    margin-left: 24px;
    padding: 0 0 24px 0;

    .horiz-box {
      background: #f7f8fa;
      border-radius: 8px;
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      padding: 16px 24px;
      margin-right: 16px;
      .ant-select {
        width: 100%;
      }
      .csv-data {
        margin-top: 24px;
        span {
          font-weight: 500;
        }
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .ant-select-selection-selected-value {
    text-transform: capitalize;
  }

  .horiz-map::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .horiz-map::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  .horiz-map::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: #bbc3d1;
  }
  .horiz-map::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }

  .map-actions {
    border-top: 1px solid #e6e9ef;
    padding: 24px 32px;
    margin-top: 32px;
    text-align: right;
    button {
      margin-left: 12px;
    }
  }
}

.mapping-forms {
  .ant-select-dropdown-menu-item {
    text-transform: capitalize;
  }
}
