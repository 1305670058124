@import '_seriph/variables';

.dialer-v3 {
  .ant-modal {
    width: 440px !important;
    min-width: 440px !important;
    max-width: 440px !important;
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important;
    padding-bottom: 0 !important;
  }
  .expand {
    position: absolute;
    right: 24px;
    top: calc(50% + 6px);
    transform: translateY(-50%);
    z-index: 999999999;
  }
  .seriph-modal-close, .seriph-modal-minimize {
    z-index: 99;
  }
  &.ready .ant-modal {
    width: calc(100% - 64px) !important;
    min-width: calc(880px - 64px) !important;
    max-width: calc(1280px - 64px) !important;
    height: calc(100% - 64px) !important;
    min-height: 640px !important;
    max-height: 768px !important;
  }
  .ant-modal-body {
    height: 100%;
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    min-height: 240px;
  }
  .ant-modal-content {
    overflow: hidden;
    height: 100% !important;
  }


  &.manual {
    width: 100%;
    min-width: calc(880px - 32px) !important;
    max-width: calc(1140px - 32px) !important;
    height: 104px !important;
    min-height: 132px !important;
    max-height: 132px !important;
    left: 50%;
    transform: translateX(-50%);
    height: auto !important;
    bottom: 0 !important;
    top: auto !important;
    overflow: visible !important;
    .seriph-modal-close, .seriph-modal-minimize, .cw-left, .caller-window > #call-information, .settings-toggle {
      display: none !important;
    }
    .ant-modal-content, .ant-modal {
      height: 104px !important;
      min-height: 104px !important;
      max-height: 104px !important;
    }
    #call-bar {
      bottom: auto;
      top: 0 !important;
    }
    .ant-modal-body {
      min-height: 104px !important;
    }
    #call-bar .bf {
      padding-right: 64px;
    }
    .infover {
      position: fixed !important;
      left: calc(50%) !important;
      bottom: 78px !important;
      z-index: 999 !important;
      &.visible {
        bottom: 148px !important;
      }
    }
  }

  &.hide-settings {
    .cw-left {
      display: none !important;
    }
  }


}