@import '_seriph/variables';

#company-round-robin {
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  #waveform {
    display: none;
  }
  .dashboard-actions {
    display: block;
    border-bottom: 1px solid #e7ecf4;
    padding-bottom: 4px;
    margin-bottom: 16px;
    > div {
      display: inline-block;
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }
  .sequence-content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 32px;
    position: relative;
    .sequence-wrap {
      width: 100%;
      padding: 24px 0;
      max-width: 1256px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .subheader {
    margin-bottom: 24px;
    font-family: $font-major;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #4d5b76;
  }
  .seriph-tabs {
    margin-bottom: 32px;
  }
  .seriph-header-wrapper {
    margin-bottom: 12px;
  }
  .sequence-table {
    border-top: 1px solid #e7ecf4;
    padding-top: 16px;
    .actions-cont {
      display: flex;
      align-items: center;
      gap: 20px;
      background: #ffffff;
      .play-section {
        svg {
          color: #d7dde9;
        }
      }
      .call-button-placeholder {
        height: 32px;
        width: 32px;
      }
      .tbl-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: transparent;
        border-radius: 100px;
        cursor: pointer;
        &:hover {
          background: #e7ecf4;
        }
      }
    }
    .sequence-actions {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: #f5f7fa;
      }
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 2.5;
      }
    }
    .sequence-box {
      h4 {
        display: flex;
        align-items: center;
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #090e15;
        margin-bottom: 2px;
        > span {
          background: #fff;
          height: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-family: $font-major;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          color: #090e15;
          border-radius: 12px;
          padding: 0 10px;
          margin-left: 16px;
          border: 2px solid #d7dde9;
          &.active {
            border-color: #c1ddbb;
            background: #c1ddbb;
          }
        }
      }
      p {
        font-family: $font-major;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #4d5b76;
        margin: 0;
      }
    }
  }
  .header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      margin-left: 12px;
    }
  }
  .agent-picture {
    display: flex;
    flex-direction: row;
    align-items: center;
    .agent-info {
      margin-left: 12px;
      h4 {
        margin: 0;
        font-family: $font-major;
        font-weight: 500;
        color: #090e15;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
      p {
        margin: 0;
        font-family: $font-major;
        font-weight: 400;
        color: #707B90;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
    img {
      width: 100%;
    }
  }
  .seq-actions button {
    visibility: hidden;
    pointer-events: none;
    transition: all 0s linear !important;
  }
  .ant-table-row-hover {
    td > .seq-actions button {
      pointer-events: auto !important;
      visibility: visible !important;
    }
  }
}
