@import '_seriph/variables';

.brief-listing {
  .explore-page {
    .listing-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px 24px 16px 24px;
      .listing-info {
        margin-left: 16px;
        .name-cat {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #707b90;
        }
      }
    }
    .company-information {
      flex: 1;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #4d5b76;
        padding: 0px 24px 16px 24px;
        margin: 0;
        a {
          margin-left: 8px;
          font-weight: 500;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      > h3 {
        padding: 0px 24px 8px 24px;
      }
      .skills {
        background: #f8f9fc;
        padding: 12px 24px;
        position: relative;
        .cmp-tstamp {
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #707b90;
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);
        }
        .skill-label {
          background-color: #f5f7fa;
          border-radius: 5px;
          display: inline-block;
          margin-right: 10px;
          padding: 5px;
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 15px;
          font-weight: 500;
          color: #090e15;
          cursor: pointer;
          &:hover {
            background-color: #e5e7ea;
          }
        }
        .objective,
        span {
          height: 24px;
          line-height: 24px;
          border-radius: 12px;
          display: inline-block;
          font-family: $font-major;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #090e15;
          &.generate {
            background: #a9e3ff;
          }
          &.qualify {
            background: #b5faaa;
          }
        }
        .objective {
          font-weight: 500;
          color: #090e15;
        }
        .info {
          display: inline-block;
          font-size: 12px;
          font-weight: 500;
          color: #090e15;
        }
        .objective svg,
        .info svg {
          color: #090e15;
          width: 12px;
          height: 12px;
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
      &.agent-explore .skills {
        background: #fff !important;
      }
    }
  }
  .company-actions {
    position: absolute;
    top: 20px;
    right: 20px;
    .star {
      cursor: pointer;
      margin-right: 15px;
      svg {
        color: #000;
        font-size: 16px;
        &.solid {
          color: #ffc900;
        }
        &:hover {
          color: #ffc900;
          fill: #ffc900;
        }
      }
    }
    button {
      text-transform: capitalize;
      min-width: 88px;
      svg {
        color: #4353ff;
      }
    }
  }
  .sellx-card {
    margin-bottom: 20px;
  }
}
