@import '_seriph/variables';

#lead-new-email {
  padding: 32px 0;
  .alert-wrapper {
    position: absolute;
    top: 40px;
    padding: 0 40px;
    width: 100%;
  }

  .compose-new {
    margin-bottom: 16px;
    text-align: right;
  }
  .sx-empty {
    margin-top: 96px;
  }

  .thread-body {
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    &:hover {
      border-color: #d7dde9;
    }
    .seriph-avatar {
      img {
        margin-right: 0;
      }
      .character {
        background: #d2d0fb;
        color: #090e15;
      }
    }
    .tagged {
      margin-right: 12px;
      > div {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e6f3fc;
        border-radius: 48px;
        height: 24px;
        font-family: Visuelt;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #090e15;
        text-transform: capitalize;
        &.opened {
          background: #c1ddbb;
        }
        &.unread,
        &.reply {
          background: #090e15;
          color: #fff;
        }
        &.bounced {
          background: #efbba9;
        }
        &.delivered {
          background: #b0d0e8;
        }
      }
    }
    .thread-members {
      font-family: $font-major;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #707b90;
    }
    .thread-content {
      min-width: 0;
      flex: 1;
      padding-right: 48px;
      padding-left: 12px;
      .thread-subject {
        font-family: $font-major;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090e15;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        > b {
          padding-left: 12px;
          font-weight: normal;

          color: #4d5b76;
        }
      }
    }
    &.unread {
      .thread-subject b {
        color: #090e15;
        font-weight: 500;
      }
      .thread-members {
        color: #090e15;
        font-weight: 500;
      }
    }
  }
}
